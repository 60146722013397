<template>
  <div id="main-wrap">
    <StrategyRegisterModal
      v-if="isShowRegisterModal"
      @close="isShowRegisterModal = false"
    />
    <div class="main-banner-section">
      <SliderPhotoBox :list="mainBanner" type="banner" />
    </div>
    <TabBox
      :myPortfolioList="myPortfolioList"
      :threeMonthProfitPortfolioTopList="threeMonthProfitPortfolioTopList"
      :latestPortfolioList="latestPortfolioList"
      @click:added="openAddStrategy"
      :key="tabBoxKey"
    />
    <div class="bannr-section">
      <div class="bannr-image-box">
        <img
          src="@/assets/images/bannr_main_01.svg"
          alt=""
          @click="openStrategy"
        />
      </div>
    </div>
    <div class="slider-section mb-16 shopping-list">
      <SliderContents :list="shoppingList" type="shopping">
        <template #header>
          <div class="flex-just cont-head mb-14" @click="goShopping">
            <h4>쇼핑</h4>
            <i class="icon--arrow-move" @click="goShopping"></i>
          </div>
        </template>
      </SliderContents>
    </div>
    <div class="bannr-section">
      <div class="bannr-image-box" @click="goShopping">
        <img src="@/assets/images/bannr_main_02.svg" alt="" />
      </div>
    </div>
    <div class="box-section mb-48">
      <div class="cont-box">
        <div class="flex-just cont-box-head">
          <h4>인기글</h4>
          <i class="icon--arrow-move" @click="goLounge(0)"></i>
        </div>
        <ul class="popular-list">
          <li
            v-for="(
              {
                loungeId,
                storageAccess,
                loungeTitle,
                loungeContents,
                storageAccesses
              },
              i
            ) in mainPopularList"
            :key="i + 'po'"
            @click="goLounge(loungeId)"
          >
            <div class="popular-prof">
              <img :src="storageAccess" alt="" />
            </div>
            <div class="popular-desc no-wrap-text">
              <p class="q-txt no-wrap-text">{{ loungeTitle }}</p>
              <span class="q-sub-txt">{{ loungeContents }}</span>
            </div>
            <div class="popular-thumb">
              <img :src="storageAccesses[0]" alt="" v-if="storageAccesses[0]" />
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="slider-section mb-48">
      <SliderContents :list="comList" type="rect">
        <template #header>
          <div class="flex-just cont-head mb-20">
            <h4>추천 커뮤니티</h4>
          </div>
        </template>
      </SliderContents>
    </div>
    <div class="slider-section mb-48">
      <SliderContents :list="rankList" type="rank">
        <template #header>
          <div class="flex-just cont-head mb-20" @click="goRank">
            <h4>이번달 수익률 배틀! TOP5</h4>
            <i class="icon--arrow-move" @click="goRank"></i>
          </div>
        </template>
      </SliderContents>
    </div>
    <div class="slider-section mb-16">
      <SliderContents :list="roungeList" type="rounge">
        <template #header>
          <div class="flex-just cont-head" @click="goLounge(0)">
            <h4>라운지</h4>
            <i class="icon--arrow-move" @click="goLounge(0)"></i>
          </div>
        </template>
      </SliderContents>
    </div>
    <div class="slider-section">
      <SliderContents :list="portList" type="port">
        <template #header>
          <div class="flex-just cont-head pb-16">
            <h4>더 많은 포트폴리오가 보고싶나요?</h4>
          </div>
        </template>
      </SliderContents>
    </div>
  </div>
</template>

<script>
import TabBox from '@/components/common/TabBox.vue'
import SliderContents from '@/components/common/SliderContents.vue'
import SliderPhotoBox from '@/components/common/SliderPhotoBox.vue'
import { axiosAuthInstance } from '@/store/axios'
import StrategyRegisterModal from '@/components/common/modal/StrategyRegisterModal.vue'

export default {
  name: 'Main',
  // components: { TabBox, SliderContents, SliderPhotoBox, StrategyRegisterModal },
  components: { StrategyRegisterModal, TabBox, SliderContents, SliderPhotoBox },
  data() {
    return {
      tabBoxKey: 0,
      mainBanner: [
        {
          bannerImage: 'main/main_banner_1'
        },
        {
          bannerImage: 'main/main_banner_2'
        }
      ],
      shoppingList: [],
      mainPopularList: [],
      comList: [],
      rankList: [],
      roungeList: [],
      portList: [],
      // toggleAddStrategy: false,
      myPortfolioList: {},
      threeMonthProfitPortfolioTopList: {},
      latestPortfolioList: {},
      isShowRegisterModal: false
    }
  },
  created() {
    axiosAuthInstance
      .get('/main', {})
      .then((response) => {
        this.shoppingList = response.latestProductList
        this.mainPopularList = response.popularLoungeTopList
        this.comList = response.recommendedCommunityTopList
        this.rankList = response.monthProfitPortfolioTopList
        this.roungeList = response.latestLoungeList
        this.portList = response.latestPortfolioTopList

        if (response.myPortfolioList) {
          this.myPortfolioList = response.myPortfolioList
        }
        if (response.threeMonthProfitPortfolioTopList) {
          this.threeMonthProfitPortfolioTopList =
            response.threeMonthProfitPortfolioTopList
        }
        if (response.latestPortfolioList) {
          this.latestPortfolioList = response.latestPortfolioList
        }
        this.tabBoxKey++
      })
      .catch((error) => {
        console.log('error', error)
      })
  },
  computed: {
    isAddStrategy: {
      get() {
        return this.$store.getters.isAddStrategy
      },
      set(isAddStrategy) {
        this.$store.commit('toggleAddStrategy', { isAddStrategy })
      }
    }
  },
  methods: {
    openAddStrategy() {
      if (this.user.isUser === false) {
        this.$router.push({ name: 'Login' })
      } else {
        this.toggleAddStrategy = true
      }
    },
    goShopping() {
      this.$router.push({ name: 'Shopping' })
    },
    goCommunity() {
      this.$router.push({ name: 'Community' })
    },
    goRank() {
      this.$router.push({ name: 'Ranking' })
    },
    goLounge(loungeId) {
      if (loungeId > 0) {
        this.$router
          .push({
            name: 'RoungeDetail',
            query: { loungeId: loungeId }
          })
          .catch(() => {})
      } else {
        this.$router.push({ name: 'Rounge' })
      }
    },
    openStrategy() {
      this.isShowRegisterModal = true
    }
  }
}
</script>

<style lang="scss" scoped>
#main-wrap {
  padding: 8px 16px 56px;
  .box-section {
    .cont-box {
      border-radius: 14px;
      background: #fff;
      box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.14);
      padding: 32px 20px;
      .cont-box-head {
        margin-bottom: 22px;
      }
    }
    .popular-list {
      display: flex;
      flex-direction: column;
      gap: 20px;
      > li {
        display: flex;
        .popular-prof,
        .popular-thumb {
          flex-shrink: 0;
          overflow: hidden;
          width: 40px;
          height: 40px;
          > img {
            width: 100%;
            height: 100%;
          }
        }
        .popular-desc {
          flex: 1;
          display: flex;
          flex-direction: column;
          gap: 4px;
        }
        .popular-prof {
          border-radius: 50px;
          margin-right: 14px;
        }
        .popular-thumb {
          margin-left: 16px;
          border-radius: 6px;
        }
      }
    }
  }
  .main-banner-section {
    position: relative;
    overflow: hidden;
    border-radius: 14px;
    width: 100%;
    // height: 170px;
    padding-top: 52%;
    background-color: red;
  }
  .bannr-section {
    margin: 24px 0;
    img {
      display: inline-block;
      width: 100%;
    }
  }
}
</style>
