var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mypage" }, [
    _c("h1", { staticClass: "mypage-title" }, [_vm._v("마이페이지")]),
    _c(
      "div",
      {
        staticClass: "mypage-profile",
        on: {
          click: function ($event) {
            return _vm.$router.push({ name: "Profile" })
          },
        },
      },
      [
        _c("figure", {
          staticClass: "mypage-profile-user-image",
          style: {
            backgroundImage: "url(" + _vm.user.storageAccess + ")",
          },
        }),
        _c("span", { staticClass: "mypage-profile-user-name" }, [
          _vm._v(_vm._s(_vm.user.nickName)),
        ]),
      ]
    ),
    _c(
      "ul",
      { staticClass: "mypage-list" },
      _vm._l(_vm.menuList, function (item) {
        return _c(
          "li",
          {
            key: item.id,
            staticClass: "mypage-list-item",
            on: {
              click: function ($event) {
                return _vm.moveToMenuPage(item.path)
              },
            },
          },
          [
            _c("i", {
              staticClass: "icon--mypage-menu mr-8",
              class: item.icon,
            }),
            _c("span", [_vm._v(_vm._s(item.text))]),
            _c("span", { staticClass: "mypage-list-item-btn" }, [
              _c(
                "svg",
                {
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    width: "14",
                    height: "20",
                    viewBox: "0 0 14 20",
                    fill: "none",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      "fill-rule": "evenodd",
                      "clip-rule": "evenodd",
                      d: "M4.29289 14.7071C4.68342 15.0976 5.31658 15.0976 5.70711 14.7071L9.70711 10.7071C10.0976 10.3166 10.0976 9.68342 9.70711 9.29289L5.70711 5.29289C5.31658 4.90237 4.68342 4.90237 4.29289 5.29289C3.90237 5.68342 3.90237 6.31658 4.29289 6.70711L7.58579 10L4.29289 13.2929C3.90237 13.6834 3.90237 14.3166 4.29289 14.7071Z",
                      fill: "#BFBFBF",
                    },
                  }),
                ]
              ),
            ]),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }