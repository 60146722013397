// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#join-wrap[data-v-e0dde682] {
  display: flex;
  flex-direction: column;
}
#join-wrap .join-head[data-v-e0dde682] {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#join-wrap .join-head > h4[data-v-e0dde682] {
  margin: 10px 0 12px;
  text-align: center;
}
#join-wrap .join-head > p[data-v-e0dde682] {
  text-align: center;
}
#join-wrap .round-box[data-v-e0dde682] {
  border-radius: 14px;
  background: #f7f7f7;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 16px;
  margin-top: 40px;
}
#join-wrap .round-box .input-tilte[data-v-e0dde682] {
  color: #666;
  font-size: 12px;
  font-weight: 500;
  width: 100%;
  margin: 24px 0 8px;
}
#join-wrap .round-box .round-input[data-v-e0dde682] {
  display: flex;
  position: relative;
  width: 100%;
  border-radius: 14px;
  border: 1px solid #e8e8e8;
  background: #fff;
  justify-content: space-between;
  align-items: center;
  padding: 17px 14px;
}
#join-wrap > .base-btn.full[data-v-e0dde682] {
  margin-top: auto;
}
#join-wrap .send-modal-body[data-v-e0dde682] {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr 1fr;
  gap: 12px;
}
#join-wrap .send-modal-body input[data-v-e0dde682] {
  display: none;
}
#join-wrap .send-modal-body > li[data-v-e0dde682] {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f1f1f1;
  border-radius: 50%;
  aspect-ratio: 1/1;
  position: relative;
}
#join-wrap .send-modal-body > li.on[data-v-e0dde682] {
  border: 2px solid #4a4ee7;
  box-sizing: border-box;
}
#join-wrap .send-modal-body > li > i[data-v-e0dde682] {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
#join-wrap .send-modal-body .modal-btn-wrap[data-v-e0dde682] {
  display: flex;
  margin-top: 48px;
  gap: 8px;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
