// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sort-box[data-v-347e9885] {
  position: relative;
}
.sort-box button[data-v-347e9885] {
  display: flex;
  align-items: center;
  color: #666;
}
.sort-box .select-wrap[data-v-347e9885] {
  position: absolute;
  width: 114px;
  right: 14px;
  top: 100%;
  border-radius: 8px;
  border: 1px solid #e8e8e8;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px 16px;
}
.sort-box .select-wrap .on[data-v-347e9885] {
  font-weight: 500;
  color: #222;
}
.slide-box[data-v-347e9885] {
  position: relative;
  aspect-ratio: 15/9;
}
.slide-box .inner-txt[data-v-347e9885] {
  position: absolute;
  left: 16px;
  bottom: 24px;
}
.slider-section[data-v-347e9885] {
  padding-left: 16px;
}
.tab-list[data-v-347e9885] {
  display: flex;
  gap: 8px;
  padding: 0 16px;
}
.tab-list li[data-v-347e9885] {
  display: flex;
  height: 42px;
  padding: 8px 17px;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid #e8e8e8;
}
.tab-list li.on[data-v-347e9885] {
  border: 1px solid #4a4ee7;
  color: #4a4ee7;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
