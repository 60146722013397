<template>
  <div class="step01">
    <div class="page-title-section">
      <h4>
        {{ styleNm }} 포트폴리오를 <br />
        <strong>더 멋지게</strong> 만들어주세요.
      </h4>
    </div>
    <div class="chart-box">
      <RadarChart :data="radarData" ref="childRadarRef"></RadarChart>
    </div>
    <div
      class="round-box mb-8"
      v-for="(info, index) in etcList"
      :key="index + 'hist'"
    >
      <div class="type-tit range-tit mb-0">
        {{ info.indicatorName }}
        <i class="icon--q" @click="openQuestionModal(info)"></i>
        <div class="toggle-btn-wrap">
          <ToggleBtn
            :info="info"
            :data="info.isData"
            @updated="toggleActiveBox02"
          ></ToggleBtn>
          <i
            class="icon--arrow-down"
            @click="info.isToggleBox = !info.isToggleBox"
          ></i>
        </div>
      </div>
      <div v-if="info.isToggleBox" :class="{ off: !info.isData }">
        <p class="mt-8">
          {{ info.description }}
        </p>
        <p class="current-status-tit mt-24">
          {{ sortOrderName(info.sortOrderAsc)
          }}<strong>{{ info.value }}%</strong>
        </p>
        <div class="range-slide-box">
          <div class="slide-mark">
            <span>10</span>
            <span>50</span>
            <span>100</span>
          </div>
          <vue-slider
            v-model="info.value"
            dot-size="34"
            tooltip="none"
            :min="10"
            :max="90"
            :interval="10"
            :drag-on-click="true"
            :disabled="!info.isData"
            @change="sliderChange(info)"
          ></vue-slider>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
import ToggleBtn from '@/components/button/ToggleButton'
import calculateValues from '@/util/calculateValues'
import RadarChart from '@/components/common/RadarChart.vue'

export default {
  name: 'Step02',
  components: {
    RadarChart,
    VueSlider,
    ToggleBtn
  },
  data() {
    return {
      etcList: [],
      questionTitle: '',
      questionDesc: '',
      radarData: [5, 5, 5, 5, 5, 5],
      styleNm: ''
    }
  },
  created() {
    let portfolioStyledList = this.$store.getters.getAddType.list
    portfolioStyledList = portfolioStyledList
      .filter((info) => {
        return info.styleSecondaryType === 'ETC'
      })
      .map((data) => ({
        ...data,
        isToggleBox: false
      }))

    this.etcList = portfolioStyledList

    const styleType = this.$store.getters.getAddType.styleType
    this.styleNmConverter(styleType)
  },
  mounted() {
    let portfolioList = this.$store.getters.getAddType.list
    this.updateRadar(portfolioList)
  },
  computed: {
    data: {
      get() {
        return this.$store.getters.getAddType.ROA
      },
      set(ROA) {
        if (ROA <= 0) {
          ROA = 1
        }
        this.$store.commit('SET_STEP', { ROA })
      }
    },
    data2: {
      get() {
        return this.$store.getters.getAddType.PSR
      },
      set(PSR) {
        if (PSR <= 0) {
          PSR = 1
        }
        this.$store.commit('SET_STEP', { PSR })
      }
    },
    isData: {
      get() {
        return this.$store.getters.getAddType.isROA
      },
      set(isROA) {
        this.$store.commit('SET_STEP', { isROA })
      }
    },
    isData2: {
      get() {
        return this.$store.getters.getAddType.isPSR
      },
      set(isPSR) {
        this.$store.commit('SET_STEP', { isPSR })
      }
    },
    currentQuestion() {
      let result = {
        title: this.questionTitle,
        desc: this.questionDesc
      }

      return result
    }
  },
  methods: {
    toggleActiveBox01(data) {
      this.isData = data
    },
    toggleActiveBox02(info, isData) {
      info.isData = isData
      let portfolioList = this.$store.getters.getAddType.list
      for (let index in portfolioList) {
        if (portfolioList[index].styleId == info.styleId) {
          portfolioList[index].isData = isData
        }
      }
      this.$store.commit('SET_STEP', { list: portfolioList })
      this.updateRadar(portfolioList)
    },
    sortOrderName(sortOrderAsc) {
      if (sortOrderAsc) {
        return '상위'
      } else {
        return '하위'
      }
    },
    openQuestionModal(info) {
      this.$Simplert.open({
        title: info.indicatorName,
        message: `<div id="strategy-add-description">${info.descriptionDetail}</div>`
      })
    },
    sliderChange(info) {
      if (info.value <= 9) {
        info.value = 10
      }

      if (info.value >= 90) {
        info.value = 90
      }

      let portfolioList = this.$store.getters.getAddType.list
      for (let index in portfolioList) {
        if (portfolioList[index].styleId === info.styleId) {
          portfolioList[index].value = info.value
        }
      }
      this.$store.commit('SET_STEP', { list: portfolioList })

      this.updateRadar(portfolioList)
    },
    updateRadar(portfolioList) {
      const factors = {}
      portfolioList
        .filter((info) => {
          return info.isData === true
        })
        .forEach((info) => {
          factors[info.indicatorCode] = info.value / 100
        })
      const a = calculateValues.calculateValues(factors)
      this.radarData[0] = Number(a.growth)
      this.radarData[1] = Number(a.value)
      this.radarData[2] = Number(a.momentum)
      this.radarData[3] = Number(a.dividend)
      this.radarData[4] = Number(a.lowvol)
      this.radarData[5] = Number(a.quality)

      this.$refs.childRadarRef.forceRender()
    },
    styleNmConverter(styleType) {
      switch (styleType) {
        case 'GROWTH':
          this.styleNm = '성장형'
          break
        case 'VALUE':
          this.styleNm = '가치형'
          break
        case 'MOMENTUM':
          this.styleNm = '모멘텀'
          break
        case 'DIVIDEND':
          this.styleNm = '배당형'
          break
        case 'LOWVOL':
          this.styleNm = '안정형'
          break
        case 'QUALITY':
          this.styleNm = '우량형'
          break
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.current-status-tit {
  color: #222;

  font-family: Pretendard;
  font-size: 18px;
  strong {
    color: #d74343;
  }
}
.off * {
  color: #bfbfbf !important;
}
.range-slide-box {
  .slide-mark {
    > span {
      color: #999;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 12px */
      letter-spacing: -0.24px;
      min-width: 24px;
      text-align: center;
    }
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }
}
::v-deep {
  .vue-slider-rail {
    background: #e8e8e8;
  }
  .vue-slider-process {
    background-color: #444444;
  }
  .vue-slider-dot-handle {
    background-color: #fff;
    stroke-width: 1px;
    border: 1px solid #e8e8e8;
    filter: drop-shadow(1px 4px 9px rgba(0, 0, 0, 0.12));
  }
}
</style>
