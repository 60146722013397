<template>
  <div class="product-box">
    <div
      class="thumb"
      :class="{
        'badge-edtion': data.state === 'edtion',
        'badge-rec': data.recommendationStatus === 'ACTIVE',
        'badge-discount': data.discountApplied
      }"
      @click="goShoppingDetail(data.productId)"
    >
      <span class="badge" :class="{ edtion: true }"></span
      ><img :src="data.storageAccesses[0]" alt="" />
    </div>
    <div class="r-box">
      <p class="name mb-2">{{ data.portfolioName }}</p>
      <p class="title no-wrap-text-2">{{ data.productInformation }}</p>
      <strong class="price"
        ><b class="discount"
          >{{
            discountRateCalculation(
              data.discountValue,
              data.price,
              data.discountApplicationMethod
            )
          }}%</b
        >{{ Number(data.salePrice).toLocaleString() }}원</strong
      >
      <p class="before-info">
        <span>
          <span class="before-price">
            {{ Number(data.price).toLocaleString() }}원
          </span>
          ({{ data.period }}개월)
        </span>
      </p>
      <p class="recent-percent">
        <span>최근3개월</span><span>|</span>
        <span
          class="percent"
          :class="radarIsDown(data.rorPercent3m) ? 'isDown' : ''"
          >{{ rorPercentConvert(data.rorPercent3m) }}%</span
        >
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QuantMarketProduct',
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    type: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  },

  mounted() {},

  methods: {
    goShoppingDetail(productId) {
      this.$router.push({
        name: 'ProductDetail',
        query: { productId: productId }
      })
    },
    discountRateCalculation(discountValue, price, discountApplicationMethod) {
      if (discountApplicationMethod === 'FIXED_PRICE') {
        const discountPrice = (discountValue / price) * 100
        return discountPrice.toFixed(2)
      } else {
        return discountValue
      }
    },
    radarIsDown(rorPercent) {
      return rorPercent < 0
    },
    rorPercentConvert(rorPercent) {
      return rorPercent >= 0 ? '+' + rorPercent : rorPercent
    }
  }
}
</script>

<style lang="scss" scoped>
.product-box {
  display: flex;
  gap: 16px;
  padding: 16px;
  border-top: 1px solid #e8e8e8;
  .r-box {
    display: flex;
    flex-direction: column;
  }
  .name {
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #444;
  }
  .thumb {
    width: 100px;
    height: 100px;
    flex-shrink: 0;
    border-radius: 8px;
    background-color: #d9d9d9;
    overflow: hidden;
    position: relative;
    img {
      display: inline-block;
      width: 100%;
      height: 100%;
    }
    &::before {
      position: absolute;
      left: 4px;
      top: 4px;
      font-size: 10px;
    }
    &.badge-rec::before {
      content: '추천';
      color: #fff;
      width: 26px;
      height: 18px;
      flex-shrink: 0;
      background-color: #4a4ee7;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &.badge-edtion::before {
      content: '한정';
      color: #fff;
      width: 26px;
      height: 18px;
      flex-shrink: 0;
      background-color: #8f4ae7;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &.badge-dis::before {
      content: '할인';
      color: #fff;
      width: 26px;
      height: 18px;
      flex-shrink: 0;
      background-color: #d74343;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .title {
    color: #666;
    font-size: 12px;
    line-height: 140%;
    margin-bottom: 5px;
  }
  .price {
    font-size: 16px;
    font-weight: 600;
    color: #222;
    .discount {
      color: #d74343;
      font-size: 16px;
      font-weight: 600;
    }
  }
  .before-info {
    span {
      color: #999;
      font-size: 10px;
    }
    .before-price {
      text-decoration: line-through;
    }
  }
  .recent-percent {
    margin-top: auto;
    display: flex;
    align-items: center;
    gap: 4px;
    span {
      font-size: 10px;
      color: #666;
    }
    .percent {
      color: #d74343;
      font-weight: 600;
    }
    .isDown {
      color: #4882f5;
    }
  }
}
.product-box.row {
  display: flex;
  flex-direction: row;
  // gap: ;
}
</style>
