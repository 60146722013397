var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "label",
    { staticClass: "switch", class: { on: _vm.isOn, big: _vm.isBig } },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.isOn,
            expression: "isOn",
          },
        ],
        attrs: { type: "checkbox" },
        domProps: {
          checked: Array.isArray(_vm.isOn)
            ? _vm._i(_vm.isOn, null) > -1
            : _vm.isOn,
        },
        on: {
          click: _vm.toggleCheckbox,
          change: function ($event) {
            var $$a = _vm.isOn,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = null,
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && (_vm.isOn = $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  (_vm.isOn = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
              }
            } else {
              _vm.isOn = $$c
            }
          },
        },
      }),
      _c("div", { staticClass: "slider" }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }