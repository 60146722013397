var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "radar-chart-wrapper",
      class: { small: _vm.size === "S" },
      style: _vm.bindStyled,
    },
    [
      _vm._l(_vm.categories, function (d, i) {
        return _c("span", { key: i + "label" }, [_vm._v(_vm._s(d))])
      }),
      _c("apexchart", {
        key: _vm.componentKey,
        attrs: {
          type: "radar",
          options: _vm.chartOption,
          series: _vm.chartSeries,
          width: _vm.chartSize.width,
          height: _vm.chartSize.height,
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }