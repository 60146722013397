<template>
  <!-- TODO tab AddStrategy01 -->
  <div class="filter-wrap">
    <swiper slides-per-view="auto" :space-between="8" :loop="false">
      <swiper-slide v-for="(data, idx) in list" :key="idx">
        <div
          class="filter-tab"
          :class="{ on: tab === data.id }"
          @click="$emit('tab', data.id)"
        >
          {{ data.text }}
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper-vue2'
export default {
  name: 'QuantMarketFilterTabList',

  components: { Swiper, SwiperSlide },
  data() {
    return {}
  },
  props: {
    list: {
      type: Array,
      default: () => {
        return [{ text: '시황', id: 0 }]
      }
    },
    tab: {
      type: [String, String],
      default: ''
    }
  },

  mounted() {},

  methods: {}
}
</script>

<style lang="scss" scoped>
.swiper-container {
  margin: 0;
}
.swiper-slide {
  flex-shrink: 1;
}
.filter-tab {
  height: 42px;
  width: 90px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #999;
  border-radius: 10px;
  border: 1px solid #e8e8e8;
  &.on {
    border: 1px solid #4a4ee7;
    color: #4a4ee7;
  }
}
</style>
