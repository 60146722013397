// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tab-box[data-v-9795a44c] {
  margin-top: 24px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.14);
  overflow: hidden;
}
.tab-box .current-tab-pannel[data-v-9795a44c] {
  padding: 24px 20px;
}
.tab-box > ul[data-v-9795a44c] {
  background-color: #f1f1f1;
}
.tab-box .navbar[data-v-9795a44c] {
  height: auto;
  width: 100%;
  background: #f1f1f1;
}
.tab-box .navbar ul[data-v-9795a44c] {
  display: flex;
}
.tab-box .navbar ul li[data-v-9795a44c] {
  flex: 1;
  display: inline;
  font-size: 1rem;
  border-radius: 14px 14px 0px 0px;
  position: relative;
}
.tab-box .navbar ul li > a[data-v-9795a44c] {
  display: inline-block;
  padding: 11px 0;
  text-align: center;
  width: 100%;
}
.tab-box .navbar ul li.on[data-v-9795a44c] {
  background: #fff;
}
.tab-box .navbar ul li a[data-v-9795a44c] {
  text-decoration: none;
  color: #999;
  font-size: 12px;
}
.tab-box .navbar ul li.on a[data-v-9795a44c] {
  color: #222;
  font-weight: 600;
}
.tab-box .navbar ul li b.left-curve[data-v-9795a44c] {
  position: absolute;
  bottom: 0px;
  left: -15px;
  height: 100%;
  width: 15px;
  background: #fff;
  display: none;
}
.tab-box .navbar ul li b.left-curve[data-v-9795a44c]::before {
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  border-bottom-right-radius: 14px;
  background: #f1f1f1;
}
.tab-box .navbar ul li b.right-curve[data-v-9795a44c] {
  position: absolute;
  right: -15px;
  top: 0px;
  height: 100%;
  width: 15px;
  background: #fff;
  display: none;
}
.tab-box .navbar ul li b.right-curve[data-v-9795a44c]::before {
  content: "";
  right: 0;
  position: absolute;
  width: 100%;
  top: 0;
  height: 100%;
  border-bottom-left-radius: 14px;
  background: #f1f1f1;
}
.tab-box .navbar ul li.on b.left-curve[data-v-9795a44c],
.tab-box .navbar ul li.on b.right-curve[data-v-9795a44c] {
  display: block;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
