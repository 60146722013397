// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.product-box[data-v-2869353b] {
  display: flex;
  flex-direction: column;
  width: 132px;
  overflow: hidden;
}
.product-box .thumb[data-v-2869353b] {
  width: 132px;
  height: 132px;
  border-radius: 8px;
  background-color: #d9d9d9;
  overflow: hidden;
  position: relative;
}
.product-box .thumb img[data-v-2869353b] {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.product-box .thumb[data-v-2869353b]::before {
  position: absolute;
  left: 4px;
  top: 4px;
  font-size: 10px;
}
.product-box .thumb.badge-rec[data-v-2869353b]::before {
  content: "추천";
  color: #fff;
  width: 26px;
  height: 18px;
  flex-shrink: 0;
  background-color: #4a4ee7;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.product-box .thumb.badge-edtion[data-v-2869353b]::before {
  content: "한정";
  color: #fff;
  width: 26px;
  height: 18px;
  flex-shrink: 0;
  background-color: #8f4ae7;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.product-box .thumb.badge-dis[data-v-2869353b]::before {
  content: "할인";
  color: #fff;
  width: 26px;
  height: 18px;
  flex-shrink: 0;
  background-color: #d74343;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.product-box .title[data-v-2869353b] {
  color: #666;
  font-size: 12px;
  line-height: 140%;
  margin-bottom: 5px;
}
.product-box .price[data-v-2869353b] {
  font-size: 16px;
  font-weight: 600;
  color: #222;
}
.product-box .price .discount[data-v-2869353b] {
  color: #d74343;
  font-size: 16px;
  font-weight: 600;
}
.product-box .before-info span[data-v-2869353b] {
  color: #999;
  font-size: 10px;
}
.product-box .before-info .before-price[data-v-2869353b] {
  text-decoration: line-through;
}
.product-box .recent-percent[data-v-2869353b] {
  display: flex;
  align-items: center;
  gap: 4px;
}
.product-box .recent-percent span[data-v-2869353b] {
  font-size: 10px;
  color: #666;
}
.product-box .recent-percent .percent[data-v-2869353b] {
  color: #d74343;
  font-weight: 600;
}
.product-box .recent-percent .isDown[data-v-2869353b] {
  color: #4882f5;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
