var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "thumb-wrap" },
    [
      _vm.thumb.length > 0
        ? _c(
            "div",
            {
              staticClass: "thumb-box",
              on: {
                click: function ($event) {
                  _vm.toggleImageModal = true
                },
              },
            },
            [
              _c("div", [
                _c("div", {
                  style: {
                    "background-image": `url(${_vm.thumb[0]})`,
                  },
                  attrs: { alt: "" },
                }),
              ]),
              _vm.thumb.length > 1
                ? _c("div", [
                    _c(
                      "div",
                      {
                        style: {
                          "background-image": `url(${_vm.thumb[1]})`,
                        },
                        attrs: { alt: "" },
                      },
                      [
                        _c("span", { staticClass: "more-no" }, [
                          _vm._v(
                            _vm._s(
                              _vm.thumb.length > 2
                                ? `+${_vm.thumb.length - 2}`
                                : ""
                            )
                          ),
                        ]),
                      ]
                    ),
                  ])
                : _vm._e(),
            ]
          )
        : _c(
            "div",
            {
              staticClass: "one-thumb-box",
              on: {
                click: function ($event) {
                  _vm.toggleImageModal = true
                },
              },
            },
            [
              _c("div", {
                style: {
                  "background-image": `url(${_vm.thumb[0]})`,
                },
                attrs: { alt: "" },
              }),
            ]
          ),
      _vm.toggleImageModal
        ? _c("ImageViewModal", {
            attrs: { list: _vm.thumb },
            on: {
              close: function ($event) {
                _vm.toggleImageModal = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }