import { login, chkAuthNumber, fetchJoin } from '@/api'

// Set current selected library as default library
const state = {
  user: {
    email: '',
    nickName: '',
    socialId: 0,
    socialType: '',
    isUser: false,
    phoneNumber: '',
    ageTerms: false,
    memgerTerms: false,
    memberPersonalTerms: false,
    marketingTerms: false,
    termsConsents: {},
    userExisting: false,
    userSocials: [],
    storageHash: '',
    storageAccess: ''
  },
  join: {
    phoneNumber: ''
  }
}

const getters = {
  getUser: (state) => state.user
}

const actions = {
  login({ commit }, { username, password }) {
    return new Promise((resolve, reject) => {
      login({
        loginId: btoa(username),
        loginPwd: btoa(password)
      })
        .then((resp) => {
          commit('SET_USER_INFO', { user: resp.data.result })
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  chkAuthNumber(payload) {
    return new Promise((resolve, reject) => {
      chkAuthNumber(payload)
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  addJoin(payload) {
    return new Promise((resolve, reject) => {
      fetchJoin(payload)
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  setProfile(payload) {
    return new Promise((resolve, reject) => {
      fetchJoin(payload)
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

const mutations = {
  SET_USER_INFO(
    state,
    {
      email,
      nickName,
      socialId,
      socialType,
      isUser,
      phoneNumber,
      ageTerms,
      memgerTerms,
      memberPersonalTerms,
      marketingTerms,
      termsConsents,
      userExisting,
      userSocials,
      storageHash,
      storageAccess
    }
  ) {
    if (email) state.user.email = email
    if (nickName) state.user.nickName = nickName
    if (socialId) state.user.socialId = socialId
    if (socialType) state.user.socialType = socialType
    if (userExisting) state.user.userExisting = userExisting
    if (isUser) state.user.isUser = isUser
    if (phoneNumber) state.user.phoneNumber = phoneNumber
    if (ageTerms) state.user.ageTerms = ageTerms
    if (memgerTerms) state.user.memgerTerms = memgerTerms
    if (memberPersonalTerms)
      state.user.memberPersonalTerms = memberPersonalTerms
    if (marketingTerms) state.user.marketingTerms = marketingTerms
    if (!marketingTerms) state.user.marketingTerms = false
    if (termsConsents) state.user.termsConsents = termsConsents
    if (userExisting) state.user.userExisting = userExisting
    if (userSocials) state.user.userSocials = userSocials
    if (storageHash) state.user.storageHash = storageHash
    if (storageAccess) state.user.storageAccess = storageAccess

    if (
      !email &&
      !nickName &&
      !socialId &&
      !socialType &&
      !isUser &&
      !phoneNumber &&
      !ageTerms &&
      !memgerTerms &&
      !memberPersonalTerms &&
      !marketingTerms &&
      !termsConsents &&
      !userExisting &&
      !userSocials &&
      !storageHash &&
      !storageAccess
    ) {
      state.user = {
        email: '',
        nickName: '',
        socialId: 0,
        socialType: '',
        isUser: false,
        phoneNumber: '',
        ageTerms: false,
        memgerTerms: false,
        memberPersonalTerms: false,
        marketingTerms: false,
        termsConsents: {},
        userExisting: false,
        userSocials: [],
        storageHash: '',
        storageAccess: ''
      }
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
