var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "valid-input", class: _vm.state }, [
    _vm.isShowSub
      ? _c("span", { staticClass: "input-sub-tit" }, [
          _vm._v(_vm._s(_vm.value.length) + " / " + _vm._s(_vm.maxLength)),
        ])
      : _vm._e(),
    _c("input", {
      attrs: {
        type: _vm.type,
        disabled: _vm.disabled,
        placeholder: _vm.placeholder,
      },
      domProps: { value: _vm.value },
      on: {
        input: function ($event) {
          return _vm.$emit("update:value", $event.target.value)
        },
      },
    }),
    _vm.value
      ? _c("i", {
          staticClass: "icon--delete",
          on: {
            click: function ($event) {
              return _vm.$emit("delete")
            },
          },
        })
      : _vm._e(),
    _vm.state
      ? _c("div", { staticClass: "valid-txt error" }, [
          _vm._v(_vm._s(_vm.validTxt)),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }