var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "policy" }, [
    _c("ul", [
      _c(
        "li",
        {
          staticClass: "policy-item",
          on: {
            click: function ($event) {
              return _vm.$router.push({
                name: "MypageTerms",
                params: { type: "operating" },
              })
            },
          },
        },
        [
          _c("span", [_vm._v("퀀트마켓 이용약관")]),
          _c("i", { staticClass: "icon--arrow-move" }),
        ]
      ),
      _c(
        "li",
        {
          staticClass: "policy-item",
          on: {
            click: function ($event) {
              return _vm.$router.push({
                name: "MypageTerms",
                params: { type: "terms" },
              })
            },
          },
        },
        [
          _c("span", [_vm._v("퀀트마켓 개인정보 처리방침")]),
          _c("i", { staticClass: "icon--arrow-move" }),
        ]
      ),
    ]),
    _c("div", { staticClass: "bottom-box wrapper" }, [
      _c("h2", { staticClass: "bottom-box-title" }, [
        _vm._v("서비스 이용 동의 현황"),
      ]),
      _c(
        "p",
        { staticClass: "bottom-box-item" },
        [
          _c("span", [_vm._v("회원 마케팅 수신동의")]),
          _c("ToggleButton", {
            ref: "termsCheckbox",
            attrs: { isBig: true, data: _vm.isUserTermsMarketing },
            on: { updated: _vm.updateTerms },
          }),
        ],
        1
      ),
      _c(
        "p",
        {
          staticClass: "bottom-box-text",
          on: {
            click: function ($event) {
              return _vm.$router.push({
                name: "MypageTerms",
                params: { type: "marketing" },
              })
            },
          },
        },
        [
          _vm._v(" 마케팅 수신 동의 "),
          _c("i", { staticClass: "icon--arrow-move" }),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }