<template>
  <div class="full-image-popup">
    <div class="close-wrap flex-end">
      <i class="icon--close-w" @click="$emit('close')"></i>
    </div>
    <div class="image-box">
      <div class="wrap">
        <swiper slides-per-view="1" :loop="true" :pagination="true">
          <swiper-slide v-for="(data, idx) in list" :key="idx">
            <div class="image">
              <img :src="data" alt="" />
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import { Pagination } from 'swiper'
import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'
// import 'swiper/css/swiper.css'
// import { swiper, swiperSlide } from 'vue-awesome-swiper'
SwiperCore.use([Pagination])
import 'swiper/swiper-bundle.css'
export default {
  name: 'QuantMarketFullModal',
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    list: {
      type: Array,
      default: () => {
        return ['', '', '']
      }
    }
  },
  data() {
    return {}
  },

  mounted() {
    this.show = true
    this.body.classList.add('scrollLock')
    this.targetApp.classList.add('scrollLock')
  },
  beforeDestroy() {
    this.body.classList.remove('scrollLock')
    this.targetApp.classList.remove('scrollLock')
  },
  distroyed() {
    this.body.classList.remove('scrollLock')
    this.targetApp.classList.remove('scrollLock')
  }
}
</script>

<style lang="scss" scoped>
.close-wrap {
  height: 48px;
}
.full-image-popup {
  z-index: 9899999999;
  padding: 0 16px;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.image-box {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .image {
    height: 380px;
    display: flex;
    justify-content: center;
    img {
      border-radius: 14px;
      overflow: hidden;
      width: 328px;
      height: 328px;
    }
  }
}
::v-deep .swiper-container {
  height: 100%;
  position: relative;
  .swiper-pagination {
    // top: 90%;
    // bottom: auto;
    .swiper-pagination-bullet {
      width: 6px;
      height: 6px;
      border-radius: 12px;
      background-color: #fff;
      opacity: 0.4;
      &-active {
        width: 18px;
        opacity: 1;
      }
    }
  }
}
</style>
