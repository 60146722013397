<template>
  <SlideUpModal>
    <template #header>
      <div class="flex-just mb-16">
        <h4>종목 찾기</h4>
        <i class="icon--close" @click="$emit('close')"></i>
      </div>
    </template>
    <template #main>
      <div class="input-list radio-input-list">
        <div class="input-list-box">
          <input
            id="searchTp1"
            v-model="searchType"
            value="stockName"
            type="radio"
          />
          <label for="searchTp1"><span>종목명</span></label>
        </div>
        <div class="input-list-box">
          <input
            id="searchTp2"
            v-model="searchType"
            value="isuAbbrvCd"
            type="radio"
          />
          <label for="searchTp2"><span>종목코드</span></label>
        </div>
      </div>
      <div class="input-list mb-32">
        <div class="search-input">
          <input
            v-model="searchKeyword"
            placeholder="종목을 입력해주세요."
            type="text"
          />
          <i class="icon-search" @click="search"></i>
        </div>
      </div>
      <div class="grid-table">
        <ul class="table-head">
          <li></li>
          <li>종목코드</li>
          <li>종목명</li>
          <li>시장</li>
        </ul>
        <ul
          v-for="(d, i) in stockList"
          :key="i + 'search'"
          class="table-list"
          :class="{ on: selectedList.includes(d) }"
        >
          <li class="input-list">
            <input
              :id="d.stockId"
              v-model="selectedList"
              type="checkbox"
              :value="d"
              @change="stockDataCheck(d)"
              :disabled="limit_reached"
            /><label :for="d.stockId"></label>
          </li>
          <li>{{ d.isuAbbrvCd }}</li>
          <li>{{ d.stockName }}</li>
          <li v-if="d.stockType === 'KOSPI'">코스피</li>
          <li v-if="d.stockType === 'KOSDAQ'">코스닥</li>
        </ul>
        <div class="empty-box" v-if="list.length === 0"></div>
      </div>
    </template>
    <template #button>
      <div class="modal-btn-wrap">
        <button class="base-btn full cl-pri" @click="save">
          <span>선택</span>
        </button>
      </div>
    </template>
  </SlideUpModal>
</template>

<script>
import SlideUpModal from '@/components/common/modal/SlideUpModal.vue'

export default {
  name: 'QuantMarketCommRegisterModal',
  props: ['stockCheckList'],
  components: { SlideUpModal },
  data() {
    return {
      selectedList: this.stockCheckList,
      stockList: [],
      searchType: 'stockName',
      searchKeyword: ''
    }
  },
  created() {
    this.stockList = this.$store.getters.getSearchList
  },
  computed: {
    list() {
      return this.$store.getters.getSearchList
    },
    limit_reached() {
      if (this.selectedList.length > 4) {
        return true
      }
      return false
    }
  },
  mounted() {},
  methods: {
    search() {
      if (this.searchType === 'stockName') {
        const list = this.$store.getters.getSearchList.filter((info) => {
          return info.stockName
            .toUpperCase()
            .includes(this.searchKeyword.toUpperCase())
        })
        this.stockList = list
      } else {
        const list = this.$store.getters.getSearchList.filter((info) => {
          return info.isuAbbrvCd.includes(this.searchKeyword)
        })
        this.stockList = list
      }

      if (this.stockList.length === 0) {
        this.$Simplert.open({
          title: '알림',
          message: `<div id="strategy-add-description">검색한 종목이 없습니다. 다른 검색어로 검색해주세요.</div>`
        })
      }
    },
    closeSlider() {
      this.$emit('close')
    },
    save() {
      this.$emit('save', this.selectedList)
    },
    stockDataCheck(data) {
      if (this.selectedList.length > 5) {
        data.checked = false
      } else {
        data.checked = !data.checked
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .modal-footer {
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
  }
}
.radio-input-list {
  display: flex;
  align-items: center;
  justify-content: center;
  > .input-list-box {
    padding: 14px 24px;
  }
}
.empty-box {
  min-height: 220px;
}
.grid-table {
  margin: 0 -24px;
  .input-list {
    position: relative;
    top: 0;
    left: 0;
    label {
      display: inline-block;
      width: 28px;
      height: 28px;
    }
  }
}
</style>
