<template>
  <div class="profile">
    <SlideUpModal v-if="isProfile">
      <template #header>
        <div class="flex-just">
          <h4>프로필 이미지를 선택해주세요</h4>
          <i class="icon--close" @click="closeSlider"></i>
        </div>
      </template>
      <template #main>
        <ul class="send-modal-body">
          <li @click="setProfile('m1')" :class="{ on: tempProfileId === 'm1' }">
            <i class="icon--char m1"></i>
          </li>
          <li @click="setProfile('m2')" :class="{ on: tempProfileId === 'm2' }">
            <i class="icon--char m2"></i>
          </li>
          <li @click="setProfile('m3')" :class="{ on: tempProfileId === 'm3' }">
            <i class="icon--char m3"></i>
          </li>
          <li @click="setProfile('m4')" :class="{ on: tempProfileId === 'm4' }">
            <i class="icon--char m4"></i>
          </li>

          <li @click="setProfile('w1')" :class="{ on: tempProfileId === 'w1' }">
            <i class="icon--char w1"></i>
          </li>
          <li @click="setProfile('w2')" :class="{ on: tempProfileId === 'w2' }">
            <i class="icon--char w2"></i>
          </li>
          <li @click="setProfile('w3')" :class="{ on: tempProfileId === 'w3' }">
            <i class="icon--char w3"></i>
          </li>
          <li @click="setProfile('w4')" :class="{ on: tempProfileId === 'w4' }">
            <i class="icon--char w4"></i>
          </li>
        </ul>
      </template>
      <template #button>
        <div class="modal-btn-wrap">
          <button class="base-btn harf cl-sec" @click="closeSlider">
            <span>취소</span>
          </button>
          <button class="base-btn harf cl-pri" @click="chooseProfile">
            <span>선택</span>
          </button>
        </div>
      </template>
    </SlideUpModal>
    <figure
      class="profile-img"
      :style="{
        backgroundImage: 'url(' + user.storageAccess + ')'
      }"
      @click="profileShow"
    >
      <i
        class="icon--black--plus type01"
        style="position: relative; top: 80px; left: 80px"
      ></i>
    </figure>
    <ul class="profile-info-list">
      <li class="profile-info-list-item">
        <span>닉네임</span>
        <span>{{ user.nickName }}</span>
      </li>
      <li class="profile-info-list-item">
        <span>이메일 주소</span>
        <span>{{ user.email }}</span>
      </li>
      <li class="profile-info-list-item">
        <span>휴대폰 번호</span>
        <span>{{ user.phoneNumber }}</span>
      </li>
      <li class="profile-info-list-item">
        <span>연령대</span>
        <span>{{ age }}</span>
      </li>
      <li class="profile-info-list-item">
        <span>성별</span>
        <span>{{ gender }}</span>
      </li>
    </ul>

    <hr />

    <div class="profile-sns">
      <h2 class="profile-sns-title">SNS 계정 연동</h2>
      <ul class="profile-sns-list">
        <li class="profile-sns-list-item">
          <span>
            <i class="icon--mypage-sns kakao"></i>
            카카오 계정 연결
          </span>
          <div>
            <ToggleButton
              :data="kakao"
              ref="kakaoCheckbox"
              :info="{ socialType: 'KAKAO' }"
              @updated="updateSocial"
            ></ToggleButton>
          </div>
        </li>
        <li class="profile-sns-list-item">
          <span>
            <i class="icon--mypage-sns google"></i>
            구글 계정 연결
          </span>
          <div>
            <ToggleButton
              :data="google"
              ref="googleCheckbox"
              :info="{ socialType: 'GOOGLE' }"
              @updated="updateSocial"
            ></ToggleButton>
          </div>
        </li>
        <li class="profile-sns-list-item">
          <span>
            <i class="icon--mypage-sns naver"></i>
            네이버 계정 연결
          </span>
          <div>
            <ToggleButton
              :data="naver"
              ref="naverCheckbox"
              :info="{ socialType: 'NAVER' }"
              @updated="updateSocial"
            ></ToggleButton>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import ToggleButton from '@/components/button/ToggleButton.vue'
import { axiosAuthInstance } from '@/store/axios'
import SlideUpModal from '@/components/common/modal/SlideUpModal.vue'

export default {
  components: { SlideUpModal, ToggleButton },
  data() {
    return {
      user: {
        userId: 0,
        phoneNumber: '',
        gender: 'UNKNOWN',
        email: '',
        birthday: null,
        nickName: '',
        visitCount: 0,
        createdId: null,
        storageAccess: '',
        allowNotifications: [],
        socialConnections: []
      },
      google: false,
      kakao: false,
      naver: false,
      isProfile: false,
      tempProfileId: 'm1',
      checkSocialType: ''
    }
  },
  computed: {
    gender() {
      switch (this.user.gender) {
        case 'MALE':
          return '남성'
        case 'FEMALE':
          return '여성'
        default:
          return '없음'
      }
    },
    age() {
      switch (this.user.age) {
        case 'AGE_10S':
          return '10대'
        case 'AGE_20S':
          return '20대'
        case 'AGE_30S':
          return '30대'
        case 'AGE_40S':
          return '40대'
        case 'AGE_50S':
          return '50대'
        case 'AGE_60S':
          return '60대'
        case 'AGE_70S':
          return '70대'
        case 'AGE_80S':
          return '80대 이상'
        default:
          return '없음'
      }
    },
    userInfo: {
      get() {
        return this.$store.getters.getUser
      }
    }
  },
  created() {
    this.getUser()
  },
  methods: {
    getUser() {
      axiosAuthInstance
        .get('/user', {})
        .then((response) => {
          this.user = response
          const user = {
            storageAccess: this.user.storageAccess
          }

          this.$store.commit('SET_USER_INFO', user)

          for (const social of this.user.socialConnections) {
            switch (social.socialType) {
              case 'GOOGLE':
                this.google = true
                this.$refs.googleCheckbox.updateCheckbox(true)
                break
              case 'NAVER':
                this.naver = true
                this.$refs.naverCheckbox.updateCheckbox(true)
                break
              case 'KAKAO':
                this.kakao = true
                this.$refs.kakaoCheckbox.updateCheckbox(true)
                break
            }
          }
        })
        .catch((error) => {
          console.log('error', error)
        })
    },
    closeSlider() {
      this.isProfile = false
    },
    setProfile(profileId) {
      this.tempProfileId = profileId
    },
    profileShow() {
      this.isProfile = true
    },
    chooseProfile() {
      this.isProfile = false
      this.profileId = this.tempProfileId

      axiosAuthInstance
        .put('/user/profile', { storageHash: this.getStorageHash() })
        .then(() => {
          this.getUser()
        })
        .catch((error) => {
          console.log('error', error)
        })
    },
    getStorageHash() {
      switch (this.profileId) {
        case 'm1':
          return 'icon_man_1'
        case 'm2':
          return 'icon_man_2'
        case 'm3':
          return 'icon_man_3'
        case 'm4':
          return 'icon_man_4'
        case 'w1':
          return 'icon_wom_1'
        case 'w2':
          return 'icon_wom_2'
        case 'w3':
          return 'icon_wom_3'
        case 'w4':
          return 'icon_wom_4'
      }
    },
    updateSocial(info, isOn) {
      console.log(isOn)
      if (isOn) {
        //켜짐
        this.goSocial(info.socialType)
      } else {
        // 꺼짐
        this.checkSocialType = info.socialType
        if (this.userInfo.socialType === info.socialType) {
          this.$Simplert.open({
            title: '알림',
            message: '현재 로그인되어 있는 계정은 연결 해제할 수 없습니다.',
            type: 'info',
            //useConfirmBtn: true,
            //customConfirmBtnText: '저장',
            customCloseBtnText: '아니오',
            onClose: this.socialRollBack
          })
        } else {
          this.$Simplert.open({
            title: '알림',
            message:
              'SNS 연결 해제 시 해당 SNS 계정은 다시 인증해야 합니다. \n' +
              '연결 해제 하시겠습니까? ',
            type: 'info',
            useConfirmBtn: true,
            customConfirmBtnText: '확인',
            customCloseBtnText: '취소',
            onConfirm: this.deleteSocial,
            onClose: this.socialRollBack
          })
        }
      }

      switch (info.socialType) {
        case 'KAKAO':
          break
        case 'GOOGLE':
          break
        case 'NAVER':
          break
      }
    },
    goSocial(socialType) {
      let social = ''
      switch (socialType) {
        case 'GOOGLE':
          social = 'google'
          break
        case 'NAVER':
          social = 'naver'
          break
        case 'KAKAO':
          social = 'kakao'
          break
      }

      axiosAuthInstance
        .get('/oauth/' + social + '/url', {})
        .then((response) => {
          console.log(response)
          window.location.href = response.url
        })
        .catch((error) => {
          console.log('error', error)
        })
    },
    socialRollBack() {
      switch (this.checkSocialType) {
        case 'GOOGLE':
          this.$refs.googleCheckbox.updateCheckbox(true)
          break
        case 'NAVER':
          this.$refs.naverCheckbox.updateCheckbox(true)
          break
        case 'KAKAO':
          this.$refs.kakaoCheckbox.updateCheckbox(true)
          break
      }
    },
    socialOnRollBack() {
      switch (this.userInfo.socialType) {
        case 'GOOGLE':
          this.$refs.googleCheckbox.updateCheckbox(true)
          break
        case 'NAVER':
          this.$refs.naverCheckbox.updateCheckbox(true)
          break
        case 'KAKAO':
          this.$refs.kakaoCheckbox.updateCheckbox(true)
          break
      }
    },
    deleteSocial() {
      axiosAuthInstance
        .delete('/user/social/' + this.checkSocialType)
        .then(() => {
          this.getUser()
        })
        .catch((error) => {
          console.log('error', error)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.profile {
  padding: 0 16px;
  &-img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-size: 100%;
    margin: 20px auto 24px;
  }

  &-info-list {
    margin-bottom: 24px;
    &-item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      padding: 16px 0;
      letter-spacing: -0.32px;
      span:first-child {
        font-weight: 600;
        color: #222;
      }
      span:last-child {
        font-weight: 400;
        color: #666;
      }
    }
  }
  hr {
    margin-bottom: 24px;
  }
  &-sns {
    &-title {
      color: #999;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: -0.24px;
      height: 37px;
      line-height: 37px;
    }
    &-list {
      &-item {
        height: 54px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        span:first-child {
          display: flex;
          align-items: center;
          color: #222;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%; /* 22.4px */
          letter-spacing: -0.32px;
        }
        i {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 8px;
          display: block;
        }
      }
    }
  }

  //   background-color: #000;
}
</style>
