// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.radar-chart-wrapper {
  position: relative;
}
.radar-chart-wrapper > div {
  position: absolute;
  min-height: auto !important;
  top: 53%;
  left: 49%;
  transform: translate(-50%, -50%);
}
.radar-chart-wrapper span {
  font-size: 12px;
  color: #666;
  position: absolute;
}
.radar-chart-wrapper span:nth-child(1) {
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.radar-chart-wrapper span:nth-child(2) {
  top: 25%;
  right: 0;
  transform: translate(40%, -50%);
}
.radar-chart-wrapper span:nth-child(3) {
  top: 75%;
  right: 0;
  transform: translate(40%, -50%);
}
.radar-chart-wrapper span:nth-child(4) {
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
.radar-chart-wrapper span:nth-child(5) {
  bottom: 25%;
  left: 0;
  transform: translate(-40%, 50%);
}
.radar-chart-wrapper span:nth-child(6) {
  bottom: 75%;
  left: 0;
  transform: translate(-40%, 50%);
}
.radar-chart-wrapper.small span {
  font-size: 8px;
}
.apexcharts-radar-series.apexcharts-plot-series polygon {
  stroke-dasharray: 2;
  stroke-width: 1;
}
.apexcharts-radar-series.apexcharts-plot-series polygon:nth-child(1) {
  stroke-dasharray: 0;
  stroke-width: 2;
}
#apexchartsradar .apexcharts-yaxistooltip {
  display: none;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
