import { fetchRankingList } from '@/api'

// Set current selected library as default library
const state = {
  rankingList: []
}

const getters = {
  getRankingList: (state) => state.rankingList || []
}

const actions = {
  fetchRankingList({ commit }) {
    return new Promise((resolve, reject) => {
      fetchRankingList()
        .then((resp) => {
          commit('SET_RANKING_DATA', resp.data.result)
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

const mutations = {
  SET_RANKING_DATA(state, payload) {
    state.rankingList = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
