<template>
  <div class="examine">
    <div class="examine-tab wrapper mb-32">
      <div
        v-for="(tab, index) in tabList"
        :key="`tab-${index}`"
        :class="{ active: tab.isActive }"
        @click="clickTab(index)"
      >
        {{ tab.text }}
      </div>
    </div>

    <div class="list-body">
      <template v-if="tab === 0">
        <RowList
          :rows="portfolios"
          :message="'심사를 신청한 포트폴리오가 없습니다.'"
          @rowClick="portfolioClick"
        />
      </template>

      <template v-if="tab === 1">
        <RowList
          :rows="communities"
          :message="'심사를 신청한 커뮤니티가 없습니다.'"
          @rowClick="communityClick"
        />
      </template>
    </div>
  </div>
</template>

<script>
import RowList from '@/components/ExamRowList.vue'
import { axiosAuthInstance } from '@/store/axios'
export default {
  components: {
    RowList
  },
  data() {
    return {
      tabList: [
        { id: 0, text: '포트폴리오', isActive: true },
        { id: 1, text: '커뮤니티', isActive: false }
      ],
      tab: 0,
      communities: [],
      portfolios: []
    }
  },
  created() {
    this.getReviews()
  },
  methods: {
    getReviews() {
      console.log('getReviews')
      axiosAuthInstance
        .get('/community/reviews', { params: this.parameters })
        .then((response) => {
          for (let item of response) {
            console.log(item)
            this.communities.push({
              id: item.reviewId,
              rowTitle: item.name,
              buttonText: this.convertStatusName(item.reviews[0].reviewStatus),
              status: this.convertStatus(item.reviews[0].reviewStatus),
              createdDate: item.createdDate,
              reviews: item.reviews
            })
          }
        })
        .catch((error) => {
          console.log('error', error)
        })

      axiosAuthInstance
        .get('/portfolio/reviews', { params: this.parameters })
        .then((response) => {
          for (let item of response) {
            console.log(item)
            this.portfolios.push({
              id: item.reviewId,
              rowTitle: item.portfolioName,
              buttonText: this.convertStatusName(item.reviews[0].reviewStatus),
              status: this.convertStatus(item.reviews[0].reviewStatus),
              createdDate: item.createdDate,
              reviews: item.reviews
            })
          }
        })
        .catch((error) => {
          console.log('error', error)
        })
    },
    convertStatus(value) {
      if (value === 'REQUEST') return 'add'
      if (value === 'UNDER_REVIEW') return 'check'
      if (value === 'APPROVAL') return 'finish'
      if (value === 'REJECT') return 'cancel'
    },
    convertStatusName(value) {
      if (value === 'REQUEST') return '심사요청'
      if (value === 'UNDER_REVIEW') return '심사중'
      if (value === 'APPROVAL') return '승인'
      if (value === 'REJECT') return '반려'
    },
    clickTab(idx) {
      this.tabList = this.tabList.map((el) => {
        return el.id === idx
          ? {
              ...el,
              isActive: true
            }
          : {
              ...el,
              isActive: false
            }
      })
      this.tab = idx
    },
    rowClick(data) {
      console.log(data)
    },
    communityClick(data) {
      console.log('community: ', data)
      this.$store.commit('SET_TYPE', 'community')
      this.$store.commit('SET_COMMUNITY', data)
      this.$router.push({
        name: 'ExamineDetail'
      })
    },
    portfolioClick(data) {
      console.log('community: ', data)
      this.$store.commit('SET_TYPE', 'portfolio')
      this.$store.commit('SET_PORTFOLIO', data)
      this.$router.push({
        name: 'ExamineDetail'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.examine {
  &-tab {
    display: flex;
    align-items: center;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #e8e8e8;
    div {
      width: 100%;
      height: 48px;
      line-height: 48px;
      letter-spacing: -0.32px;
      color: #aaa;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: -0.32px;
    }
    .active {
      color: #222;
      position: relative;
    }
    .active::after {
      content: '';
      display: block;
      position: absolute;
      bottom: -1.5px;
      width: 95%;
      left: 50%;
      transform: translateX(-50%);
      height: 2px;
      background-color: #222;
    }
  }
  .list-body {
    padding: 0 16px;
  }
}
</style>
