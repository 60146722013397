var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("header", [
    _vm._m(0),
    _c("ul", { staticClass: "gnb-menu" }, [
      _c(
        "li",
        [
          _c("router-link", { attrs: { to: { name: "Ranking" } } }, [
            _c("i", { staticClass: "icon--gnb-rank on" }),
          ]),
        ],
        1
      ),
      _c(
        "li",
        [
          _c("router-link", { attrs: { to: { name: "Community" } } }, [
            _c("i", { staticClass: "icon--gnb-message on" }),
          ]),
        ],
        1
      ),
      false
        ? _c(
            "li",
            [
              _c("router-link", { attrs: { to: { name: "Ranking" } } }, [
                _c("i", { staticClass: "icon--gnb-alarm on" }),
              ]),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "logo-box" }, [
      _c("i", { staticClass: "icon--gnb-logo on" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }