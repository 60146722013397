<template>
  <div class="tab-box">
    <div class="navbar">
      <ul>
        <li class="list-item" :class="{ on: current === 'MY' }">
          <b class="right-curve"></b>
          <a @click="changeTab('MY')">MY 포폴</a>
        </li>
        <li class="list-item" :class="{ on: current === 'THREE_MONTH' }">
          <b class="left-curve"></b>
          <b class="right-curve"></b>
          <a @click="changeTab('THREE_MONTH')">수익률(3개월)</a>
        </li>
        <li class="list-item" :class="{ on: current === 'LATEST' }">
          <b class="left-curve"></b>
          <a @click="changeTab('LATEST')">최신순</a>
        </li>
      </ul>
    </div>
    <!-- <div class="current-tab-pannel"></div> -->
    <TabPannel :portfolioList="portfolioList" ref="childTabPannelRef" />
  </div>
</template>

<script>
import TabPannel from './TabPannel.vue'

export default {
  name: 'Login',
  props: {
    myPortfolioList: {
      type: Object,
      default: () => {
        return {}
      }
    },
    threeMonthProfitPortfolioTopList: {
      type: Object,
      default: () => {
        return {}
      }
    },
    latestPortfolioList: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      current: 'MY',
      portfolioList: []
    }
  },
  created() {
    if (this.myPortfolioList.portfolios) {
      this.portfolioList = this.myPortfolioList.portfolios
      if (this.portfolioList.length === 0) {
        this.current = 'THREE_MONTH'
      }
    }
  },
  mounted() {
    this.changeTab(this.current)
  },
  computed: {},
  methods: {
    changeTab(current) {
      this.current = current
      switch (this.current) {
        case 'MY':
          this.portfolioList = this.myPortfolioList.portfolios
          break
        case 'THREE_MONTH':
          this.portfolioList = this.threeMonthProfitPortfolioTopList.portfolios
          break
        case 'LATEST':
          this.portfolioList = this.latestPortfolioList.portfolios
          break
      }

      this.$refs.childTabPannelRef.forceRender()
    }
  },
  components: { TabPannel }
}
</script>

<style lang="scss" scoped>
.tab-box {
  margin-top: 24px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.14);
  overflow: hidden;
  .current-tab-pannel {
    padding: 24px 20px;
  }
  > ul {
    background-color: #f1f1f1;
  }

  .navbar {
    height: auto;
    width: 100%;
    background: #f1f1f1;
  }

  .navbar ul {
    display: flex;
  }

  .navbar ul li {
    flex: 1;
    display: inline;
    font-size: 1rem;
    border-radius: 14px 14px 0px 0px;
    position: relative;
    > a {
      display: inline-block;
      padding: 11px 0;
      text-align: center;
      width: 100%;
    }
  }

  .navbar ul li.on {
    background: #fff;
  }

  .navbar ul li a {
    text-decoration: none;
    color: #999;
    font-size: 12px;
  }

  .navbar ul li.on a {
    color: #222;
    font-weight: 600;
  }
  .navbar ul li b.left-curve {
    position: absolute;
    bottom: 0px;
    left: -15px;
    height: 100%;
    width: 15px;
    background: #fff;
    display: none;
  }

  .navbar ul li b.left-curve::before {
    content: '';
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    border-bottom-right-radius: 14px;
    background: #f1f1f1;
  }

  .navbar ul li b.right-curve {
    position: absolute;
    right: -15px;
    top: 0px;
    height: 100%;
    width: 15px;
    background: #fff;
    display: none;
  }

  .navbar ul li b.right-curve::before {
    content: '';
    right: 0;
    position: absolute;
    width: 100%;
    top: 0;
    height: 100%;
    border-bottom-left-radius: 14px;
    background: #f1f1f1;
  }

  .navbar ul li.on b.left-curve,
  .navbar ul li.on b.right-curve {
    display: block;
  }
}
</style>
