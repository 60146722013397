<template>
  <div>
    <ExamineDetail :data="data"></ExamineDetail>
  </div>
</template>

<script>
import ExamineDetail from '@/components/common/ExamineDetail'
export default {
  name: 'QuantMarketAddProductDetail',
  components: { ExamineDetail },
  data() {
    return {
      data: {
        stage: 2,
        resultTp: 'reject',
        state: 'REJECT',
        reason: '이러한 이유로 반려되었음',
        progressList: [
          { date: '2023.08.01' },
          { date: '2023.08.01' },
          { date: '2023.08.01' }
        ]
      }
    }
  },

  mounted() {},

  methods: {}
}
</script>

<style lang="scss" scoped></style>
