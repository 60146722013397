<template>
  <div class="product-detail-wrap layer">
    <div class="page-head-options">
      <i class="icon--fav"></i>
      <i class="icon--share-b"></i>
      <i class="icon--dot icon_b"></i>
    </div>
    <FullModal v-if="isShowCompo">
      <div class="fixed-head">
        <i class="icon--arrow-back" @click="$router.push(-1)"></i>
        <h5 class="page-tit">구성종목 비교</h5>
        <i @click="isShowCompo = false" class="icon--close"></i>
      </div>
      <DiffStock
        :operatingFunds="this.operatingFunds"
        :portfolioId="this.portfolio.portfolioId"
      ></DiffStock>
    </FullModal>
    <div class="slide-box">
      <SliderPhotoBox
        class="inner-slider"
        type="product"
        :list="productImages"
      />
    </div>
    <div class="product-info section mb-8">
      <h4 class="name mb-10">{{ productSubscription.productName }}</h4>
      <div class="round-date">
        <span>구독기간</span>
        <span>|</span>
        <span>{{ productSubscription.subscriptionStartDate }}</span>
        <span>~</span>
        <span>{{ productSubscription.subscriptionEndDate }}</span>
      </div>
    </div>
    <div class="range-section section mb-8">
      <div class="sec-head flex-just">
        <h3>운용자금입력</h3>
      </div>
      <p><span class="on-txt">최소 3천만원</span>금액 투자를 권장합니다.</p>
      <p class="on-price">{{ operatingFunds }}천만원</p>
      <div class="range-slide-box">
        <div class="slide-mark">
          <span>1천</span>
          <span>1억원</span>
        </div>
        <vue-slider
          v-model="operatingFunds"
          dot-size="34"
          tooltip="none"
          :min="1"
          :max="10"
          :interval="1"
          :drag-on-click="true"
        ></vue-slider>
      </div>
    </div>
    <div class="compo-section section mb-8">
      <div class="sec-head flex-start mb-24">
        <h3 class="l_box">구성종목</h3>
        <span class="compo-date">{{
          portfolio.portfolioStockWeights[0].baseDate
        }}</span>
        <button @click="isShowCompo = true" class="diff-btn">
          <i class="icon--diff"></i>비교
        </button>
      </div>
      <div class="sec-body">
        <div class="grid-table">
          <ul class="table-head">
            <li>종목</li>
            <li>주가</li>
            <li>비중</li>
            <li>주식수</li>
          </ul>
          <ul
            v-for="(d, i) in stockDate"
            :key="i + 'search'"
            class="table-list"
          >
            <li>
              <p class="no-wrap-text">{{ d.stockName }}</p>
            </li>
            <li>{{ Number(d.currentPrices).toLocaleString() }}</li>
            <li>{{ weight(d.weight) }}%</li>
            <li>
              {{
                Number(
                  stockCalculate(d.currentPrices, d.weight)
                ).toLocaleString()
              }}주
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="guide-section section mb-8">
      <div class="sec-head flex-start mb-16">
        <h3 class="l_box">투자가이드</h3>
      </div>
      <div class="sec-body">
        <div class="grid-table">
          <ul class="table-head">
            <li>작성일</li>
            <li>가이드</li>
          </ul>
          <ul
            v-for="(d, i) in productGuides"
            :key="i + 'guide'"
            class="table-list"
          >
            <li>{{ d.createdDate }}</li>
            <li>{{ d.productInformation }}</li>
          </ul>
          <ul v-if="isGuide" class="table-list">
            <li></li>
            <li>작성된 가이드가 없습니다.</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="section mb-1">
      <div class="sec-head flex-just">
        <h3>누적수익률</h3>
      </div>
      <p class="big-desc">
        {{ productSubscription.productName }}에 {{ operatingFunds }}천만원
        투자했다면,<br />지금 <strong>{{ totalRevenue }}만원</strong>이 되어
        있을 거에요
      </p>
    </div>
  </div>
</template>

<script>
import SliderPhotoBox from '@/components/common/SliderPhotoBox.vue'
import FullModal from '@/components/common/modal/FullModal.vue'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
import DiffStock from './DiffStock.vue'
import { axiosAuthInstance } from '@/store/axios'
export default {
  name: 'QuantMarketIndex',
  components: {
    FullModal,
    SliderPhotoBox,
    VueSlider,
    DiffStock
  },
  data() {
    return {
      operatingFunds: 5,
      compoDate: '2023.06.01',
      isShowCompo: false,
      productSubscription: {},
      product: {},
      portfolio: {},
      productImages: [],
      portfolioStockWeight: {},
      rorCumPercent: 0,
      stockDate: [],
      productGuides: []
    }
  },
  created() {
    axiosAuthInstance
      .get('/product/subscription/' + this.$route.params.id, {})
      .then((response) => {
        this.productSubscription = response.productSubscription
        this.product = response.product
        this.productGuides = response.product.productGuides
        this.portfolio = response.portfolio
        this.portfolioStockWeight = response.portfolioStockWeight
        this.productImages = this.product.storageAccesses
        this.stockDate = this.portfolio.portfolioStockWeights[0].stockData
        this.rorCumPercent = response.portfolio.rorCumPercent
      })
      .catch((error) => {
        console.log('error', error)
      })
  },
  computed: {
    list() {
      let arr = Array.from({ length: 10 }, (v, i) => i + 1)
      return arr.map((e) => {
        console.log(e)
        return {
          state: 'edtion',
          title:
            '타이틀타이틀타이틀타이틀타이틀타이틀타이틀타이틀타이틀타이틀타이틀타이틀타이틀타이틀타이틀타이틀타이틀타이틀타이틀',
          thumb: '',
          discount: 23.7,
          price: '9,999',
          beforePrice: '9,999',
          recentPercent: 15,
          name: '성장쑥쑥 포트폴리오'
        }
      })
    },
    totalRevenue() {
      const proceeds = this.operatingFunds * 1000 * (this.rorCumPercent / 100)
      return (Number(this.operatingFunds * 1000) + Number(proceeds)).toFixed(0)
    },
    isGuide() {
      return this.productGuides.length === 0
    }
  },
  mounted() {
    this.$store.dispatch('fetchCommunityBannr')
  },
  methods: {
    setSort(value) {
      this.sort = value
      this.toggleSortOpt = false
    },
    weight(val) {
      return (val * 100).toFixed(2)
    },
    stockCalculate(price, weight) {
      const totalRevenue = this.operatingFunds * 10 * 1000 * 1000
      return ((totalRevenue * weight) / price).toFixed(0)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .full-popup {
    padding: 8px 0;
  }
}
.discount {
  color: #d74343;
  font-size: 20px;
  font-weight: 600;
}
.product-detail-wrap {
  background-color: #f1f1f1;
}
.section {
  background-color: #fff;
  padding: 32px 16px;
}
.slide-box {
  position: relative;
  aspect-ratio: 1;
  .inner-txt {
    position: absolute;
    left: 16px;
    bottom: 24px;
  }
}
.product-info {
  padding: 32px 16px;
  .title {
    color: #666;
    font-size: 12px;
    line-height: 140%;
    margin-bottom: 5px;
  }
  .desc {
    margin-top: 8px;
    margin-bottom: 12px;
  }
  .price {
    font-size: 22px;
    font-weight: 600;
    color: #222;
    .discount {
      color: #d74343;
      font-size: 22px;
      font-weight: 600;
    }
  }
  .before-info {
    span {
      color: #999;
      font-size: 14px;
    }
    .before-price {
      text-decoration: line-through;
    }
  }
  .recent-percent {
    display: flex;
    align-items: center;
    gap: 4px;
    span {
      font-size: 14px;
      color: #666;
    }
    .percent {
      color: #d74343;
      font-weight: 600;
    }
  }
  .round-date {
    span {
      color: #666;
      font-size: 12px;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    width: 215px;
    height: 25px;
    border-radius: 50px;
    background: #f5f5ff;
    gap: 6px;
  }
}
.sec-head {
  h3 {
    font-size: 18px;
  }
  .status-box {
    strong {
      color: #4a4ee7;
      font-size: 20px;
      font-weight: 600;
    }
  }
  .rate-box {
    strong {
      color: #d74343;
      font-size: 20px;
      font-weight: 600;
    }
  }
  span {
    padding-left: 8px;
    color: #999;
    font-size: 12px;
    font-weight: 400;
  }
}

.range-section {
  .on-txt,
  .on-price {
    color: #4a4ee7;
  }
  .on-price {
    margin: 20px 0 8px;
    font-weight: 600;
    font-size: 18px;
  }
}
.range-slide-box {
  .slide-mark {
    > span {
      color: #999;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 12px */
      letter-spacing: -0.24px;
      min-width: 24px;
      text-align: center;
    }
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }
}
::v-deep {
  .vue-slider-rail {
    background: #e8e8e8;
  }
  .vue-slider-process {
    background-color: #444444;
  }
  .vue-slider-dot-handle {
    background-color: #fff;
    stroke-width: 1px;
    border: 1px solid #e8e8e8;
    filter: drop-shadow(1px 4px 9px rgba(0, 0, 0, 0.12));
  }
}

.compo-section {
  padding: 40px 0 0;
  .sec-head {
    padding: 0px 16px;
  }
  .compo-date {
    color: #999;
    font-size: 12px;
  }
  .grid-table {
    .table-head {
      li {
        font-weight: 500;
      }
    }
    .table-head,
    .table-list {
      padding-left: 24px;
      padding-right: 24px;
      grid-template-columns: 35% 30% 15% 20%;
    }
    .table-list {
      min-height: 42px;
      height: auto;
      padding-top: 8px;
      padding-bottom: 8px;
      > li {
        height: auto;
        min-height: auto;
        color: #666;
      }
    }
  }
}
.guide-section {
  padding: 40px 0 0;
  .sec-head {
    padding: 0px 16px;
  }
  .compo-date {
    color: #999;
    font-size: 12px;
  }
  .grid-table {
    .table-head {
      li {
        font-weight: 500;
      }
    }
    .table-head,
    .table-list {
      padding-left: 24px;
      padding-right: 24px;
      grid-template-columns: 70px 1fr;
    }
    .table-list {
      min-height: 42px;
      height: auto;
      padding-top: 8px;
      padding-bottom: 8px;
      column-gap: 20px;
      > li {
        height: auto;
        min-height: auto;
        color: #666;
        align-items: start;
        min-width: 80px;
      }
    }
  }
}
.big-desc {
  color: #999;
  margin-top: 10px;
  font-size: 18px;
  strong {
    color: #d74343;
    font-weight: 600;
  }
}
</style>
