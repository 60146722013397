<template>
  <div>
    <SlideUpModal class="comm-regist-modal">
      <template #header>
        <div class="flex-just">
          <h4>댓글작성</h4>
          <i class="icon--close" @click="close"></i>
        </div>
      </template>
      <template #main>
        <div class="input-list">
          <InputText
            class="mb-32"
            v-model="title"
            :max-length="50"
            :placeholder="`${
              isReply ? '대댓글을 입력해주세요' : '댓글을 입력해주세요'
            }`"
            @update:value="title = $event"
          />
        </div>
        <input
          type="file"
          name="file"
          id="file-element"
          @change="uploadFile($event.target.files)"
          ref="files"
          hidden
          multiple
        />
        <ul class="photo-add-list">
          <li class="photo-add-btn" @click="openUpload">
            <i class="icon--photo-b"></i><span>사진추가</span>
          </li>
          <li
            v-for="(d, i) in images"
            :key="i + 'photo'"
            class="choose-user-photo"
          >
            <img
              :src="d.accessPath"
              alt=""
              style="max-width: 100%; object-fit: contain"
            />
            <i class="icon--delete" @click="deleteImage(i)"></i>
          </li>
        </ul>
        <span class="list-desc"
          >*5MB 미만 jpg, jpeg, png, heic 만 등록 가능</span
        >
      </template>
      <template #button>
        <div class="modal-btn-wrap">
          <button
            class="base-btn full cl-pri"
            @click="isCreate ? saveComment() : updateComment()"
          >
            <span v-if="isCreate">등록</span>
            <span v-if="!isCreate">수정</span>
          </button>
        </div>
      </template>
    </SlideUpModal>
  </div>
</template>

<script>
import SlideUpModal from '@/components/common/modal/SlideUpModal.vue'
import InputText from '@/components/base/InputText.vue'
import { isNumber } from 'chart.js/helpers'
import { axiosAuthInstance } from '@/store/axios'

export default {
  name: 'QuantMarketCommRegisterModal',
  props: {
    isReply: {
      type: Boolean,
      default: false
    },
    loungeId: {
      type: Number,
      default: 0
    },
    isCreate: {
      type: Boolean,
      default: true
    },
    updateTarget: {
      type: Object
    }
  },
  components: { SlideUpModal, InputText },
  data() {
    return {
      tab: 0,
      title: '',
      commentId: 0,
      images: []
    }
  },
  created() {
    if (!this.isCreate) this.setUpdateData()
  },
  mounted() {},
  methods: {
    close() {
      this.title = ''
      this.images = []
      console.log('close')
      this.$emit('close', false)
    },
    openUpload() {
      document.getElementById('file-element').click()
    },
    setUpdateData() {
      console.log(this.updateTarget)
      this.commentId = this.updateTarget.commentId
      this.title = this.updateTarget.commentContents
      // this.images = this.updateTarget.images
      for (let index in this.updateTarget.storageAccesses) {
        this.images.push({
          accessPath: this.updateTarget.storageAccesses[index]
        })
      }
    },
    uploadFile(targetFile) {
      console.log('targetFile', targetFile)
      for (const index in targetFile) {
        console.log('file => ', index)
        if (!isNumber(index)) {
          break
        }
        console.log(index)
        const formData = new FormData()
        formData.append('file', targetFile[index])
        axiosAuthInstance
          .post('/file', formData, {
            header: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then((response) => {
            this.images.push({
              storageHash: response.storageHash,
              accessPath: response.accessPath
            })
          })
      }
    },
    updateComment() {
      console.log('updateComment')
      let parameters = {
        loungeId: this.loungeId,
        commentContents: this.title,
        images: this.images
      }
      console.log(parameters)
      axiosAuthInstance
        .post('/lounge/comment/' + this.commentId, JSON.stringify(parameters), {
          headers: {
            'Content-Type': `application/json`
          }
        })
        .then(() => {
          this.$emit('close', true)
        })
        .catch((error) => {
          console.log('error', error)
        })
    },
    deleteImage(index) {
      console.log(index)
      let changeImages = []
      this.images.forEach((value, imageIndex) => {
        console.log(value, imageIndex)
        if (index !== imageIndex) changeImages.push(value)
      })
      this.images = changeImages
    },
    saveComment() {
      let parameters = {
        loungeId: this.loungeId,
        commentContents: this.title,
        images: this.images
      }
      console.log(parameters)
      axiosAuthInstance
        .put('/lounge/comment', JSON.stringify(parameters), {
          headers: {
            'Content-Type': `application/json`
          }
        })
        .then(() => {
          this.$emit('close', true)
        })
        .catch((error) => {
          console.log('error', error)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.comm-regist-modal {
  ::v-deep {
    .modal-cont-wrap {
      display: flex;
      flex-direction: column;
      height: calc(var(--vh, 1vh) * 100);
      //   min-height: 80vh;
    }
    .input-list {
      gap: 12px;
    }
  }
}
.input-list {
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 32px;
  margin-bottom: 32px;
}
.photo-add-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 8px;
  margin-bottom: 10px;

  > li {
    width: 72px;
    height: 72px;
    border-radius: 8px;
    flex-shrink: 0;
    border: 1px solid #e8e8e8;
    overflow: hidden;
  }
  .photo-add-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .choose-user-photo {
    position: relative;
    > i {
      position: absolute;
      top: 2px;
      right: 2px;
    }
  }
  span {
    margin-top: -8px;
    color: #999;
    font-size: 12px;
  }
}
.list-desc {
  color: #bfbfbf;
  font-size: 12px;
  font-weight: 400;
}
</style>
