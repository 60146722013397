var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    { staticClass: "oauth-btn", class: _vm.is, on: { click: _vm.useClick } },
    [
      _c("span", { class: _vm.type === "naver" ? "cl-white" : "" }, [
        _vm._v(_vm._s(_vm.text)),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }