// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rounge-wrap[data-v-c83c7cde] {
  min-height: calc(var(--vh, 1vh) * 100 - 48px);
}
.section[data-v-c83c7cde] {
  padding: 0 16px;
}
.sort-box[data-v-c83c7cde] {
  position: relative;
  padding: 14px 0;
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
}
.sort-box button[data-v-c83c7cde] {
  display: flex;
  align-items: center;
  color: #666;
}
.sort-box .select-wrap[data-v-c83c7cde] {
  position: absolute;
  width: 114px;
  right: 14px;
  top: 100%;
  border-radius: 8px;
  border: 1px solid #e8e8e8;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px 16px;
}
.sort-box .select-wrap .on[data-v-c83c7cde] {
  font-weight: 500;
  color: #222;
}
.edit-modal .edit-modal-list li[data-v-c83c7cde] {
  font-size: 16px;
  padding: 14px 0;
}
.input-list[data-v-c83c7cde] {
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px 0;
}
.input-list label[data-v-c83c7cde] {
  display: inline-block;
  width: 28px;
  height: 28px;
}
.cate-tab-list[data-v-c83c7cde] {
  display: flex;
  align-items: center;
  gap: 24px;
}
.cate-tab-list li[data-v-c83c7cde] {
  font-size: 20px;
  font-weight: 600;
  color: #aaa;
}
.cate-tab-list li.on[data-v-c83c7cde] {
  color: #222;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
