<template v-if="isDisplay">
  <div
    class="ranking-box"
    :class="{ 'is-top-rank': isTop3 }"
    @click="goPortfolioDetail(data.portfolioId)"
  >
    <div class="prof-thumb">
      <img
        :src="data.storageAccess"
        alt=""
        :class="{ 'rank-image-center': isTop3 }"
      />
    </div>
    <div class="rank-no">
      <span class="no">{{ index }}</span>
      <template v-if="!isTop3">
        <!-- <i v-if="변경없음" class="icon--rank-up"></i> -->
        <i class="icon--rank-up" v-if="isRankUp"></i>
        <!-- <span v-if="data.isNew" class="new-badge">new</span> -->
        <span class="new-badge" v-if="isNew">new</span>
      </template>
    </div>
    <div class="rank-desc no-wrap-text">
      <p class="no-wrap-text-2">{{ data.nickName }}</p>
      <span>{{ data.portfolioName }}</span>
    </div>
    <div class="percent" :class="isDown(data.rorValueIndex) ? 'status' : ''">
      {{ getSign(data.rorValueIndex) }}{{ data.rorValueIndex }}%
    </div>
  </div>
</template>

<script>
export default {
  name: 'QuantMarketRankBox',
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    index: {
      type: Number,
      default: () => {
        return 1
      }
    },
    rankType: {
      type: String,
      default: () => {
        return ''
      }
    }
  },
  data() {
    return {}
  },
  computed: {
    isDisplay() {
      return Object.keys(this.data).length > 0
    },
    isTop3() {
      return this.rankType === 'top3'
    },
    isRankUp() {
      return this.data.currentRank < this.data.lastLank
    },
    isNew() {
      return this.data.lastLank == 0
    }
  },
  mounted() {},

  methods: {
    goPortfolioDetail(portfolioId) {
      this.$router
        .push({
          name: 'PortfolioDetail',
          params: { id: portfolioId }
        })
        .catch(() => {})
    },
    isDown(rorValueIndex) {
      return rorValueIndex < 0
    },
    getSign(rorValueIndex) {
      return rorValueIndex >= 0 ? '+' : ''
    }
  }
}
</script>

<style lang="scss" scoped>
.rank-image-center {
  position: absolute !important;
  width: 70% !important;
  height: 70% !important;
  left: 15% !important;
  top: 15% !important;
}
.ranking-box {
  display: flex;
  align-items: center;
  gap: 12px;
  .prof-thumb {
    border-radius: 50%;
    min-width: 40px;
    min-height: 40px;
    max-width: 40px;
    max-height: 40px;
    flex-shrink: 0;
    position: relative;
    // overflow: hidden;
    > img {
      border-radius: 50%;
      display: inline-block;
      width: 100%;
      height: 100%;
    }
  }
  .rank-no {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-basis: 20px;
    flex-shrink: 0;
    font-size: 14px;
    font-weight: 600;
    gap: 6px;
    > .no {
      font-size: 14px;
    }
    > .new-badge {
      color: #d74343;
      text-align: center;
      font-size: 8px;
    }
  }
  .rank-desc {
    // max-width: 150px;
    // flex: 0;
    display: flex;
    flex-direction: column;
    > p {
      // width: 100%;
      font-size: 14px;
      color: #222;
      font-weight: 500;
      margin-bottom: 4px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: inline-block;
    }
    > span {
      color: #aaa;
      font-size: 12px;
      font-weight: 400;
    }
  }
  .percent {
    color: #d74343;
    font-size: 18px;
    font-weight: 600;
    margin-left: auto;
    &.status {
      color: #4a4ee7;
    }
  }
  &.is-top-rank {
    flex: 1;
    border-radius: 14px;
    border: 1px solid #f1f1f1;
    height: 187px;
    background: #f7f7f7;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;
    gap: 8px;
    padding: 16px 10px 20px;
    .prof-thumb {
      min-width: 66px;
      min-height: 66px;
      border-radius: 50px;
      border: 2px solid #feea61;
      &::after {
        content: '';
        position: absolute;
        display: inline-block;
        top: -0;
        left: 50%;
        width: 22px;
        height: 14px;
        transform: translate(-50%, -9px);
        background-image: url('~@/assets/images/icon_crown.svg');
      }
    }
    .rank-no {
      position: absolute;
      left: 8px;
      top: 8px;
      display: block;
      font-size: 16px;
      > .no {
        font-size: 16px;
      }
    }
    .rank-desc {
      // max-width: 80px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      > p {
        text-align: center;
        // max-width: 80px;
        // display: inline-block;
      }
      > span {
      }
    }
    .percent {
      text-align: center;
      color: #d74343;
      font-size: 18px;
      font-weight: 600;
      margin-left: auto;
      margin-right: auto;
      &.status {
        color: #4a4ee7;
      }
    }
  }
}
</style>
