<template>
  <div class="posting">
    <SlideUpModal
      class="edit-modal"
      v-if="editListShow"
      @close="editListShow = false"
    >
      <template #main>
        <ul class="edit-modal-list">
          <li @click="openSetEdit('edit', 1)">신고하기</li>
          <li @click="openSetEdit('delete', 2)">수정하기</li>
          <li @click="openSetEdit('comment', 3)">삭제하기</li>
        </ul>
      </template>
    </SlideUpModal>
    <TextRegisterModal
      v-if="editShow"
      @close="editShow = false"
    ></TextRegisterModal>
    <CommentRegisterModal
      v-if="commentShow"
      @close="commentShow = false"
    ></CommentRegisterModal>
    <div class="posting-tab wrapper">
      <div
        v-for="(t, index) in tabList"
        :key="`tab-${index}`"
        :class="{ active: tab === t.id }"
        style="height: 35px !important"
        @click="clickTab(t.id)"
      >
        {{ t.text }}
      </div>
    </div>
    <!-- list Component -->
    <template v-if="tab === 0">
      <ul class="posting-list">
        <li
          v-for="(d, i) in lounges"
          :key="i + 'list'"
          :class="toggleList.includes(d.seq) ? 'on' : ''"
          class="posting-list-box on"
        >
          <div class="flex-between">
            <span class="posting-list-box-title list-title-box">{{
              d.loungeTitle
            }}</span>
            <span class="posting-list-box-status">
              <i class="icon--coment"></i>
              <span class="number">{{ d.commentCount }}</span>
              <i
                class="icon--dot"
                @click="$emit('toggle:edit', data.loungeId, data.isOwner)"
              ></i>
            </span>
          </div>
          <div class="posting-list-box-date">{{ d.createdDate }}</div>
          <p class="posting-list-box-description desc-box">
            {{ d.loungeContents }}
          </p>
          <ThumbBox :thumb="d.storageAccesses" />
          <div
            :class="toggleList.includes(d.seq) ? '' : 'show'"
            class="comment-list-box-add-view"
            @click="moveLoungDetail(d.loungeId)"
          >
            더 보기
          </div>
        </li>
      </ul>
    </template>

    <!-- list Component -->
    <template v-if="tab === 1">
      <ul class="comment-list">
        <li
          v-for="(d, i) in loungeComments"
          :key="i + 'list'"
          class="comment-list-box"
        >
          <div class="flex-between">
            <span class="comment-list-box-title list-title-box">{{
              d.commentTitle
            }}</span>
            <span class="comment-list-box-status">
              <i class="icon--dot" @click="openEditModal(1)"></i>
            </span>
          </div>
          <div class="comment-list-box-date">{{ d.createdDate }}</div>
          <p class="comment-list-box-description desc-box">
            {{ d.commentContents }}
          </p>
          <ThumbBox :thumb="d.storageAccesses" />
          <div
            :class="toggleList.includes(d.seq) ? '' : 'show'"
            class="comment-list-box-add-view"
            @click="moveLoungDetail(d.loungeId)"
          >
            더 보기
          </div>
        </li>
      </ul>
    </template>
  </div>
</template>

<script>
import { mypagePosting, mypageComent } from '@/data/data'
import ThumbBox from '@/components/common/ThumbBox.vue'
import TextRegisterModal from '@/components/common/modal/TextRegisterModal.vue'
import CommentRegisterModal from '@/components/common/modal/CommentRegisterModal.vue'
import SlideUpModal from '../../../components/common/modal/SlideUpModal.vue'
import { axiosAuthInstance } from '@/store/axios'

export default {
  data() {
    return {
      parameters: {
        page: 1,
        pageSize: 50,
        start: 0,
        isMyLounge: true,
        sort: 'NEWEST'
      },
      tabList: [
        { id: 0, text: '내 의견', isActive: true },
        { id: 1, text: '댓글', isActive: false }
      ],
      tab: 0,
      editShow: false,
      deleteShow: false,
      commentShow: false,
      editListShow: false,
      mypagePosting,
      mypageComent,
      toggleList: [],
      lounges: [],
      loungeComments: []
    }
  },
  created() {
    this.getLounges()
  },
  methods: {
    clickTab(idx) {
      this.tab = idx
      if (idx == 0) {
        this.getLounges()
      } else {
        this.getLoungComment()
      }
    },
    openEditModal() {
      this.editListShow = true
    },
    openSetEdit(type, id) {
      this.editListShow = false
      if (type === 'edit' && id) this.editShow = true
      if (type === 'delete' && id) this.deleteShow = true
      if (type === 'comment' && id) this.commentShow = true
    },
    getLounges() {
      axiosAuthInstance
        .get('/lounge', { params: this.parameters })
        .then((response) => {
          this.lounges = response.lounges
        })
        .catch((error) => {
          console.log('error', error)
        })
    },
    getLoungComment() {
      axiosAuthInstance
        .get('/lounge/comment', { params: this.parameters })
        .then((response) => {
          this.loungeComments = response.loungeComments
        })
        .catch((error) => {
          console.log('error', error)
        })
    },
    moveLoungDetail(value) {
      this.$router.push({ name: 'RoungeDetail', query: { loungeId: value } })
    }
  },
  components: {
    ThumbBox,
    TextRegisterModal,
    CommentRegisterModal,
    SlideUpModal
  }
}
</script>

<style lang="scss" scoped>
.list-title-box {
  width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.desc-box {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.posting {
  &-tab {
    display: flex;
    align-items: center;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #e8e8e8;
    div {
      width: 100%;
      height: 48px;

      color: #aaa;
      font-size: 16px;
      font-weight: 600;
    }
    .active {
      position: relative;
    }
    .active::after {
      content: '';
      display: block;
      position: absolute;
      bottom: -1.5px;
      width: 95%;
      left: 50%;
      transform: translateX(-50%);
      height: 2px;
      background-color: #222;
    }
  }
}

// 리스트 컴포넌트
.posting-list,
.comment-list {
  &-box {
    &.on {
      .desc-box {
        text-overflow: unset;
      }
    }
    padding: 24px 4.4444444%;
    border-bottom: 1px solid #f1f1f1;
    &-date {
      color: #aaa;
      text-align: left;
      font-size: 12px;
      margin-bottom: 11px;
    }

    &-title {
      font-weight: 500;
      margin-bottom: 4px;
    }

    &-status {
      display: flex;
      align-items: center;
      img {
        width: 20px;
        height: 20px;
        margin-right: 4px;
      }
      span {
        color: #aaa;

        font-size: 12px;

        margin-right: 14px;
      }
      span:last-child {
        margin-right: 0;
      }
    }
    &-description {
      color: #666;
    }

    &-img {
      margin-top: 12px;
      width: 100%;
      min-height: 164px;
      max-height: 164px;
      background-color: #000;
      border-radius: 6px;
      overflow: hidden;
    }
    &-add-view {
      color: #aaa;

      display: none;
      margin-top: 14px;
    }
    &-add-view.show {
      display: block;
    }
  }
}

// .edit {
//   &-modal {
//     padding: 40px 24px;
//     width: 100%;
//     border-top-left-radius: 14px;
//     border-top-right-radius: 14px;
//     height: 221px;
//     background-color: #fff;
//     position: absolute;
//     bottom: 0;
//     &-item {
//       color: #666;
//       font-size: 16px;
//       font-weight: 500;

//       height: 50px;
//     }
//   }

//   // 글쓰기
//   &-post,
//   &-comment {
//     border-top-left-radius: 14px;
//     border-top-right-radius: 14px;
//     position: absolute;
//     bottom: 0;
//     width: 100%;
//     padding: 38px 24px 60px;
//     background-color: #fff;
//     max-height: 90vh;
//     overflow-y: scroll;
//     &-title {
//       font-size: 18px;
//       font-weight: 600;
//       margin-bottom: 30px;
//     }
//     &-menu {
//       position: relative;
//       width: 100%;
//       height: 42px;
//       margin-bottom: 40px;
//       &-box {
//         width: 100%;
//         display: flex;
//         overflow-x: scroll;
//         position: absolute;
//         &-item {
//           text-align: center;
//           flex-shrink: 0;
//           flex-basis: 90px;
//           height: 42px;

//           border-radius: 10px;
//           border: 1px solid #e8e8e8;
//           color: #999;
//           margin-right: 8px;
//         }
//         .active {
//           color: #4a4ee7;
//           border: 1px solid #4a4ee7;
//         }
//       }
//     }
//     &-input {
//       width: 100%;
//       padding-bottom: 6px;
//       border-bottom: 2px solid #f1f1f1;
//       margin-bottom: 8px;
//       font-size: 20px;
//     }
//     &-input::placeholder {
//       color: #bfbfbf;
//     }
//     h3 {
//       color: #999;
//       font-size: 12px;

//       margin-bottom: 6px;
//     }
//     textarea {
//       font-size: 20px;
//     }
//     &-title-length,
//     &-description-length {
//       text-align: right;
//       margin-bottom: 30px;
//       color: #bfbfbf;
//       font-size: 12px;
//     }
//     &-photo-list {
//       display: grid;
//       grid-template-columns: 1fr 1fr 1fr 1fr;
//       justify-content: space-around;
//       grid-gap: 8px;
//       li {
//         width: 20.54054054vw;
//         height: 20.54054054vw;
//         background-color: lightgreen;
//         position: relative;
//         // margin: 0 auto 8px;
//       }
//       li:after {
//         content: 'x';
//         color: #fff;
//         text-align: center;

//         display: block;
//         position: absolute;
//         top: 2px;
//         right: 2px;
//         width: 18px;
//         height: 18px;
//         background-color: #000;
//         border-radius: 50%;
//       }
//     }
//   }

//   // 삭제하기
//   &-delete {
//     display: flex;
//     flex-wrap: wrap;
//     padding: 24px 16px 16px;
//     width: 80.55555%;
//     border-radius: 14px;
//     position: absolute;
//     left: 50%;
//     top: 50%;
//     transform: translate(-50%, -50%);

//     background-color: #fff;
//     li {
//       font-weight: 600;
//     }
//     li:first-child {
//       font-size: 16px;
//       font-weight: 500;
//       width: 50%;
//       width: 100%;
//     }
//     li:nth-child(2),
//     li:nth-child(3) {
//       margin-top: 24px;
//       width: 48.449612403%;
//       height: 42px;

//       text-align: center;
//       border-radius: 14px;
//     }

//     li:nth-child(2) {
//       background: #edeeff;
//       color: #4a4ee7;
//     }
//     li:nth-child(3) {
//       background: #4a4ee7;
//       color: #fff;
//       margin-left: auto;
//     }
//   }
//   .photo-desc {
//     padding-top: 10px;
//     color: #bfbfbf;
//     font-size: 12px;
//   }
// }
.edit-modal {
  .edit-modal-list {
    li {
      font-size: 16px;
      padding: 14px 0;
    }
  }
}
</style>
