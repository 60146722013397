// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.transparent {
  background-color: transparent !important;
}
.fixed-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  width: 100%;
  top: 0;
  padding: 0 16px;
  height: 48px;
  background-color: #fff;
  z-index: 99;
}
.fixed-head.no-sticky {
  position: relative;
}
.fixed-head > .page-tit {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  font-weight: 600;
}
.fixed-head > .page-tit.color_w {
  color: #fff;
}
.fixed-head .my-info-update {
  color: #4a4ee7;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
