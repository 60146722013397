var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "examine wrapper" },
    [
      _c("div", { staticClass: "examine-top" }, [
        _c("h2", { staticClass: "examine-top-title" }, [_vm._v("심사항목")]),
        _vm.isPortfolio
          ? _c("div", { staticClass: "sub-box" }, [
              _c("p", { staticClass: "examine-top-description" }, [
                _vm._v(_vm._s(_vm.reviewData.rowTitle)),
              ]),
              _c(
                "button",
                {
                  staticClass: "flex-between",
                  attrs: { type: "button" },
                  on: { click: _vm.movePortfolioDetail },
                },
                [_vm._v(" 상세 "), _c("i", { staticClass: "icon--arrow-move" })]
              ),
            ])
          : _vm._e(),
      ]),
      [
        _c("ul", { staticClass: "examine-status" }, [
          _c(
            "li",
            {
              staticClass: "examine-status-item",
              class: _vm.isActive("REQUEST"),
            },
            [_vm._v(" 심사 접수 ")]
          ),
          _c("li", { staticClass: "bar" }),
          _c(
            "li",
            {
              staticClass: "examine-status-item",
              class: _vm.isActive("UNDER_REVIEW"),
            },
            [_vm._v(" 심사중 ")]
          ),
          _c("li", { staticClass: "bar" }),
          _c(
            "li",
            {
              staticClass: "examine-status-item",
              class: _vm.isActive("APPROVAL"),
            },
            [_vm._v(" 심사 완료 ")]
          ),
        ]),
      ],
      [
        _c("div", { staticClass: "examine-text" }, [
          _c("div", { staticClass: "examine-text-box" }, [
            _vm.lastReview.reviewStatus === "APPROVAL"
              ? _c("div", [
                  _c("p", [_vm._v(_vm._s(_vm.lastReview.comment))]),
                  _vm.lastReview.reviewStatus === "REJECT"
                    ? _c("div", [
                        _c(
                          "div",
                          { staticClass: "examine-text-box-status reject" },
                          [_vm._v("결과: 반려")]
                        ),
                        _c("p", { staticClass: "examine-text-box-reject" }, [
                          _vm._v(" " + _vm._s(_vm.lastReview.comment) + " "),
                        ]),
                      ])
                    : _vm._e(),
                ])
              : _c("div", [
                  _c("p", { staticClass: "examine-text-box-description" }, [
                    _vm._v(" " + _vm._s(_vm.lastReview.comment) + " "),
                  ]),
                ]),
          ]),
        ]),
      ],
      [
        _c("div", { staticClass: "examine-list" }, [
          _c("div", { staticClass: "examine-list-nav flex-between" }, [
            _c("p", { staticClass: "examine-list-nav-title" }, [
              _vm._v("진행내역"),
            ]),
            _vm.lastReview.reviewStatus === "REQUEST"
              ? _c(
                  "button",
                  { attrs: { type: "button" }, on: { click: _vm.cancelExam } },
                  [
                    _vm._v(" 심사 요청취소 "),
                    _c("i", { staticClass: "icon--arrow-move" }),
                  ]
                )
              : _vm._e(),
          ]),
          _c(
            "ul",
            { staticClass: "examine-list-box" },
            _vm._l(_vm.reviewData.reviews, function (item) {
              return _c(
                "li",
                { key: item.reviewId, staticClass: "examine-list-box-item" },
                [
                  _c("span", { staticClass: "examine-list-box-item-date" }, [
                    _vm._v(" " + _vm._s(item.processDate)),
                  ]),
                  _c("span", [_vm._v(_vm._s(item.comment))]),
                ]
              )
            }),
            0
          ),
        ]),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }