var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex coll white-layer", attrs: { id: "authority-wrap" } },
    [
      _vm._m(0),
      _vm._m(1),
      _c("p", { staticClass: "q-sub-txt txt-size--s" }, [
        _vm._v(
          " ※ 선택 접근 권한은 동의하지 않아도 서비스 이용 할 수 있습니다. "
        ),
      ]),
      _c(
        "button",
        { staticClass: "base-btn cl-pri full", on: { click: _vm.goAuthBtn } },
        [_c("span", { staticClass: "txt-size--m" }, [_vm._v("확인")])]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "page-title-section" }, [
      _c("h4", [_vm._v("이용을 위해 권한 허용이 필요해요")]),
      _c("p", { staticClass: "q-sub-txt" }, [
        _vm._v(" 퀀트마켓 이용을 위해 다음 권한의 허용이 필요합니다. "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", { staticClass: "authority-list" }, [
      _c("li", [
        _c("i", { staticClass: "icon--call" }),
        _c("div", [
          _c("p", { staticClass: "txt-size--s" }, [_vm._v("전화(필수)")]),
          _c("span", { staticClass: "q-sub-txt txt-size--xs" }, [
            _vm._v("휴대전화 본인인증, 기기 식별인증 및 전화 연결"),
          ]),
        ]),
      ]),
      _c("li", [
        _c("i", { staticClass: "icon--photo" }),
        _c("div", [
          _c("p", { staticClass: "txt-size--s" }, [_vm._v("저장(필수)")]),
          _c("span", { staticClass: "q-sub-txt txt-size--xs" }, [
            _vm._v("사진, 미디어, 파일 저장 및 불러오기 접근 허용"),
          ]),
        ]),
      ]),
      _c("li", [
        _c("i", { staticClass: "icon--camera" }),
        _c("div", [
          _c("p", { staticClass: "txt-size--s" }, [_vm._v("카메라(선택)")]),
          _c("span", { staticClass: "q-sub-txt txt-size--xs" }, [
            _vm._v("실명인증을 위한 신분증 또는 면허증 제출"),
          ]),
        ]),
      ]),
      _c("li", [
        _c("i", { staticClass: "icon--area" }),
        _c("div", [
          _c("p", { staticClass: "txt-size--s" }, [_vm._v("위치(선택)")]),
          _c("span", { staticClass: "q-sub-txt txt-size--xs" }, [
            _vm._v("주소 찾기"),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }