var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "rounge-wrap" },
    [
      _vm.declarShow
        ? _c("SlideUpModal", {
            staticClass: "edit-modal",
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "flex-just mb-16" }, [
                        _c("h4", [_vm._v("이 의견을 신고하는 이유")]),
                        _c("i", {
                          staticClass: "icon--close",
                          on: {
                            click: function ($event) {
                              _vm.declarShow = false
                            },
                          },
                        }),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "main",
                  fn: function () {
                    return [
                      _c(
                        "ul",
                        { staticClass: "declar-list" },
                        _vm._l(_vm.declarList, function (d, i) {
                          return _c(
                            "li",
                            { key: i + "declar", staticClass: "input-list" },
                            [
                              _c("div", { staticClass: "l-box" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.selectedDeclarList,
                                      expression: "selectedDeclarList",
                                    },
                                  ],
                                  attrs: { id: d.id, type: "radio" },
                                  domProps: {
                                    value: d.id,
                                    checked: _vm._q(
                                      _vm.selectedDeclarList,
                                      d.id
                                    ),
                                  },
                                  on: {
                                    change: function ($event) {
                                      _vm.selectedDeclarList = d.id
                                    },
                                  },
                                }),
                                _c("label", { attrs: { for: d.id } }),
                              ]),
                              _c("div", { staticClass: "r-box" }, [
                                _c("p", [_vm._v(_vm._s(d.title))]),
                              ]),
                            ]
                          )
                        }),
                        0
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "button",
                  fn: function () {
                    return [
                      _c(
                        "button",
                        {
                          staticClass: "base-btn full cl-pri",
                          on: {
                            click: function ($event) {
                              return _vm.report()
                            },
                          },
                        },
                        [_c("span", [_vm._v("확인")])]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              4235255348
            ),
          })
        : _vm._e(),
      _vm.editComentShow
        ? _c("SlideUpModal", {
            staticClass: "edit-modal",
            on: {
              close: function ($event) {
                _vm.editComentShow = false
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "main",
                  fn: function () {
                    return [
                      _c("ul", { staticClass: "edit-modal-list" }, [
                        _c("li", { on: { click: _vm.showReport } }, [
                          _vm._v("신고하기"),
                        ]),
                        _vm.isOwner
                          ? _c("li", { on: { click: _vm.moveUpdate } }, [
                              _vm._v("수정하기"),
                            ])
                          : _vm._e(),
                        _vm.isOwner
                          ? _c("li", { on: { click: _vm.deleteComment } }, [
                              _vm._v("삭제하기"),
                            ])
                          : _vm._e(),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              1685485161
            ),
          })
        : _vm._e(),
      _vm.toggleCommentModal
        ? _c("CommentRegisterModal", {
            attrs: {
              "lounge-id": _vm.loungeId,
              "is-create": _vm.isCreate,
              "update-target": _vm.updateTarget,
            },
            on: { close: _vm.saveComplete },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "board-section" },
        [
          _c("BoardItemBox", {
            attrs: { "is-detail": true, data: _vm.loungeData, active: true },
            on: { "toggle:edit": _vm.toggleEditModal },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "comment-section" }, [
        _vm.loungeData.loungeComments.length > 0
          ? _c(
              "ul",
              { staticClass: "coment-list" },
              _vm._l(_vm.loungeData.loungeComments, function (d, i) {
                return _c("li", { key: i + "comm" }, [
                  _c("div", { staticClass: "comment" }, [
                    _c("div", { staticClass: "flex-between" }, [
                      _c("div", { staticClass: "prof-box" }, [
                        _c("div", { staticClass: "thumb mr-8" }, [
                          d.storageAccess
                            ? _c("img", {
                                attrs: { src: d.storageAccess, alt: "" },
                              })
                            : _c("i", { staticClass: "icon--profil-temp" }),
                        ]),
                        _c("span", { staticClass: "user-name" }, [
                          _vm._v(_vm._s(d.nickName)),
                        ]),
                        _c("span", { staticClass: "date ml-4" }, [
                          _vm._v(" " + _vm._s(d.createdDate) + " "),
                        ]),
                      ]),
                      _c("ul", { staticClass: "status-box" }, [
                        _c("li", [
                          _c("i", {
                            staticClass: "icon--dot",
                            on: {
                              click: function ($event) {
                                return _vm.toggleEditComentModal(
                                  d.commentId,
                                  d.isOwner
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "body" },
                      [
                        _c("p", { staticClass: "board-desc" }, [
                          _vm._v(" " + _vm._s(d.commentTitle) + " "),
                        ]),
                        _c("p", { staticClass: "board-desc" }, [
                          _vm._v(" " + _vm._s(d.commentContents) + " "),
                        ]),
                        _c("ThumbBox", { attrs: { thumb: d.storageAccesses } }),
                      ],
                      1
                    ),
                  ]),
                ])
              }),
              0
            )
          : _c("div", { staticClass: "empty" }, [
              _c("i", { staticClass: "icon--coment-empty" }),
              _c("p", [_vm._v("서로에게 따뜻한 의견을 남겨주세요.")]),
            ]),
        _vm.$store.getters.getUser.isUser
          ? _c("div", { staticClass: "coment-input-wrap" }, [
              _c(
                "div",
                {
                  staticClass: "round-input",
                  on: { click: _vm.openCreateModal },
                },
                [_c("span", [_vm._v("댓글을 입력해주세요")])]
              ),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }