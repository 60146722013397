<template>
  <div
    v-if="isDisplay"
    class="rounge-card-view"
    @click="goLounge(rounge.loungeId)"
  >
    <div class="top-box">
      <div class="l-box">
        <div class="rounge-type">{{ rounge.category }}</div>
        <div class="rounge-profil">
          <div class="prof-thumb">
            <img
              v-if="rounge.storageAccess"
              :src="rounge.storageAccess"
              alt=""
            />
            <i v-else class="icon--profil-temp"></i>
          </div>
          <span class="profil-name">{{ rounge.nickName }}</span>
        </div>
      </div>
      <div class="r-box" v-if="rounge.storageAccesses[0]">
        <img class="lounge-img" :src="rounge.storageAccesses[0]" alt="" />
      </div>
    </div>
    <div class="bot-box">
      <p>{{ rounge.loungeContents }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QuantMarketRoungeCard',
  props: {
    rounge: {
      type: Object,
      default: () => {
        return {
          loungeId: 0,
          category: '',
          storageHash: '',
          loungeTitle: '',
          loungeContents: '',
          storageHashes: []
        }
      }
    }
  },
  data() {
    return {}
  },
  computed: {
    isDisplay() {
      return Object.keys(this.rounge).length > 0
    }
  },

  mounted() {},

  methods: {
    goLounge(loungeId) {
      this.$router
        .push({
          name: 'RoungeDetail',
          query: { loungeId: loungeId }
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.rounge-card-view {
  padding: 24px 20px;
  width: 287px;
  height: 184px;
  border-radius: 14px;
  background: #fff;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.14);

  .top-box {
    display: flex;
    margin-bottom: 12px;
    .l-box {
      flex: 1;
      display: flex;
      gap: 12px;
      align-items: flex-start;
      flex-direction: column;
      justify-content: space-between;
      .rounge-type {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 10px;
        height: 28px;
        border-radius: 50px;
        border: 1px solid #4a4ee7;
        color: #4a4ee7;
        font-size: 12px;
      }
      .rounge-profil {
        display: flex;
        align-items: center;
        gap: 8px;
        .prof-thumb {
          width: 28px;
          height: 28px;
          > img {
            width: 100%;
            height: 100%;
          }
        }
        .profil-name {
          color: #666;
        }
      }
    }
    .r-box {
      width: 68px;
      height: 68px;
      background-color: lightblue;
      border-radius: 6px;
      overflow: hidden;
      flex-shrink: 0;
      .lounge-img {
        width: 100%;
      }
    }
  }
  .bot-box {
    p {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      font-size: 14px;
      font-weight: 400;
      color: #999;
      line-height: 140%;
    }
  }
}
</style>
