<template>
  <div>
    <slot name="header"></slot>
    <!-- 리스트 비동기 예외처리 추가 -->
    <template v-if="list.length > 0">
      <template v-if="type === 'rounge'">
        <div class="quant-swiper-box">
          <swiper
            class="pt-14 pb-48 lounge-swiper"
            slides-per-view="auto"
            :space-between="12"
            :loop="false"
          >
            <swiper-slide v-for="(data, idx) in list" :key="idx">
              <RoungeCard :rounge="data" />
            </swiper-slide>
          </swiper></div
      ></template>
      <template v-else-if="type === 'rank'">
        <div class="quant-swiper-box">
          <swiper
            class="pt-8"
            slides-per-view="auto"
            :space-between="12"
            :loop="false"
            @click="goRank"
          >
            <swiper-slide
              v-for="(
                { portfolioName, rorValueIndex, storageAccess }, idx
              ) in list"
              :key="idx"
            >
              <div class="quant-rank-slide-item" :class="type">
                <div class="slide-thumb">
                  <img :src="storageAccess" alt="" class="rank-image-center" />
                  <span class="rank-no">{{ idx + 1 }}</span>
                </div>
                <div class="slide-txt-cont flex coll center mt-8">
                  <span
                    class="percent"
                    :class="isDown(rorValueIndex) ? 'status' : ''"
                    >{{ getSign(rorValueIndex) }}{{ rorValueIndex }}%</span
                  >
                  <span class="ranker-name">{{ portfolioName }}</span>
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </div></template
      >
      <template v-else-if="type === 'port'">
        <div class="quant-swiper-box">
          <swiper slides-per-view="auto" :space-between="12" :loop="false">
            <swiper-slide
              v-for="(
                { portfolioId, styleType, portfolioName, nickName }, idx
              ) in list"
              :key="idx"
            >
              <div
                class="quant-port-slide-item"
                :class="type"
                @click="goPortfolioDetail(portfolioId)"
              >
                <div class="circle-box">
                  <i
                    class="icon--state size-big"
                    :class="`${portfolioType(styleType)}`"
                  ></i>
                  <strong>#{{ portfolioTypeName(styleType) }}</strong>
                </div>
                <p class="port-title no-wrap-text">{{ portfolioName }}</p>
                <span class="user-name">{{ nickName }}</span>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </template>
      <template v-else-if="type === 'shopping'">
        <div class="quant-swiper-box">
          <swiper
            class="pt-16"
            slides-per-view="auto"
            :space-between="8"
            :loop="false"
          >
            <swiper-slide v-for="(data, idx) in list" :key="idx">
              <Product :data="data" />
            </swiper-slide>
          </swiper></div
      ></template>
      <template v-else>
        <div class="quant-swiper-box">
          <swiper slides-per-view="auto" :space-between="12" :loop="false">
            <swiper-slide
              v-for="({ communityId, name, storageAccesses }, idx) in list"
              :key="idx"
            >
              <div
                class="quant-slide-item"
                :class="type"
                @click="goCommunityDetail(communityId)"
              >
                <div class="slide-thumb">
                  <img :src="storageAccesses[0]" alt="" />
                </div>
                <p class="slide-desc">{{ name }}</p>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import swiperSettings from '@/data/swiperSettings.js'

import { Swiper, SwiperSlide } from 'swiper-vue2'
import Product from './Product.vue'
// import 'swiper/css/swiper.css'
// import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
import RoungeCard from './RoungeCard.vue'
export default {
  name: 'SliderContents',
  components: { Swiper, SwiperSlide, RoungeCard, Product },
  props: {
    list: {
      type: Array,
      default: () => {
        return []
      }
    },
    type: {
      type: String,
      default: 'square'
    }
  },
  data() {
    return { ...swiperSettings }
  },

  mounted() {},

  methods: {
    goPortfolioDetail(portfolioId) {
      this.$router
        .push({
          name: 'PortfolioDetail',
          params: { id: portfolioId }
        })
        .catch(() => {})
    },
    goCommunityDetail(communityId) {
      this.$router
        .push({
          name: 'CommunityDetail',
          query: { communityId: communityId }
        })
        .catch(() => {})
    },
    goRank() {
      this.$router.push({ name: 'Ranking' }).catch(() => {})
    },
    portfolioTypeName(type) {
      switch (type) {
        case 'GROWTH':
          return '성장형'
        case 'VALUE':
          return '가치형'
        case 'MOMENTUM':
          return '모멘텀'
        case 'DIVIDEND':
          return '배당형'
        case 'LOWVOL':
          return '안정형'
        case 'QUALITY':
          return '우량형'
        case 'STOCKS':
          return '직접 선택'
      }
    },
    portfolioType(type) {
      switch (type) {
        case 'GROWTH':
          return 'exp'
        case 'VALUE':
          return 'val'
        case 'MOMENTUM':
          return 'moe'
        case 'DIVIDEND':
          return 'money'
        case 'LOWVOL':
          return 'safe'
        case 'QUALITY':
          return 'big'
        case 'STOCKS':
          return 'stocks'
      }
    },
    isDown(rorValueIndex) {
      return rorValueIndex < 0
    },
    getSign(rorValueIndex) {
      return rorValueIndex >= 0 ? '+' : ''
    }
  }
}
</script>

<style lang="scss" scoped>
.rank-image-center {
  position: absolute !important;
  width: 70% !important;
  height: 70% !important;
  left: 15% !important;
  top: 15% !important;
}
.quant-swiper-box {
  display: flex;
  justify-content: space-between;
  width: 100%;
  //   height: 360px;
  align-items: center;
  .swiper-container {
    margin: 0;
  }
  .swiper-slide {
    flex-shrink: 1;
  }
}
.quant-slide-item {
  .slide-thumb {
    width: 132px;
    height: 132px;
    border-radius: 8px;
    overflow: hidden;
    background-color: grey;
    > img {
      display: inline-block;
      width: 100%;
      height: 100%;
    }
  }
  .slide-desc {
    font-size: 14px;
    color: #666;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  &.rect {
    .slide-thumb {
      width: 153px;
      height: 100px;
      border-radius: 14px;
      overflow: hidden;
    }
    .slide-desc {
      color: #222;
    }
  }
}
.quant-rank-slide-item {
  .slide-thumb {
    // overflow: hidden;
    border-radius: 50%;
    width: 92px;
    height: 92px;
    position: relative;
    border: 2px solid #feea61;
    > img {
      border-radius: 50%;
      width: 100%;
      height: 100%;
    }
    > .rank-no {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #444;
      color: #fff;
      border-radius: 50%;
      left: 2px;
      top: 5px;
      width: 17px;
      height: 17px;
    }
    &::after {
      content: '';
      position: absolute;
      display: inline-block;
      top: -0;
      left: 50%;
      width: 22px;
      height: 14px;
      transform: translate(-50%, -9px);
      background-image: url('~@/assets/images/icon_crown.svg');
    }
  }
  .slide-txt-cont {
    .percent {
      color: #d74343;
      font-size: 16px;
      &.status {
        color: #4a4ee7;
      }
    }
    .ranker-name {
      font-size: 12px;
      color: #aaa;
    }
  }
}
.quant-port-slide-item {
  text-align: center;
  .circle-box {
    border-radius: 50%;
    width: 92px;
    height: 92px;
    border-radius: 50px;
    background: #fafafb;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 6px;
    // margin-bottom: ;
    strong {
      color: #4a4ee7;
      font-size: 16px;
    }
  }
  .port-title {
    font-size: 14px;
    color: #444;
    margin: 8px 0 0;
    max-width: 92px;
    min-width: 92px;
  }
  .user-name {
    font-size: 12px;
    color: #aaa;
  }
}
.lounge-swiper {
  padding-left: 10px;
}
</style>
