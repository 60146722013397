import { getPortfolioStyleds, getSearchStock } from '@/api'

const state = {
  searchList: [],
  portfolioStyledList: [],
  portfolioCalculationData: {},
  addType: {
    type: '',
    list: [],
    styleType: 'GROWTH',
    updDateTp: 'MONTHLY',
    updAmountTp: 'EQUAL_WEIGHT',
    startDate: '',
    endDate: '',
    factor: []
  }
}

const getters = {
  getAddType: (state) => state.addType,
  getSearchList: (state) => state.searchList,
  getPortfolioCalculationData: (state) => state.portfolioCalculationData,
  getPortfolioStyledList: (state) => state.portfolioStyledList
}

const actions = {
  getSearchStock({ commit }) {
    return new Promise((resolve, reject) => {
      getSearchStock()
        .then((resp) => {
          const stockDataList = resp.stockDataList.map((data) => ({
            ...data,
            checked: false
          }))
          commit('SET_SEARCH_LIST', stockDataList)
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getPortfolioStyled({ commit }) {
    return new Promise((resolve, reject) => {
      getPortfolioStyleds()
        .then((resp) => {
          commit('SET_PORTFOLIO_STYLED_LIST', resp.portfoliStyleDataList)
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

const mutations = {
  SET_SEARCH_LIST(state, payload) {
    state.searchList = payload
  },
  SET_PORTFOLIO_STYLED_LIST(state, payload) {
    state.portfolioStyledList = payload
  },
  SET_PORTFOLIO_CALCULATION_DATA(state, payload) {
    state.portfolioCalculationData = payload
  },
  SET_STEP(
    state,
    {
      list,
      type,
      updDateTp,
      updAmountTp,
      startDate,
      endDate,
      styleType,
      factor
    }
  ) {
    if (list) state.addType.list = list
    if (type) state.addType.type = type
    if (updDateTp) state.addType.updDateTp = updDateTp
    if (updAmountTp) state.addType.updAmountTp = updAmountTp
    if (startDate) state.addType.startDate = startDate
    if (endDate) state.addType.endDate = endDate

    //
    if (styleType) state.addType.styleType = styleType
    if (factor) state.addType.factor = factor

    if (
      !list &&
      !type &&
      !updDateTp &&
      !updAmountTp &&
      !startDate &&
      !endDate &&
      !styleType &&
      !factor
    ) {
      state.addType = {
        list: [],
        type: '',
        styleType: 'GROWTH',
        updDateTp: 'MONTHLY',
        updAmountTp: 'EQUAL_WEIGHT',
        startDate: '',
        endDate: '',
        factor: []
      }
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
