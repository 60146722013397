<template>
  <div class="step-box">
    <p class="info">
      <span>{{ stage }}</span
      >/{{ step }}
    </p>
    <div class="bar">
      <div class="inner-slider" :style="barStatus"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QuantMarketStatusBar',
  props: {
    stage: {
      type: Number,
      default: 3
    },
    step: {
      type: Number,
      default: 8
    }
  },
  data() {
    return {}
  },
  computed: {
    barStatus() {
      const calc = 100 - (100 / this.step) * this.stage
      return { transform: `translateX(-${calc}%)` }
    }
  },

  mounted() {},

  methods: {}
}
</script>

<style lang="scss" scoped>
.step-box {
  flex-shrink: 0;
  overflow: hidden;
  .info {
    text-align: end;
    color: #999;
    font-size: 12px;
    > span {
      color: #222;
      font-size: 12px;
    }
  }
  .bar {
    position: relative;
    width: 100%;
    height: 2px;
    border-radius: 50px;
    background: #e8e8e8;
    .inner-slider {
      // position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 50px;
      background: #4a4ee7;
    }
  }
}
</style>
