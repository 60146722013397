// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.current-status-tit[data-v-268efea0] {
  color: #222;
  font-family: Pretendard;
  font-size: 18px;
}
.current-status-tit strong[data-v-268efea0] {
  color: #d74343;
}
.range-slide-box .slide-mark[data-v-268efea0] {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}
.range-slide-box .slide-mark > span[data-v-268efea0] {
  color: #999;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 12px */
  letter-spacing: -0.24px;
  min-width: 24px;
  text-align: center;
}
[data-v-268efea0] .vue-slider-rail {
  background: #e8e8e8;
}
[data-v-268efea0] .vue-slider-process {
  background-color: #444444;
}
[data-v-268efea0] .vue-slider-dot-handle {
  background-color: #fff;
  stroke-width: 1px;
  border: 1px solid #e8e8e8;
  filter: drop-shadow(1px 4px 9px rgba(0, 0, 0, 0.12));
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
