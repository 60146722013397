// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#main-wrap[data-v-c1f1971a] {
  padding: 8px 16px 56px;
}
#main-wrap .box-section .cont-box[data-v-c1f1971a] {
  border-radius: 14px;
  background: #fff;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.14);
  padding: 32px 20px;
}
#main-wrap .box-section .cont-box .cont-box-head[data-v-c1f1971a] {
  margin-bottom: 22px;
}
#main-wrap .box-section .popular-list[data-v-c1f1971a] {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
#main-wrap .box-section .popular-list > li[data-v-c1f1971a] {
  display: flex;
}
#main-wrap .box-section .popular-list > li .popular-prof[data-v-c1f1971a],
#main-wrap .box-section .popular-list > li .popular-thumb[data-v-c1f1971a] {
  flex-shrink: 0;
  overflow: hidden;
  width: 40px;
  height: 40px;
}
#main-wrap .box-section .popular-list > li .popular-prof > img[data-v-c1f1971a],
#main-wrap .box-section .popular-list > li .popular-thumb > img[data-v-c1f1971a] {
  width: 100%;
  height: 100%;
}
#main-wrap .box-section .popular-list > li .popular-desc[data-v-c1f1971a] {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
#main-wrap .box-section .popular-list > li .popular-prof[data-v-c1f1971a] {
  border-radius: 50px;
  margin-right: 14px;
}
#main-wrap .box-section .popular-list > li .popular-thumb[data-v-c1f1971a] {
  margin-left: 16px;
  border-radius: 6px;
}
#main-wrap .main-banner-section[data-v-c1f1971a] {
  position: relative;
  overflow: hidden;
  border-radius: 14px;
  width: 100%;
  padding-top: 52%;
  background-color: red;
}
#main-wrap .bannr-section[data-v-c1f1971a] {
  margin: 24px 0;
}
#main-wrap .bannr-section img[data-v-c1f1971a] {
  display: inline-block;
  width: 100%;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
