var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "layer" },
    [
      _c("div", { staticClass: "fixed-head" }, [
        _vm.userBack
          ? _c("i", {
              staticClass: "icon--arrow-back",
              on: { click: _vm.back },
            })
          : _vm._e(),
        _c("h5", { staticClass: "page-tit" }, [_vm._v(_vm._s(_vm.pageTitle))]),
        _vm.useClose ? _c("i", { staticClass: "icon--close" }) : _vm._e(),
      ]),
      _c("router-view"),
      _c("BotMenu"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }