<template>
  <div id="add-step-01">
    <div class="page-title-section mb-24">
      <h4>구성종목을 추가하세요</h4>
      <p class="q-sub-txt">(최대 5개)</p>
    </div>
    <ul class="regist-list">
      <li v-for="(d, i) in list" :key="i + 'list'" class="round-box">
        <span>종목{{ i + 1 }}</span>
        <p>{{ d.stockName }}</p>
        <i class="icon--delete" @click="removeList(d)"></i>
      </li>
      <li
        v-if="list.length < 5"
        class="add-button round-box flex-center"
        @click="toggleSearchModal = true"
      >
        <span>종목 추가하기<i class="icon--plus ml-4"></i></span>
      </li>
    </ul>
    <StrategySearchModal
      v-if="toggleSearchModal"
      @close="toggleSearchModal = false"
      v-bind:stockCheckList="this.list"
      @save="addList"
    ></StrategySearchModal>
  </div>
</template>

<script>
import StrategySearchModal from '@/components/common/modal/StrategySearchModal'

export default {
  name: 'QuantMarketAddStrategyType1',
  components: { StrategySearchModal },
  data() {
    return {
      toggleSearchModal: false
    }
  },
  mounted() {},
  computed: {
    list: {
      get() {
        return this.$store.getters.getAddType.list
      },
      set(list) {
        this.$store.commit('SET_STEP', { list })
      }
    }
  },
  methods: {
    addList(list) {
      this.list = [...list]
      this.toggleSearchModal = false
    },
    removeList(data) {
      const { stockId } = data
      this.list = this.list.filter((e) => e.stockId !== stockId)
    },
    goNext() {
      this.$emit('move')
    }
  }
}
</script>
<style lang="scss" scoped>
#add-step-01 {
  display: flex;
  flex-direction: column;
  flex: 1;
  .base-btn {
    margin-top: auto;
  }
  .regist-list {
    .round-box p {
      color: #222;

      font-size: 16px;
      font-weight: 600;
    }
  }
}
</style>
