// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.board-item[data-v-183b6654] {
  border-bottom: 1px solid #f1f1f1;
  padding: 24px 16px;
}
.board-item.on .board-desc[data-v-183b6654] {
  text-overflow: unset;
  -webkit-line-clamp: unset;
}
.board-item .prof-box[data-v-183b6654] {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
}
.board-item .prof-box .date[data-v-183b6654] {
  color: #aaa;
  font-size: 12px;
}
.board-item .status-box[data-v-183b6654] {
  display: flex;
  gap: 14px;
  align-items: center;
}
.board-item .status-box li[data-v-183b6654] {
  display: flex;
  align-items: center;
}
.board-item .status-box span[data-v-183b6654] {
  color: #aaa;
  font-size: 12px;
}
.board-item .status-box .edit[data-v-183b6654] {
  padding-bottom: 6px;
}
.board-item .board-title[data-v-183b6654] {
  color: #222;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  margin-bottom: 8px;
}
.board-item .board-desc[data-v-183b6654] {
  width: 100%;
  color: #666;
  font-size: 14px;
  margin-bottom: 14px;
}
.board-item .thumb-box[data-v-183b6654] {
  display: flex;
  border-radius: 6px;
  overflow: hidden;
}
.board-item .thumb-box > div[data-v-183b6654] {
  width: 50%;
}
.board-item .thumb-box > div > div[data-v-183b6654] {
  display: block;
  width: 100%;
  padding-top: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}
.board-item .thumb-box > div > div .more-no[data-v-183b6654] {
  position: absolute;
  font-size: 30px;
  font-weight: 700;
  top: 50%;
  left: 50%;
  color: #fff;
  transform: translate(-50%, -50%);
}
.board-item .more-text.show[data-v-183b6654] {
  margin-top: 14px;
  color: #aaa;
  font-size: 12px;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
