var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "filter-wrap" },
    [
      _c(
        "swiper",
        {
          attrs: { "slides-per-view": "auto", "space-between": 8, loop: false },
        },
        _vm._l(_vm.list, function (data, idx) {
          return _c("swiper-slide", { key: idx }, [
            _c(
              "div",
              {
                staticClass: "filter-tab",
                class: { on: _vm.tab === data.id },
                on: {
                  click: function ($event) {
                    return _vm.$emit("tab", data.id)
                  },
                },
              },
              [_vm._v(" " + _vm._s(data.text) + " ")]
            ),
          ])
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }