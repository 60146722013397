<template>
  <div class="error_st"></div>
</template>

<script>
export default {
  name: 'PageNotFound'
}
</script>

<style lang="scss" scoped>
.img {
  @media (min-width: 768px) {
    margin: 0 auto 0 0;
  }
}
</style>
