var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._t("header"),
      _vm.list.length > 0
        ? [
            _vm.type === "rounge"
              ? [
                  _c(
                    "div",
                    { staticClass: "quant-swiper-box" },
                    [
                      _c(
                        "swiper",
                        {
                          staticClass: "pt-14 pb-48 lounge-swiper",
                          attrs: {
                            "slides-per-view": "auto",
                            "space-between": 12,
                            loop: false,
                          },
                        },
                        _vm._l(_vm.list, function (data, idx) {
                          return _c(
                            "swiper-slide",
                            { key: idx },
                            [_c("RoungeCard", { attrs: { rounge: data } })],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]
              : _vm.type === "rank"
              ? [
                  _c(
                    "div",
                    { staticClass: "quant-swiper-box" },
                    [
                      _c(
                        "swiper",
                        {
                          staticClass: "pt-8",
                          attrs: {
                            "slides-per-view": "auto",
                            "space-between": 12,
                            loop: false,
                          },
                          on: { click: _vm.goRank },
                        },
                        _vm._l(
                          _vm.list,
                          function (
                            { portfolioName, rorValueIndex, storageAccess },
                            idx
                          ) {
                            return _c("swiper-slide", { key: idx }, [
                              _c(
                                "div",
                                {
                                  staticClass: "quant-rank-slide-item",
                                  class: _vm.type,
                                },
                                [
                                  _c("div", { staticClass: "slide-thumb" }, [
                                    _c("img", {
                                      staticClass: "rank-image-center",
                                      attrs: { src: storageAccess, alt: "" },
                                    }),
                                    _c("span", { staticClass: "rank-no" }, [
                                      _vm._v(_vm._s(idx + 1)),
                                    ]),
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "slide-txt-cont flex coll center mt-8",
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "percent",
                                          class: _vm.isDown(rorValueIndex)
                                            ? "status"
                                            : "",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.getSign(rorValueIndex)) +
                                              _vm._s(rorValueIndex) +
                                              "%"
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "ranker-name" },
                                        [_vm._v(_vm._s(portfolioName))]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ])
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                ]
              : _vm.type === "port"
              ? [
                  _c(
                    "div",
                    { staticClass: "quant-swiper-box" },
                    [
                      _c(
                        "swiper",
                        {
                          attrs: {
                            "slides-per-view": "auto",
                            "space-between": 12,
                            loop: false,
                          },
                        },
                        _vm._l(
                          _vm.list,
                          function (
                            { portfolioId, styleType, portfolioName, nickName },
                            idx
                          ) {
                            return _c("swiper-slide", { key: idx }, [
                              _c(
                                "div",
                                {
                                  staticClass: "quant-port-slide-item",
                                  class: _vm.type,
                                  on: {
                                    click: function ($event) {
                                      return _vm.goPortfolioDetail(portfolioId)
                                    },
                                  },
                                },
                                [
                                  _c("div", { staticClass: "circle-box" }, [
                                    _c("i", {
                                      staticClass: "icon--state size-big",
                                      class: `${_vm.portfolioType(styleType)}`,
                                    }),
                                    _c("strong", [
                                      _vm._v(
                                        "#" +
                                          _vm._s(
                                            _vm.portfolioTypeName(styleType)
                                          )
                                      ),
                                    ]),
                                  ]),
                                  _c(
                                    "p",
                                    { staticClass: "port-title no-wrap-text" },
                                    [_vm._v(_vm._s(portfolioName))]
                                  ),
                                  _c("span", { staticClass: "user-name" }, [
                                    _vm._v(_vm._s(nickName)),
                                  ]),
                                ]
                              ),
                            ])
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                ]
              : _vm.type === "shopping"
              ? [
                  _c(
                    "div",
                    { staticClass: "quant-swiper-box" },
                    [
                      _c(
                        "swiper",
                        {
                          staticClass: "pt-16",
                          attrs: {
                            "slides-per-view": "auto",
                            "space-between": 8,
                            loop: false,
                          },
                        },
                        _vm._l(_vm.list, function (data, idx) {
                          return _c(
                            "swiper-slide",
                            { key: idx },
                            [_c("Product", { attrs: { data: data } })],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]
              : [
                  _c(
                    "div",
                    { staticClass: "quant-swiper-box" },
                    [
                      _c(
                        "swiper",
                        {
                          attrs: {
                            "slides-per-view": "auto",
                            "space-between": 12,
                            loop: false,
                          },
                        },
                        _vm._l(
                          _vm.list,
                          function (
                            { communityId, name, storageAccesses },
                            idx
                          ) {
                            return _c("swiper-slide", { key: idx }, [
                              _c(
                                "div",
                                {
                                  staticClass: "quant-slide-item",
                                  class: _vm.type,
                                  on: {
                                    click: function ($event) {
                                      return _vm.goCommunityDetail(communityId)
                                    },
                                  },
                                },
                                [
                                  _c("div", { staticClass: "slide-thumb" }, [
                                    _c("img", {
                                      attrs: {
                                        src: storageAccesses[0],
                                        alt: "",
                                      },
                                    }),
                                  ]),
                                  _c("p", { staticClass: "slide-desc" }, [
                                    _vm._v(_vm._s(name)),
                                  ]),
                                ]
                              ),
                            ])
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                ],
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }