var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "service" }, [
    _c("ul", [
      _c(
        "li",
        {
          staticClass: "service-item flex-between",
          on: {
            click: function ($event) {
              return _vm.$router.push({ name: "Faq" })
            },
          },
        },
        [
          _c("span", { staticClass: "service-title" }, [
            _vm._v("자주 묻는 질문"),
          ]),
          _c("i", { staticClass: "icon--arrow-move" }),
        ]
      ),
      _c(
        "li",
        {
          staticClass: "service-item flex-between",
          on: {
            click: function ($event) {
              return _vm.$router.push({ name: "Qna" })
            },
          },
        },
        [
          _c("span", { staticClass: "service-title" }, [_vm._v("1:1 문의")]),
          _c("i", { staticClass: "icon--arrow-move" }),
        ]
      ),
      _c(
        "li",
        {
          staticClass: "service-item flex-between",
          on: {
            click: function ($event) {
              return _vm.$router.push({ name: "Policy" })
            },
          },
        },
        [
          _c("span", { staticClass: "service-title" }, [
            _vm._v("약관 및 정책"),
          ]),
          _c("i", { staticClass: "icon--arrow-move" }),
        ]
      ),
      _vm._m(0),
      _c(
        "li",
        {
          staticClass: "service-item flex-between",
          on: { click: _vm.userLogoutBtn },
        },
        [
          _c("span", { staticClass: "service-title" }, [_vm._v("로그아웃")]),
          _c("i", { staticClass: "icon--arrow-move" }),
        ]
      ),
      _c(
        "li",
        {
          staticClass: "service-item flex-between",
          on: { click: _vm.userLeaveBtn },
        },
        [
          _c("span", { staticClass: "service-title" }, [_vm._v("탈퇴하기")]),
          _c("i", { staticClass: "icon--arrow-move" }),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "service-item flex-between" }, [
      _c("span", { staticClass: "service-title" }, [_vm._v("버전정보")]),
      _c("button", { staticClass: "service-version" }, [
        _vm._v("현재 버전 1.0"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }