var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "community-box" }, [
    _c("div", { staticClass: "thumb-box" }, [
      _c("img", { attrs: { src: _vm.data.storageAccesses[0], alt: "" } }),
    ]),
    _c("div", { staticClass: "desc-box" }, [
      _c("p", { staticClass: "no-wrap-text" }, [_vm._v(_vm._s(_vm.data.name))]),
      _c("span", { staticClass: "no-wrap-text-2" }, [
        _vm._v(_vm._s(_vm.data.subTitle)),
      ]),
      _c("span", { staticClass: "no-wrap-text-2" }, [
        _vm._v(_vm._s(_vm.data.contents)),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }