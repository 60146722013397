<template>
  <div id="add-strategy-01-wrap" class="white-layer flex coll">
    <StepBar class="mb-30" :stage="stage" :step="step" />
    <template v-if="stage === 1">
      <Step01 />
    </template>
    <template v-else>
      <Step02 />
    </template>
    <div class="btn-wrap foot-btn-wrap">
      <button
        v-if="stage === 1"
        :disabled="stepData.list.length === 0"
        class="base-btn full cl-pri"
        @click="goNext"
      >
        <span>다음</span>
      </button>
      <button v-if="stage === 2" class="base-btn harf cl-sec" @click="goBefore">
        <span>이전</span>
      </button>
      <button v-if="stage === 2" class="base-btn harf cl-pri" @click="goResult">
        <span>결과보기</span>
      </button>
    </div>
  </div>
</template>

<script>
import StepBar from '@/components/base/StepBar.vue'
import Step01 from './type01/Step01.vue'
import Step02 from './type01/Step02.vue'

export default {
  name: 'QuantMarketAddStrategyType1',
  components: { StepBar, Step01, Step02 },
  data() {
    return {
      stage: 1,
      step: 2,
      toggleSearchModal: false
    }
  },
  created() {
    this.$store.commit('SET_STEP', {})
    this.$store.dispatch('getSearchStock')
  },
  computed: {
    stepData() {
      return this.$store.getters.getAddType
    }
  },
  mounted() {},
  methods: {
    goNext() {
      this.stage = 2
    },
    goBefore() {
      this.stage = 1
    },
    goResult() {
      const step = {
        'type': 'STOCKS'
      }
      this.$store.commit('SET_STEP', step)
      this.$router.replace({ name: 'Result' })
    }
  }
}
</script>

<style lang="scss">
#add-strategy-01-wrap {
  overflow-y: scroll;
  .round-box {
    border-radius: 14px;
    border: 1px solid #e8e8e8;
    background: #fff;
    padding: 16px 20px;
    p {
      color: #999;
      font-size: 12px;
    }
    .type-tit {
      color: #222;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 6px;
    }
    &.on {
      border: 1px solid #4a4ee7;
      .type-tit {
        color: #4a4ee7;
      }
    }
    &.add-button {
      text-align: center;
      > span {
        display: inline-flex;
        align-items: center;
        color: #666;
      }
    }
  }
  .regist-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
    .round-box {
      display: flex;
      align-items: center;

      span {
        color: #666;
        margin-right: 12px;
      }
      i {
        margin-left: auto;
      }
    }
  }
  .tab-list {
    display: flex;
    gap: 8px;
    li {
      display: flex;
      height: 42px;
      flex: 1;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      border: 1px solid #e8e8e8;
      &.on {
        border: 1px solid #4a4ee7;
        color: #4a4ee7;
      }
    }
  }
  .btn-wrap {
    margin-top: auto;
    display: flex;
    gap: 8px;
  }
}
</style>
