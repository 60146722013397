var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "white-layer", attrs: { id: "login-wrap" } },
    [
      _vm.hasAuthList.length === 0
        ? [
            _vm._m(0),
            _c(
              "ul",
              { staticClass: "oauth-list" },
              _vm._l(_vm.useOauthTypes, function ({ type, text }, i) {
                return _c("PlatformAuthBtn", {
                  key: `${i}_oauth_btn`,
                  attrs: { type: type, text: text },
                  on: { useClick: _vm.goAuthBtn },
                })
              }),
              1
            ),
          ]
        : [
            _vm._m(1),
            _c(
              "ul",
              { staticClass: "oauth-list" },
              _vm._l(_vm.hasAuthList, function ({ type, text }, i) {
                return _c("PlatformAuthBtn", {
                  key: `${i}_oauth_btn`,
                  attrs: { type: type, text: text },
                  on: { useClick: _vm.goAuthBtn },
                })
              }),
              1
            ),
            _c("ul", { staticClass: "btn-wrap" }, [
              !_vm.isBeforeLogin
                ? _c(
                    "button",
                    {
                      staticClass: "base-btn full cl-pri",
                      on: { click: _vm.goNotAccount },
                    },
                    [_c("span", [_vm._v("본인 계정이 아니에요")])]
                  )
                : _vm._e(),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "page-title-section" }, [
      _c("h4", [
        _vm._v("SNS 계정으로"),
        _c("br"),
        _vm._v("간편하게 로그인 하세요"),
      ]),
      _c("p", { staticClass: "q-sub-txt" }, [
        _vm._v("SNS 계정으로 간편하게 로그인 할 수 있어요."),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "page-title-section" }, [
      _c("h4", [
        _vm._v("SNS 계정으로"),
        _c("br"),
        _vm._v("간편하게 로그인 하세요"),
      ]),
      _c("p", { staticClass: "q-sub-txt" }, [
        _vm._v("SNS 계정으로 간편하게 로그인 할 수 있어요."),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }