// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grid-table .table-head li[data-v-5539c2a4],
.grid-table .table-head span[data-v-5539c2a4] {
  font-weight: 500;
}
.grid-table .table-head[data-v-5539c2a4],
.grid-table .table-list[data-v-5539c2a4] {
  padding-left: 24px;
  padding-right: 24px;
  grid-template-columns: repeat(5, 1fr);
  padding: 0;
}
.grid-table .table-head .no-wrap-text-2[data-v-5539c2a4],
.grid-table .table-list .no-wrap-text-2[data-v-5539c2a4] {
  padding-left: 16px;
}
.grid-table .table-head .proceeds[data-v-5539c2a4],
.grid-table .table-list .proceeds[data-v-5539c2a4] {
  color: #4a4ee7;
}
.grid-table .table-list[data-v-5539c2a4] {
  min-height: 42px;
  height: auto;
  padding-top: 8px;
  padding-bottom: 8px;
}
.grid-table .table-list > li[data-v-5539c2a4] {
  height: auto;
  min-height: auto;
  color: #666;
}
.products-sell[data-v-5539c2a4] {
  padding: 16px 0 38px;
}
.products-sell-datepicker[data-v-5539c2a4] {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 26px;
  border-bottom: 1px solid #e8e8e8;
}
.products-sell-datepicker span[data-v-5539c2a4] {
  font-size: 20px;
  font-weight: 500;
  margin: 0 12px;
}
.products-sell-list[data-v-5539c2a4] {
  padding: 24px 0 0 0;
}
.products-sell-list-month[data-v-5539c2a4] {
  padding-left: 16px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.32px;
  margin-bottom: 16px;
}
.products-sell-list-box[data-v-5539c2a4] {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  margin-bottom: 24px;
}
.products-sell-list-box .row-title[data-v-5539c2a4] {
  background: #f7f7f7;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.28px;
  width: 20%;
  height: 36px;
  line-height: 36px;
}
.products-sell-list-box .row-title.price[data-v-5539c2a4] {
  color: #4a4ee7;
}
.products-sell-list-box .row-data[data-v-5539c2a4] {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: #666;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.28px;
  border-bottom: 1px solid #e8e8e8;
}
.products-sell-list-box .row-data span[data-v-5539c2a4] {
  display: inline-block;
  width: 20%;
  padding: 13px 0;
}
.products-sell-list-box .row-data span[data-v-5539c2a4]:last-child {
  color: #4a4ee7;
}
.products-sell-list-box .row-data[data-v-5539c2a4]:last-child {
  color: #4a4ee7;
}
.products-sell .null-message[data-v-5539c2a4] {
  color: #999;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.28px;
  margin-top: 48px;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
