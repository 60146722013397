var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "step-box" }, [
    _c("p", { staticClass: "info" }, [
      _c("span", [_vm._v(_vm._s(_vm.stage))]),
      _vm._v("/" + _vm._s(_vm.step) + " "),
    ]),
    _c("div", { staticClass: "bar" }, [
      _c("div", { staticClass: "inner-slider", style: _vm.barStatus }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }