var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "white-layer", attrs: { id: "ranking-wrap" } },
    [
      _vm._m(0),
      _c(
        "ul",
        { staticClass: "top-ranker-box" },
        _vm._l(_vm.rankingList.slice(0, 3), function (d, i) {
          return _c(
            "li",
            { key: i + `index` },
            [
              _c("RankBox", {
                attrs: { data: d, index: i + 1, rankType: "top3" },
              }),
            ],
            1
          )
        }),
        0
      ),
      _c(
        "ul",
        { staticClass: "ranker-box" },
        _vm._l(_vm.rankingList.slice(3, 50), function (d, i) {
          return _c(
            "li",
            { key: i + `index` },
            [
              _c("RankBox", {
                attrs: { data: d, index: i + 4, rankType: "etc" },
              }),
            ],
            1
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "page-title-section mt-8 mb-20" }, [
      _c("h4", [_vm._v("이번달 수익률 순위")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }