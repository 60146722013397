<template>
  <div class="rounge-wrap layer">
    <button
      v-if="$store.getters.getUser.isUser"
      class="btn-add-rounge"
      @click="openCreateModal"
    ></button>
    <SlideUpModal class="edit-modal" v-if="editShow" @close="editShow = false">
      <template #main>
        <ul class="edit-modal-list">
          <li @click="showReport">신고하기</li>
          <li v-if="isOwner" @click="moveUpdate">수정하기</li>
          <li v-if="isOwner" @click="deleteLounge">삭제하기</li>
        </ul>
      </template>
    </SlideUpModal>
    <SlideUpModal class="edit-modal" v-if="declarShow">
      <template #header>
        <div class="flex-just mb-16">
          <h4>이 의견을 신고하는 이유</h4>
          <i class="icon--close" @click="declarShow = false"></i>
        </div>
      </template>
      <template #main>
        <ul class="declar-list">
          <li
            class="input-list"
            v-for="(d, i) in declarList"
            :key="i + 'declar'"
          >
            <div class="l-box">
              <input
                :id="d.id"
                v-model="selectedDeclarList"
                type="radio"
                :value="d.id"
              /><label :for="d.id"></label>
            </div>
            <div class="r-box">
              <p>{{ d.title }}</p>
            </div>
          </li>
        </ul>
      </template>
      <template #button>
        <button class="base-btn full cl-pri" @click="report()">
          <span>확인</span>
        </button>
      </template>
    </SlideUpModal>
    <TextRegisterModal
      @close="saveComplete"
      v-if="toggleRegistRounge"
      :is-create="isCreate"
      :update-target="updateTarget"
    ></TextRegisterModal>
    <div class="page-head-options">
      <span>내 활동 보기</span>
    </div>
    <ul class="cate-tab-list section pt-24 pb-16">
      <li :class="{ on: cate === 0 }" @click="changeLounge(0, '시황·종목')">
        시황·종목
      </li>
      <li :class="{ on: cate === 1 }" @click="changeLounge(1, '퀀트전략')">
        퀀트전략
      </li>
      <li :class="{ on: cate === 2 }" @click="changeLounge(2, '재테크')">
        재테크
      </li>
    </ul>
    <div class="sort-box flex-end pr-14 pb-16">
      <button @click="toggleSortOpt = true">
        {{ sort }} <i class="icon--arrow-down"></i>
      </button>
      <ul class="select-wrap" v-if="toggleSortOpt">
        <li>
          <p :class="{ on: sort === '최신순' }" @click="setSort('최신순')">
            최신순
          </p>
        </li>
        <li>
          <p :class="{ on: sort === '인기순' }" @click="setSort('인기순')">
            인기순
          </p>
        </li>
      </ul>
    </div>
    <div class="board-list-section">
      <ul>
        <li v-for="(d, i) in list" :key="i + 'board'">
          <BoardItemBox
            :data="d"
            :active="toggleList.includes(d.seq)"
            @toggle:more="moveDetail"
            @toggle:edit="toggleEditModal"
          ></BoardItemBox>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import BoardItemBox from '@/components/common/BoardItemBox.vue'
import SlideUpModal from '../../components/common/modal/SlideUpModal.vue'
import { axiosAuthInstance } from '@/store/axios'
import TextRegisterModal from '../../components/common/modal/TextRegisterModal.vue'

export default {
  name: 'QuantMarketIndex',
  components: {
    BoardItemBox,
    SlideUpModal,
    TextRegisterModal
  },
  data() {
    return {
      cate: 0,
      isOwner: false,
      toggleRegistRounge: false,
      toggleSortOpt: false,
      sort: '최신순',
      list: [],
      parameters: {
        index: 0,
        size: 50,
        sort: 'NEWEST',
        category: '시황·종목',
        isMyLounge: false
      },
      isCreate: true,
      updateTarget: {},
      toggleList: [],
      selectedDeclarList: [],
      editShow: false,
      editTaget: null,
      declarShow: false,
      declarList: [
        { title: '스팸, 광고', id: 0 },
        { title: '폭언, 비속어, 혐오발언', id: 1 },
        { title: '음란성, 선정성 글', id: 2 },
        { title: '개인정보 노출', id: 3 },
        { title: '부적절한 닉네임', id: 4 },
        { title: '부적절한 프로필이미지', id: 5 },
        { title: '주제와 무관', id: 6 }
      ]
    }
  },
  created() {
    this.getLounges()
  },
  mounted() {
    this.updateButtonPosition()
  },
  methods: {
    updateButtonPosition() {
      document.getElementsByClassName('btn-add-rounge')[0].style.right =
        window.innerWidth -
        document
          .getElementsByClassName('mobile-cont')[0]
          .getBoundingClientRect().right +
        20 +
        'px'
      console.log(document.getElementsByClassName('btn-add-rounge')[0])
    },
    changeLounge(index, value) {
      console.log(index, value)
      this.cate = index
      this.parameters.category = value
      this.parameters.index = 0
      this.getLounges()
    },
    getLounges() {
      axiosAuthInstance
        .get('/lounge', { params: this.parameters })
        .then((response) => {
          this.list = response.lounges
        })
        .catch((error) => {
          console.log('error', error)
        })
    },
    openCreateModal() {
      this.isCreate = true
      this.toggleRegistRounge = true
    },
    saveComplete(refresh) {
      this.toggleRegistRounge = false
      if (refresh) {
        this.parameters.page = 0
        this.getLounges()
      }
    },
    setSort(sort) {
      console.log(sort)
      this.sort = sort
      this.toggleSortOpt = false
      this.parameters.sort = sort == '인기순' ? 'POPULAR' : 'NEWEST'
      this.parameters.start = 0
      this.getLounges()
    },
    toggleEditModal(loungeId, isOwner) {
      this.editTaget = loungeId
      this.isOwner = isOwner
      this.editShow = true
    },
    showReport() {
      this.editShow = false
      this.declarShow = true
    },
    report() {
      let report
      for (const info of this.declarList) {
        if (info.id === this.selectedDeclarList) {
          console.log(info)
          report = info.title
        }
      }
      let request = {
        requestReportId: this.editTaget,
        loungeType: 'LOUNGE',
        reportReasonType: report
      }
      axiosAuthInstance
        .post('/lounge/report', JSON.stringify(request), {
          headers: {
            'Content-Type': `application/json`
          }
        })
        .then(() => {
          this.selectedDeclarList = ''
          this.editShow = false
          this.declarShow = false
          let obj = {
            title: '신고되었습니다',
            // message: '',
            type: 'info',
            useConfirmBtn: false
          }
          this.$Simplert.open(obj)
        })
        .catch((error) => {
          console.log('error', error)
        })
        .finally(() => {
          this.editShow = false
        })
    },
    moveUpdate() {
      console.log('this.editTaget', this.editTaget)
      for (let data in this.list) {
        if (this.list[data].loungeId === this.editTaget) {
          this.updateTarget = this.list[data]
        }
      }
      this.isCreate = false
      this.toggleRegistRounge = true
    },
    deleteLounge() {
      console.log(' deleteLounge')
      axiosAuthInstance
        .delete('/lounge/' + this.editTaget)
        .then(() => {
          this.list = this.list.filter((fo) => fo.loungeId !== this.editTaget)
        })
        .catch((error) => {
          console.log('error', error)
        })
        .finally(() => {
          this.editShow = false
        })
    },
    moveDetail(value) {
      this.$router.push({ name: 'RoungeDetail', query: { loungeId: value } })
    }
  }
}
</script>

<style lang="scss" scoped>
.rounge-wrap {
  min-height: calc(calc(var(--vh, 1vh) * 100) - 48px);
}
.section {
  padding: 0 16px;
}

.sort-box {
  position: relative;
  padding: 14px 0;
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;

  button {
    display: flex;
    align-items: center;
    color: #666;
  }

  .select-wrap {
    position: absolute;
    width: 114px;
    right: 14px;
    top: 100%;
    border-radius: 8px;
    border: 1px solid #e8e8e8;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 20px 16px;

    .on {
      font-weight: 500;
      color: #222;
    }
  }
}

.edit-modal {
  .edit-modal-list {
    li {
      font-size: 16px;
      padding: 14px 0;
    }
  }
}

.input-list {
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px 0;

  label {
    display: inline-block;
    width: 28px;
    height: 28px;
  }
}

.cate-tab-list {
  display: flex;
  align-items: center;
  gap: 24px;

  li {
    font-size: 20px;
    font-weight: 600;
    color: #aaa;

    &.on {
      color: #222;
    }
  }
}
</style>
