<template>
  <div :class="active ? 'on' : ''" class="board-item">
    <div class="flex-between">
      <div class="prof-box">
        <div class="thumb mr-8">
          <img v-if="data.storageAccess" :src="data.storageAccess" alt="" />
          <i v-else class="icon--profil-temp"></i>
        </div>
        <span class="user-name">{{ data.nickName }}</span>
        <span class="date ml-4">
          {{ data.createdDate }}
        </span>
      </div>
      <ul class="status-box">
        <li>
          <i
            class="icon--coment"
            @click="$emit('toggle:more', data.loungeId)"
          ></i>
          <span class="number">{{ data.commentCount }}</span>
        </li>
        <li v-if="!isDetail">
          <i
            class="icon--dot"
            @click="$emit('toggle:edit', data.loungeId, data.isOwner)"
          ></i>
        </li>
      </ul>
    </div>
    <p class="board-title no-wrap-text">{{ data.loungeTitle }}</p>
    <p class="board-desc no-wrap-text-2">
      {{ data.loungeContents }}
    </p>
    <ThumbBox :thumb="data.storageAccesses" />
    <div
      v-if="!active"
      :class="active ? '' : 'show'"
      class="more-text"
      @click="$emit('toggle:more', data.loungeId)"
    >
      더 보기
    </div>
  </div>
</template>

<script>
import ThumbBox from './ThumbBox.vue'

export default {
  name: 'QuantMarketCommunityListBox',
  props: {
    isDetail: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => {
        return {
          storageHashes: '',
          nickName: '',
          createdDate: '',
          like: '',
          commentCount: '',
          loungeTitle: '',
          loungeContents: [],
          loungeId: ''
        }
      }
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  mounted() {},
  methods: {},
  components: { ThumbBox }
}
</script>

<style lang="scss" scoped>
.board-item {
  &.on {
    .board-desc {
      text-overflow: unset;
      -webkit-line-clamp: unset;
    }
  }
  border-bottom: 1px solid #f1f1f1;
  padding: 24px 16px;
  .prof-box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
    .date {
      color: #aaa;
      font-size: 12px;
    }
  }

  .status-box {
    display: flex;
    gap: 14px;
    align-items: center;
    li {
      display: flex;
      align-items: center;
    }
    span {
      color: #aaa;
      font-size: 12px;
    }
    .edit {
      padding-bottom: 6px;
    }
  }
  .board-title {
    color: #222;
    font-size: 16px;
    font-weight: 500;
    width: 100%;
    margin-bottom: 8px;
  }
  .board-desc {
    width: 100%;
    color: #666;
    font-size: 14px;
    margin-bottom: 14px;
  }
  .thumb-box {
    display: flex;
    border-radius: 6px;
    overflow: hidden;
    > div {
      width: 50%;
      > div {
        display: block;
        width: 100%;
        padding-top: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        .more-no {
          position: absolute;
          font-size: 30px;
          font-weight: 700;
          top: 50%;
          left: 50%;
          color: #fff;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
  .more-text.show {
    margin-top: 14px;
    color: #aaa;
    font-size: 12px;
  }
}
</style>
