// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#add-strategy-01-wrap .round-box[data-v-26ab2da2] {
  border-radius: 14px;
  border: 1px solid #e8e8e8;
  background: #fff;
  padding: 16px 20px;
}
#add-strategy-01-wrap .round-box p[data-v-26ab2da2] {
  color: #999;
  font-size: 12px;
}
#add-strategy-01-wrap .round-box .type-tit[data-v-26ab2da2] {
  color: #222;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 6px;
}
#add-strategy-01-wrap .round-box.on[data-v-26ab2da2] {
  border: 1px solid #4a4ee7;
}
#add-strategy-01-wrap .round-box.on .type-tit[data-v-26ab2da2] {
  color: #4a4ee7;
}
#add-strategy-01-wrap .round-box.add-button[data-v-26ab2da2] {
  text-align: center;
}
#add-strategy-01-wrap .round-box.add-button > span[data-v-26ab2da2] {
  display: inline-flex;
  align-items: center;
  color: #666;
}
#add-strategy-01-wrap .regist-list[data-v-26ab2da2] {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
#add-strategy-01-wrap .regist-list .round-box[data-v-26ab2da2] {
  display: flex;
  align-items: center;
}
#add-strategy-01-wrap .regist-list .round-box span[data-v-26ab2da2] {
  color: #666;
  margin-right: 12px;
}
#add-strategy-01-wrap .regist-list .round-box i[data-v-26ab2da2] {
  margin-left: auto;
}
#add-strategy-01-wrap .tab-list[data-v-26ab2da2] {
  display: flex;
  gap: 8px;
}
#add-strategy-01-wrap .tab-list li[data-v-26ab2da2] {
  display: flex;
  height: 42px;
  flex: 1;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid #e8e8e8;
}
#add-strategy-01-wrap .tab-list li.on[data-v-26ab2da2] {
  border: 1px solid #4a4ee7;
  color: #4a4ee7;
}
#add-strategy-01-wrap .btn-wrap[data-v-26ab2da2] {
  display: flex;
  gap: 8px;
}
.set-date-box[data-v-26ab2da2] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
}
.set-date-box .round-input[data-v-26ab2da2] {
  flex: 1;
  padding: 14px;
  width: 152px;
  position: relative;
  height: 42px;
}
.set-date-box .round-input input[data-v-26ab2da2] {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;
  border: none;
  width: 100%;
  height: 100%;
  background-color: transparent;
  opacity: 0;
}
.set-date-box .round-input input[data-v-26ab2da2]::-webkit-calendar-picker-indicator {
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 0;
  background-image: none;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
