// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.discount[data-v-51d5a09a] {
  color: #d74343;
  font-size: 20px;
  font-weight: 600;
}
.product-detail-wrap[data-v-51d5a09a] {
  background-color: #f1f1f1;
}
.section[data-v-51d5a09a] {
  background-color: #fff;
  padding: 32px 16px;
}
.slide-box[data-v-51d5a09a] {
  position: relative;
  aspect-ratio: 1;
}
.slide-box .inner-txt[data-v-51d5a09a] {
  position: absolute;
  left: 16px;
  bottom: 24px;
}
.product-info[data-v-51d5a09a] {
  padding: 32px 16px;
}
.product-info .title[data-v-51d5a09a] {
  color: #666;
  font-size: 12px;
  line-height: 140%;
  margin-bottom: 5px;
}
.product-info .desc[data-v-51d5a09a] {
  margin-top: 8px;
  margin-bottom: 12px;
}
.product-info .price[data-v-51d5a09a] {
  font-size: 22px;
  font-weight: 600;
  color: #222;
}
.product-info .price .discount[data-v-51d5a09a] {
  color: #d74343;
  font-size: 22px;
  font-weight: 600;
}
.product-info .before-info[data-v-51d5a09a] {
  margin-top: 2px;
  margin-bottom: 4px;
}
.product-info .before-info span[data-v-51d5a09a] {
  color: #999;
  font-size: 14px;
}
.product-info .before-info .before-price[data-v-51d5a09a] {
  text-decoration: line-through;
}
.product-info .recent-percent[data-v-51d5a09a] {
  display: flex;
  align-items: center;
  gap: 4px;
}
.product-info .recent-percent span[data-v-51d5a09a] {
  font-size: 14px;
  color: #666;
}
.product-info .recent-percent .percent[data-v-51d5a09a] {
  color: #d74343;
  font-weight: 600;
}
.product-info .recent-percent .isDown[data-v-51d5a09a] {
  color: #4882f5;
}
.product-info .round-date[data-v-51d5a09a] {
  margin-top: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 215px;
  height: 25px;
  border-radius: 50px;
  background: #f5f5ff;
  gap: 6px;
}
.product-info .round-date span[data-v-51d5a09a] {
  color: #666;
  font-size: 12px;
}
.sec-head h3[data-v-51d5a09a] {
  font-size: 18px;
}
.sec-head .status-box strong[data-v-51d5a09a] {
  color: #4a4ee7;
  font-size: 20px;
  font-weight: 600;
}
.sec-head .rate-box strong[data-v-51d5a09a] {
  color: #d74343;
  font-size: 20px;
  font-weight: 600;
}
.sec-head span[data-v-51d5a09a] {
  padding-left: 8px;
  color: #999;
  font-size: 12px;
  font-weight: 400;
}
.round-box-list[data-v-51d5a09a] {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 8px;
  -moz-column-gap: 28px;
       column-gap: 28px;
  padding: 16px 35px;
  border-radius: 14px;
  background: #f7f7f7;
}
.round-box-list > li[data-v-51d5a09a] {
  display: flex;
  justify-content: space-between;
  align-self: center;
  max-width: 68px;
  padding-left: 8px;
  position: relative;
}
.round-box-list > li[data-v-51d5a09a]::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: #d9d9d9;
}
.round-box-list > li .l-box[data-v-51d5a09a] {
  color: #666;
}
.round-box-list > li .r-box[data-v-51d5a09a] {
  font-weight: 500;
}
.date-tab-list[data-v-51d5a09a] {
  display: flex;
  justify-content: space-between;
}
.date-tab-list > li[data-v-51d5a09a] {
  padding: 10px;
  min-width: 52px;
  text-align: center;
}
.date-tab-list > li.on[data-v-51d5a09a] {
  border-radius: 10px;
  background: #f9f9f9;
  font-weight: 600;
}
.result-section .round-box[data-v-51d5a09a] {
  border-radius: 14px;
  border: 1px solid #e8e8e8;
  background: #fff;
  padding: 16px 20px;
  display: flex;
  align-items: center;
  gap: 12px;
}
.result-section .round-box .r-box strong[data-v-51d5a09a] {
  color: #222;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 6px;
}
.result-section .round-box .r-box p[data-v-51d5a09a] {
  color: #999;
  font-size: 12px;
  font-weight: 400;
}
.result-section .etc-box .val-list[data-v-51d5a09a] {
  margin-top: 14px;
  display: flex;
  flex-direction: column;
  gap: 14px;
}
.result-section .etc-box .val-list > li[data-v-51d5a09a] {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.result-section .etc-box .val-list > li span[data-v-51d5a09a] {
  color: #666;
}
.purple-box[data-v-51d5a09a] {
  border-radius: 14px;
  background: #f5f5ff;
  padding: 20px 16px;
}
.purple-box strong[data-v-51d5a09a] {
  color: #4a4ee7;
  font-size: 16px;
  font-weight: 600;
}
.purple-box .disc-list[data-v-51d5a09a] {
  margin-top: 8px;
}
.purple-box .disc-list li[data-v-51d5a09a] {
  margin: 0;
}
.line-box[data-v-51d5a09a] {
  border-radius: 14px;
  border: 1px solid #e8e8e8;
  padding: 24px 16px;
  margin-top: 10px;
}
.line-box p[data-v-51d5a09a] {
  color: #666;
}
.subs-wrap[data-v-51d5a09a] {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 150px;
}
.subs-wrap .cont[data-v-51d5a09a] {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.subs-wrap .cont h4[data-v-51d5a09a] {
  margin-top: 16px;
  margin-bottom: 24px;
  text-align: center;
}
.subs-wrap .cont .round-box[data-v-51d5a09a] {
  background-color: #f7f7f7;
  border-radius: 14px;
  padding: 24px;
  width: 100%;
}
.subs-wrap .cont .round-box ul[data-v-51d5a09a] {
  display: flex;
  flex-direction: column;
  gap: 14px;
}
.subs-wrap .cont .round-box ul li[data-v-51d5a09a] {
  display: flex;
  gap: 14px;
}
.subs-wrap .cont .round-box .l-box[data-v-51d5a09a] {
  font-weight: 600;
}
.subs-wrap .cont .round-box .r-box[data-v-51d5a09a] {
  color: #666;
}
.subs-wrap .btn-wrap[data-v-51d5a09a] {
  margin-top: auto;
}
.big-desc[data-v-51d5a09a] {
  color: #999;
  margin-top: 10px;
  font-size: 18px;
}
.big-desc strong .up[data-v-51d5a09a] {
  color: #d74343;
  font-weight: 600;
}
.big-desc strong .down[data-v-51d5a09a] {
  color: #4a4ee7;
  font-weight: 600;
}
.before-price[data-v-51d5a09a] {
  text-decoration: line-through;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
