export default {
  calculateValues(input, calibration) {
    const defaultData = {
      SALES_REVENUE_GR: 0,
      PER: 0,
      ROE: 0,
      ASSET_TURNOVER_RATIO: 0,
      PBR: 0,
      PSR: 0,
      DEBT_RATIO: 0,
      DYR: 0,
      PROPENSITY_DIVIDEND: 0,
      OPERATING_INCOME_GR: 0,
      MOM_PRICE_3M: 0,
      MOM_TRDVAL_3M: 0,
      CURRENT_RATIO: 0,
      VOL_PRICE_3M: 0,
      TURNOVER_1M: 0,
      MOM_PRICE_1M: 0,
      EV: 0,
      RSI_14D: 0,
      BETA_12M: 0,
      OBV_10D: 0,
      MOM_TRDVAL_1M: 0,
      EV_EBIT: 0
    }

    for (let data in input) {
      defaultData[data] = input[data]
    }

    return {
      growth: getGrowth(defaultData, calibration),
      value: getValue(defaultData, calibration),
      dividend: getDividend(defaultData, calibration),
      momentum: getMomentum(defaultData, calibration),
      lowvol: getLowvol(defaultData, calibration),
      quality: getQuality(defaultData, calibration)
    }
  }
}

const calibration = {
  GROWTH: {
    SALES_REVENUE_GR: 0.35,
    PER: 0.35,
    ROE: -0.1,
    ASSET_TURNOVER_RATIO: -0.2,
    PBR: -0.25,
    PSR: -0.1,
    DEBT_RATIO: -0.15,
    DYR: -0.3,
    PROPENSITY_DIVIDEND: -0.3,
    OPERATING_INCOME_GR: 0.3,
    MOM_PRICE_3M: 0.1,
    MOM_TRDVAL_3M: 0.1,
    CURRENT_RATIO: -0.3,
    VOL_PRICE_3M: -0.45,
    TURNOVER_1M: 0.25,
    MOM_PRICE_1M: 0.1,
    EV: -0.2,
    RSI_14D: -0.05,
    BETA_12M: -0.1,
    OBV_10D: 0.03,
    MOM_TRDVAL_1M: 0.05,
    EV_EBIT: -0.1
  },
  VALUE: {
    SALES_REVENUE_GR: -0.25,
    PER: -0.35,
    ROE: 0.2,
    ASSET_TURNOVER_RATIO: 0.1,
    PBR: 0.2,
    PSR: 0.15,
    DEBT_RATIO: 0.05,
    DYR: 0.05,
    PROPENSITY_DIVIDEND: 0,
    OPERATING_INCOME_GR: 0.05,
    MOM_PRICE_3M: -0.5,
    MOM_TRDVAL_3M: 0.1,
    CURRENT_RATIO: 0.1,
    VOL_PRICE_3M: 0.15,
    TURNOVER_1M: 0,
    MOM_PRICE_1M: -0.2,
    EV: 0.15,
    RSI_14D: 0.05,
    BETA_12M: -0.05,
    OBV_10D: -0.3,
    MOM_TRDVAL_1M: -0.2,
    EV_EBIT: 0.1
  },
  DIVIDEND: {
    SALES_REVENUE_GR: 0.2,
    PER: -0.4,
    ROE: 0.2,
    ASSET_TURNOVER_RATIO: -0.1,
    PBR: 0,
    PSR: 0.1,
    DEBT_RATIO: 0.1,
    DYR: 0.25,
    PROPENSITY_DIVIDEND: 0.25,
    OPERATING_INCOME_GR: -0.3,
    MOM_PRICE_3M: 0,
    MOM_TRDVAL_3M: 0,
    CURRENT_RATIO: -0.3,
    VOL_PRICE_3M: 0,
    TURNOVER_1M: 0,
    MOM_PRICE_1M: 0,
    EV: 0.1,
    RSI_14D: 0,
    BETA_12M: 0,
    OBV_10D: 0,
    MOM_TRDVAL_1M: 0,
    EV_EBIT: 0.1
  },
  MOMENTUM: {
    SALES_REVENUE_GR: 0.15,
    PER: 0.25,
    ROE: -0.1,
    ASSET_TURNOVER_RATIO: -0.3,
    PBR: -0.15,
    PSR: -0.1,
    DEBT_RATIO: -0.1,
    DYR: -0.3,
    PROPENSITY_DIVIDEND: 0,
    OPERATING_INCOME_GR: 0,
    MOM_PRICE_3M: 0.25,
    MOM_TRDVAL_3M: 0.2,
    CURRENT_RATIO: -0.1,
    VOL_PRICE_3M: -0.45,
    TURNOVER_1M: 0.35,
    MOM_PRICE_1M: 0.2,
    EV: -0.1,
    RSI_14D: -0.05,
    BETA_12M: -0.05,
    OBV_10D: 0.05,
    MOM_TRDVAL_1M: 0.05,
    EV_EBIT: 0
  },
  LOWVOL: {
    SALES_REVENUE_GR: -0.1,
    PER: -0.3,
    ROE: -0.2,
    ASSET_TURNOVER_RATIO: 0.1,
    PBR: 0.05,
    PSR: -0.2,
    DEBT_RATIO: 0.2,
    DYR: 0,
    PROPENSITY_DIVIDEND: -0.2,
    OPERATING_INCOME_GR: -0.05,
    MOM_PRICE_3M: -0.1,
    MOM_TRDVAL_3M: -0.1,
    CURRENT_RATIO: 0.3,
    VOL_PRICE_3M: 0.25,
    TURNOVER_1M: -0.1,
    MOM_PRICE_1M: 0,
    EV: 0.1,
    RSI_14D: 0.1,
    BETA_12M: 0.05,
    OBV_10D: -0.15,
    MOM_TRDVAL_1M: -0.15,
    EV_EBIT: 0.1
  },
  QUALITY: {
    SALES_REVENUE_GR: 0.25,
    PER: -0.3,
    ROE: 0.25,
    ASSET_TURNOVER_RATIO: 0.1,
    PBR: 0.1,
    PSR: 0.1,
    DEBT_RATIO: 0.05,
    DYR: 0.05,
    PROPENSITY_DIVIDEND: 0,
    OPERATING_INCOME_GR: 0.05,
    MOM_PRICE_3M: -0.1,
    MOM_TRDVAL_3M: -0.2,
    CURRENT_RATIO: 0.25,
    VOL_PRICE_3M: 0.1,
    TURNOVER_1M: 0.05,
    MOM_PRICE_1M: 0.1,
    EV: -0.1,
    RSI_14D: -0.15,
    BETA_12M: 0.1,
    OBV_10D: -0.15,
    MOM_TRDVAL_1M: 0,
    EV_EBIT: 0.05
  }
}

function getQuality(input) {
  let SALES_REVENUE_GR =
    1 + input.SALES_REVENUE_GR * calibration.QUALITY.SALES_REVENUE_GR
  let PER = 1 + input.PER * calibration.QUALITY.PER
  let ROE = 1 + input.ROE * calibration.QUALITY.ROE
  let ASSET_TURNOVER_RATIO =
    1 + input.ASSET_TURNOVER_RATIO * calibration.QUALITY.ASSET_TURNOVER_RATIO
  let PBR = 1 + input.PBR * calibration.QUALITY.PBR
  let PSR = 1 + input.PSR * calibration.QUALITY.PSR
  let DEBT_RATIO = 1 + input.DEBT_RATIO * calibration.QUALITY.DEBT_RATIO
  let DYR = 1 + input.DYR * calibration.QUALITY.DYR
  let PROPENSITY_DIVIDEND =
    1 + input.PROPENSITY_DIVIDEND * calibration.QUALITY.PROPENSITY_DIVIDEND
  let OPERATING_INCOME_GR =
    1 + input.OPERATING_INCOME_GR * calibration.QUALITY.OPERATING_INCOME_GR
  let MOM_PRICE_3M = 1 + input.MOM_PRICE_3M * calibration.QUALITY.MOM_PRICE_3M
  let MOM_TRDVAL_3M =
    1 + input.MOM_TRDVAL_3M * calibration.QUALITY.MOM_TRDVAL_3M
  let CURRENT_RATIO =
    1 + input.CURRENT_RATIO * calibration.QUALITY.CURRENT_RATIO
  let VOL_PRICE_3M = 1 + input.VOL_PRICE_3M * calibration.QUALITY.VOL_PRICE_3M
  let TURNOVER_1M = 1 + input.TURNOVER_1M * calibration.QUALITY.TURNOVER_1M
  let EV = 1 + input.EV * calibration.QUALITY.EV
  let MOM_PRICE_1M = 1 + input.MOM_PRICE_1M * calibration.QUALITY.MOM_PRICE_1M
  let RSI_14D = 1 + input.RSI_14D * calibration.QUALITY.RSI_14D
  let BETA_12M = 1 + input.BETA_12M * calibration.QUALITY.BETA_12M
  let OBV_10D = 1 + input.OBV_10D * calibration.QUALITY.OBV_10D
  let MOM_TRDVAL_1M =
    1 + input.MOM_TRDVAL_1M * calibration.QUALITY.MOM_TRDVAL_1M
  let EV_EBIT = 1 + input.EV_EBIT * calibration.QUALITY.EV_EBIT

  let response =
    5 *
    SALES_REVENUE_GR *
    PER *
    ROE *
    ASSET_TURNOVER_RATIO *
    PBR *
    PSR *
    DEBT_RATIO *
    DYR *
    PROPENSITY_DIVIDEND *
    OPERATING_INCOME_GR *
    MOM_PRICE_3M *
    MOM_TRDVAL_3M *
    CURRENT_RATIO *
    VOL_PRICE_3M *
    TURNOVER_1M *
    EV *
    MOM_PRICE_1M *
    RSI_14D *
    BETA_12M *
    OBV_10D *
    MOM_TRDVAL_1M *
    EV_EBIT
  return response.toFixed(1)
}
function getLowvol(input) {
  let SALES_REVENUE_GR =
    1 + input.SALES_REVENUE_GR * calibration.LOWVOL.SALES_REVENUE_GR
  let PER = 1 + input.PER * calibration.LOWVOL.PER
  let ROE = 1 + input.ROE * calibration.LOWVOL.ROE
  let ASSET_TURNOVER_RATIO =
    1 + input.ASSET_TURNOVER_RATIO * calibration.LOWVOL.ASSET_TURNOVER_RATIO
  let PBR = 1 + input.PBR * calibration.LOWVOL.PBR
  let PSR = 1 + input.PSR * calibration.LOWVOL.PSR
  let DEBT_RATIO = 1 + input.DEBT_RATIO * calibration.LOWVOL.DEBT_RATIO
  let DYR = 1 + input.DYR * calibration.LOWVOL.DYR
  let PROPENSITY_DIVIDEND =
    1 + input.PROPENSITY_DIVIDEND * calibration.LOWVOL.PROPENSITY_DIVIDEND
  let OPERATING_INCOME_GR =
    1 + input.OPERATING_INCOME_GR * calibration.LOWVOL.OPERATING_INCOME_GR
  let MOM_PRICE_3M = 1 + input.MOM_PRICE_3M * calibration.LOWVOL.MOM_PRICE_3M
  let MOM_TRDVAL_3M = 1 + input.MOM_TRDVAL_3M * calibration.LOWVOL.MOM_TRDVAL_3M
  let CURRENT_RATIO = 1 + input.CURRENT_RATIO * calibration.LOWVOL.CURRENT_RATIO
  let VOL_PRICE_3M = 1 + input.VOL_PRICE_3M * calibration.LOWVOL.VOL_PRICE_3M
  let TURNOVER_1M = 1 + input.TURNOVER_1M * calibration.LOWVOL.TURNOVER_1M
  let EV = 1 + input.EV * calibration.LOWVOL.EV
  let MOM_PRICE_1M = 1 + input.MOM_PRICE_1M * calibration.LOWVOL.MOM_PRICE_1M
  let RSI_14D = 1 + input.RSI_14D * calibration.LOWVOL.RSI_14D
  let BETA_12M = 1 + input.BETA_12M * calibration.LOWVOL.BETA_12M
  let OBV_10D = 1 + input.OBV_10D * calibration.LOWVOL.OBV_10D
  let MOM_TRDVAL_1M = 1 + input.MOM_TRDVAL_1M * calibration.LOWVOL.MOM_TRDVAL_1M
  let EV_EBIT = 1 + input.EV_EBIT * calibration.LOWVOL.EV_EBIT

  let response =
    5 *
    SALES_REVENUE_GR *
    PER *
    ROE *
    ASSET_TURNOVER_RATIO *
    PBR *
    PSR *
    DEBT_RATIO *
    DYR *
    PROPENSITY_DIVIDEND *
    OPERATING_INCOME_GR *
    MOM_PRICE_3M *
    MOM_TRDVAL_3M *
    CURRENT_RATIO *
    VOL_PRICE_3M *
    TURNOVER_1M *
    EV *
    MOM_PRICE_1M *
    RSI_14D *
    BETA_12M *
    OBV_10D *
    MOM_TRDVAL_1M *
    EV_EBIT
  return response.toFixed(1)
}
function getMomentum(input) {
  let SALES_REVENUE_GR =
    1 + input.SALES_REVENUE_GR * calibration.MOMENTUM.SALES_REVENUE_GR
  let PER = 1 + input.PER * calibration.MOMENTUM.PER
  let ROE = 1 + input.ROE * calibration.MOMENTUM.ROE
  let ASSET_TURNOVER_RATIO =
    1 + input.ASSET_TURNOVER_RATIO * calibration.MOMENTUM.ASSET_TURNOVER_RATIO
  let PBR = 1 + input.PBR * calibration.MOMENTUM.PBR
  let PSR = 1 + input.PSR * calibration.MOMENTUM.PSR
  let DEBT_RATIO = 1 + input.DEBT_RATIO * calibration.MOMENTUM.DEBT_RATIO
  let DYR = 1 + input.DYR * calibration.MOMENTUM.DYR
  let PROPENSITY_DIVIDEND =
    1 + input.PROPENSITY_DIVIDEND * calibration.MOMENTUM.PROPENSITY_DIVIDEND
  let OPERATING_INCOME_GR =
    1 + input.OPERATING_INCOME_GR * calibration.MOMENTUM.OPERATING_INCOME_GR
  let MOM_PRICE_3M = 1 + input.MOM_PRICE_3M * calibration.MOMENTUM.MOM_PRICE_3M
  let MOM_TRDVAL_3M =
    1 + input.MOM_TRDVAL_3M * calibration.MOMENTUM.MOM_TRDVAL_3M
  let CURRENT_RATIO =
    1 + input.CURRENT_RATIO * calibration.MOMENTUM.CURRENT_RATIO
  let VOL_PRICE_3M = 1 + input.VOL_PRICE_3M * calibration.MOMENTUM.VOL_PRICE_3M
  let TURNOVER_1M = 1 + input.TURNOVER_1M * calibration.MOMENTUM.TURNOVER_1M
  let EV = 1 + input.EV * calibration.MOMENTUM.EV
  let MOM_PRICE_1M = 1 + input.MOM_PRICE_1M * calibration.MOMENTUM.MOM_PRICE_1M
  let RSI_14D = 1 + input.RSI_14D * calibration.MOMENTUM.RSI_14D
  let BETA_12M = 1 + input.BETA_12M * calibration.MOMENTUM.BETA_12M
  let OBV_10D = 1 + input.OBV_10D * calibration.MOMENTUM.OBV_10D
  let MOM_TRDVAL_1M =
    1 + input.MOM_TRDVAL_1M * calibration.MOMENTUM.MOM_TRDVAL_1M
  let EV_EBIT = 1 + input.EV_EBIT * calibration.MOMENTUM.EV_EBIT

  let response =
    5 *
    SALES_REVENUE_GR *
    PER *
    ROE *
    ASSET_TURNOVER_RATIO *
    PBR *
    PSR *
    DEBT_RATIO *
    DYR *
    PROPENSITY_DIVIDEND *
    OPERATING_INCOME_GR *
    MOM_PRICE_3M *
    MOM_TRDVAL_3M *
    CURRENT_RATIO *
    VOL_PRICE_3M *
    TURNOVER_1M *
    EV *
    MOM_PRICE_1M *
    RSI_14D *
    BETA_12M *
    OBV_10D *
    MOM_TRDVAL_1M *
    EV_EBIT
  return response.toFixed(1)
}
function getDividend(input) {
  let SALES_REVENUE_GR =
    1 + input.SALES_REVENUE_GR * calibration.DIVIDEND.SALES_REVENUE_GR
  let PER = 1 + input.PER * calibration.DIVIDEND.PER
  let ROE = 1 + input.ROE * calibration.DIVIDEND.ROE
  let ASSET_TURNOVER_RATIO =
    1 + input.ASSET_TURNOVER_RATIO * calibration.DIVIDEND.ASSET_TURNOVER_RATIO
  let PBR = 1 + input.PBR * calibration.DIVIDEND.PBR
  let PSR = 1 + input.PSR * calibration.DIVIDEND.PSR
  let DEBT_RATIO = 1 + input.DEBT_RATIO * calibration.DIVIDEND.DEBT_RATIO
  let DYR = 1 + input.DYR * calibration.DIVIDEND.DYR
  let PROPENSITY_DIVIDEND =
    1 + input.PROPENSITY_DIVIDEND * calibration.DIVIDEND.PROPENSITY_DIVIDEND
  let OPERATING_INCOME_GR =
    1 + input.OPERATING_INCOME_GR * calibration.DIVIDEND.OPERATING_INCOME_GR
  let MOM_PRICE_3M = 1 + input.MOM_PRICE_3M * calibration.DIVIDEND.MOM_PRICE_3M
  let MOM_TRDVAL_3M =
    1 + input.MOM_TRDVAL_3M * calibration.DIVIDEND.MOM_TRDVAL_3M
  let CURRENT_RATIO =
    1 + input.CURRENT_RATIO * calibration.DIVIDEND.CURRENT_RATIO
  let VOL_PRICE_3M = 1 + input.VOL_PRICE_3M * calibration.DIVIDEND.VOL_PRICE_3M
  let TURNOVER_1M = 1 + input.TURNOVER_1M * calibration.DIVIDEND.TURNOVER_1M
  let EV = 1 + input.EV * calibration.DIVIDEND.EV
  let MOM_PRICE_1M = 1 + input.MOM_PRICE_1M * calibration.DIVIDEND.MOM_PRICE_1M
  let RSI_14D = 1 + input.RSI_14D * calibration.DIVIDEND.RSI_14D
  let BETA_12M = 1 + input.BETA_12M * calibration.DIVIDEND.BETA_12M
  let OBV_10D = 1 + input.OBV_10D * calibration.DIVIDEND.OBV_10D
  let MOM_TRDVAL_1M =
    1 + input.MOM_TRDVAL_1M * calibration.DIVIDEND.MOM_TRDVAL_1M
  let EV_EBIT = 1 + input.EV_EBIT * calibration.DIVIDEND.EV_EBIT

  let response =
    5 *
    SALES_REVENUE_GR *
    PER *
    ROE *
    ASSET_TURNOVER_RATIO *
    PBR *
    PSR *
    DEBT_RATIO *
    DYR *
    PROPENSITY_DIVIDEND *
    OPERATING_INCOME_GR *
    MOM_PRICE_3M *
    MOM_TRDVAL_3M *
    CURRENT_RATIO *
    VOL_PRICE_3M *
    TURNOVER_1M *
    EV *
    MOM_PRICE_1M *
    RSI_14D *
    BETA_12M *
    OBV_10D *
    MOM_TRDVAL_1M *
    EV_EBIT
  return response.toFixed(1)
}
function getValue(input) {
  let SALES_REVENUE_GR =
    1 + input.SALES_REVENUE_GR * calibration.VALUE.SALES_REVENUE_GR
  let PER = 1 + input.PER * calibration.VALUE.PER
  let ROE = 1 + input.ROE * calibration.VALUE.ROE
  let ASSET_TURNOVER_RATIO =
    1 + input.ASSET_TURNOVER_RATIO * calibration.VALUE.ASSET_TURNOVER_RATIO
  let PBR = 1 + input.PBR * calibration.VALUE.PBR
  let PSR = 1 + input.PSR * calibration.VALUE.PSR
  let DEBT_RATIO = 1 + input.DEBT_RATIO * calibration.VALUE.DEBT_RATIO
  let DYR = 1 + input.DYR * calibration.VALUE.DYR
  let PROPENSITY_DIVIDEND =
    1 + input.PROPENSITY_DIVIDEND * calibration.VALUE.PROPENSITY_DIVIDEND
  let OPERATING_INCOME_GR =
    1 + input.OPERATING_INCOME_GR * calibration.VALUE.OPERATING_INCOME_GR
  let MOM_PRICE_3M = 1 + input.MOM_PRICE_3M * calibration.VALUE.MOM_PRICE_3M
  let MOM_TRDVAL_3M = 1 + input.MOM_TRDVAL_3M * calibration.VALUE.MOM_TRDVAL_3M
  let CURRENT_RATIO = 1 + input.CURRENT_RATIO * calibration.VALUE.CURRENT_RATIO
  let VOL_PRICE_3M = 1 + input.VOL_PRICE_3M * calibration.VALUE.VOL_PRICE_3M
  let TURNOVER_1M = 1 + input.TURNOVER_1M * calibration.VALUE.TURNOVER_1M
  let EV = 1 + input.EV * calibration.VALUE.EV
  let MOM_PRICE_1M = 1 + input.MOM_PRICE_1M * calibration.VALUE.MOM_PRICE_1M
  let RSI_14D = 1 + input.RSI_14D * calibration.VALUE.RSI_14D
  let BETA_12M = 1 + input.BETA_12M * calibration.VALUE.BETA_12M
  let OBV_10D = 1 + input.OBV_10D * calibration.VALUE.OBV_10D
  let MOM_TRDVAL_1M = 1 + input.MOM_TRDVAL_1M * calibration.VALUE.MOM_TRDVAL_1M
  let EV_EBIT = 1 + input.EV_EBIT * calibration.VALUE.EV_EBIT

  let response =
    5 *
    SALES_REVENUE_GR *
    PER *
    ROE *
    ASSET_TURNOVER_RATIO *
    PBR *
    PSR *
    DEBT_RATIO *
    DYR *
    PROPENSITY_DIVIDEND *
    OPERATING_INCOME_GR *
    MOM_PRICE_3M *
    MOM_TRDVAL_3M *
    CURRENT_RATIO *
    VOL_PRICE_3M *
    TURNOVER_1M *
    EV *
    MOM_PRICE_1M *
    RSI_14D *
    BETA_12M *
    OBV_10D *
    MOM_TRDVAL_1M *
    EV_EBIT
  return response.toFixed(1)
}
function getGrowth(input) {
  let SALES_REVENUE_GR =
    1 + input.SALES_REVENUE_GR * calibration.GROWTH.SALES_REVENUE_GR
  let PER = 1 + input.PER * calibration.GROWTH.PER
  let ROE = 1 + input.ROE * calibration.GROWTH.ROE
  let ASSET_TURNOVER_RATIO =
    1 + input.ASSET_TURNOVER_RATIO * calibration.GROWTH.ASSET_TURNOVER_RATIO
  let PBR = 1 + input.PBR * calibration.GROWTH.PBR
  let PSR = 1 + input.PSR * calibration.GROWTH.PSR
  let DEBT_RATIO = 1 + input.DEBT_RATIO * calibration.GROWTH.DEBT_RATIO
  let DYR = 1 + input.DYR * calibration.GROWTH.DYR
  let PROPENSITY_DIVIDEND =
    1 + input.PROPENSITY_DIVIDEND * calibration.GROWTH.PROPENSITY_DIVIDEND
  let OPERATING_INCOME_GR =
    1 + input.OPERATING_INCOME_GR * calibration.GROWTH.OPERATING_INCOME_GR
  let MOM_PRICE_3M = 1 + input.MOM_PRICE_3M * calibration.GROWTH.MOM_PRICE_3M
  let MOM_TRDVAL_3M = 1 + input.MOM_TRDVAL_3M * calibration.GROWTH.MOM_TRDVAL_3M
  let CURRENT_RATIO = 1 + input.CURRENT_RATIO * calibration.GROWTH.CURRENT_RATIO
  let VOL_PRICE_3M = 1 + input.VOL_PRICE_3M * calibration.GROWTH.VOL_PRICE_3M
  let TURNOVER_1M = 1 + input.TURNOVER_1M * calibration.GROWTH.TURNOVER_1M
  let EV = 1 + input.EV * calibration.GROWTH.EV
  let MOM_PRICE_1M = 1 + input.MOM_PRICE_1M * calibration.GROWTH.MOM_PRICE_1M
  let RSI_14D = 1 + input.RSI_14D * calibration.GROWTH.RSI_14D
  let BETA_12M = 1 + input.BETA_12M * calibration.GROWTH.BETA_12M
  let OBV_10D = 1 + input.OBV_10D * calibration.GROWTH.OBV_10D
  let MOM_TRDVAL_1M = 1 + input.MOM_TRDVAL_1M * calibration.GROWTH.MOM_TRDVAL_1M
  let EV_EBIT = 1 + input.EV_EBIT * calibration.GROWTH.EV_EBIT

  let response =
    5 *
    SALES_REVENUE_GR *
    PER *
    ROE *
    ASSET_TURNOVER_RATIO *
    PBR *
    PSR *
    DEBT_RATIO *
    DYR *
    PROPENSITY_DIVIDEND *
    OPERATING_INCOME_GR *
    MOM_PRICE_3M *
    MOM_TRDVAL_3M *
    CURRENT_RATIO *
    VOL_PRICE_3M *
    TURNOVER_1M *
    EV *
    MOM_PRICE_1M *
    RSI_14D *
    BETA_12M *
    OBV_10D *
    MOM_TRDVAL_1M *
    EV_EBIT
  return response.toFixed(1)
}
