var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "white-layer", attrs: { id: "add-strategy-02-wrap" } },
    [
      _c("StepBar", {
        staticClass: "mb-20",
        attrs: { stage: _vm.stage, step: _vm.step },
      }),
      _vm.stage === 1
        ? _c("Step01", { on: { updated: _vm.setStage1Data } })
        : _vm._e(),
      _vm.stage === 2
        ? _c("Step02", {
            attrs: { data: _vm.stage02 },
            on: { updated: _vm.setStage2Data },
          })
        : _vm._e(),
      _vm.stage === 3
        ? _c("Step03", {
            attrs: { data: _vm.stage03 },
            on: { updated: _vm.setStage3Data },
          })
        : _vm._e(),
      _vm.stage === 4
        ? _c("Step04", {
            attrs: { data: _vm.stage04 },
            on: { updated: _vm.setStage4Data },
          })
        : _vm._e(),
      _vm.stage === 5
        ? _c("Step05", {
            attrs: { data: _vm.stage05 },
            on: { updated: _vm.setStage5Data },
          })
        : _vm._e(),
      _vm.stage === 1
        ? _c("div", { staticClass: "btn-wrap" }, [
            _c(
              "button",
              {
                staticClass: "base-btn full cl-pri",
                on: {
                  click: function ($event) {
                    _vm.stage++
                  },
                },
              },
              [_c("span", [_vm._v("다음")])]
            ),
          ])
        : _vm.stage === 5
        ? _c("div", { staticClass: "btn-wrap" }, [
            _c(
              "button",
              {
                staticClass: "base-btn harf cl-sec",
                on: {
                  click: function ($event) {
                    _vm.stage--
                  },
                },
              },
              [_c("span", [_vm._v("이전")])]
            ),
            _c(
              "button",
              {
                staticClass: "base-btn harf cl-pri",
                on: { click: _vm.goResult },
              },
              [_c("span", [_vm._v("결과보기")])]
            ),
          ])
        : _c("div", { staticClass: "btn-wrap" }, [
            _c(
              "button",
              {
                staticClass: "base-btn harf cl-sec",
                on: {
                  click: function ($event) {
                    _vm.stage--
                  },
                },
              },
              [_c("span", [_vm._v("이전")])]
            ),
            _c(
              "button",
              {
                staticClass: "base-btn harf cl-pri",
                on: {
                  click: function ($event) {
                    _vm.stage++
                  },
                },
              },
              [_c("span", [_vm._v("다음")])]
            ),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }