var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "profile-edit" }, [
    _c("ul", { staticClass: "profile-edit-form" }, [
      _c("li", [
        _c("label", [_vm._v("닉네임")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.user.nickName,
              expression: "user.nickName",
            },
          ],
          class: { error: _vm.nickNameCheck },
          attrs: { type: "text" },
          domProps: { value: _vm.user.nickName },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.user, "nickName", $event.target.value)
            },
          },
        }),
        _vm.nickNameCheck
          ? _c("span", { staticClass: "error-message" }, [
              _vm._v("이미 사용중인 닉네임이에요"),
            ])
          : _vm._e(),
      ]),
      _c("li", [
        _c("label", [_vm._v("이메일 주소")]),
        _c("input", {
          attrs: { type: "text", placeholder: _vm.user.email, disabled: "" },
        }),
      ]),
      _c("li", [
        _c("label", [_vm._v("휴대폰 번호")]),
        _c("input", {
          attrs: {
            type: "text",
            placeholder: _vm.user.phoneNumber,
            disabled: "",
          },
        }),
      ]),
      _c("li", [
        _c("div", [_vm._v("연령대")]),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.user.age,
                expression: "user.age",
              },
            ],
            on: {
              change: function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.user,
                  "age",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              },
            },
          },
          _vm._l(_vm.ageOptions, function (d, i) {
            return _c(
              "option",
              { key: i + "ageOption", domProps: { value: d.age } },
              [_vm._v(" " + _vm._s(d.ageName) + " ")]
            )
          }),
          0
        ),
      ]),
      _c("li", [
        _c("div", [_vm._v("성별")]),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.user.gender,
                expression: "user.gender",
              },
            ],
            on: {
              change: function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.user,
                  "gender",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              },
            },
          },
          _vm._l(_vm.genderOptions, function (d, i) {
            return _c(
              "option",
              { key: i + "genderOption", domProps: { value: d.gender } },
              [_vm._v(" " + _vm._s(d.genderName) + " ")]
            )
          }),
          0
        ),
      ]),
    ]),
    _c("div", { staticClass: "section" }, [
      _c(
        "button",
        { staticClass: "base-btn full cl-pri", on: { click: _vm.userUpdate } },
        [_c("span", [_vm._v("수정 완료")])]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }