<template>
  <div class="thumb-wrap">
    <div
      v-if="thumb.length > 0"
      class="thumb-box"
      @click="toggleImageModal = true"
    >
      <div>
        <div
          :style="{
            'background-image': `url(${thumb[0]})`
          }"
          alt=""
        ></div>
      </div>
      <div v-if="thumb.length > 1">
        <div
          :style="{
            'background-image': `url(${thumb[1]})`
          }"
          alt=""
        >
          <span class="more-no">{{
            thumb.length > 2 ? `+${thumb.length - 2}` : ''
          }}</span>
        </div>
      </div>
    </div>
    <div class="one-thumb-box" v-else @click="toggleImageModal = true">
      <div
        :style="{
          'background-image': `url(${thumb[0]})`
        }"
        alt=""
      ></div>
    </div>

    <ImageViewModal
      v-if="toggleImageModal"
      :list="thumb"
      @close="toggleImageModal = false"
    ></ImageViewModal>
  </div>
</template>

<script>
import ImageViewModal from './modal/ImageViewModal.vue'

export default {
  name: 'QuantMarketThumbBox',
  props: ['thumb'],
  data() {
    return {
      toggleImageModal: false
    }
  },
  mounted() {},
  methods: {},
  components: { ImageViewModal }
}
</script>

<style lang="scss" scoped>
.thumb-box {
  display: flex;
  border-radius: 6px;
  overflow: hidden;
  > div {
    width: 50%;
    > div {
      display: block;
      width: 100%;
      padding-top: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      position: relative;
      .more-no {
        position: absolute;
        font-size: 30px;
        font-weight: 700;
        top: 50%;
        left: 50%;
        color: #fff;
        transform: translate(-50%, -50%);
      }
    }
  }
}
</style>
