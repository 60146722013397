// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tab-pannel-box[data-v-45f38c19] {
  padding: 24px 16px;
}
.tab-pannel-box .empty-box[data-v-45f38c19] {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  height: 230px;
}
.tab-pannel-box .empty-box > p[data-v-45f38c19] {
  text-align: center;
  color: #999;
}
.tab-pannel-box .empty-box > .add-port-btn[data-v-45f38c19] {
  border-radius: 50px;
  width: 126px;
  height: 36px;
}
.port-box .top[data-v-45f38c19] {
  height: 124px;
  gap: 8px;
  justify-content: space-between;
}
.port-box .top .l-box .status-box[data-v-45f38c19] {
  margin-bottom: 8px;
}
.port-box .top .l-box .status-box strong[data-v-45f38c19] {
  color: #4a4ee7;
  font-weight: 600;
}
.port-box .top .l-box .user-name[data-v-45f38c19] {
  margin-bottom: 4px;
  font-size: 12px;
  color: #999;
}
.port-box .top .l-box .port-title[data-v-45f38c19] {
  color: #222;
  font-size: 16px;
  font-weight: 600;
}
.port-box .top .l-box .port-rate[data-v-45f38c19] {
  margin-top: 8px;
  font-size: 24px;
  font-weight: 600;
}
.port-box .top .l-box .up[data-v-45f38c19] {
  color: #d74343;
}
.port-box .top .l-box .down[data-v-45f38c19] {
  color: #4a4ee7;
}
.port-box .r-box[data-v-45f38c19] {
  padding: 0px 12px;
  display: flex;
  flex: 1;
  justify-content: center;
}
[data-v-45f38c19] .swiper-container {
  height: 100%;
  position: relative;
}
[data-v-45f38c19] .swiper-container .swiper-pagination .swiper-pagination-bullet {
  width: 6px;
  height: 6px;
  border-radius: 12px;
  background-color: #444;
  opacity: 0.1;
}
[data-v-45f38c19] .swiper-container .swiper-pagination .swiper-pagination-bullet-active {
  width: 18px;
  opacity: 1;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
