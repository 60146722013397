<template>
  <div id="community-wrap" class="white-layer">
    <CommRegisterModal
      v-if="toggleAddComm"
      @close="toggleAddComm = false"
    ></CommRegisterModal>
    <div class="page-title-section mt-8 mb-20">
      <h4>커뮤니티</h4>
    </div>
    <div
      class="round-desc-box"
      @click="toggleAddComm = true"
      v-if="$store.getters.getUser.isUser"
    >
      <p>퀀트마켓에서 공유하고 싶은 주제가 있나요?</p>
      <p class="puple-txt">커뮤니티를 등록해보세요.</p>
    </div>
    <div class="slide-box mb-32">
      <SliderPhotoBox
        :useTitle="true"
        class="inner-slider"
        :list="communityBanner"
      />
    </div>
    <ul class="comm-list">
      <li
        v-for="(d, i) in list"
        :key="i + 'comm'"
        @click="goDetail(d.communityId)"
      >
        <!-- {{ d }} -->
        <CommunityListBox :data="d"></CommunityListBox>
      </li>
    </ul>
  </div>
</template>

<script>
import CommunityListBox from '@/components/common/CommunityListBox.vue'
import SliderPhotoBox from '@/components/common/SliderPhotoBox.vue'
import CommRegisterModal from '@/components/common/modal/CommRegisterModal.vue'
import { axiosAuthInstance } from '@/store/axios'
export default {
  name: 'QuantMarketRoungeCard',
  components: { SliderPhotoBox, CommunityListBox, CommRegisterModal },
  data() {
    return {
      parameters: {
        page: 1,
        pageSize: 50,
        start: 0
      },
      communityBanner: [
        {
          bannerImage: 'community/community_banner_1'
        },
        {
          bannerImage: 'community/community_banner_2'
        }
      ],
      toggleAddComm: false,
      list: []
    }
  },
  created() {
    this.getCommunities()
  },
  mounted() {
    this.$store.dispatch('fetchCommunity')
    this.$store.dispatch('fetchCommunityBannr')
  },
  methods: {
    goDetail(communityId) {
      console.log('communityId', communityId)
      this.$router.push({
        name: 'CommunityDetail',
        query: { communityId: communityId }
      })
    },
    getCommunities() {
      axiosAuthInstance
        .get('/community', { params: this.parameters })
        .then((response) => {
          this.list = response.communities
        })
        .catch((error) => {
          console.log('error', error)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.round-desc-box {
  border-radius: 14px;
  background: #f7f7f7;
  margin-bottom: 16px;
  padding: 16px 20px;
  background-image: url(~@/assets/images/icon_arrow_next.svg);
  background-size: 14px 20px;
  background-position: calc(100% - 16px) 50%;
  background-repeat: no-repeat;
  .puple-txt {
    color: #4a4ee7;
    font-weight: 600;
  }
}
.slide-box {
  position: relative;
  aspect-ratio: 15/9;
  margin-left: -20px;
  margin-right: -20px;
  .inner-txt {
    position: absolute;
    left: 16px;
    bottom: 24px;
  }
}
.comm-list {
  display: flex;
  flex-direction: column;
  gap: 14px;
}
</style>
