// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-v-32dae648] .modal-footer {
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
}
.radio-input-list[data-v-32dae648] {
  display: flex;
  align-items: center;
  justify-content: center;
}
.radio-input-list > .input-list-box[data-v-32dae648] {
  padding: 14px 24px;
}
.empty-box[data-v-32dae648] {
  min-height: 220px;
}
.grid-table[data-v-32dae648] {
  margin: 0 -24px;
}
.grid-table .input-list[data-v-32dae648] {
  position: relative;
  top: 0;
  left: 0;
}
.grid-table .input-list label[data-v-32dae648] {
  display: inline-block;
  width: 28px;
  height: 28px;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
