var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "qna" },
    [
      _c(
        "div",
        { staticClass: "qna-tab" },
        _vm._l(_vm.tabList, function (tab, index) {
          return _c(
            "div",
            {
              key: `tab-${index}`,
              class: { active: tab.isActive },
              on: {
                click: function ($event) {
                  return _vm.clickTab(index)
                },
              },
            },
            [_vm._v(" " + _vm._s(tab.text) + " ")]
          )
        }),
        0
      ),
      _vm.tab === 0
        ? [
            _c(
              "ul",
              { staticClass: "qna-list" },
              _vm._l(_vm.list, function (d) {
                return _c(
                  "li",
                  {
                    key: d.qnaId,
                    staticClass: "qna-list-box",
                    class: { on: _vm.toggleList.includes(d.qnaId) },
                  },
                  [
                    _c("p", { staticClass: "qna-list-box-date" }, [
                      _vm._v(_vm._s(d.createdDate)),
                    ]),
                    _c("p", { staticClass: "qna-list-box-title" }, [
                      _vm._v(" " + _vm._s(d.title) + " "),
                    ]),
                    _c("p", { staticClass: "qna-list-box-description" }, [
                      _vm._v(" " + _vm._s(d.content) + " "),
                    ]),
                    _c("ThumbBox", { attrs: { thumb: d.storageAccesses } }),
                    _c(
                      "p",
                      {
                        staticClass: "qna-list-box-status flex-just",
                        class: { finish: d.replyStatus },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(d.replyStatus ? "답변완료" : "미답변") +
                            " "
                        ),
                        d.replyStatus
                          ? _c("i", {
                              class: `icon--arrow-${
                                _vm.toggleList.includes(d.qnaId) ? "up" : "down"
                              }`,
                              on: {
                                click: function ($event) {
                                  _vm.toggleList.includes(d.qnaId)
                                    ? _vm.toggleList.pop(d.qnaId)
                                    : _vm.toggleList.push(d.qnaId)
                                },
                              },
                            })
                          : _vm._e(),
                      ]
                    ),
                    d.replyStatus && _vm.toggleList.includes(d.qnaId)
                      ? _c("div", { staticClass: "qna-list-box-answer" }, [
                          _c("ul", { staticClass: "qna-list-box-answer-box" }, [
                            _c(
                              "li",
                              {
                                staticClass:
                                  "qna-list-box-answer-box-date flex-start",
                              },
                              [
                                _c("i", { staticClass: "icon--answer" }),
                                _c("span", [_vm._v(_vm._s(d.replyDate))]),
                              ]
                            ),
                            _c(
                              "li",
                              {
                                staticClass:
                                  "qna-list-box-answer-box-description",
                              },
                              [_vm._v(" " + _vm._s(d.replyContent) + " ")]
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ],
                  1
                )
              }),
              0
            ),
          ]
        : _vm._e(),
      _vm.tab === 1
        ? [
            _c(
              "div",
              { staticClass: "qna-add" },
              [
                _c("h2", [_vm._v("제목")]),
                _c("InputText", {
                  attrs: { "max-length": 50, placeholder: "제목" },
                  on: {
                    "update:value": function ($event) {
                      _vm.title = $event
                    },
                  },
                  model: {
                    value: _vm.title,
                    callback: function ($$v) {
                      _vm.title = $$v
                    },
                    expression: "title",
                  },
                }),
                _c("h2", [_vm._v("내용")]),
                _c("InputText", {
                  attrs: { "max-length": 2000, placeholder: "내용" },
                  on: {
                    "update:value": function ($event) {
                      _vm.content = $event
                    },
                  },
                  model: {
                    value: _vm.content,
                    callback: function ($$v) {
                      _vm.content = $$v
                    },
                    expression: "content",
                  },
                }),
                _c("input", {
                  ref: "files",
                  attrs: {
                    type: "file",
                    name: "file",
                    id: "file-element",
                    hidden: "",
                    multiple: "",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.uploadFile($event.target.files)
                    },
                  },
                }),
                _c(
                  "ul",
                  {
                    staticClass: "photo-add-list",
                    staticStyle: { "margin-top": "64px" },
                  },
                  [
                    _c(
                      "li",
                      {
                        staticClass: "photo-add-btn",
                        on: { click: _vm.openUpload },
                      },
                      [
                        _c("i", { staticClass: "icon--photo-b" }),
                        _c("span", [_vm._v("사진추가")]),
                      ]
                    ),
                    _vm._l(_vm.images, function (d, i) {
                      return _c(
                        "li",
                        { key: i + "photo", staticClass: "choose-user-photo" },
                        [
                          _c("img", {
                            staticStyle: {
                              "max-width": "100%",
                              "object-fit": "contain",
                            },
                            attrs: { src: d.accessPath, alt: "" },
                          }),
                          _c("i", {
                            staticClass: "icon--delete",
                            on: {
                              click: function ($event) {
                                return _vm.deleteImage(i)
                              },
                            },
                          }),
                        ]
                      )
                    }),
                  ],
                  2
                ),
                _c("span", { staticClass: "list-desc" }, [
                  _vm._v("*5MB 미만 jpg, jpeg, png, heic 만 등록 가능"),
                ]),
                _c(
                  "button",
                  {
                    staticClass:
                      "base-btn cl-pri full static-height margin-top-10p",
                    on: { click: _vm.saveQna },
                  },
                  [_c("span", [_vm._v("저장하기")])]
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }