// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#strategy-result-wrap[data-v-5c4f76af] {
  background: #f1f1f1;
}
#strategy-result-wrap .page-title-section[data-v-5c4f76af] {
  padding: 0 16px;
  background-color: #fff;
  border-bottom: 1px solid #f1f1f1;
}
#strategy-result-wrap .sec-head h3[data-v-5c4f76af] {
  font-size: 18px;
}
#strategy-result-wrap .sec-head .status-box strong[data-v-5c4f76af] {
  color: #4a4ee7;
  font-size: 20px;
  font-weight: 600;
}
#strategy-result-wrap .sec-head .rate-box strong[data-v-5c4f76af] {
  color: #d74343;
  font-size: 20px;
  font-weight: 600;
}
#strategy-result-wrap .date-tab-list[data-v-5c4f76af] {
  display: flex;
  justify-content: space-between;
}
#strategy-result-wrap .date-tab-list > li[data-v-5c4f76af] {
  padding: 10px;
}
#strategy-result-wrap .date-tab-list > li.on[data-v-5c4f76af] {
  border-radius: 10px;
  font-weight: 600;
  background: #f9f9f9;
}
#strategy-result-wrap .box-section[data-v-5c4f76af] {
  background-color: #fff;
  padding: 32px 16px;
  margin-bottom: 8px;
}
#strategy-result-wrap .result-section .round-box[data-v-5c4f76af] {
  border-radius: 14px;
  border: 1px solid #e8e8e8;
  background: #fff;
  padding: 16px 20px;
  display: flex;
  align-items: center;
  gap: 12px;
}
#strategy-result-wrap .result-section .round-box .r-box strong[data-v-5c4f76af] {
  color: #222;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 6px;
}
#strategy-result-wrap .result-section .round-box .r-box p[data-v-5c4f76af] {
  color: #999;
  font-size: 12px;
  font-weight: 400;
}
#strategy-result-wrap .result-section .etc-box .val-list[data-v-5c4f76af] {
  margin-top: 14px;
  display: flex;
  flex-direction: column;
  gap: 14px;
}
#strategy-result-wrap .result-section .etc-box .val-list > li[data-v-5c4f76af] {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#strategy-result-wrap .result-section .etc-box .val-list > li span[data-v-5c4f76af] {
  color: #666;
}
#strategy-result-wrap .round-box-list[data-v-5c4f76af] {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 8px;
  -moz-column-gap: 28px;
       column-gap: 28px;
  padding: 16px 35px;
  border-radius: 14px;
  background: #f7f7f7;
}
#strategy-result-wrap .round-box-list > li[data-v-5c4f76af] {
  display: flex;
  justify-content: space-between;
  align-self: center;
  max-width: 68px;
  padding-left: 8px;
  position: relative;
}
#strategy-result-wrap .round-box-list > li[data-v-5c4f76af]::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: #d9d9d9;
}
#strategy-result-wrap .round-box-list > li .l-box[data-v-5c4f76af] {
  color: #666;
}
#strategy-result-wrap .round-box-list > li .r-box[data-v-5c4f76af] {
  font-weight: 500;
}
#strategy-result-wrap .compo-section .compo-date[data-v-5c4f76af] {
  color: #999;
  font-size: 12px;
}
#strategy-result-wrap .compo-section .grid-table .table-head[data-v-5c4f76af],
#strategy-result-wrap .compo-section .grid-table .table-list[data-v-5c4f76af] {
  grid-template-columns: 1fr 1fr 1fr;
}
#strategy-result-wrap .compo-section .grid-table .table-list[data-v-5c4f76af] {
  min-height: 42px;
  height: auto;
  padding: 8px 0;
}
#strategy-result-wrap .compo-section .grid-table .table-list > li[data-v-5c4f76af] {
  height: auto;
  min-height: auto;
  color: #666;
}
.btn-wrap[data-v-5c4f76af] {
  display: flex;
  gap: 8px;
}
[data-v-5c4f76af] #toggle-slider-modal .modal-cont-wrap .modal-body,[data-v-5c4f76af] #toggle-slider-modal .modal-cont-wrap .modal-head {
  padding-top: 0;
  padding-bottom: 0;
}
[data-v-5c4f76af] .modal-cont-wrap {
  height: calc(var(--vh, 1vh) * 100);
}
.valid-modal .modal-body-wrap .port-list[data-v-5c4f76af] {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  gap: 28px;
}
.valid-modal .modal-body-wrap .port-list > li[data-v-5c4f76af] {
  display: flex;
  align-items: center;
  gap: 12px;
}
.valid-modal .modal-body-wrap .port-list > li .port-name[data-v-5c4f76af],
.valid-modal .modal-body-wrap .port-list > li .port-date[data-v-5c4f76af] {
  font-size: 16px;
  font-weight: 500;
}
.valid-modal .modal-body-wrap .port-list > li .port-date[data-v-5c4f76af] {
  margin-left: auto;
  color: #999;
}
.valid-modal .modal-btn-wrap[data-v-5c4f76af] {
  display: flex;
  gap: 8px;
}
.complete-modal .complete-modal-body[data-v-5c4f76af] {
  padding: 24px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 150px;
}
.complete-modal .complete-modal-body h3[data-v-5c4f76af] {
  margin-top: 16px;
  text-align: center;
}
.complete-modal .complete-modal-body p[data-v-5c4f76af] {
  text-align: center;
  margin-top: 8px;
  color: #bfbfbf;
}
.complete-modal .complete-modal-body .base-btn[data-v-5c4f76af] {
  margin-top: auto;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
