var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.community
    ? _c(
        "div",
        { staticClass: "white-layer", attrs: { id: "community-detail-wrap" } },
        [
          _c("div", { staticClass: "page-title-section mb-24" }, [
            _c("h4", [_vm._v(_vm._s(_vm.community.name))]),
            _c("p", { staticClass: "q-sub-txt" }, [
              _vm._v(_vm._s(_vm.community.subTitle)),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "slide-box mb-24" },
            [
              _c("SliderPhotoBox", {
                staticClass: "inner-slider",
                attrs: {
                  list: _vm.community.storageAccesses,
                  type: "community",
                },
              }),
            ],
            1
          ),
          _c("div", {
            staticClass: "content-box",
            domProps: { innerHTML: _vm._s(_vm.community.contents) },
          }),
          _c(
            "button",
            {
              staticClass: "go-button base-btn cl-pri full",
              on: {
                click: function ($event) {
                  return _vm.goChannel(_vm.community.outLink)
                },
              },
            },
            [_c("span", [_vm._v("입장하기")])]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }