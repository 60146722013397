var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("SlideUpModal", {
        staticClass: "comm-regist-modal",
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _c("div", { staticClass: "flex-just" }, [
                  _c("h4", [_vm._v("글쓰기")]),
                  _c("i", {
                    staticClass: "icon--close",
                    on: { click: _vm.cancel },
                  }),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "main",
            fn: function () {
              return [
                _c("FilterTabList", {
                  attrs: { tab: _vm.tab, list: _vm.filterTab },
                  on: {
                    tab: function ($event) {
                      _vm.tab = $event
                    },
                  },
                }),
                _c(
                  "div",
                  { staticClass: "input-list" },
                  [
                    _c("InputText", {
                      staticClass: "mb-32",
                      attrs: { "max-length": 50, placeholder: "(선택) 제목" },
                      on: {
                        "update:value": function ($event) {
                          _vm.title = $event
                        },
                      },
                      model: {
                        value: _vm.title,
                        callback: function ($$v) {
                          _vm.title = $$v
                        },
                        expression: "title",
                      },
                    }),
                    _c("InputText", {
                      staticClass: "mb-32",
                      attrs: {
                        "max-length": 200,
                        placeholder: "내용을 입력해주세요",
                      },
                      on: {
                        "update:value": function ($event) {
                          _vm.subTitle = $event
                        },
                      },
                      model: {
                        value: _vm.subTitle,
                        callback: function ($$v) {
                          _vm.subTitle = $$v
                        },
                        expression: "subTitle",
                      },
                    }),
                  ],
                  1
                ),
                _c("input", {
                  ref: "files",
                  attrs: {
                    type: "file",
                    name: "file",
                    id: "file-element",
                    hidden: "",
                    multiple: "",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.uploadFile($event.target.files)
                    },
                  },
                }),
                _c(
                  "ul",
                  { staticClass: "photo-add-list" },
                  [
                    _c(
                      "li",
                      {
                        staticClass: "photo-add-btn",
                        on: { click: _vm.openUpload },
                      },
                      [
                        _c("i", { staticClass: "icon--photo-b" }),
                        _c("span", [_vm._v("사진추가")]),
                      ]
                    ),
                    _vm._l(_vm.images, function (d, i) {
                      return _c(
                        "li",
                        { key: i + "photo", staticClass: "choose-user-photo" },
                        [
                          _c("img", {
                            staticStyle: {
                              "max-width": "100%",
                              "object-fit": "contain",
                            },
                            attrs: { src: d.accessPath, alt: "" },
                          }),
                          _c("i", {
                            staticClass: "icon--delete",
                            on: {
                              click: function ($event) {
                                return _vm.deleteImage(i)
                              },
                            },
                          }),
                        ]
                      )
                    }),
                  ],
                  2
                ),
                _c("span", { staticClass: "list-desc" }, [
                  _vm._v("*5MB 미만 jpg, jpeg, png, heic 만 등록 가능"),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "button",
            fn: function () {
              return [
                _c("div", { staticClass: "modal-btn-wrap" }, [
                  _c(
                    "button",
                    {
                      staticClass: "base-btn full cl-pri",
                      on: {
                        click: function ($event) {
                          _vm.isCreate ? _vm.saveLounge() : _vm.updateLounge()
                        },
                      },
                    },
                    [
                      _vm.isCreate ? _c("span", [_vm._v("등록")]) : _vm._e(),
                      !_vm.isCreate ? _c("span", [_vm._v("수정")]) : _vm._e(),
                    ]
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }