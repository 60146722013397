<template>
  <div class="full-popup">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'QuantMarketFullModal',

  data() {
    return {}
  },

  mounted() {},

  methods: {}
}
</script>

<style lang="scss" scoped>
.full-popup {
  z-index: 9899999;
  padding: 24px 16px;
  background-color: #fff;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow-y: scroll;
}
</style>
