<template>
  <div class="quant-swiper-box" v-if="list.length > 0">
    <swiper
      ref="swiper"
      slides-per-view="1"
      :loop="true"
      :pagination="{ type: 'fraction' }"
      @click="onClick"
      @swiper="onSwiper"
      @slideChange="onSlideChange"
    >
      <swiper-slide v-for="(data, idx) in list" :key="idx">
        <div class="quant-slide-item">
          <img
            v-if="
              type === 'community' || type === 'store' || type === 'product'
            "
            alt=""
            class="slide-thumb"
            :src="data"
          />
          <img
            v-else
            alt=""
            class="slide-thumb"
            :src="require(`@/assets/images/banner/${data.bannerImage}.png`)"
          />
          <div class="round-desc-box" v-if="useRound">
            <p>{{ data.title }}</p>
            <p>{{ data.desc }}</p>
          </div>
          <div class="title-box no-wrap-text" v-if="useTitle">
            <h3 class="mb-4">{{ data.title }}</h3>
            <p>{{ data.desc }}</p>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import swiperSettings from '@/data/swiperSettings.js'
import { Pagination } from 'swiper'
import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'
// import 'swiper/css/swiper.css'
// import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/components/pagination/pagination.min.css'
import 'swiper/swiper-bundle.css'
SwiperCore.use([Pagination])
export default {
  name: 'SliderContents',
  components: { Swiper, SwiperSlide },
  props: {
    type: {
      type: String,
      default: 'banner'
    },
    list: {
      type: Array,
      default: () => {
        return []
      }
    },
    useRound: {
      type: Boolean,
      default: false
    },
    useTitle: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return { ...swiperSettings }
  },
  computed: {
    slideNumber() {
      return { ...this.$refs.swiper }
      // return `${this.$refs.swiper.realIndex} / ${this.list.length}`
    }
  },
  methods: {
    onClick() {
      this.$router.push({ name: 'Shopping' })
    },
    onSwiper() {},
    onSlideChange() {}
  },
  mounted() {}
}
</script>

<style lang="scss" scoped>
.round-desc-box {
  border-radius: 14px;
  background: #f7f7f7;
  margin-bottom: 16px;
  padding: 16px 20px;
  background-image: url(~@/assets/images/icon_arrow_next.svg);
  background-size: 14px 20px;
  background-position: calc(100% - 16px) 50%;
  background-repeat: no-repeat;
  .puple-txt {
    color: #4a4ee7;
    font-weight: 600;
  }
}
.quant-swiper-box {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  .quant-slide-item {
    width: 100%;
    height: 100%;
    background-color: grey;
    position: relative;
    img {
      position: absolute;
      left: 0;
      top: 0;
      display: inline-block;
      width: 100%;
      height: 100%;
    }
    .title-box {
      position: absolute;
      max-width: 50%;
      * {
        color: #fff;
      }

      bottom: 24px;
      left: 24px;
      z-index: 99;
    }
  }
  ::v-deep .swiper-container {
    height: 100%;
    position: relative;
    .swiper-pagination {
      position: absolute;
      bottom: 20px;
      left: calc(100% - 34px);
      transform: translateX(-50%);
      display: inline-flex;
      padding: 4px 6px;
      justify-content: center;
      align-items: center;
      border-radius: 50px;
      background: rgba(0, 0, 0, 0.2);
      color: #fff;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      width: 32px;
      span {
        color: #fff;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
      }
    }
  }
}
</style>
