import {
  getCommunityBannr,
  getCommunity,
  getCommunityDtl,
  setCommunity
} from '@/api'

// Set current selected library as default library
const state = {
  communityList: [],
  communityBannr: [],
  communityDetail: null
}

const getters = {
  getCommunityList: (state) => state.communityList,
  getCommunityBannr: (state) => state.communityBannr,
  getCommunityDetail: (state) => state.communityDetail
}

const actions = {
  fetchCommunityBannr({ commit }) {
    return new Promise((resolve, reject) => {
      getCommunityBannr()
        .then((resp) => {
          commit('SET_COMMUNITY_BANNR', resp.data.result)
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  fetchCommunity({ commit }) {
    return new Promise((resolve, reject) => {
      getCommunity()
        .then((resp) => {
          commit('SET_COMMUNITY_DATA', resp.data.result)
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  fetchCommunityDtl({ commit }) {
    return new Promise((resolve, reject) => {
      getCommunityDtl()
        .then((resp) => {
          commit('SET_COMMUNITY_DTL', resp.data.result)
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  setCommunity() {
    return new Promise((resolve, reject) => {
      setCommunity()
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

const mutations = {
  SET_COMMUNITY_BANNR(state, payload) {
    state.communityBannr = payload
  },
  SET_COMMUNITY_DATA(state, payload) {
    state.communityList = payload
  },
  SET_COMMUNITY_DTL(state, payload) {
    state.communityDetail = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
