<template>
  <div id="portfolio-detail-wrap" class="default-layer">
    <div class="page-head-options" style="z-index: 9999" v-if="isOpen">
      <i class="icon--share" @click="sharePage()"></i>
      <i class="icon--dot" @click="isTogglePortEditList = true"></i>
    </div>
    <template v-if="resultData">
      <div class="page-title-section flex coll">
        <h4>{{ portfolioData.portfolioName }}</h4>
        <div
          class="exam-box"
          v-if="getPortfolioStatusName(portfolioData.reviewStatus)"
        >
          {{ getPortfolioStatusName(portfolioData.reviewStatus) }}
        </div>
        <p class="name">{{ portfolioData.nickName }}</p>
      </div>
      <div class="page-body">
        <div class="style-section box-section">
          <div class="sec-head flex-just">
            <h3>스타일</h3>
            <div class="status-box flex-center" v-if="isStyle">
              <i class="icon--state size-big" :class="`${styleTp}`"></i>
              <strong>{{ styleNm }}</strong>
            </div>
          </div>
          <div class="sec-body">
            <div class="flex-center pt-18 pb-16">
              <RadarChart
                :data="radarData"
                :isDown="isDown"
                ref="childRadarRef"
              ></RadarChart>
            </div>
            <ul class="round-box-list">
              <li>
                <span class="l-box">성장</span
                ><span class="r-box">{{ radarData[0] }}</span>
              </li>
              <li>
                <span class="l-box">가치</span
                ><span class="r-box">{{ radarData[1] }}</span>
              </li>
              <li>
                <span class="l-box">모멘텀</span
                ><span class="r-box">{{ radarData[2] }}</span>
              </li>
              <li>
                <span class="l-box">배당</span
                ><span class="r-box">{{ radarData[3] }}</span>
              </li>
              <li>
                <span class="l-box">안전</span
                ><span class="r-box">{{ radarData[4] }}</span>
              </li>
              <li>
                <span class="l-box">우량</span
                ><span class="r-box">{{ radarData[5] }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="rate-section box-section">
          <div class="sec-head flex-just">
            <h3>
              수익률<span
                >{{ rateOfReturnStartDate }} ~ {{ rateOfReturnEndDate }}</span
              >
            </h3>
            <div
              class="flex-center"
              :class="isDown ? 'status-box' : 'rate-box'"
            >
              <strong>{{ rateOfReturn }}%</strong>
            </div>
          </div>
          <div class="sec-body">
            <Graph :data="lineData" ref="childLineRef" :isDown="isDown" />
            <ul class="date-tab-list">
              <li :class="{ on: viewRate === 1 }" @click="changeViewRate(1)">
                <span
                  :class="{ graphDisAbleLabel: !portfolioData.rorPercent3m }"
                  >3개월</span
                >
              </li>
              <li :class="{ on: viewRate === 2 }" @click="changeViewRate(2)">
                <span
                  :class="{ graphDisAbleLabel: !portfolioData.rorPercent6m }"
                  >6개월</span
                >
              </li>
              <li :class="{ on: viewRate === 3 }" @click="changeViewRate(3)">
                <span
                  :class="{ graphDisAbleLabel: !portfolioData.rorPercent12m }"
                  >1년</span
                >
              </li>
              <li :class="{ on: viewRate === 4 }" @click="changeViewRate(4)">
                <span
                  :class="{ graphDisAbleLabel: !portfolioData.rorPercent24m }"
                  >2년</span
                >
              </li>
              <li :class="{ on: viewRate === 5 }" @click="changeViewRate(5)">
                <span
                  :class="{ graphDisAbleLabel: !portfolioData.rorPercent36m }"
                  >3년</span
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="result-section box-section">
          <div class="sec-head flex-just">
            <h3 class="l_box">최근 3년 성과</h3>
            <i
              :class="isToggleRcntYear ? 'icon--arrow-up' : 'icon--arrow-down'"
              @click="isToggleRcntYear = !isToggleRcntYear"
            ></i>
          </div>
          <div class="etc-box mt-32" v-if="isToggleRcntYear">
            <div class="sec-head">
              <h3>기타성과지표</h3>
            </div>
            <div class="sec-body">
              <ul class="val-list">
                <li>
                  <span class="l-box">연평균수익률(CAGR)</span>
                  <span class="r-box">
                    {{ portfolioData.createPerformance.ROR_AVG_PCT }}%
                  </span>
                </li>
                <li>
                  <span class="l-box">최대낙폭(MDD)</span>
                  <span class="r-box">
                    {{ portfolioData.createPerformance.MDD_PCT }}%
                  </span>
                </li>
                <li>
                  <span class="l-box">변동성(연)</span>
                  <span class="r-box">
                    {{ portfolioData.createPerformance.VOL_YEAR }}%
                  </span>
                </li>
                <li>
                  <span class="l-box">베타</span>
                  <span class="r-box"
                    >{{ portfolioData.createPerformance.BETA }}%
                  </span>
                </li>
                <li>
                  <span class="l-box">샤프비율</span>
                  <span class="r-box"
                    >{{ portfolioData.createPerformance.SHARPE_RATIO }}%
                  </span>
                </li>
                <li>
                  <span class="l-box">평균보유종목</span>
                  <span class="r-box">
                    {{ portfolioData.createPerformance.AVG_HOLDING_ISU }}종목
                  </span>
                </li>
                <li class="sec-head mt-18"><h3>누적수익률 비교</h3></li>
                <li>
                  <span class="l-box">회원님 전략</span>
                  <span class="r-box">
                    {{ portfolioData.createPerformance.ROR_TOT_PCT }}%
                  </span>
                </li>
                <li>
                  <span class="l-box">코스피 지수</span>
                  <span class="r-box">
                    {{ portfolioData.createPerformance.KOSPI_ROR_PCT }}%
                  </span>
                </li>
                <li>
                  <span class="l-box">코스닥 지수</span>
                  <span class="r-box">
                    {{ portfolioData.createPerformance.KOSDAQ_ROR_PCT }}%
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="compo-section box-section">
          <div class="sec-head flex-start mb-24">
            <h3 class="l_box">구성종목</h3>
            <span class="compo-date">{{ stockWeights.baseDate }}</span>
            <button class="diff-btn" v-if="false">
              <i class="icon--diff"></i>비교
            </button>
          </div>
          <div class="sec-body">
            <div class="grid-table">
              <ul class="table-head">
                <li>종목</li>
                <li>주가</li>
                <li>비중</li>
              </ul>
              <ul
                v-for="(d, i) in stockWeights.stockData"
                :key="i + 'search'"
                class="table-list"
              >
                <li>{{ d.stockName }}</li>
                <li>{{ Number(d.currentPrices).toLocaleString() }}</li>
                <li>{{ weight(d.weight) }}%</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="result-section box-section" v-if="isOpen && isStyle">
          <div class="etc-box">
            <div class="sec-head flex-start">
              <h3 class="l_box">전략 설정값</h3>
              <span>회원님이 만든 전략 설정값입니다.</span>
            </div>
            <div class="sec-body">
              <ul
                class="val-list"
                v-for="(d, i) in portfolioStyleData"
                :key="i + 'strategy'"
              >
                <li>
                  <span class="l-box">{{ d.indicatorName }}</span>
                  <span class="r-box">
                    {{ sortOrderName(d.sortOrderAsc) }}
                    {{ factorsValue(d.value) }}%
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="result-section box-section" v-if="isOpen">
          <div class="sec-head flex-just">
            <h3 class="l_box">
              백테스트 내용<span
                >{{ portfolioData.startDate }} ~
                {{ portfolioData.endDate }}</span
              >
            </h3>
            <i
              :class="isToggleBack ? 'icon--arrow-up' : 'icon--arrow-down'"
              @click="isToggleBack = !isToggleBack"
            ></i>
          </div>
          <div class="etc-box mt-32" v-if="isToggleBack">
            <div class="sec-head">
              <h3>기타성과지표</h3>
            </div>
            <div class="sec-body">
              <ul class="val-list">
                <li>
                  <span class="l-box">연평균수익률(CAGR)</span>
                  <span class="r-box">
                    {{ portfolioData.createPerformance.ROR_AVG_PCT }}%
                  </span>
                </li>
                <li>
                  <span class="l-box">최대낙폭(MDD)</span>
                  <span class="r-box">
                    {{ portfolioData.createPerformance.MDD_PCT }}%
                  </span>
                </li>
                <li>
                  <span class="l-box">변동성(연)</span>
                  <span class="r-box">
                    {{ portfolioData.createPerformance.VOL_YEAR }}%
                  </span>
                </li>
                <li class="sec-head mt-18"><h3>누적수익률 비교</h3></li>
                <li>
                  <span class="l-box">회원님 전략</span>
                  <span class="r-box">
                    {{ portfolioData.createPerformance.ROR_TOT_PCT }}%
                  </span>
                </li>
                <li>
                  <span class="l-box">코스피 지수</span>
                  <span class="r-box">
                    {{ portfolioData.createPerformance.KOSPI_ROR_PCT }}%
                  </span>
                </li>
                <li>
                  <span class="l-box">코스닥 지수</span>
                  <span class="r-box">
                    {{ portfolioData.createPerformance.KOSDAQ_ROR_PCT }}%
                  </span>
                </li>
              </ul>
              <Graph
                :data="backTestLine"
                ref="backTestChildLineRef"
                :isDown="isDown"
              ></Graph>
            </div>
          </div>
        </div>
      </div>
    </template>
    <SlideUpModal class="edit-modal" v-if="isTogglePortEditList">
      <template #header>
        <div class="flex-just">
          <h4></h4>
          <i class="icon--close" @click="closeSlider"></i>
        </div>
      </template>
      <template #main>
        <ul class="edit-modal-list">
          <li @click="togglePortName">포트폴리오 이름 변경</li>
          <li @click="toggleReleaseBox">공개/비공개 설정</li>
          <li @click="removePort">삭제하기</li>
        </ul>
      </template>
    </SlideUpModal>
  </div>
</template>

<script>
import SlideUpModal from '@/components/common/modal/SlideUpModal.vue'
import RadarChart from '../../components/common/RadarChart.vue'
import Graph from '../../components/common/chart/Graph.vue'
import { axiosAuthInstance } from '@/store/axios'

export default {
  name: 'QuantMarketAddStrategyType1',
  data() {
    return {
      viewRate: 1,
      isToggleBack: false,
      isToggleRcntYear: false,
      isTogglePortEditList: false,
      isToggleEditName: false,
      isToggleRelease: false,
      changeName: '',
      styleType: '',
      styleNm: '',
      styleTp: '',
      rateOfReturnStartDate: '',
      rateOfReturnEndDate: '',
      portfolioData: {},
      portfolioStatusName: '',
      lineData: [],
      backTestLine: [],
      isDown: false,
      rateOfReturn: 0,
      stockWeights: {},
      calculate: {},
      radarData: [5, 5, 5, 5, 5, 5],
      portfolioStyleData: []
    }
  },
  created() {
    axiosAuthInstance
      .get('/portfolio/' + this.$route.params.id, {})
      .then((response) => {
        this.portfolioData = response
        this.stockWeights = this.portfolioData.portfolioStockWeights[0]
        this.calculate = this.portfolioData.styleData

        for (const factorsKey in this.portfolioData.requestData.FACTORS) {
          const portfolioStyleData =
            this.portfolioData.portfolioStyleData.filter((data) => {
              return data.indicatorCode === factorsKey
            })
          this.portfolioStyleData.push({
            ...portfolioStyleData[0],
            value: this.portfolioData.requestData.FACTORS[factorsKey]
          })
          this.styleType = this.portfolioData.styleType
          switch (this.styleType) {
            case 'GROWTH':
              this.styleNm = '성장형'
              this.styleTp = 'exp'
              break
            case 'VALUE':
              this.styleNm = '가치형'
              this.styleTp = 'val'
              break
            case 'MOMENTUM':
              this.styleNm = '모멘텀'
              this.styleTp = 'moe'
              break
            case 'DIVIDEND':
              this.styleNm = '배당형'
              this.styleTp = 'money'
              break
            case 'LOWVOL':
              this.styleNm = '안정형'
              this.styleTp = 'safe'
              break
            case 'QUALITY':
              this.styleNm = '우량형'
              this.styleTp = 'big'
              break
          }
        }

        this.backTestLine = this.portfolioData.portfolioTimeSeries.map(
          (info) => {
            return { x: info.baseDate, y: info.valueIndex - 100 }
          }
        )

        this.updateRadar(this.calculate)
        this.changeViewRate(1)
        this.$refs.backTestChildLineRef.forceRender()
      })
      .catch((error) => {
        console.log('error', error)
      })
  },
  computed: {
    resultData() {
      if (Object.keys(this.$store.getters.getPortfolioDtl).length === 0)
        return false
      return this.$store.getters.getPortfolioDtl
    },
    isStyle() {
      return this.portfolioData.styleType !== 'STOCKS'
    },
    isOpen() {
      let isOpen = false
      if (this.portfolioData.myPortfolio) {
        isOpen = true
      }
      return isOpen
    }
  },
  mounted() {
    this.$store.dispatch('fetchPortfolioDtl')
  },
  methods: {
    sharePage() {
      console.log('share')
      // const shareObject = {
      //   title: '포트폴리오 공유하기',
      //   text: '',
      //   url: window.location.href,
      //   files: []
      // }

      let navigator = window.navigate
      if (navigator.canShare()) {
        alert('can share')
      } else {
        this.$Simplert.open({
          title: '알림',
          message:
            '페이지 공유를 지원하지 않습니다\n지원하는 기기에서 시도해주세요'
        })
      }

      // if (navigator.share) {
      //   // Navigator를 지원하는 경우만 실행
      //   navigator
      //     .share(shareObject)
      //     .then(() => {
      //       // 정상 동작할 경우 실행
      //       alert('공유하기 성공')
      //     })
      //     .catch(() => {
      //       alert('에러가 발생했습니다')
      //     })
      // } else {
      //   // navigator를 지원하지 않는 경우
      //   this.$Simplert.open({
      //     title: '알림',
      //     message:
      //       '페이지 공유를 지원하지 않습니다\n지원하는 기기에서 시도해주세요'
      //   })
      // }
    },
    getPortfolioStatusName(portfolioStatus) {
      if (portfolioStatus === 'WAIT') return '심사요청 가능'
      if (portfolioStatus === 'REQUEST') return '심사 접수'
      if (portfolioStatus === 'UNDER_REVIEW') return '심사중'
      if (portfolioStatus === 'APPROVAL') return '승인'
      if (portfolioStatus === 'REJECT') return '반려'
    },
    updateRadar(calculate) {
      this.radarData[0] = Number(calculate.growth)
      this.radarData[1] = Number(calculate.value)
      this.radarData[2] = Number(calculate.momentum)
      this.radarData[3] = Number(calculate.dividend)
      this.radarData[4] = Number(calculate.lowvol)
      this.radarData[5] = Number(calculate.quality)

      this.$refs.childRadarRef.forceRender()
    },
    isAble(viewRate) {
      console.log(viewRate)
    },
    changeViewRate(viewRate) {
      let tempViewRate = this.viewRate
      this.viewRate = viewRate

      if (this.viewRate == 1) {
        if (!this.portfolioData.rorPercent3m) {
          this.viewRate = tempViewRate
          return
        }
        this.rateOfReturn = this.rorPercentConvert(
          this.portfolioData.rorPercent3m
        )
        const timeseriesLength = this.portfolioData.portfolioTimeSeries.length
        const timeseriesStartNumber =
          timeseriesLength > 90 ? timeseriesLength - 90 : 0
        this.lineData = this.portfolioData.portfolioTimeSeries.slice(
          timeseriesStartNumber,
          timeseriesLength
        )
        let value = 100 - this.lineData[0].valueIndex
        this.lineData = this.lineData.map((info) => {
          return { x: info.baseDate, y: info.valueIndex - 100 + value }
        })
      } else if (this.viewRate == 2) {
        if (!this.portfolioData.rorPercent6m) {
          this.viewRate = tempViewRate
          return
        }
        this.rateOfReturn = this.rorPercentConvert(
          this.portfolioData.rorPercent6m
        )
        const timeseriesLength = this.portfolioData.portfolioTimeSeries.length
        const timeseriesStartNumber =
          timeseriesLength > 180 ? timeseriesLength - 180 : 0
        this.lineData = this.portfolioData.portfolioTimeSeries.slice(
          timeseriesStartNumber,
          timeseriesLength
        )
        let value = 100 - this.lineData[0].valueIndex
        this.lineData = this.lineData.map((info) => {
          return { x: info.baseDate, y: info.valueIndex - 100 + value }
        })
      } else if (this.viewRate == 3) {
        if (!this.portfolioData.rorPercent12m) {
          this.viewRate = tempViewRate
          return
        }
        this.rateOfReturn = this.rorPercentConvert(
          this.portfolioData.rorPercent12m
        )
        const timeseriesLength = this.portfolioData.portfolioTimeSeries.length
        const timeseriesStartNumber =
          timeseriesLength > 365 ? timeseriesLength - 365 : 0
        this.lineData = this.portfolioData.portfolioTimeSeries.slice(
          timeseriesStartNumber,
          timeseriesLength
        )
        let value = 100 - this.lineData[0].valueIndex
        this.lineData = this.lineData.map((info) => {
          return { x: info.baseDate, y: info.valueIndex - 100 + value }
        })
        console.log(this.portfolioData)
      } else if (this.viewRate == 4) {
        if (!this.portfolioData.rorPercent24m) {
          this.viewRate = tempViewRate
          return
        }
        this.rateOfReturn = this.rorPercentConvert(
          this.portfolioData.rorPercent24m
        )
        const timeseriesLength = this.portfolioData.portfolioTimeSeries.length
        const timeseriesStartNumber =
          timeseriesLength > 730 ? timeseriesLength - 730 : 0
        this.lineData = this.portfolioData.portfolioTimeSeries.slice(
          timeseriesStartNumber,
          timeseriesLength
        )
        let value = 100 - this.lineData[0].valueIndex
        this.lineData = this.lineData.map((info) => {
          return { x: info.baseDate, y: info.valueIndex - 100 + value }
        })
      } else if (this.viewRate == 5) {
        if (!this.portfolioData.rorPercent36m) {
          this.viewRate = tempViewRate
          return
        }
        this.rateOfReturn = this.rorPercentConvert(
          this.portfolioData.rorPercent36m
        )
        const timeseriesLength = this.portfolioData.portfolioTimeSeries.length
        const timeseriesStartNumber =
          timeseriesLength > 1096 ? timeseriesLength - 1096 : 0
        this.lineData = this.portfolioData.portfolioTimeSeries.slice(
          timeseriesStartNumber,
          timeseriesLength
        )
        let value = 100 - this.lineData[0].valueIndex
        this.lineData = this.lineData.map((info) => {
          return { x: info.baseDate, y: info.valueIndex - 100 + value }
        })
      }
      console.log(this.lineData)
      const lineDataLength = this.lineData.length
      this.rateOfReturnStartDate = this.lineData[0].x
      this.rateOfReturnEndDate = this.lineData[lineDataLength - 1].x
      this.isDown = this.rateOfReturn < 0
      this.$refs.childLineRef.forceRender()
      this.$refs.childRadarRef.forceRender()
    },
    weight(val) {
      return (val * 100).toFixed(2)
    },
    factorsValue(val) {
      return (val * 100).toFixed(0)
    },
    sortOrderName(sortOrderAsc) {
      if (sortOrderAsc) {
        return '상위'
      } else {
        return '하위'
      }
    },
    addList(list) {
      console.log(list)
      this.list = list
      this.toggleSearchModal = false
    },
    removeList(data) {
      const { seq } = data
      this.list = this.list.filter((e) => e.seq !== seq)
    },
    async togglePortName() {
      let obj = {
        title: '포트폴리오 이름 변경',
        message: `<div class="round-input mt-16 mb-24">
          <input id="change-name-input" class="full-input" type="text" placeholder="한글/영문/숫자 조합 6~10자"/><i
            id="alert-delete"
            class="icon--delete"
          ></i>
        </div>`,
        type: 'info',
        useConfirmBtn: true,
        customConfirmBtnText: '저장',
        customCloseBtnText: '아니오',
        onConfirm: this.confirmChangeNameClick
      }
      // this.$refs.simplert.openSimplert(obj)
      try {
        await this.$Simplert.open(obj)
        const remove = document.getElementById('alert-delete')
        console.log(remove)
        remove.onclick = () => {
          const target = document.getElementById('change-name-input')
          target.value = ''
        }
      } catch (err) {
        console.log(err)
      }
    },
    confirmChangeNameClick() {
      const target = document.getElementById('change-name-input')
      const param = {
        portfolioId: this.portfolioData.portfolioId,
        portfolioName: target.value
      }
      axiosAuthInstance
        .put('/portfolio/portfolioName', JSON.stringify(param), {
          headers: {
            'Content-Type': `application/json`
          }
        })
        .then(() => {
          const target = document.getElementById('change-name-input')
          this.portfolioData.portfolioName = target.value
          this.isTogglePortEditList = false
        })
        .catch((error) => {
          console.log('error', error)
        })
    },
    async toggleReleaseBox() {
      this.isToggleRelease = true
      let obj = {
        title: '공개/비공개 설정',
        message: `<div id="port-alert-dtl" class="input-list radio-input-list">
        <div class="input-list-box">
          <input id="searchTp1" name="releaseValue" value="true" type="radio" />
          <label for="searchTp1"><strong>공개</strong><p>회원님의 멋진 포트폴리오가 어떻게 만들어졌고, 구성되었는지 다른 회원도 볼 수 있어요.</p></label>
        </div>
        <div class="input-list-box">
          <input id="searchTp2" name="releaseValue" value="false" type="radio" />
          <label for="searchTp2"><strong>비공개</strong><p>다른 사람들이 지표설정값, 포트폴리오의
성과지표와 구성종목을 볼 수 없어요.</p></label>
        </div>
      </div>`,
        type: 'info',
        customCloseBtnText: '변경',
        onClose: this.confirmReleaseClick
      }
      try {
        await this.$Simplert.open(obj)
        // 위 togglePortName 처럼 dom객체를 vue로 연결해 사용
      } catch (err) {
        console.log(err)
      }
    },
    confirmReleaseClick() {
      const value = document.querySelector(
        'input[name="releaseValue"]:checked'
      ).value
      const param = {
        portfolioId: this.portfolioData.portfolioId,
        openStatus: value === 'true'
      }
      axiosAuthInstance
        .put('/portfolio/openStatus', JSON.stringify(param), {
          headers: {
            'Content-Type': `application/json`
          }
        })
        .then(() => {
          this.portfolioData.openStatus = value === 'true'
          this.isTogglePortEditList = false
        })
        .catch((error) => {
          console.log('error', error)
        })
    },
    removePort() {
      let obj = {
        title: `삭제한 포트폴리오는 복구 할 수 없습니다
정말 삭제하시겠습니까?`,
        type: 'info',
        useConfirmBtn: true,
        useRadius: true,
        customConfirmBtnText: '삭제',
        customCloseBtnText: '취소',
        onConfirm: this.removePortConfirm
      }
      this.$Simplert.open(obj)
    },
    removePortConfirm() {
      const param = {
        portfolioList: [this.portfolioData.portfolioId]
      }
      console.log(param)
      axiosAuthInstance
        .post('/portfolio/storage/clean', JSON.stringify(param), {
          headers: {
            'Content-Type': `application/json`
          }
        })
        .then(() => {
          this.isTogglePortEditList = false
          this.$router.push({
            name: 'PortfolioStorage'
          })
        })
        .catch((error) => {
          console.log('error', error)
        })
    },
    closeSlider() {
      this.isTogglePortEditList = false
    },
    rorPercentConvert(rorPercent) {
      return rorPercent >= 0 ? '+' + rorPercent : rorPercent
    }
  },
  components: { SlideUpModal, RadarChart, Graph }
}
</script>

<style lang="scss" scoped>
.graphDisAbleLabel {
  color: #999999;
}
#portfolio-detail-wrap {
  .page-title-section {
    padding: 20px 16px 40px;
    position: relative;
    background-color: transparent;
    h4,
    p {
      color: #fff;
    }
    .exam-box {
      position: absolute;
      top: 20px;
      right: 16px;
      width: 82px;
      height: 36px;
      border-radius: 10px;
      font-weight: 600;
      color: #4a4ee7;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .page-body {
    background: #f1f1f1;
    border-top-right-radius: 14px;
    border-top-left-radius: 14px;
    overflow: hidden;
  }
  .sec-head {
    h3 {
      font-size: 18px;
    }
    .status-box {
      strong {
        color: #4a4ee7;
        font-size: 20px;
        font-weight: 600;
      }
    }
    .rate-box {
      strong {
        color: #d74343;
        font-size: 20px;
        font-weight: 600;
      }
    }
    span {
      padding-left: 8px;
      color: #999;
      font-size: 12px;
      font-weight: 400;
    }
  }
  .date-tab-list {
    display: flex;
    justify-content: space-between;
    > li {
      padding: 10px;
      &.on {
        border-radius: 10px;
        font-weight: 600;
        background: #f9f9f9;
      }
    }
  }
  .box-section {
    background-color: #fff;
    padding: 32px 16px;
    margin-bottom: 8px;
  }
  .result-section {
    .round-box {
      border-radius: 14px;
      border: 1px solid #e8e8e8;
      background: #fff;
      padding: 16px 20px;
      display: flex;
      align-items: center;
      gap: 12px;
      .r-box {
        strong {
          color: #222;
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 6px;
        }
        p {
          color: #999;
          font-size: 12px;
          font-weight: 400;
        }
      }
    }
    .etc-box {
      .val-list {
        margin-top: 14px;
        display: flex;
        flex-direction: column;
        gap: 14px;
        > li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          span {
            color: #666;
          }
        }
      }
    }
  }
  .round-box-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 8px;
    column-gap: 28px;
    padding: 16px 35px;
    border-radius: 14px;
    background: #f7f7f7;
    > li {
      display: flex;
      justify-content: space-between;
      align-self: center;
      max-width: 68px;
      padding-left: 8px;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background-color: #d9d9d9;
      }

      .l-box {
        color: #666;
      }
      .r-box {
        font-weight: 500;
      }
    }
  }
  .compo-section {
    .compo-date {
      color: #999;
      font-size: 12px;
    }
    .grid-table {
      .table-head,
      .table-list {
        grid-template-columns: 1fr 1fr 1fr;
      }
      .table-list {
        min-height: 42px;
        height: auto;
        padding: 8px 0;
        > li {
          height: auto;
          min-height: auto;
          color: #666;
        }
      }
    }
  }
  .edit-modal {
    .edit-modal-list {
      li {
        padding: 14px 0;
        font-size: 16px;
      }
    }
  }
}
</style>
