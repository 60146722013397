<template>
  <div class="faq">
    <h2>자주 묻는 질문</h2>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.faq {
  width: 100%;
  min-height: calc(var(--vh, 1vh) * 100);
  background: #f7f7f7;
}
</style>
