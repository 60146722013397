<template>
  <div id="community-detail-wrap" class="white-layer" v-if="community">
    <!-- TODO: 작업해야함 -->
    <div class="page-title-section mb-24">
      <h4>{{ community.name }}</h4>
      <p class="q-sub-txt">{{ community.subTitle }}</p>
    </div>
    <div class="slide-box mb-24">
      <SliderPhotoBox
        class="inner-slider"
        :list="community.storageAccesses"
        type="community"
      />
    </div>
    <div class="content-box" v-html="community.contents"></div>
    <button
      class="go-button base-btn cl-pri full"
      @click="goChannel(community.outLink)"
    >
      <span>입장하기</span>
    </button>
  </div>
</template>

<script>
import SliderPhotoBox from '@/components/common/SliderPhotoBox.vue'
import { axiosAuthInstance } from '@/store/axios'

export default {
  name: 'QuantMarketCommunityDetailCard',
  components: { SliderPhotoBox },
  data() {
    return {
      community: {}
    }
  },
  computed: {},
  created() {
    this.communityId = this.$route.query.communityId
    this.getCommunity()
  },
  methods: {
    goChannel(data) {
      console.log(data)
      window.open(data)
    },
    getCommunity() {
      console.log('getCommunity')
      axiosAuthInstance
        .get('/community/' + this.communityId)
        .then((response) => {
          this.community = response
          console.log('this.community', this.community)
        })
        .catch((error) => {
          console.log('error', error)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
#community-detail-wrap {
  display: flex;
  flex-direction: column;
}
.slide-box {
  position: relative;
  aspect-ratio: 15/9;
  border-radius: 14px;
  overflow: hidden;
  .inner-txt {
    position: absolute;
    left: 16px;
    bottom: 24px;
  }
}
.content-box {
  color: #666;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 22.4px */
  letter-spacing: -0.28px;
}
.go-button {
  margin-top: auto;
}
</style>
