var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.width > 1024
    ? _c(
        "portal",
        { attrs: { to: "slide-up-modal" } },
        [
          _c(
            "Transition",
            { attrs: { duration: "550", name: "fade-to-slide" } },
            [
              _vm.show
                ? _c(
                    "div",
                    {
                      attrs: { id: "toggle-slider-modal" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.$emit("close")
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "inner modal-cont-wrap" }, [
                        _c(
                          "div",
                          { staticClass: "modal-head" },
                          [
                            _c("div", { staticClass: "modal-top-area" }),
                            _vm._t("header"),
                          ],
                          2
                        ),
                        _c(
                          "div",
                          { staticClass: "modal-body" },
                          [_vm._t("main")],
                          2
                        ),
                        _c(
                          "div",
                          { staticClass: "modal-footer" },
                          [_vm._t("button")],
                          2
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]
          ),
        ],
        1
      )
    : _c("Transition", { attrs: { duration: "550", name: "fade-to-slide" } }, [
        _vm.show
          ? _c(
              "div",
              {
                attrs: { id: "toggle-slider-modal" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.$emit("close")
                  },
                },
              },
              [
                _c("div", { staticClass: "inner modal-cont-wrap" }, [
                  _c(
                    "div",
                    { staticClass: "modal-head" },
                    [
                      _c("div", { staticClass: "modal-top-area" }),
                      _vm._t("header"),
                    ],
                    2
                  ),
                  _c("div", { staticClass: "modal-body" }, [_vm._t("main")], 2),
                  _c(
                    "div",
                    { staticClass: "modal-footer" },
                    [_vm._t("button")],
                    2
                  ),
                ]),
              ]
            )
          : _vm._e(),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }