<template>
  <div id="strategy-result-wrap">
    <Spinner v-if="loading"></Spinner>
    <template>
      <div class="page-title-section flex coll">
        <i class="icon--cong mb-8"></i>
        <h4>
          멋진 포트폴리오가<br />
          완성되었어요!
        </h4>
      </div>
      <div class="style-section box-section">
        <div class="sec-head flex-just">
          <h3>스타일</h3>
          <div class="status-box flex-center">
            <i class="icon--state size-big" :class="`${styleTp}`"></i
            ><strong>{{ styleNm }}</strong>
          </div>
        </div>
        <div class="sec-body">
          <div class="flex-center pt-18 pb-16">
            <RadarChart :data="radarData" ref="childRadarRef"></RadarChart>
          </div>
          <ul class="round-box-list">
            <li>
              <span class="l-box">성장</span
              ><span class="r-box">{{ radarData[0] }}</span>
            </li>
            <li>
              <span class="l-box">가치</span
              ><span class="r-box">{{ radarData[1] }}</span>
            </li>
            <li>
              <span class="l-box">모멘텀</span
              ><span class="r-box">{{ radarData[2] }}</span>
            </li>
            <li>
              <span class="l-box">배당</span
              ><span class="r-box">{{ radarData[3] }}</span>
            </li>
            <li>
              <span class="l-box">안전</span
              ><span class="r-box">{{ radarData[4] }}</span>
            </li>
            <li>
              <span class="l-box">우량</span
              ><span class="r-box">{{ radarData[5] }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="rate-section box-section">
        <div class="sec-head flex-just">
          <h3>수익률</h3>
          <div class="flex-center" :class="isDown ? 'status-box' : 'rate-box'">
            <strong>{{ rateOfReturn }}%</strong>
          </div>
        </div>
        <div class="sec-body">
          <Graph :data="lineData" ref="childLineRef" :isDown="isDown"></Graph>
          <ul class="date-tab-list">
            <li :class="{ on: viewRate === 1 }" @click="changeViewRate(1)">
              <span>3개월</span>
            </li>
            <li :class="{ on: viewRate === 2 }" @click="changeViewRate(2)">
              <span>6개월</span>
            </li>
            <li :class="{ on: viewRate === 3 }" @click="changeViewRate(3)">
              <span>1년</span>
            </li>
            <li :class="{ on: viewRate === 4 }" @click="changeViewRate(4)">
              <span>2년</span>
            </li>
            <li :class="{ on: viewRate === 5 }" @click="changeViewRate(5)">
              <span>3년</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="result-section box-section">
        <div class="sec-head mb-20">
          <h3 class="l_box">
            성과지표<i
              class="icon--q ml-4"
              @click="performanceIndicatorsDesc"
            ></i>
          </h3>
        </div>
        <div class="sec-body">
          <div class="round-box mb-8">
            <i class="icon--graph-up"></i>
            <div class="r-box">
              <strong
                >연평균수익률(CAGR)<i class="icon--q ml-4" @click="cargDesc"></i
              ></strong>
              <p>
                테스트 기간에 연평균
                {{ portfolioCalculationData.performance.rorAvgPct }}%의 수익률을
                거뒀어요.
              </p>
            </div>
          </div>
          <div class="round-box">
            <i class="icon--graph-down"></i>
            <div class="r-box">
              <strong
                >최대낙폭(MDD)<i class="icon--q ml-4" @click="mddDesc"></i
              ></strong>
              <p>
                테스트 기간에 최고점 대비
                {{ portfolioCalculationData.performance.mddPct }}% 만큼
                하락했어요.
              </p>
            </div>
          </div>
        </div>
        <div class="etc-box">
          <div class="sec-head flex-just mt-32">
            <h3>기타성과지표</h3>
            <i
              :class="toggleEct ? 'icon--arrow-up' : 'icon--arrow-down'"
              @click="toggleEct = !toggleEct"
            ></i>
          </div>
          <div v-if="toggleEct" class="sec-body">
            <ul class="val-list">
              <li>
                <span class="l-box">변동성(연)</span>
                <span class="r-box"
                  >{{ portfolioCalculationData.performance.volYear }}%</span
                >
              </li>
              <li>
                <span class="l-box">베타</span>
                <span class="r-box"
                  >{{ portfolioCalculationData.performance.beta }}%</span
                >
              </li>
              <li>
                <span class="l-box">샤프비율</span>
                <span class="r-box"
                  >{{ portfolioCalculationData.performance.sharpeRatio }}%</span
                >
              </li>
              <li>
                <span class="l-box">평균보유종목</span>
                <span class="r-box"
                  >{{
                    portfolioCalculationData.performance.avgHoldingIsu
                  }}종목</span
                >
              </li>
              <li class="sec-head mt-18"><h3>누적수익률 비교</h3></li>
              <li>
                <span class="l-box">회원님 전략</span>
                <span class="r-box"
                  >{{ portfolioCalculationData.performance.rorTotPct }}%</span
                >
              </li>
              <li>
                <span class="l-box">코스피 지수</span>
                <span class="r-box"
                  >{{ portfolioCalculationData.performance.kospiRorPct }}%</span
                >
              </li>
              <li>
                <span class="l-box">코스닥 지수</span>
                <span class="r-box"
                  >{{
                    portfolioCalculationData.performance.kosdaqRorPct
                  }}%</span
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="compo-section box-section">
        <div class="sec-head flex-start mb-24">
          <h3 class="l_box mr-10">구성종목</h3>
          <span class="compo-date">{{
            portfolioCalculationData.savePortfolio.baseDate
          }}</span>
        </div>
        <div class="sec-body">
          <div class="grid-table">
            <ul class="table-head">
              <li>종목</li>
              <li>주가</li>
              <li>비중</li>
            </ul>
            <ul
              v-for="(d, i) in portfolioCalculationData.portfolioWeight"
              :key="i + 'search'"
              class="table-list"
            >
              <li>{{ d.stockName }}</li>
              <li>{{ Number(d.currentPrices).toLocaleString() }}</li>
              <li>{{ weight(d.weight) }}%</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="naming-section box-section">
        <p class="input-tilte mt-0 mb-8">포트폴리오 이름</p>
        <div class="round-input">
          <input
            type="text"
            placeholder="포트폴리오 이름 입력"
            v-model="portfolioName"
          />
          <i class="icon--random"></i>
        </div>

        <div class="btn-wrap mt-40">
          <button class="base-btn harf cl-sec" @click="portfolioCancel">
            <span>나가기</span>
          </button>
          <button class="base-btn harf cl-pri" @click="save">
            <span>저장하기</span>
          </button>
        </div>
      </div>
    </template>
    <SlideUpModal class="valid-modal" v-if="toggleValidModal">
      <template #header
        ><div class="flex-just">
          <h4>저장소가 부족해요!</h4>
          <i class="icon--close" @click="toggleValidModal = false"></i></div
      ></template>
      <template #main>
        <div class="modal-body-wrap">
          <p class="q-sub-txt">기존 내용을 삭제하고 저장 공간을 확보하세요</p>
          <ul class="port-list">
            <li v-for="(d, i) in portfolioList" :key="i + 'port'">
              <div class="base-check-box rounded">
                <input
                  :id="d.portfolioId"
                  :value="d.portfolioId"
                  v-model="deleteList"
                  type="checkbox"
                />
                <label :for="d.portfolioId"></label>
              </div>
              <p class="port-name">{{ d.portfolioName }}</p>
              <span class="port-date">{{ d.portfolioDate }}</span>
            </li>
          </ul>
        </div>
      </template>
      <template #button>
        <div class="modal-btn-wrap">
          <button class="base-btn harf cl-sec" @click="removePort">
            <span>선택삭제</span>
          </button>
          <button
            class="base-btn harf cl-pri"
            @click="toggleValidModal = false"
          >
            <span>취소</span>
          </button>
        </div></template
      >
    </SlideUpModal>
    <FullModal v-if="isComplete" class="complete-modal">
      <div class="complete-modal-body">
        <i class="icon--complete-make"></i>
        <h3>
          포트폴리오가<br />
          성공적으로 저장되었어요.
        </h3>
        <p>
          포트폴리오 이름, 공개여부는<br />[포트폴리오-설정]에서도 가능합니다.
        </p>
        <button
          class="base-btn full cl-pri"
          @click="
            $router.replace({ name: 'PortfolioStorage', query: { tap: 'my' } })
          "
        >
          <span>보관함으로 이동</span>
        </button>
      </div>
    </FullModal>
  </div>
</template>

<script>
import SlideUpModal from '@/components/common/modal/SlideUpModal.vue'
import FullModal from '@/components/common/modal/FullModal.vue'
import { setPortfolioCalculation } from '@/api'
import { axiosAuthInstance } from '@/store/axios'
import calculateValues from '@/util/calculateValues'
import RadarChart from '../../components/common/RadarChart.vue'
import Graph from '../../components/common/chart/Graph.vue'
import Spinner from '@/components/common/modal/Spinner.vue'

export default {
  name: 'QuantMarketAddStrategyType1',
  components: {
    Spinner,
    SlideUpModal,
    FullModal,
    RadarChart,
    Graph
  },
  data() {
    return {
      loading: true,
      viewRate: 1,
      portfolioName: '',
      toggleEct: false,
      toggleValidModal: false,
      styleType: '',
      styleNm: '',
      styleTp: '',
      deleteList: [],
      isComplete: false,
      radarData: [5, 5, 5, 5, 5, 5],
      lineData: [],
      isDown: true,
      rateOfReturn: 0,
      calculate: {},
      portfolioCalculationData: {
        'calculationId': 0,
        'performance': {
          'rorTotPct': 0.0,
          'rorAvgPct': 0.0,
          'volYear': 0.0,
          'mddPct': 0.0,
          'avgHoldingIsu': 0.0,
          'kospiRorPct': 0.0,
          'kosdaqRorPct': 0.0,
          'sharpeRatio': 0.0,
          'beta': 0.0
        },
        portfolioWeight: [],
        timeseries: [],
        qualification: {
          qualificationProduct: ''
        },
        savePortfolio: {
          'baseDate': '',
          'valueIdx': 0.0,
          'ror1dPct': 0.0,
          'ror3mPct': 0.0,
          'ror6mPct': 0.0,
          'ror12mPct': 0.0,
          'ror24mPct': 0.0,
          'ror36mPct': 0.0,
          'rorCumPct': 0.0,
          'rorMtdPct': 0.0
        }
      }
    }
  },
  created() {
    const data = this.$store.getters.getAddType
    const param = {
      'type': data.type,
      'interval': data.updDateTp,
      'startDate': data.startDate,
      'endDate': data.endDate,
      'stockIds': [],
      'weight': data.updAmountTp,
      'factors': []
    }

    const portfolioData = this.$store.getters.getAddType
    this.styleType = portfolioData.styleType
    switch (this.styleType) {
      case 'GROWTH':
        this.styleNm = '성장형'
        this.styleTp = 'exp'
        break
      case 'VALUE':
        this.styleNm = '가치형'
        this.styleTp = 'val'
        break
      case 'MOMENTUM':
        this.styleNm = '모멘텀'
        this.styleTp = 'moe'
        break
      case 'DIVIDEND':
        this.styleNm = '배당형'
        this.styleTp = 'money'
        break
      case 'LOWVOL':
        this.styleNm = '안정형'
        this.styleTp = 'safe'
        break
      case 'QUALITY':
        this.styleNm = '우량형'
        this.styleTp = 'big'
        break
    }

    if (data.type === 'STOCKS') {
      this.styleNm = '직접 선택'
      this.styleTp = 'stocks'
      param.stockIds = data.list.map((info) => info.isuAbbrvCd)
    } else {
      console.log('data', data)
      param.factors = data.list
        .filter((info) => {
          return info.isData === true
        })
        .map((info) => {
          return {
            indicatorCode: info.indicatorCode,
            value: info.value / 100,
            sortAsc: info.sortOrderAsc
          }
        })
    }

    setPortfolioCalculation(param)
      .then((resp) => {
        this.loading = false
        this.$store.commit('SET_PORTFOLIO_CALCULATION_DATA', resp)
        this.portfolioCalculationData = resp

        this.changeViewRate(this.viewRate)
      })
      .catch((err) => {
        console.log(err)
        this.$Simplert.open({
          title: '문제가 발생했습니다.',
          message:
            '열심히 문제를 해결하고 있습니다. 잠시 후에 다시 시도해 주세요!',
          onClose: this.goMain
        })
      })
  },
  mounted() {
    let portfolioList = this.$store.getters.getAddType.list
    console.log('portfolioList', portfolioList)
    this.updateRadar(portfolioList)
  },
  computed: {
    resultData() {
      if (Object.keys(this.$store.getters.getPortfolioDtl).length === 0)
        return false
      return this.$store.getters.getPortfolioDtl
    },
    portfolioList() {
      return this.$store.getters.getPortfolioList
    }
  },
  methods: {
    async save() {
      // 포트폴리오 이름 필수 입력체크
      if (this.portfolioName === '') {
        this.$Simplert.open({
          title: '포트폴리오 이름을 입력해 주세요.',
          message:
            '포트폴리오 이름이 입력되지 않았어요.<br/>' +
            '멋진 이름을 지정해 주세요.'
        })
        return false
      }

      await this.$store.dispatch('fetchPortfolioUserList')
      let portfolioList = this.$store.getters.getPortfolioList
      console.log(portfolioList)
      let portfolioUserLimitCount =
        this.$store.getters.getPortfolioUserLimitCount
      console.log(portfolioUserLimitCount)

      // 저장소 체크
      if (portfolioList.length >= Number(portfolioUserLimitCount)) {
        this.toggleValidModal = true
        // 저장 내용 추가
        return false
      }
      const data = this.$store.getters.getAddType
      let styleType = ''
      if (data.type === 'STOCKS') {
        styleType = 'STOCKS'
      } else {
        styleType = this.styleType
      }

      const param = {
        styleType: styleType,
        styleData: JSON.stringify(this.calculate),
        portfolioName: this.portfolioName,
        calculationName: 'portfolio',
        reBalancingName: 'update_portfolio',
        calculationId: this.portfolioCalculationData.calculationId
      }
      axiosAuthInstance
        .post('/portfolio', JSON.stringify(param), {
          headers: {
            'Content-Type': `application/json`
          }
        })
        .then(() => {
          this.isComplete = true
          console.log('save')
        })
        .catch((error) => {
          console.log('error', error)
        })

      // this.isComplete = true
      // console.log('save')
    },
    portfolioCancel() {
      this.$Simplert.open({
        useConfirmBtn: true,
        onClose: this.save,
        onConfirm: this.goMain,
        customCloseBtnText: '저장하기',
        customConfirmBtnText: '나가기',
        title: '포트폴리오 나가기',
        message: '지금 만든 멋진 전략을<br/>' + '저장하지 않고 나가시겠습니까?'
      })
    },
    goMain() {
      this.$router.push({ name: 'Main' })
    },
    weight(val) {
      return (val * 100).toFixed(2)
    },
    removePort() {
      this.$store.commit('SET_PORT_LIST', this.portfolioList.slice(0, 3))

      const parma = {
        portfolioList: this.deleteList
      }
      axiosAuthInstance
        .post('/portfolio/storage/clean', JSON.stringify(parma), {
          headers: {
            'Content-Type': `application/json`
          }
        })
        .then((response) => {
          console.log(response)
          this.$store.dispatch('fetchPortfolioUserList')
        })
        .catch((error) => {
          console.log('error', error)
        })
    },
    updateRadar(portfolioList) {
      const factors = {}
      portfolioList
        .filter((info) => {
          return info.isData === true
        })
        .forEach((info) => {
          factors[info.indicatorCode] = info.value / 100
        })

      const calculate = calculateValues.calculateValues(factors)
      this.calculate = calculate

      this.radarData[0] = Number(calculate.growth)
      this.radarData[1] = Number(calculate.value)
      this.radarData[2] = Number(calculate.momentum)
      this.radarData[3] = Number(calculate.dividend)
      this.radarData[4] = Number(calculate.lowvol)
      this.radarData[5] = Number(calculate.quality)

      this.$refs.childRadarRef.forceRender()
    },
    changeViewRate(viewRate) {
      this.viewRate = viewRate

      if (this.viewRate == 1) {
        this.rateOfReturn = this.rorPercentConvert(
          this.portfolioCalculationData.savePortfolio.ror3mPct
        )
        const timeseriesLength = this.portfolioCalculationData.timeseries.length
        const timeseriesStartNumber =
          timeseriesLength > 90 ? timeseriesLength - 90 : 0
        this.lineData = this.portfolioCalculationData.timeseries.slice(
          timeseriesStartNumber,
          timeseriesLength
        )
        this.lineData = this.lineData.map((info) => {
          return { x: info.baseDate, y: info.valueIdx - 100 }
        })
      } else if (this.viewRate == 2) {
        this.rateOfReturn = this.rorPercentConvert(
          this.portfolioCalculationData.savePortfolio.ror6mPct
        )
        const timeseriesLength = this.portfolioCalculationData.timeseries.length
        const timeseriesStartNumber =
          timeseriesLength > 180 ? timeseriesLength - 180 : 0
        this.lineData = this.portfolioCalculationData.timeseries.slice(
          timeseriesStartNumber,
          timeseriesLength
        )
        this.lineData = this.lineData.map((info) => {
          return { x: info.baseDate, y: info.valueIdx - 100 }
        })
      } else if (this.viewRate == 3) {
        this.rateOfReturn = this.rorPercentConvert(
          this.portfolioCalculationData.savePortfolio.ror12mPct
        )
        const timeseriesLength = this.portfolioCalculationData.timeseries.length
        const timeseriesStartNumber =
          timeseriesLength > 365 ? timeseriesLength - 365 : 0
        this.lineData = this.portfolioCalculationData.timeseries.slice(
          timeseriesStartNumber,
          timeseriesLength
        )
        this.lineData = this.lineData.map((info) => {
          return { x: info.baseDate, y: info.valueIdx - 100 }
        })
      } else if (this.viewRate == 4) {
        this.rateOfReturn = this.rorPercentConvert(
          this.portfolioCalculationData.savePortfolio.ror24mPct
        )
        const timeseriesLength = this.portfolioCalculationData.timeseries.length
        const timeseriesStartNumber =
          timeseriesLength > 730 ? timeseriesLength - 730 : 0
        this.lineData = this.portfolioCalculationData.timeseries.slice(
          timeseriesStartNumber,
          timeseriesLength
        )
        this.lineData = this.lineData.map((info) => {
          return { x: info.baseDate, y: info.valueIdx - 100 }
        })
      } else if (this.viewRate == 5) {
        this.rateOfReturn = this.rorPercentConvert(
          this.portfolioCalculationData.savePortfolio.ror36mPct
        )
        const timeseriesLength = this.portfolioCalculationData.timeseries.length
        const timeseriesStartNumber =
          timeseriesLength > 1096 ? timeseriesLength - 1096 : 0
        this.lineData = this.portfolioCalculationData.timeseries.slice(
          timeseriesStartNumber,
          timeseriesLength
        )
        this.lineData = this.lineData.map((info) => {
          return { x: info.baseDate, y: info.valueIdx - 100 }
        })
      }
      this.isDown = this.rateOfReturn < 0
      this.$refs.childLineRef.forceRender()
    },
    rorPercentConvert(rorPercent) {
      return rorPercent >= 0 ? '+' + rorPercent : rorPercent
    },
    performanceIndicatorsDesc() {
      this.$Simplert.open({
        title: '성과지표',
        message: `<div id="strategy-add-description">
                    과거 데이터를 기반으로 회원님의 전략을 평가했습니다.<br/>
                    다만, 과거의 성과가 미래의 수익률을 보장하지는 않습니다.
                  </div>`
      })
    },
    cargDesc() {
      this.$Simplert.open({
        title: '연평균수익률(CAGR)',
        message: `<div id="strategy-add-description">
                    1년 동안 평균적으로 기대할 수 있는 수익률입니다.
                  </div>`
      })
    },
    mddDesc() {
      this.$Simplert.open({
        title: '최대낙폭(MDD)',
        message: `<div id="strategy-add-description">
                    최악의 경우 발생할 수 있는 손실의 정도를 의미합니다.
                  </div>`
      })
    }
  }
}
</script>

<style lang="scss" scoped>
#strategy-result-wrap {
  background: #f1f1f1;
  .page-title-section {
    padding: 0 16px;
    background-color: #fff;
    border-bottom: 1px solid #f1f1f1;
  }
  .sec-head {
    h3 {
      font-size: 18px;
    }
    .status-box {
      strong {
        color: #4a4ee7;
        font-size: 20px;
        font-weight: 600;
      }
    }
    .rate-box {
      strong {
        color: #d74343;
        font-size: 20px;
        font-weight: 600;
      }
    }
  }
  .date-tab-list {
    display: flex;
    justify-content: space-between;
    > li {
      padding: 10px;
      &.on {
        border-radius: 10px;
        font-weight: 600;
        background: #f9f9f9;
      }
    }
  }
  .box-section {
    background-color: #fff;
    padding: 32px 16px;
    margin-bottom: 8px;
  }
  .result-section {
    .round-box {
      border-radius: 14px;
      border: 1px solid #e8e8e8;
      background: #fff;
      padding: 16px 20px;
      display: flex;
      align-items: center;
      gap: 12px;
      .r-box {
        strong {
          color: #222;
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 6px;
        }
        p {
          color: #999;
          font-size: 12px;
          font-weight: 400;
        }
      }
    }
    .etc-box {
      .val-list {
        margin-top: 14px;
        display: flex;
        flex-direction: column;
        gap: 14px;
        > li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          span {
            color: #666;
          }
        }
      }
    }
  }
  .round-box-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 8px;
    column-gap: 28px;
    padding: 16px 35px;
    border-radius: 14px;
    background: #f7f7f7;
    > li {
      display: flex;
      justify-content: space-between;
      align-self: center;
      max-width: 68px;
      padding-left: 8px;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background-color: #d9d9d9;
      }

      .l-box {
        color: #666;
      }
      .r-box {
        font-weight: 500;
      }
    }
  }
  .compo-section {
    .compo-date {
      color: #999;
      font-size: 12px;
    }
    .grid-table {
      .table-head,
      .table-list {
        grid-template-columns: 1fr 1fr 1fr;
      }
      .table-list {
        min-height: 42px;
        height: auto;
        padding: 8px 0;
        > li {
          height: auto;
          min-height: auto;
          color: #666;
        }
      }
    }
  }
}
.btn-wrap {
  display: flex;
  gap: 8px;
}
::v-deep {
  #toggle-slider-modal .modal-cont-wrap .modal-body,
  #toggle-slider-modal .modal-cont-wrap .modal-head {
    padding-top: 0;
    padding-bottom: 0;
  }
  .modal-cont-wrap {
    height: calc(var(--vh, 1vh) * 100);
  }
}
.valid-modal {
  .modal-body-wrap {
    .port-list {
      display: flex;
      flex-direction: column;
      margin-top: 30px;
      gap: 28px;
      > li {
        display: flex;
        align-items: center;
        gap: 12px;
        .port-name,
        .port-date {
          font-size: 16px;
          font-weight: 500;
        }
        .port-date {
          margin-left: auto;
          color: #999;
        }
      }
    }
  }
  .modal-btn-wrap {
    display: flex;
    gap: 8px;
  }
}
.complete-modal {
  .complete-modal-body {
    padding: 24px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 150px;

    h3 {
      margin-top: 16px;
      text-align: center;
    }
    p {
      text-align: center;
      margin-top: 8px;
      color: #bfbfbf;
    }

    .base-btn {
      margin-top: auto;
    }
  }
}
</style>
