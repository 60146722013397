<template>
  <div class="rounge-wrap">
    <SlideUpModal class="edit-modal" v-if="declarShow">
      <template #header>
        <div class="flex-just mb-16">
          <h4>이 의견을 신고하는 이유</h4>
          <i class="icon--close" @click="declarShow = false"></i>
        </div>
      </template>
      <template #main>
        <ul class="declar-list">
          <li
            class="input-list"
            v-for="(d, i) in declarList"
            :key="i + 'declar'"
          >
            <div class="l-box">
              <input
                :id="d.id"
                v-model="selectedDeclarList"
                type="radio"
                :value="d.id"
              /><label :for="d.id"></label>
            </div>
            <div class="r-box">
              <p>{{ d.title }}</p>
            </div>
          </li>
        </ul>
      </template>
      <template #button>
        <button class="base-btn full cl-pri" @click="report()">
          <span>확인</span>
        </button>
      </template>
    </SlideUpModal>
    <SlideUpModal
      class="edit-modal"
      v-if="editComentShow"
      @close="editComentShow = false"
    >
      <template #main>
        <ul class="edit-modal-list">
          <li @click="showReport">신고하기</li>
          <li v-if="isOwner" @click="moveUpdate">수정하기</li>
          <li v-if="isOwner" @click="deleteComment">삭제하기</li>
        </ul>
      </template>
    </SlideUpModal>
    <CommentRegisterModal
      :lounge-id="loungeId"
      v-if="toggleCommentModal"
      :is-create="isCreate"
      :update-target="updateTarget"
      @close="saveComplete"
    ></CommentRegisterModal>
    <div class="board-section">
      <BoardItemBox
        :is-detail="true"
        :data="loungeData"
        :active="true"
        @toggle:edit="toggleEditModal"
      ></BoardItemBox>
    </div>
    <div class="comment-section">
      <ul class="coment-list" v-if="loungeData.loungeComments.length > 0">
        <li v-for="(d, i) in loungeData.loungeComments" :key="i + 'comm'">
          <div class="comment">
            <div class="flex-between">
              <div class="prof-box">
                <div class="thumb mr-8">
                  <img v-if="d.storageAccess" :src="d.storageAccess" alt="" />
                  <i v-else class="icon--profil-temp"></i>
                </div>
                <span class="user-name">{{ d.nickName }}</span>
                <span class="date ml-4">
                  {{ d.createdDate }}
                </span>
              </div>
              <ul class="status-box">
                <li>
                  <i
                    class="icon--dot"
                    @click="toggleEditComentModal(d.commentId, d.isOwner)"
                  ></i>
                </li>
              </ul>
            </div>
            <div class="body">
              <p class="board-desc">
                {{ d.commentTitle }}
              </p>
              <p class="board-desc">
                {{ d.commentContents }}
              </p>
              <ThumbBox :thumb="d.storageAccesses" />
            </div>
          </div>
        </li>
      </ul>
      <div class="empty" v-else>
        <i class="icon--coment-empty"></i>
        <p>서로에게 따뜻한 의견을 남겨주세요.</p>
      </div>
      <div class="coment-input-wrap" v-if="$store.getters.getUser.isUser">
        <div class="round-input" @click="openCreateModal">
          <span>댓글을 입력해주세요</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BoardItemBox from '@/components/common/BoardItemBox.vue'
import { roungeBoardDetail } from '@/data/data'
import SlideUpModal from '@/components/common/modal/SlideUpModal.vue'
import ThumbBox from '@/components/common/ThumbBox.vue'
import CommentRegisterModal from '@/components/common/modal/CommentRegisterModal.vue'
import { axiosAuthInstance } from '@/store/axios'

export default {
  name: 'QuantMarketIndex',
  components: {
    BoardItemBox,
    SlideUpModal,
    ThumbBox,
    CommentRegisterModal
  },
  data() {
    return {
      sort: '최신순',
      toggleCommentModal: false,
      list: roungeBoardDetail,
      loungeId: 1,
      isOwner: false,
      loungeData: {
        loungeId: '',
        userId: '',
        viewCount: '',
        loungeTitle: '',
        loungeContents: '',
        category: '',
        status: '',
        createdDate: '',
        modifiedDate: '',
        nickName: '',
        imageType: '',
        storageAccess: '',
        commentCount: 0,
        loungeComments: [
          {
            commentId: '',
            userId: '',
            nickName: '',
            commentTitle: '',
            commentContents: '',
            commentSort: ''
          }
        ]
      },
      selectedDeclarList: [],
      editShow: false,
      isCreate: true,
      editComentShow: false,
      editTaget: null,
      editComentTaget: null,
      updateTarget: {},
      declarShow: false,
      declarList: [
        { title: '스팸, 광고', id: 0 },
        { title: '폭언, 비속어, 혐오발언', id: 1 },
        { title: '음란성, 선정성 글', id: 2 },
        { title: '개인정보 노출', id: 3 },
        { title: '부적절한 닉네임', id: 4 },
        { title: '부적절한 프로필이미지', id: 5 },
        { title: '주제와 무관', id: 6 }
      ]
    }
  },
  created() {
    this.loungeId = this.$route.query.loungeId
    this.getComments()
  },
  methods: {
    getComments() {
      console.log('getComments', this.loungeId)
      axiosAuthInstance
        .get('/lounge/' + this.loungeId)
        .then((response) => {
          console.log('response', response)
          this.loungeData = response
        })
        .catch((error) => {
          console.log('error', error)
        })
    },
    toggleEditModal(value) {
      this.editTaget = value
      this.editShow = true
    },
    toggleEditComentModal(value, isOwner) {
      console.log(value, isOwner)
      this.editComentTaget = value
      this.isOwner = isOwner
      this.editComentShow = true
    },
    showReport() {
      this.editShow = false
      this.editComentShow = false
      this.declarShow = true
      console.log(' showReport')
    },
    report() {
      let report
      for (const info of this.declarList) {
        if (info.id === this.selectedDeclarList) {
          report = info.title
        }
      }
      let request = {
        requestReportId: this.editComentTaget,
        loungeType: 'COMMENT',
        reportReasonType: report
      }
      console.log(report, request)
      axiosAuthInstance
        .post('/lounge/report', JSON.stringify(request), {
          headers: {
            'Content-Type': `application/json`
          }
        })
        .then(() => {
          this.selectedDeclarList = ''
          this.editComentShow = false
          this.declarShow = false
          let obj = {
            title: '신고되었습니다',
            // message: '',
            type: 'info',
            useConfirmBtn: false
          }
          this.$Simplert.open(obj)
        })
        .catch((error) => {
          console.log('error', error)
        })
        .finally(() => {
          this.editShow = false
        })
    },
    moveUpdate() {
      console.log('this.editComentTaget', this.editComentTaget)
      console.log('this.loungeData', this.loungeData)
      for (let index in this.loungeData.loungeComments) {
        if (
          this.loungeData.loungeComments[index].commentId ===
          this.editComentTaget
        ) {
          this.updateTarget = this.loungeData.loungeComments[index]
          console.log(this.updateTarget)
        }
      }
      this.isCreate = false
      this.toggleCommentModal = true
    },
    deleteComment() {
      console.log('deleteComment')
      axiosAuthInstance
        .delete('/lounge/comment/' + this.editComentTaget)
        .then(() => {
          this.loungeData.loungeComments =
            this.loungeData.loungeComments.filter(
              (fo) => fo.commentId !== this.editComentTaget
            )
        })
        .catch((error) => {
          console.log('error', error)
        })
        .finally(() => {
          this.editShow = false
          this.editComentShow = false
        })
    },
    saveComplete(refresh) {
      this.toggleCommentModal = false
      if (refresh) {
        this.getComments()
      }
    },
    openCreateModal() {
      this.isCreate = true
      this.toggleCommentModal = true
    }
  }
}
</script>

<style lang="scss" scoped>
.top-area {
  display: flex;
  padding: 14px 16px;
  align-self: center;
  border-bottom: 1px solid #e8e8e8;
  border-top: 8px solid #e8e8e8;

  .sort-box {
    display: flex;
    align-items: center;
    gap: 8px;

    span {
      color: #666;
    }

    .on {
      color: #222;
      font-weight: 500;
    }
  }

  .my-box {
    span {
      color: #666;
    }

    margin-left: auto;
    display: flex;
    align-items: center;

    gap: 4px;
  }
}

.comment {
  padding: 8px 16px 16px;
  border-bottom: 1px solid #e8e8e8;
}

.re-coment {
  padding: 8px 16px 16px 44px;
}

.section {
  padding: 0 16px;
}

.edit-modal {
  .edit-modal-list {
    li {
      font-size: 16px;
      padding: 14px 0;
    }
  }
}

.body {
  padding-left: 36px;
}

.input-list {
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px 0;

  label {
    display: inline-block;
    width: 28px;
    height: 28px;
  }
}

.prof-box {
  display: flex;
  align-items: center;
  justify-content: center;

  .date {
    color: #aaa;
    font-size: 12px;
  }
}

.board-desc {
  width: 100%;
  color: #666;
  font-size: 14px;
  padding-bottom: 14px;
}

.thumb-box {
  display: flex;
  border-radius: 6px;
  overflow: hidden;

  > div {
    width: 50%;

    > div {
      display: block;
      width: 100%;
      padding-top: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      position: relative;

      .more-no {
        position: absolute;
        font-size: 30px;
        font-weight: 700;
        top: 50%;
        left: 50%;
        color: #fff;
        transform: translate(-50%, -50%);
      }
    }
  }
}

.status-box {
  display: flex;
  align-items: center;
  gap: 14px;

  li {
    display: flex;
    align-items: center;
    gap: 4px;

    span {
      color: #aaa;
      font-size: 12px;
    }
  }
}

.body {
  .status-box {
    margin-top: 14px;
  }
}

.coment-input-wrap {
  border-top: 1px solid #e8e8e8;
  padding: 12px 16px 33px;

  > .round-input {
    background: #f7f7f7;

    span {
      color: #bfbfbf;
    }
  }
}

.empty {
  min-height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    color: #999;
    text-align: center;
    font-size: 14px;
  }
}
</style>
