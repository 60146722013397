<template>
  <div>
    <SlideUpModal class="comm-regist-modal" v-if="type === 'comm'">
      <template #header>
        <div class="flex-just">
          <h4>커뮤니티 등록</h4>
          <i class="icon--close" @click="closeSlider"></i>
        </div>
      </template>
      <template #main>
        <div class="input-list">
          <InputText
            class="mb-32"
            v-model="title"
            placeholder="커뮤니티명"
            :isShowSub="false"
            @update:value="title = $event"
          />
          <InputText
            class="mb-32"
            v-model="subTitle"
            :max-length="50"
            placeholder="커뮤니티 부제 (목적)"
            @update:value="subTitle = $event"
          />
          <InputText
            class="mb-32"
            v-model="outLink"
            :max-length="200"
            placeholder="외부 링크"
            @update:value="outLink = $event"
          />
          <div>
            <InputText
              class="mb-32"
              v-model="contents"
              :max-length="200"
              placeholder="내용"
              @update:value="contents = $event"
            />
          </div>
        </div>
        <input
          type="file"
          name="file"
          id="file-element"
          @change="uploadFile($event.target.files)"
          ref="files"
          hidden
          multiple
        />
        <ul class="photo-add-list">
          <li class="photo-add-btn" @click="openUpload">
            <i class="icon--photo-b"></i><span>사진추가</span>
          </li>
          <li
            v-for="(d, i) in images"
            :key="i + 'photo'"
            class="choose-user-photo"
          >
            <img
              :src="d.accessPath"
              alt=""
              style="max-width: 100%; object-fit: contain"
            />
            <i class="icon--delete"></i>
          </li>
        </ul>
        <span class="list-desc">*썸네일 이미지 *5MB 미만 파일만 등록 가능</span>
      </template>
      <template #button>
        <div class="modal-btn-wrap">
          <button class="base-btn full cl-pri" @click="saveCommunity">
            <span>등록</span>
          </button>
        </div>
      </template>
    </SlideUpModal>
  </div>
</template>

<script>
import SlideUpModal from '@/components/common/modal/SlideUpModal.vue'
import InputText from '@/components/base/InputText.vue'
import { isNumber } from 'chart.js/helpers'
import { axiosAuthInstance } from '@/store/axios'

export default {
  name: 'QuantMarketCommRegisterModal',
  props: {
    type: { type: String, default: 'comm' }
  },
  components: { SlideUpModal, InputText },
  data() {
    return {
      title: '',
      subTitle: '',
      contents: '',
      outLink: '',
      list: [],
      images: []
    }
  },
  mounted() {},
  methods: {
    confirmClick() {
      this.$emit('close')
    },
    closeSlider() {
      let obj = {
        title: '커뮤니티 등록을 중단하고 나가시겠습니까?',
        message: '',
        type: 'info',
        useConfirmBtn: true,
        customConfirmBtnText: '예',
        customCloseBtnText: '아니오',
        onConfirm: this.confirmClick
      }
      this.$Simplert.open(obj)
    },
    saveCommunity() {
      let parameters = {
        name: this.title,
        subTitle: this.subTitle,
        contents: this.contents,
        outLink: this.outLink,
        images: this.images
      }
      console.log(parameters)
      axiosAuthInstance
        .post('/community', JSON.stringify(parameters), {
          headers: {
            'Content-Type': `application/json`
          }
        })
        .then(() => {
          let obj = {
            title: '신청되었습니다',
            message: '심사완료까지 5영업일 이상 소요될 수 있습니다.',
            type: 'info',
            useConfirmBtn: false
          }
          this.$Simplert.open(obj)
          this.$emit('close')
        })
        .catch((error) => {
          console.log('error', error)
        })
    },
    openUpload() {
      document.getElementById('file-element').click()
      console.log('upload')
    },
    uploadFile(targetFile) {
      console.log('targetFile', targetFile)
      for (const index in targetFile) {
        console.log('file => ', index)
        if (!isNumber(index)) {
          break
        }
        console.log(index)
        const formData = new FormData()
        formData.append('file', targetFile[index])
        axiosAuthInstance
          .post('/file', formData, {
            header: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then((response) => {
            this.images.push({
              storageHash: response.storageHash,
              accessPath: response.accessPath
            })
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.comm-regist-modal {
  ::v-deep {
    .modal-cont-wrap {
      display: flex;
      flex-direction: column;
      height: calc(var(--vh, 1vh) * 100);
      //   min-height: 80vh;
    }
  }
}

.input-list {
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 32px;
  margin-bottom: 32px;
}

.photo-add-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 8px;
  margin-bottom: 10px;

  > li {
    width: 72px;
    height: 72px;
    border-radius: 8px;
    flex-shrink: 0;
    border: 1px solid #e8e8e8;
    overflow: hidden;
  }

  .photo-add-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .choose-user-photo {
    position: relative;

    > i {
      position: absolute;
      top: 2px;
      right: 2px;
    }
  }

  span {
    margin-top: -8px;
    color: #999;
    font-size: 12px;
  }
}

.list-desc {
  color: #bfbfbf;
  font-size: 12px;
  font-weight: 400;
}
</style>
