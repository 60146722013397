// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-title-box[data-v-720cd4ea] {
  width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.desc-box[data-v-720cd4ea] {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.posting-tab[data-v-720cd4ea] {
  display: flex;
  align-items: center;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #e8e8e8;
}
.posting-tab div[data-v-720cd4ea] {
  width: 100%;
  height: 48px;
  color: #aaa;
  font-size: 16px;
  font-weight: 600;
}
.posting-tab .active[data-v-720cd4ea] {
  position: relative;
}
.posting-tab .active[data-v-720cd4ea]::after {
  content: "";
  display: block;
  position: absolute;
  bottom: -1.5px;
  width: 95%;
  left: 50%;
  transform: translateX(-50%);
  height: 2px;
  background-color: #222;
}
.posting-list-box[data-v-720cd4ea],
.comment-list-box[data-v-720cd4ea] {
  padding: 24px 4.4444444%;
  border-bottom: 1px solid #f1f1f1;
}
.posting-list-box.on .desc-box[data-v-720cd4ea],
.comment-list-box.on .desc-box[data-v-720cd4ea] {
  text-overflow: unset;
}
.posting-list-box-date[data-v-720cd4ea],
.comment-list-box-date[data-v-720cd4ea] {
  color: #aaa;
  text-align: left;
  font-size: 12px;
  margin-bottom: 11px;
}
.posting-list-box-title[data-v-720cd4ea],
.comment-list-box-title[data-v-720cd4ea] {
  font-weight: 500;
  margin-bottom: 4px;
}
.posting-list-box-status[data-v-720cd4ea],
.comment-list-box-status[data-v-720cd4ea] {
  display: flex;
  align-items: center;
}
.posting-list-box-status img[data-v-720cd4ea],
.comment-list-box-status img[data-v-720cd4ea] {
  width: 20px;
  height: 20px;
  margin-right: 4px;
}
.posting-list-box-status span[data-v-720cd4ea],
.comment-list-box-status span[data-v-720cd4ea] {
  color: #aaa;
  font-size: 12px;
  margin-right: 14px;
}
.posting-list-box-status span[data-v-720cd4ea]:last-child,
.comment-list-box-status span[data-v-720cd4ea]:last-child {
  margin-right: 0;
}
.posting-list-box-description[data-v-720cd4ea],
.comment-list-box-description[data-v-720cd4ea] {
  color: #666;
}
.posting-list-box-img[data-v-720cd4ea],
.comment-list-box-img[data-v-720cd4ea] {
  margin-top: 12px;
  width: 100%;
  min-height: 164px;
  max-height: 164px;
  background-color: #000;
  border-radius: 6px;
  overflow: hidden;
}
.posting-list-box-add-view[data-v-720cd4ea],
.comment-list-box-add-view[data-v-720cd4ea] {
  color: #aaa;
  display: none;
  margin-top: 14px;
}
.posting-list-box-add-view.show[data-v-720cd4ea],
.comment-list-box-add-view.show[data-v-720cd4ea] {
  display: block;
}
.edit-modal .edit-modal-list li[data-v-720cd4ea] {
  font-size: 16px;
  padding: 14px 0;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
