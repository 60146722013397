<template>
  <div class="bg-layer">
    <div class="spinner">
      <div class="loader-box">
        <span class="loader"></span>
      </div>
      <h4>전략을 만드는 중 입니다.</h4>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ClientFrontSpinner',

  data() {
    return {}
  },

  mounted() {},

  methods: {}
}
</script>

<style lang="scss" scoped>
.bg-layer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.spinner {
  .loader-box {
    height: 48px;
    margin-left: -20px;
    text-align: center;
  }
  h4 {
    margin-top: 32px;
    color: #fff;

    text-align: center;

    font-size: 22px;
    font-weight: 600;
  }
  .loader {
    width: 8px;
    height: 32px;
    border-radius: 4px;
    display: inline-block;
    margin-left: 20px;
    margin-top: 10px;
    position: relative;
    background: currentColor;
    color: #fff;
    box-sizing: border-box;
    animation: animloader 0.5s 0.5s linear infinite alternate;
  }
  .loader::after,
  .loader::before {
    content: '';
    box-sizing: border-box;
    width: 8px;
    height: 32px;
    border-radius: 4px;
    background: currentColor;
    position: absolute;
    bottom: 0;
    left: 20px;
    animation: animloader1 0.5s 0.65s linear infinite alternate;
  }
  .loader::before {
    left: -20px;
    animation-delay: 0s;
  }

  @keyframes animloader {
    0% {
      height: 32px;
      transform: translateY(0);
    }
    100% {
      height: 10px;
      transform: translateY(30px);
    }
  }

  @keyframes animloader1 {
    0% {
      height: 48px;
    }
    100% {
      height: 4.8px;
    }
  }
}
</style>
