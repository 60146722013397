var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.type === "comm"
        ? _c("SlideUpModal", {
            staticClass: "comm-regist-modal",
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "flex-just" }, [
                        _c("h4", [_vm._v("커뮤니티 등록")]),
                        _c("i", {
                          staticClass: "icon--close",
                          on: { click: _vm.closeSlider },
                        }),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "main",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "input-list" },
                        [
                          _c("InputText", {
                            staticClass: "mb-32",
                            attrs: {
                              placeholder: "커뮤니티명",
                              isShowSub: false,
                            },
                            on: {
                              "update:value": function ($event) {
                                _vm.title = $event
                              },
                            },
                            model: {
                              value: _vm.title,
                              callback: function ($$v) {
                                _vm.title = $$v
                              },
                              expression: "title",
                            },
                          }),
                          _c("InputText", {
                            staticClass: "mb-32",
                            attrs: {
                              "max-length": 50,
                              placeholder: "커뮤니티 부제 (목적)",
                            },
                            on: {
                              "update:value": function ($event) {
                                _vm.subTitle = $event
                              },
                            },
                            model: {
                              value: _vm.subTitle,
                              callback: function ($$v) {
                                _vm.subTitle = $$v
                              },
                              expression: "subTitle",
                            },
                          }),
                          _c("InputText", {
                            staticClass: "mb-32",
                            attrs: {
                              "max-length": 200,
                              placeholder: "외부 링크",
                            },
                            on: {
                              "update:value": function ($event) {
                                _vm.outLink = $event
                              },
                            },
                            model: {
                              value: _vm.outLink,
                              callback: function ($$v) {
                                _vm.outLink = $$v
                              },
                              expression: "outLink",
                            },
                          }),
                          _c(
                            "div",
                            [
                              _c("InputText", {
                                staticClass: "mb-32",
                                attrs: {
                                  "max-length": 200,
                                  placeholder: "내용",
                                },
                                on: {
                                  "update:value": function ($event) {
                                    _vm.contents = $event
                                  },
                                },
                                model: {
                                  value: _vm.contents,
                                  callback: function ($$v) {
                                    _vm.contents = $$v
                                  },
                                  expression: "contents",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("input", {
                        ref: "files",
                        attrs: {
                          type: "file",
                          name: "file",
                          id: "file-element",
                          hidden: "",
                          multiple: "",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.uploadFile($event.target.files)
                          },
                        },
                      }),
                      _c(
                        "ul",
                        { staticClass: "photo-add-list" },
                        [
                          _c(
                            "li",
                            {
                              staticClass: "photo-add-btn",
                              on: { click: _vm.openUpload },
                            },
                            [
                              _c("i", { staticClass: "icon--photo-b" }),
                              _c("span", [_vm._v("사진추가")]),
                            ]
                          ),
                          _vm._l(_vm.images, function (d, i) {
                            return _c(
                              "li",
                              {
                                key: i + "photo",
                                staticClass: "choose-user-photo",
                              },
                              [
                                _c("img", {
                                  staticStyle: {
                                    "max-width": "100%",
                                    "object-fit": "contain",
                                  },
                                  attrs: { src: d.accessPath, alt: "" },
                                }),
                                _c("i", { staticClass: "icon--delete" }),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                      _c("span", { staticClass: "list-desc" }, [
                        _vm._v("*썸네일 이미지 *5MB 미만 파일만 등록 가능"),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "button",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "modal-btn-wrap" }, [
                        _c(
                          "button",
                          {
                            staticClass: "base-btn full cl-pri",
                            on: { click: _vm.saveCommunity },
                          },
                          [_c("span", [_vm._v("등록")])]
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              741006574
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }