// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.switch[data-v-b357b7ba] {
  position: relative;
  display: block;
  width: 24px;
  height: 14px;
  padding: 2px;
  background-color: #d9d9d9;
  border-radius: 50px;
}
.switch.on[data-v-b357b7ba] {
  background-color: #4a4ee7;
}
.switch input[data-v-b357b7ba] {
  display: none;
}
.switch input:checked + .slider[data-v-b357b7ba]:before {
  left: 50%;
}
.switch .slider[data-v-b357b7ba] {
  display: flex;
  position: absolute;
  cursor: pointer;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  transition: 0.4s;
}
.switch .slider[data-v-b357b7ba]:before {
  position: absolute;
  content: "";
  height: 100%;
  width: 50%;
  top: 0;
  left: 0;
  border-radius: 50%;
  background-color: white;
  transition: 0.4s;
}
.switch.big[data-v-b357b7ba] {
  width: 46px;
  height: 28px;
  padding: 4px;
}
.switch.big .slider[data-v-b357b7ba] {
  top: 4px;
  left: 4px;
  right: 4px;
  bottom: 4px;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
