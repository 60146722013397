<template>
  <div class="layer">
    <div class="fixed-head">
      <i class="icon--arrow-back" v-if="userBack" @click="back"> </i>
      <h5 class="page-tit">{{ pageTitle }}</h5>
      <i v-if="useClose" class="icon--close"></i>
    </div>
    <router-view />
    <BotMenu></BotMenu>
  </div>
</template>

<script>
import BotMenu from '../components/common/BotMenu.vue'

export default {
  components: { BotMenu },
  data() {
    return {}
  },

  computed: {
    pageTitle() {
      return this.$route.meta.pageTitle
    },
    useHistory() {
      return this.$route.meta.history
    },
    userBack() {
      return this.$route.meta.back
    },
    useClose() {
      return this.$route.meta.close
    },
    useTrans() {
      return this.$route.meta.trans
    }
  },
  beforeMount() {},
  async mounted() {},
  methods: {
    back() {
      this.$router.push(this.$route.meta.history)
    }
  }
}
</script>

<style lang="scss">
.transparent {
  background-color: transparent !important;
}
.fixed-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  position: sticky;
  width: 100%;
  top: 0;
  padding: 0 16px;
  height: 48px;
  background-color: #fff;
  z-index: 99;
  > .page-tit {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 16px;
    font-weight: 600;
    &.color_w {
      color: #fff;
    }
  }
}
</style>
