<template>
  <div class="mypage">
    <h1 class="mypage-title">마이페이지</h1>
    <div class="mypage-profile" @click="$router.push({ name: 'Profile' })">
      <figure
        class="mypage-profile-user-image"
        :style="{
          backgroundImage: 'url(' + user.storageAccess + ')'
        }"
      ></figure>
      <span class="mypage-profile-user-name">{{ user.nickName }}</span>
    </div>
    <ul class="mypage-list">
      <li
        v-for="item in menuList"
        :key="item.id"
        class="mypage-list-item"
        @click="moveToMenuPage(item.path)"
      >
        <!-- <img :src="item.icon" :alt="item.icon" class="mypage-list-item-icon" /> -->
        <i class="icon--mypage-menu mr-8" :class="item.icon"></i>
        <span>{{ item.text }}</span>
        <span class="mypage-list-item-btn"
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="20"
            viewBox="0 0 14 20"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4.29289 14.7071C4.68342 15.0976 5.31658 15.0976 5.70711 14.7071L9.70711 10.7071C10.0976 10.3166 10.0976 9.68342 9.70711 9.29289L5.70711 5.29289C5.31658 4.90237 4.68342 4.90237 4.29289 5.29289C3.90237 5.68342 3.90237 6.31658 4.29289 6.70711L7.58579 10L4.29289 13.2929C3.90237 13.6834 3.90237 14.3166 4.29289 14.7071Z"
              fill="#BFBFBF"
            />
          </svg>
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
import { mypageUser } from '@/data/data'
import { axiosAuthInstance } from '@/store/axios'
export default {
  data() {
    return {
      mypageUser,
      menuList: []
    }
  },
  created() {
    axiosAuthInstance
      .get('/user', {})
      .then((response) => {
        if (response.userType === 'GENERAL') {
          const tempMenuList = this.getMenu()
          this.menuList = tempMenuList.filter((menu) => menu.id !== 1)
        } else {
          this.menuList = this.getMenu()
        }
      })
      .catch((error) => {
        console.log('error', error)
      })
  },
  methods: {
    moveToMenuPage(name) {
      if (!name) return
      this.$router.push({ name: name })
    },
    getMenu() {
      return [
        {
          id: 0,
          icon: 'i01',
          text: '내 심사 내역',
          path: 'Examine'
        },
        {
          id: 1,
          icon: 'i02',
          text: '내 상품 판매 내역',
          path: 'ProductsSell'
        },
        {
          id: 2,
          icon: 'i03',
          text: '내정보 관리',
          path: 'Profile'
        },
        {
          id: 3,
          icon: 'i04',
          text: '보관함',
          path: 'PortfolioStorage'
        },
        {
          id: 4,
          icon: 'i05',
          text: '내가 쓴 글',
          path: 'Posting'
        },
        {
          id: 5,
          icon: 'i06',
          text: '알림설정',
          path: 'Setting'
        },
        {
          id: 6,
          icon: 'i07',
          text: '고객센터',
          path: 'Service'
        }
      ]
    }
  },
  computed: {
    user: {
      get() {
        return this.$store.getters.getUser
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.mypage {
  width: 100%;
  height: calc(calc(var(--vh, 1vh) * 100) - 48px);
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0 4.444444%;
  font-family: 'Pretendard';
  background-color: #fff;

  // title
  &-title {
    font-size: 22px;
    height: 48px;
    line-height: 48px;
    color: #222;
    margin-bottom: 12px;
    font-weight: 600;
  }

  // profile
  &-profile {
    color: #222;
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 32px;
    letter-spacing: -0.4px;
    &-user-image {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      //background-color: #000;
      margin-right: 14px;
      background-repeat: no-repeat;
    }
  }

  &-list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    &-item {
      display: flex;
      align-items: center;
      width: 100%;
      font-size: 16px;
      padding: 16px 0;
      box-sizing: border-box;
      color: #666;
      font-weight: 500;
      position: relative;
      &-icon {
        display: block;
        width: 28px;
        margin-right: 8px;
      }
      &-btn {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
      }
    }
  }
}
</style>
