var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pc-wrapper" }, [
    _vm._m(0),
    _c("div", { staticClass: "mobile-cont" }, [
      _c(
        "div",
        { staticClass: "modal-layer" },
        [
          _c("portal-target", { attrs: { name: "slide-up-modal" } }),
          _c("portal-target", { attrs: { name: "btn-layer" } }),
        ],
        1
      ),
      _c(
        "div",
        { attrs: { id: "main-app" } },
        [
          _c("simplert", { attrs: { useIcon: false } }),
          _vm.mode === "default" ? _c("DefaultLayer") : _vm._e(),
          _vm.mode === "full" ? _c("FullPageLayer") : _vm._e(),
          _vm.mode === "sub" ? _c("SubPageLayer") : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "pc-cont pb-80" }, [
      _c("div", { staticClass: "top" }, [
        _c("img", {
          staticClass: "mb-26",
          attrs: { src: require("@/assets/images/gnb/logo.svg"), alt: "" },
        }),
        _c("h1", [
          _vm._v(" 퀀트투자가 처음이라면,"),
          _c("br"),
          _c("strong", [_vm._v("퀀트마켓")]),
          _vm._v("으로 오라! "),
        ]),
        _c("div", { staticClass: "round-box mt-30" }, [
          _c("ul", [
            _c("li", [
              _c("i", { staticClass: "icon--pc type01" }),
              _c("p", [
                _c("span", [_vm._v("터치 몇 번으로")]),
                _vm._v(" 내가 직접 투자 포트폴리오를 구성! "),
              ]),
            ]),
            _c("li", [
              _c("i", { staticClass: "icon--pc type02" }),
              _c("p", [
                _c("span", [_vm._v("수익률 좋은 회원들의")]),
                _vm._v(" 포트폴리오 엿보기"),
              ]),
            ]),
            _c("li", [
              _c("i", { staticClass: "icon--pc type03" }),
              _c("p", [
                _vm._v(" 한 달에 한 번 씩 "),
                _c("span", [_vm._v("우수 포트폴리오에 소정의 상품!")]),
              ]),
            ]),
            _c("li", [
              _c("i", { staticClass: "icon--pc type04" }),
              _c("p", [
                _c("span", [_vm._v("퀀트전문가들이 만든")]),
                _vm._v(" 모델포트폴리오 구독!"),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "bot flex mt-90" }, [
        _c("div", { staticClass: "l-box mr-70" }, [
          _c("h3", [
            _vm._v(" 내 손으로 그리는 수익률, 퀀트마켓 "),
            _c("br"),
            _c("strong", [_vm._v("앱으로 만나보세요")]),
          ]),
          _c("div", { staticClass: "btn-wrap mt-16" }, [
            _c("button", { staticClass: "btn--down-google" }),
            _c("button", { staticClass: "btn--down-apple" }),
          ]),
        ]),
        _c("div", { staticClass: "r-box" }, [
          _c("button", { staticClass: "btn--down-qr" }),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }