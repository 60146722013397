// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.community-box[data-v-633e5e08] {
  display: flex;
  gap: 14px;
}
.community-box .thumb-box[data-v-633e5e08] {
  width: 130px;
  height: 80px;
  flex-shrink: 0;
  border-radius: 8px;
  overflow: hidden;
}
.community-box .thumb-box > img[data-v-633e5e08] {
  width: 100%;
  height: 100%;
}
.community-box .desc-box[data-v-633e5e08] {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  overflow: hidden;
  gap: 4px;
}
.community-box .desc-box > p[data-v-633e5e08] {
  color: #222;
  align-self: stretch;
}
.community-box .desc-box > span[data-v-633e5e08] {
  font-size: 12px;
  color: #aaa;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
