<template>
  <div class="product-detail-wrap layer">
    <div class="page-head-options">
      <i class="icon--fav" v-if="false"></i>
      <i class="icon--share-b"></i>
    </div>
    <FullModal v-if="isComplete">
      <div class="subs-wrap">
        <div class="cont">
          <i class="icon--cong"></i>
          <h4>상품 구독을 시작합니다!</h4>
          <div class="round-box">
            <ul>
              <li>
                <div class="l-box">구독 상품</div>
                <div class="r-box">
                  {{ product.productName }}
                </div>
              </li>
              <li>
                <div class="l-box">구독 기간</div>
                <div class="r-box">
                  {{ toStringByFormatting(product.salesStartDate) }} ~
                  {{ toStringByFormatting(product.salesEndDate) }}
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="btn-wrap">
          <button class="base-btn full cl-pri mb-12" @click="goStorage">
            <span>보관함으로 이동</span>
          </button>
          <button class="base-btn full cl-sec" @click="goShoppingSubs">
            <span>구독한 상품 상세보기</span>
          </button>
        </div>
      </div>
    </FullModal>
    <FullModal v-if="isFail">
      <!-- <FullModal> -->
      <div class="subs-wrap">
        <div class="cont">
          <i class="icon--warning"></i>
          <h4>
            결제가 정상적으로<br />
            완료되지 않았습니다
          </h4>
          <p>이용에 불편을 드려 죄송합니다.</p>
        </div>
        <div class="btn-wrap">
          <button class="base-btn full cl-pri" @click="isFail = false">
            <span>닫기</span>
          </button>
        </div>
      </div>
    </FullModal>
    <div class="slide-box">
      <SliderPhotoBox
        class="inner-slider"
        :list="product.storageAccesses"
        type="store"
      />
    </div>
    <div class="product-info section mb-8">
      <h4 class="name">{{ product.productName }}</h4>
      <p class="desc">{{ product.productInformation }}</p>
      <strong class="price"
        ><b class="discount"
          >{{
            discountRateCalculation(
              baseOption.discountValue,
              baseOption.price,
              baseOption.discountApplicationMethod
            )
          }}%</b
        >{{ Number(baseOption.salePrice).toLocaleString() }}원</strong
      >
      <p class="before-info">
        <span
          ><span class="before-price"
            >{{ Number(baseOption.price).toLocaleString() }}원</span
          >
          ({{ baseOption.period }}개월)</span
        >
      </p>
      <p class="recent-percent">
        <span>최근3개월</span><span>|</span>
        <span
          class="percent"
          :class="radarIsDown(portfolio.rorPercent3m) ? 'isDown' : ''"
          >{{ portfolio.rorPercent3m }}%</span
        >
      </p>
      <div class="round-date">
        <span>판매기간</span>
        <span>|</span>
        <span>{{ toStringByFormatting(product.salesStartDate) }}</span>
        <span>~</span>
        <span>{{ toStringByFormatting(product.salesEndDate) }}</span>
      </div>
    </div>
    <div class="style-section box-section section mb-1">
      <div class="sec-head flex-just">
        <h3>스타일</h3>
        <div class="status-box flex-center">
          <i
            v-if="portfolio"
            class="icon--state size-big"
            :class="portfolioType(portfolio.styleType)"
          ></i
          ><strong>{{ portfolioTypeName(portfolio.styleType) }}</strong>
        </div>
      </div>
      <div class="sec-body">
        <div class="flex-center pt-18 pb-16">
          <RadarChart
            :data="radarData"
            :isDown="isDown"
            ref="childRadarRef"
          ></RadarChart>
        </div>
        <ul class="round-box-list">
          <li>
            <span class="l-box">성장</span
            ><span class="r-box">{{ radarData[0] }}</span>
          </li>
          <li>
            <span class="l-box">가치</span
            ><span class="r-box">{{ radarData[1] }}</span>
          </li>
          <li>
            <span class="l-box">모멘텀</span
            ><span class="r-box">{{ radarData[2] }}</span>
          </li>
          <li>
            <span class="l-box">배당</span
            ><span class="r-box">{{ radarData[3] }}</span>
          </li>
          <li>
            <span class="l-box">안전</span
            ><span class="r-box">{{ radarData[4] }}</span>
          </li>
          <li>
            <span class="l-box">우량</span
            ><span class="r-box">{{ radarData[5] }}</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="section mb-1">
      <div class="sec-head flex-just">
        <h3>누적수익률</h3>
      </div>
      <p class="big-desc down">
        {{ product.productName }}에 1000만원 투자했다면,<br />지금
        <strong>{{ investmentCalculation }}원</strong>이 되어 있을 거에요
      </p>
    </div>
    <div class="section mb-1">
      <div class="sec-head flex-just">
        <h3>상품특징</h3>
      </div>
      <ul class="disc-list">
        <li v-for="(data, index) in productFeaturesList" :key="index + 'disc'">
          {{ data }}
        </li>
      </ul>
    </div>
    <div class="section mb-8">
      <div class="sec-head flex-just">
        <h3>
          수익률<span
            >{{ rateOfReturnStartDate }} ~ {{ rateOfReturnEndDate }}</span
          >
        </h3>
        <strong class="discount">{{ rateOfReturn }}%</strong>
      </div>
      <Graph
        :data="lineData"
        ref="childLineRef"
        :isDown="isDown"
        :key="lineKey"
      />
      <ul class="date-tab-list">
        <li :class="{ on: viewRate === 1 }" @click="changeViewRate(1)">
          <span>3개월</span>
        </li>
        <li :class="{ on: viewRate === 2 }" @click="changeViewRate(2)">
          <span>6개월</span>
        </li>
        <li :class="{ on: viewRate === 3 }" @click="changeViewRate(3)">
          <span>1년</span>
        </li>
        <li :class="{ on: viewRate === 4 }" @click="changeViewRate(4)">
          <span>2년</span>
        </li>
        <li :class="{ on: viewRate === 5 }" @click="changeViewRate(5)">
          <span>3년</span>
        </li>
      </ul>
    </div>
    <div class="result-section section mb-8">
      <div class="sec-head flex-just">
        <h3 class="l_box">최근 3년 성과</h3>
        <i
          :class="isToggleRcntYear ? 'icon--arrow-up' : 'icon--arrow-down'"
          @click="isToggleRcntYear = !isToggleRcntYear"
        ></i>
      </div>
      <div class="etc-box mt-32" v-if="isToggleRcntYear">
        <div class="sec-head">
          <h3>기타성과지표</h3>
        </div>
        <div class="sec-body">
          <ul class="val-list">
            <li>
              <span class="l-box">연평균수익률(CAGR)</span>
              <span class="r-box">
                {{ portfolio.createPerformance.ROR_AVG_PCT }}%
              </span>
            </li>
            <li>
              <span class="l-box">최대낙폭(MDD)</span>
              <span class="r-box">
                {{ portfolio.createPerformance.MDD_PCT }}%
              </span>
            </li>
            <li>
              <span class="l-box">변동성(연)</span>
              <span class="r-box"
                >{{ portfolio.createPerformance.VOL_YEAR }}%</span
              >
            </li>
            <li>
              <span class="l-box">베타</span>
              <span class="r-box">{{ portfolio.createPerformance.BETA }}%</span>
            </li>
            <li>
              <span class="l-box">샤프비율</span>
              <span class="r-box"
                >{{ portfolio.createPerformance.SHARPE_RATIO }}%</span
              >
            </li>
            <li>
              <span class="l-box">평균보유종목</span>
              <span class="r-box"
                >{{ portfolio.createPerformance.AVG_HOLDING_ISU }}종목</span
              >
            </li>
            <li class="sec-head mt-18"><h3>누적수익률 비교</h3></li>
            <li>
              <span class="l-box">회원님 전략</span>
              <span class="r-box"
                >{{ portfolio.createPerformance.ROR_TOT_PCT }}%</span
              >
            </li>
            <li>
              <span class="l-box">코스피 지수</span>
              <span class="r-box"
                >{{ portfolio.createPerformance.KOSPI_ROR_PCT }}%</span
              >
            </li>
            <li>
              <span class="l-box">코스닥 지수</span>
              <span class="r-box"
                >{{ portfolio.createPerformance.KOSDAQ_ROR_PCT }}%</span
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="section mb-1">
      <div class="purple-box">
        <strong>이런 분들께 추천해요</strong>
        <ul class="disc-list">
          <li v-for="(data, index) in recommendedList" :key="index + 'purple'">
            {{ data }}
          </li>
        </ul>
      </div>
      <div class="line-box">
        <h5 class="flex-start mb-10">
          핵심전략<i class="icon--arrow-move"></i>
        </h5>
        <p>
          {{ product.keyStrategy }}
        </p>
      </div>
    </div>
    <div class="section mb-1">
      <div class="sec-head flex-just">
        <h3>유의사항</h3>
      </div>
      <ul class="disc-list">
        <li>
          {{ product.instructions }}
        </li>
      </ul>
    </div>
    <div class="section mb-1">
      <div class="sec-head flex-just">
        <h3>취소/환불 정보</h3>
      </div>
      <ul class="disc-list">
        <li>
          {{ product.cancellationRefundInformation }}
        </li>
      </ul>
    </div>
    <div class="section">
      <button
        class="base-btn full cl-pri"
        @click="toggleSubs"
        v-if="$store.getters.getUser.isUser"
      >
        <span>구독하기</span>
      </button>
    </div>
  </div>
</template>

<script>
import SliderPhotoBox from '@/components/common/SliderPhotoBox.vue'
import FullModal from '@/components/common/modal/FullModal.vue'
import RadarChart from '../../components/common/RadarChart.vue'
import Graph from '../../components/common/chart/Graph.vue'
import { axiosAuthInstance } from '@/store/axios'

export default {
  name: 'QuantMarketIndex',
  components: {
    SliderPhotoBox,
    FullModal,
    RadarChart,
    Graph
  },
  data() {
    return {
      // 구독완료
      isComplete: false,
      isFail: false,
      viewRate: 1,
      isToggleRcntYear: false,
      product: {},
      portfolio: {},
      baseOption: {},
      isDown: false,
      lineKey: 0,
      rateOfReturn: 0,
      lineData: [],
      radarData: [5, 5, 5, 5, 5, 5],
      rorTotPct: 0,
      rateOfReturnStartDate: '',
      rateOfReturnEndDate: '',
      recommendedList: [],
      productFeaturesList: [],
      orderId: ''
    }
  },
  created() {
    axiosAuthInstance
      .get('/product/' + this.$route.query.productId, {})
      .then((response) => {
        this.product = response.product
        this.portfolio = response.portfolio
        this.baseOption = this.product.productOptions.filter(function (option) {
          return option.baseOption === true
        })[0]
        this.converterRadarData(this.portfolio.styleData)
        this.rorTotPct = this.portfolio.createPerformance.ROR_TOT_PCT
        this.recommendedList = this.product.recommendedTarget.split(',')
        this.productFeaturesList = this.product.productFeatures.split(',')

        this.updateRadar()
        this.changeViewRate(1)
      })
      .catch((error) => {
        console.log('error', error)
      })
  },
  computed: {
    investmentCalculation() {
      const investmentAmount = 10_000_000
      return Number(
        parseInt(investmentAmount * (this.rorTotPct / 100), 10) +
          investmentAmount
      ).toLocaleString()
    }
  },
  methods: {
    async toggleSubs() {
      // <div className="input-list-box">
      //   <input id="searchTp2" name="releseValue" value="02" type="radio"/>
      //   <label htmlFor="searchTp2"><strong>3개월<span className="badge">할인</span></strong><strong><span
      //       className="before-price">60,000원</span>50,000원</strong></label>
      // </div>
      let options = ``
      for (const option of this.product.productOptions) {
        //<span className="badge">할인</span>
        //<span className="before-price">60,000원</span>

        let discount = ''
        let discountPrice = ''

        if (option.discountApplied) {
          discount = `<span class="badge">할인</span>`
          discountPrice =
            `<span class="before-price">` +
            Number(option.price).toLocaleString() +
            `원</span>`
        }

        options +=
          `<div class="input-list-box">
              <input id="` +
          option.productOptionId +
          `" name="releseValue" value="` +
          option.productOptionId +
          `" type="radio"/>
              <label for="` +
          option.productOptionId +
          `">
                <strong>` +
          option.period +
          `개월` +
          discount +
          `</strong>
                <strong>` +
          discountPrice +
          Number(option.salePrice).toLocaleString() +
          `원</strong>
              </label>
            </div>`
      }

      let obj = {
        title: '구독 기간 선택',
        message:
          `<div id="product-alert-dtl" class="input-list radio-input-list">` +
          options +
          `</div>`,
        type: 'info',
        useConfirmBtn: true,
        customConfirmBtnText: '다음',
        customCloseBtnText: '취소',
        onClose: this.setRelease,
        onConfirm: this.order
      }

      try {
        await this.$Simplert.open(obj)
        // 위 togglePortName 처럼 dom객체를 vue로 연결해 사용
      } catch (err) {
        console.log(err)
      }
    },
    async validAlert1() {
      let obj = {
        title: `YYYY.MM.DD HH:MM 부터
구독하실 수 있습니다.
아쉽지만 조금만 기다려주세요!`,
        customCloseBtnText: '확인'
      }
      try {
        await this.$Simplert.open(obj)
        // 위 togglePortName 처럼 dom객체를 vue로 연결해 사용
      } catch (err) {
        console.log(err)
      }
    },
    async validAlert2() {
      let obj = {
        title: `YYYY.MM.DD 일자로 판매기간이
종료되어 구독하실 수 없습니다.
다른 상품을 보여드릴까요?`,
        customCloseBtnText: '다른 상품 보러가기'
      }
      try {
        await this.$Simplert.open(obj)
        // 위 togglePortName 처럼 dom객체를 vue로 연결해 사용
      } catch (err) {
        console.log(err)
      }
    },
    order() {
      const target = document.getElementsByName('releseValue')

      let optionId = ''
      for (let i = 0, max = target.length; i < max; i++) {
        if (target[i].checked) {
          optionId = target[i].value
        }
      }
      const param = {
        productId: this.product.productId,
        optionId: optionId
      }
      axiosAuthInstance
        .post('/order/subScript', JSON.stringify(param), {
          headers: {
            'Content-Type': `application/json`
          }
        })
        .then((response) => {
          console.log(response)
          this.orderId = response.orderId
          this.isComplete = true
        })
        .catch((error) => {
          this.isFail = true
          console.log('error', error)
        })
    },
    setRelease() {
      // 위 togglePortName 처럼 dom객체를 vue로 연결해 사용
      this.isTogglePortEditList = false
    },
    discountRateCalculation(discountValue, price, discountApplicationMethod) {
      if (discountApplicationMethod === 'FIXED_PRICE') {
        const discountPrice = (discountValue / price) * 100
        return discountPrice.toFixed(2)
      } else {
        return discountValue
      }
    },
    portfolioTypeName(type) {
      switch (type) {
        case 'GROWTH':
          return '성장형'
        case 'VALUE':
          return '가치형'
        case 'MOMENTUM':
          return '모멘텀'
        case 'DIVIDEND':
          return '배당형'
        case 'LOWVOL':
          return '안정형'
        case 'QUALITY':
          return '우량형'
        case 'STOCKS':
          return '직접 선택'
      }
    },
    portfolioType(type) {
      switch (type) {
        case 'GROWTH':
          return 'exp'
        case 'VALUE':
          return 'val'
        case 'MOMENTUM':
          return 'moe'
        case 'DIVIDEND':
          return 'money'
        case 'LOWVOL':
          return 'safe'
        case 'QUALITY':
          return 'big'
        case 'STOCKS':
          return 'stocks'
        default:
          return ''
      }
    },
    converterRadarData(radarData) {
      this.radarData[0] = Number(radarData.growth)
      this.radarData[1] = Number(radarData.value)
      this.radarData[2] = Number(radarData.momentum)
      this.radarData[3] = Number(radarData.dividend)
      this.radarData[4] = Number(radarData.lowvol)
      this.radarData[5] = Number(radarData.quality)
    },
    radarIsDown(rorPercent) {
      return rorPercent < 0
    },
    updateRadar() {
      this.$refs.childRadarRef.forceRender()
    },
    changeViewRate(viewRate) {
      this.viewRate = viewRate
      if (this.viewRate === 1) {
        this.rateOfReturn = this.portfolio.rorPercent3m
        const timeseriesLength = this.portfolio.portfolioTimeSeries.length
        const timeseriesStartNumber =
          timeseriesLength > 90 ? timeseriesLength - 90 : 0
        this.lineData = this.portfolio.portfolioTimeSeries.slice(
          timeseriesStartNumber,
          timeseriesLength
        )
        let value = 100 - this.lineData[0].valueIndex
        this.lineData = this.lineData.map((info) => {
          return { x: info.baseDate, y: info.valueIndex - 100 + value }
        })
      } else if (this.viewRate === 2) {
        this.rateOfReturn = this.portfolio.rorPercent6m
        const timeseriesLength = this.portfolio.portfolioTimeSeries.length
        const timeseriesStartNumber =
          timeseriesLength > 180 ? timeseriesLength - 180 : 0
        this.lineData = this.portfolio.portfolioTimeSeries.slice(
          timeseriesStartNumber,
          timeseriesLength
        )
        let value = 100 - this.lineData[0].valueIndex
        this.lineData = this.lineData.map((info) => {
          return { x: info.baseDate, y: info.valueIndex - 100 + value }
        })
      } else if (this.viewRate === 3) {
        this.rateOfReturn = this.portfolio.rorPercent12m
        const timeseriesLength = this.portfolio.portfolioTimeSeries.length
        const timeseriesStartNumber =
          timeseriesLength > 365 ? timeseriesLength - 365 : 0
        this.lineData = this.portfolio.portfolioTimeSeries.slice(
          timeseriesStartNumber,
          timeseriesLength
        )
        let value = 100 - this.lineData[0].valueIndex
        this.lineData = this.lineData.map((info) => {
          return { x: info.baseDate, y: info.valueIndex - 100 + value }
        })
      } else if (this.viewRate === 4) {
        this.rateOfReturn = this.portfolio.rorPercent24m
        const timeseriesLength = this.portfolio.portfolioTimeSeries.length
        const timeseriesStartNumber =
          timeseriesLength > 730 ? timeseriesLength - 730 : 0
        this.lineData = this.portfolio.portfolioTimeSeries.slice(
          timeseriesStartNumber,
          timeseriesLength
        )
        let value = 100 - this.lineData[0].valueIndex
        this.lineData = this.lineData.map((info) => {
          return { x: info.baseDate, y: info.valueIndex - 100 + value }
        })
      } else if (this.viewRate === 5) {
        this.rateOfReturn = this.portfolio.rorPercent36m
        const timeseriesLength = this.portfolio.portfolioTimeSeries.length
        const timeseriesStartNumber =
          timeseriesLength > 1096 ? timeseriesLength - 1096 : 0
        this.lineData = this.portfolio.portfolioTimeSeries.slice(
          timeseriesStartNumber,
          timeseriesLength
        )
        let value = 100 - this.lineData[0].valueIndex
        this.lineData = this.lineData.map((info) => {
          return { x: info.baseDate, y: info.valueIndex - 100 + value }
        })
      }
      this.isDown = this.rateOfReturn < 0
      const lineDataLength = this.lineData.length
      this.rateOfReturnStartDate = this.lineData[0].x
      this.rateOfReturnEndDate = this.lineData[lineDataLength - 1].x
      this.lineKey++

      this.updateRadar()
    },
    toStringByFormatting(targetDate) {
      const date = new Date(targetDate)
      const year = date.getFullYear()
      const month = this.leftPad(date.getMonth() + 1)
      const day = this.leftPad(date.getDate())

      return [year, month, day].join('-')
    },
    leftPad(value) {
      if (value >= 10) {
        return value
      }

      return `0${value}`
    },
    goStorage() {
      this.$router.replace({ name: 'PortfolioStorage' })
    },
    goShoppingSubs() {
      this.$router.replace({
        name: 'SubscribeDetail',
        params: { id: this.orderId }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.discount {
  color: #d74343;
  font-size: 20px;
  font-weight: 600;
}
.product-detail-wrap {
  background-color: #f1f1f1;
}
.section {
  background-color: #fff;
  padding: 32px 16px;
}
.slide-box {
  position: relative;
  aspect-ratio: 1;
  .inner-txt {
    position: absolute;
    left: 16px;
    bottom: 24px;
  }
}
.product-info {
  padding: 32px 16px;
  .title {
    color: #666;
    font-size: 12px;
    line-height: 140%;
    margin-bottom: 5px;
  }
  .desc {
    margin-top: 8px;
    margin-bottom: 12px;
  }
  .price {
    font-size: 22px;
    font-weight: 600;
    color: #222;
    .discount {
      color: #d74343;
      font-size: 22px;
      font-weight: 600;
    }
  }
  .before-info {
    margin-top: 2px;
    margin-bottom: 4px;
    span {
      color: #999;
      font-size: 14px;
    }
    .before-price {
      text-decoration: line-through;
    }
  }
  .recent-percent {
    display: flex;
    align-items: center;
    gap: 4px;
    span {
      font-size: 14px;
      color: #666;
    }
    .percent {
      color: #d74343;
      font-weight: 600;
    }
    .isDown {
      color: #4882f5;
    }
  }
  .round-date {
    margin-top: 14px;
    span {
      color: #666;
      font-size: 12px;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    width: 215px;
    height: 25px;
    border-radius: 50px;
    background: #f5f5ff;
    gap: 6px;
  }
}
.sec-head {
  h3 {
    font-size: 18px;
  }
  .status-box {
    strong {
      color: #4a4ee7;
      font-size: 20px;
      font-weight: 600;
    }
  }
  .rate-box {
    strong {
      color: #d74343;
      font-size: 20px;
      font-weight: 600;
    }
  }
  span {
    padding-left: 8px;
    color: #999;
    font-size: 12px;
    font-weight: 400;
  }
}
.round-box-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 8px;
  column-gap: 28px;
  padding: 16px 35px;
  border-radius: 14px;
  background: #f7f7f7;
  > li {
    display: flex;
    justify-content: space-between;
    align-self: center;
    max-width: 68px;
    padding-left: 8px;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 3px;
      height: 3px;
      border-radius: 50%;
      background-color: #d9d9d9;
    }

    .l-box {
      color: #666;
    }
    .r-box {
      font-weight: 500;
    }
  }
}
.date-tab-list {
  display: flex;
  justify-content: space-between;

  > li {
    padding: 10px;
    min-width: 52px;
    text-align: center;
    &.on {
      border-radius: 10px;
      background: #f9f9f9;
      font-weight: 600;
    }
  }
}
.result-section {
  .round-box {
    border-radius: 14px;
    border: 1px solid #e8e8e8;
    background: #fff;
    padding: 16px 20px;
    display: flex;
    align-items: center;
    gap: 12px;
    .r-box {
      strong {
        color: #222;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 6px;
      }
      p {
        color: #999;
        font-size: 12px;
        font-weight: 400;
      }
    }
  }
  .etc-box {
    .val-list {
      margin-top: 14px;
      display: flex;
      flex-direction: column;
      gap: 14px;
      > li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
          color: #666;
        }
      }
    }
  }
}
.purple-box {
  border-radius: 14px;
  background: #f5f5ff;
  padding: 20px 16px;
  strong {
    color: #4a4ee7;
    font-size: 16px;
    font-weight: 600;
  }
  .disc-list {
    margin-top: 8px;

    li {
      margin: 0;
    }
  }
}
.line-box {
  border-radius: 14px;
  border: 1px solid #e8e8e8;
  padding: 24px 16px;
  margin-top: 10px;
  p {
    color: #666;
  }
}
.subs-wrap {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 150px;
  .cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h4 {
      margin-top: 16px;
      margin-bottom: 24px;
      text-align: center;
    }
    .round-box {
      background-color: #f7f7f7;
      border-radius: 14px;
      padding: 24px;
      width: 100%;
      ul {
        display: flex;
        flex-direction: column;
        gap: 14px;
        li {
          display: flex;
          gap: 14px;
        }
      }

      .l-box {
        font-weight: 600;
      }
      .r-box {
        color: #666;
      }
    }
  }
  .btn-wrap {
    margin-top: auto;
  }
}
.big-desc {
  color: #999;
  margin-top: 10px;
  font-size: 18px;
  strong .up {
    color: #d74343;
    font-weight: 600;
  }
  strong .down {
    color: #4a4ee7;
    font-weight: 600;
  }
}
.before-price {
  text-decoration: line-through;
}
</style>
