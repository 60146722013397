// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#bot-menu[data-v-7b95f092] {
  z-index: 98999;
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 8px 16px 12px;
  background-color: #fff;
}
#bot-menu ul[data-v-7b95f092] {
  display: flex;
  justify-content: space-between;
  gap: 22px;
}
#bot-menu ul > li[data-v-7b95f092] {
  flex: 1;
  min-width: 48px;
}
#bot-menu ul > li a[data-v-7b95f092] {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#bot-menu ul > li a span[data-v-7b95f092] {
  font-size: 10px;
  color: #999;
}
#bot-menu ul > li a i.on ~ span[data-v-7b95f092] {
  color: #222;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
