<template>
  <div id="chart">
    <apexchart
      type="area"
      :options="chartOption"
      :series="chartSeries"
      :height="chartSize"
      :key="componentKey"
    ></apexchart>
  </div>
</template>
<script>
export default {
  data() {
    return {
      componentKey: 0,
      chartOptions: {
        chart: {
          id: 'area',
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          },
          selection: {
            enabled: false
          }
        },
        zoom: {
          enabled: false
        },
        selection: {
          enabled: false
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight',
          colors: ['#4882F5'],
          width: 2
        },
        grid: {
          borderColor: '#D9D9D9',
          strokeDashArray: 3,
          width: 2
        },
        fill: {
          type: 'gradient',

          colors: ['#4882F5'],
          gradient: {
            shadeIntensity: 1,
            inverseColors: true,
            opacityFrom: 0.8,
            opacityTo: 0.5,
            stops: [0, 100]
          }
        },
        xaxis: {
          show: false,
          type: 'datetime',
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          labels: {
            show: false
          }
        },
        yaxis: {
          show: false,
          tickAmount: 1,
          labels: {
            show: false
          }
        },
        tooltip: {
          enabled: false
        }
      },
      series: [
        {
          name: 'graph',
          data: this.data
        }
      ]
    }
  },
  computed: {
    chartSeries() {
      let series = this.series
      if (this.$props.data.length > 0) {
        series[0].data = this.$props.data
      }

      return series
    },
    chartOption() {
      let opt = { ...this.chartOptions }
      opt.stroke.colors = this.$props.isDown ? ['#4882F5'] : ['#D74343']
      opt.fill.colors = this.$props.isDown ? '#4882F5' : '#D74343'
      return opt
    },
    chartSize() {
      if (this.$props.size === 'S') {
        return 100
      }
      return 154
    }
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    isDown: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'M'
    }
  },
  methods: {
    forceRender() {
      this.componentKey++
    }
  }
}
</script>
<style lang="scss">
.apexcharts-grid-borders {
  display: none;
}
.apexcharts-gridlines-horizontal {
  line {
    display: none;
    &:last-child {
      display: block;
    }
  }
}
</style>
<!-- -->
