var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isDisplay
    ? _c(
        "div",
        {
          staticClass: "rounge-card-view",
          on: {
            click: function ($event) {
              return _vm.goLounge(_vm.rounge.loungeId)
            },
          },
        },
        [
          _c("div", { staticClass: "top-box" }, [
            _c("div", { staticClass: "l-box" }, [
              _c("div", { staticClass: "rounge-type" }, [
                _vm._v(_vm._s(_vm.rounge.category)),
              ]),
              _c("div", { staticClass: "rounge-profil" }, [
                _c("div", { staticClass: "prof-thumb" }, [
                  _vm.rounge.storageAccess
                    ? _c("img", {
                        attrs: { src: _vm.rounge.storageAccess, alt: "" },
                      })
                    : _c("i", { staticClass: "icon--profil-temp" }),
                ]),
                _c("span", { staticClass: "profil-name" }, [
                  _vm._v(_vm._s(_vm.rounge.nickName)),
                ]),
              ]),
            ]),
            _vm.rounge.storageAccesses[0]
              ? _c("div", { staticClass: "r-box" }, [
                  _c("img", {
                    staticClass: "lounge-img",
                    attrs: { src: _vm.rounge.storageAccesses[0], alt: "" },
                  }),
                ])
              : _vm._e(),
          ]),
          _c("div", { staticClass: "bot-box" }, [
            _c("p", [_vm._v(_vm._s(_vm.rounge.loungeContents))]),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }