<template>
  <div class="row-component">
    <ul class="use-list">
      <li v-for="(d, i) in rows" :key="i + 'row'">
        <div class="l-box">
          <p class="title">{{ d.rowTitle }}</p>
          <p class="save-date"><span>저장일 | </span>{{ d.createdDate }}</p>
        </div>
        <div class="r-box">
          <button
            class="status-btn"
            :class="{
              before: d.status === 'WAIT' || d.status === 'REJECT',
              after: d.status === 'add',
              ing: d.status === 'check',
              done: d.status === 'finish'
            }"
            @click="$emit('rowClick', d)"
          >
            <!-- <button class="status-btn before" @click="$emit('rowClick', d)"> -->
            {{ buttonText(d.status) }}
          </button>
        </div>
      </li>
      <li class="empty" v-if="rows.length === 0">
        {{ message }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    rows: {
      type: Array,
      default: () => {
        return []
      }
    },
    message: {
      type: String,
      default: '조건을 만족하는 포트폴리오가 없습니다.'
    }
  },
  data() {
    return {}
  },
  methods: {
    buttonText(status) {
      //심사 신청 가능(WAIT), 심사 대기(REQUEST), 심사중(UNDER_REVIEW),승인(APPROVAL), 신청불가(UNABLE), 반려(REJECT)
      switch (status) {
        case 'WAIT':
          return '심사 요청'
        case 'add':
          return '심사 접수'
        case 'check':
          return '심사 중'
        case 'finish':
          return '승인'
        case 'UNABLE':
          return '심사 불가'
        case 'cancel':
          return '반려'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.row-component {
  .use-list {
    li {
      display: flex;
      align-items: center;
      padding: 16px 0;
      border-bottom: 1px solid #e8e8e8;
      &:first-child {
        padding-top: 0;
      }
      .l-box {
        flex: 1;
        .title {
          color: #444;
          margin-bottom: 4px;
        }
        .save-date {
          color: #999;
          font-size: 12px;
        }
      }
      .r-box {
        margin-left: auto;
        flex-shrink: 0;
        .status-btn {
          width: 90px;
          height: 36px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 10px;
          border: 1px solid #e8e8e8;

          font-weight: 500;
          &.before {
            font-weight: 600;
            border: 1px solid #4a4ee7;
            color: #4a4ee7;
          }
          &.after {
            color: #666;
          }
          &.ing {
            color: #d74343;
          }
          &.done {
            color: #4a4ee7;
          }
        }
      }
    }
    li.empty {
      padding: 80px 0 120px;
      text-align: center;
      color: #999;
      justify-content: center;
    }
  }
}
</style>
