import axios from 'axios'
import router from '@/router'
import store from '@/store'

const axiosAuthApi = axios.create({
  baseURL: process.env.VUE_APP_API_URL
})

axiosAuthApi.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem('accessToken')
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
      const refreshToken = localStorage.getItem('refreshToken')
      if (refreshToken && isAccessTokenExpire(token)) {
        config.headers['Refresh-Token'] = refreshToken
      }
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

axiosAuthApi.interceptors.request.use(
  (config) => {
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// Route to 404 when not found
axiosAuthApi.interceptors.response.use(
  (response) => {
    if (response.headers['access-token']) {
      localStorage.setItem('accessToken', response.headers['access-token'])
    }
    if (response.headers['refresh-token']) {
      localStorage.setItem('refreshToken', response.headers['refresh-token'])
    }

    return response.data
  },
  (error) => {
    console.log(error)
    if (error.response.status === 404) {
      router.push({ name: '404' })
    }

    if (error.response.status === 403) {
      localStorage.setItem('accessToken', '')
      localStorage.setItem('refreshToken', '')
      store.commit('SET_USER_INFO', {})
      router.push({ name: '403' })
    }

    if (error.response.status === 401) {
      //axiosAuthApi(error.config)
      // 인증에러 처리부분 401
      if (error.response.data.message === 'JWT_EXPIRED') {
        console.log(error)
        //error.config.headers[]
        axiosAuthApi(error.config)
      } else {
        localStorage.setItem('accessToken', '')
        localStorage.setItem('refreshToken', '')
        store.commit('SET_USER_INFO', {})
        router.push({ name: 'Login' })
      }
    }

    return Promise.reject(error)
  }
)

// accessToken이 만료되었는지 여부를 가져옵니다.
function isAccessTokenExpire(token) {
  let expire = false
  // accessToken에서 .(도트)로 분리하여 payload를 가져옵니다.
  let base64Payload = token.split('.')[1]
  // URL과 호환되지 않는 문자를 base64 표준 문자로 교체합니다.
  base64Payload = base64Payload.replace(/-/g, '+').replace(/_/g, '/')
  // atob() 메소드로 복호화합니다.
  base64Payload = atob(base64Payload)
  // JSON 객체로 변환합니다.
  var payloadObject = JSON.parse(base64Payload)
  // accessToken의 만료 시간을 확인합니다.
  var currentDate = new Date().getTime() / 1000
  if (payloadObject.exp <= currentDate) {
    console.log('token expired')
    expire = true
  } else {
    //console.log('token valid')
  }
  return expire
}

export const axiosAuthInstance = axiosAuthApi
