import {
  mainShoppingList,
  mainCommunityList,
  mainRankList,
  mainRoungeList,
  mainPopularList,
  mainMorePortpolio,
  rankingDataList,
  communityDataList,
  communityBnnrList,
  mypageUser,
  mypageQna,
  mypagePosting,
  mypageComent,
  mypageTerms,
  mypagePrivacy,
  mypageProductsSell,
  communityDataDtl,
  portfolioDtl
} from '@/data/data'
import { axiosAuthInstance } from '@/store/axios'
// 로그인
export const login = () => {
  return new Promise((resolve) => {
    resolve({
      data: {
        result: mypageUser
      }
    })
  })
}
// 회원 가입시 모바일 인증번호 확인
export const chkAuthNumber = () => {
  return new Promise((resolve) => {
    resolve({
      data: {
        result: {}
      }
    })
  })
}

// 회원 가입
export const fetchJoin = () => {
  return new Promise((resolve) => {
    resolve({
      data: {
        result: mypageUser
      }
    })
  })
}
// 회원 등록 ( 닉네임 , 프로필 )
export const setProfile = () => {
  return new Promise((resolve) => {
    resolve({
      data: {
        result: {}
      }
    })
  })
}

// 메인 목록
export const getMain = () => {
  return new Promise((resolve) => {
    resolve({
      data: {
        result: {
          mainShoppingList,
          mainCommunityList,
          mainRankList,
          mainRoungeList,
          mainPopularList,
          mainMorePortpolio
        }
      }
    })
  })
}

// 랭킹 목록
export const fetchRankingList = () => {
  return new Promise((resolve) => {
    resolve({
      data: {
        result: rankingDataList
      }
    })
  })
}

// 커뮤니티 배너 목록
export const getCommunityBannr = () => {
  return new Promise((resolve) => {
    resolve({
      data: {
        result: communityBnnrList
      }
    })
  })
}
// 커뮤니티 목록
export const getCommunity = () => {
  return new Promise((resolve) => {
    resolve({
      data: {
        result: communityDataList
      }
    })
  })
}
// 커뮤니티 상세
export const getCommunityDtl = () => {
  return new Promise((resolve) => {
    resolve({
      data: {
        result: communityDataDtl
      }
    })
  })
}

// 커뮤니티 등록
export const setCommunity = () => {
  return new Promise((resolve) => {
    resolve({
      data: {
        result: {}
      }
    })
  })
}

// 종목 찾기
export const getSearchStock = (data) => {
  return axiosAuthInstance.get('/stock/stockDatas', data)
}

export const getPortfolioStyleds = (data) => {
  return axiosAuthInstance.get('/portfolio/styles', data)
}

// 마이페이지 - 포스팅 목록
export const getPostingList = () => {
  return new Promise((resolve) => {
    resolve({
      data: {
        result: mypagePosting
      }
    })
  })
}

// 마이페이지 - 코멘트 목록
export const getCommentList = () => {
  return new Promise((resolve) => {
    resolve({
      data: {
        result: mypageComent
      }
    })
  })
}

// 마이페이지 - 판매 목록
export const getSellList = () => {
  return new Promise((resolve) => {
    resolve({
      data: {
        result: mypageProductsSell
      }
    })
  })
}

// 약관
export const getTerms = () => {
  return new Promise((resolve) => {
    resolve({
      data: {
        result: mypageTerms
      }
    })
  })
}

// 개인정보 보호
export const getPrivacy = () => {
  return new Promise((resolve) => {
    resolve({
      data: {
        result: mypagePrivacy
      }
    })
  })
}

// 마이페이지 - qna
export const getQnaList = () => {
  return new Promise((resolve) => {
    resolve({
      data: {
        result: mypageQna
      }
    })
  })
}

// 포트폴리오 목록
export const setPortfolioCalculation = (data) => {
  return axiosAuthInstance.post('/portfolio/calculation', data)
}

// 포트폴리오 목록
export const getUserPortfolioList = (header) => {
  return axiosAuthInstance.get('/portfolio/user', { 'headers': header })
}

// 포트폴리오 상세 & 만들기 결과
export const getPortfolioDtl = () => {
  return new Promise((resolve) => {
    resolve({
      data: {
        result: portfolioDtl
      }
    })
  })
}
// 포트폴리오 저장
export const setPortfolio = () => {
  return new Promise((resolve) => {
    resolve({
      data: {
        result: {}
      }
    })
  })
}
