var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "posting" },
    [
      _vm.editListShow
        ? _c("SlideUpModal", {
            staticClass: "edit-modal",
            on: {
              close: function ($event) {
                _vm.editListShow = false
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "main",
                  fn: function () {
                    return [
                      _c("ul", { staticClass: "edit-modal-list" }, [
                        _c(
                          "li",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.openSetEdit("edit", 1)
                              },
                            },
                          },
                          [_vm._v("신고하기")]
                        ),
                        _c(
                          "li",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.openSetEdit("delete", 2)
                              },
                            },
                          },
                          [_vm._v("수정하기")]
                        ),
                        _c(
                          "li",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.openSetEdit("comment", 3)
                              },
                            },
                          },
                          [_vm._v("삭제하기")]
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              826264392
            ),
          })
        : _vm._e(),
      _vm.editShow
        ? _c("TextRegisterModal", {
            on: {
              close: function ($event) {
                _vm.editShow = false
              },
            },
          })
        : _vm._e(),
      _vm.commentShow
        ? _c("CommentRegisterModal", {
            on: {
              close: function ($event) {
                _vm.commentShow = false
              },
            },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "posting-tab wrapper" },
        _vm._l(_vm.tabList, function (t, index) {
          return _c(
            "div",
            {
              key: `tab-${index}`,
              class: { active: _vm.tab === t.id },
              staticStyle: { height: "35px !important" },
              on: {
                click: function ($event) {
                  return _vm.clickTab(t.id)
                },
              },
            },
            [_vm._v(" " + _vm._s(t.text) + " ")]
          )
        }),
        0
      ),
      _vm.tab === 0
        ? [
            _c(
              "ul",
              { staticClass: "posting-list" },
              _vm._l(_vm.lounges, function (d, i) {
                return _c(
                  "li",
                  {
                    key: i + "list",
                    staticClass: "posting-list-box on",
                    class: _vm.toggleList.includes(d.seq) ? "on" : "",
                  },
                  [
                    _c("div", { staticClass: "flex-between" }, [
                      _c(
                        "span",
                        {
                          staticClass: "posting-list-box-title list-title-box",
                        },
                        [_vm._v(_vm._s(d.loungeTitle))]
                      ),
                      _c("span", { staticClass: "posting-list-box-status" }, [
                        _c("i", { staticClass: "icon--coment" }),
                        _c("span", { staticClass: "number" }, [
                          _vm._v(_vm._s(d.commentCount)),
                        ]),
                        _c("i", {
                          staticClass: "icon--dot",
                          on: {
                            click: function ($event) {
                              return _vm.$emit(
                                "toggle:edit",
                                _vm.data.loungeId,
                                _vm.data.isOwner
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                    _c("div", { staticClass: "posting-list-box-date" }, [
                      _vm._v(_vm._s(d.createdDate)),
                    ]),
                    _c(
                      "p",
                      { staticClass: "posting-list-box-description desc-box" },
                      [_vm._v(" " + _vm._s(d.loungeContents) + " ")]
                    ),
                    _c("ThumbBox", { attrs: { thumb: d.storageAccesses } }),
                    _c(
                      "div",
                      {
                        staticClass: "comment-list-box-add-view",
                        class: _vm.toggleList.includes(d.seq) ? "" : "show",
                        on: {
                          click: function ($event) {
                            return _vm.moveLoungDetail(d.loungeId)
                          },
                        },
                      },
                      [_vm._v(" 더 보기 ")]
                    ),
                  ],
                  1
                )
              }),
              0
            ),
          ]
        : _vm._e(),
      _vm.tab === 1
        ? [
            _c(
              "ul",
              { staticClass: "comment-list" },
              _vm._l(_vm.loungeComments, function (d, i) {
                return _c(
                  "li",
                  { key: i + "list", staticClass: "comment-list-box" },
                  [
                    _c("div", { staticClass: "flex-between" }, [
                      _c(
                        "span",
                        {
                          staticClass: "comment-list-box-title list-title-box",
                        },
                        [_vm._v(_vm._s(d.commentTitle))]
                      ),
                      _c("span", { staticClass: "comment-list-box-status" }, [
                        _c("i", {
                          staticClass: "icon--dot",
                          on: {
                            click: function ($event) {
                              return _vm.openEditModal(1)
                            },
                          },
                        }),
                      ]),
                    ]),
                    _c("div", { staticClass: "comment-list-box-date" }, [
                      _vm._v(_vm._s(d.createdDate)),
                    ]),
                    _c(
                      "p",
                      { staticClass: "comment-list-box-description desc-box" },
                      [_vm._v(" " + _vm._s(d.commentContents) + " ")]
                    ),
                    _c("ThumbBox", { attrs: { thumb: d.storageAccesses } }),
                    _c(
                      "div",
                      {
                        staticClass: "comment-list-box-add-view",
                        class: _vm.toggleList.includes(d.seq) ? "" : "show",
                        on: {
                          click: function ($event) {
                            return _vm.moveLoungDetail(d.loungeId)
                          },
                        },
                      },
                      [_vm._v(" 더 보기 ")]
                    ),
                  ],
                  1
                )
              }),
              0
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }