// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.top-ranker-box[data-v-002717ec] {
  display: flex;
  gap: 16px;
}
.top-ranker-box > li[data-v-002717ec] {
  flex: 1;
  width: 30%;
}
.ranker-box[data-v-002717ec] {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 28px;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
