var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("SlideUpModal", {
    staticClass: "strategy-regist-modal",
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [
            _c("div", { staticClass: "flex-just" }, [
              _c("h4", [_vm._v("전략만들기 타입을 선택해주세요")]),
              _c("i", {
                staticClass: "icon--close",
                on: { click: _vm.closeSlider },
              }),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "main",
        fn: function () {
          return [
            _c("div", { staticClass: "choose-mode-list" }, [
              _c(
                "div",
                {
                  staticClass: "mode-box",
                  class: { on: _vm.choose === "01" },
                  on: {
                    click: function ($event) {
                      _vm.choose = "01"
                    },
                  },
                },
                [
                  _c("i", { staticClass: "icon--mode-01" }),
                  _c("h4", { staticClass: "mode-name" }, [
                    _vm._v("직접종목선정"),
                  ]),
                  _c("p", { staticClass: "mode-desc" }, [
                    _vm._v("마음에 드는 종목만"),
                    _c("br"),
                    _vm._v("쏙쏙 골라 담기"),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "mode-box",
                  class: { on: _vm.choose === "02" },
                  on: {
                    click: function ($event) {
                      _vm.choose = "02"
                    },
                  },
                },
                [
                  _c("i", { staticClass: "icon--mode-02" }),
                  _c("h4", { staticClass: "mode-name" }, [
                    _vm._v("전략구현하기"),
                  ]),
                  _c("p", { staticClass: "mode-desc" }, [
                    _vm._v(" 설정한 조건에 따라"),
                    _c("br"),
                    _vm._v(" 만들어지는 포트폴리오 "),
                  ]),
                ]
              ),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "button",
        fn: function () {
          return [
            _c("div", { staticClass: "modal-btn-wrap" }, [
              _c(
                "button",
                {
                  staticClass: "base-btn full cl-pri",
                  on: { click: _vm.goAdded },
                },
                [_c("span", [_vm._v("만들기")])]
              ),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }