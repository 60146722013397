<template>
  <div class="diff-wrap">
    <div class="head-box">
      <h3>구성종목</h3>
      <ul class="disc-list mt-8">
        <li>원 투자금액 기준 이전 포트포트폴리오 구성비 변경 분 입니다.</li>
        <li>수익 및 손실은 적용되지 않습니다.</li>
      </ul>
      <div class="date-box flex mt-32">
        <div class="l-box" v-if="previousBaseDate">
          <strong>비교대상</strong>
          <div class="date flex-center">
            <i
              class="icon--arrow-back"
              v-if="isPreviousBtn"
              @click="moveStockData(1)"
            ></i>
            <span>{{ previousBaseDate }}</span>
            <i
              class="icon--arrow-move"
              v-if="isNextBtn"
              @click="moveStockData(-1)"
            ></i>
          </div>
        </div>
        <div class="r-box">
          <strong>현재</strong>
          <div class="date">
            <span>{{ nowBaseDate }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="diff-body mt-16">
      <div class="compo-section">
        <div class="sec-body">
          <div class="grid-table">
            <ul class="table-head">
              <li>종목</li>
              <li>주가</li>
              <li>비중</li>
              <li>주식수</li>
            </ul>
            <ul
              v-for="(d, i) in diffInfo"
              :key="i + 'search'"
              class="table-list"
            >
              <li>
                <p>{{ d.stockName }}</p>
              </li>
              <li>
                <p>{{ Number(d.currentPrices).toLocaleString() }}</p>
                <p class="mod-price" :class="d.priceStatus">
                  ({{ Number(d.diffCurrentPrices).toLocaleString() }})
                </p>
              </li>
              <li>
                <p>{{ weight(d.weight) }}%</p>
                <p class="mod-price" :class="d.weightStatus">
                  ({{ weight(d.diffWeight) }}%)
                </p>
              </li>
              <li>
                <p>{{ Number(d.amount).toLocaleString() }}주</p>
                <p class="mod-price" :class="d.amountStatus">
                  ({{ Number(d.modAmount).toLocaleString() }})
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { axiosAuthInstance } from '@/store/axios'

export default {
  name: 'QuantMarketDiffStock',
  props: {
    operatingFunds: {
      type: Number
    },
    portfolioId: {
      type: Number
    }
  },
  data() {
    return {
      baseDates: [],
      portfolioStockWeights: [],
      nowStockDataList: [],
      nowBaseDate: '',
      previousStockDataList: [],
      previousBaseDate: '',
      previousIndex: 1
    }
  },
  created() {
    axiosAuthInstance
      .get('/portfolio/stock/weight/' + this.portfolioId, {})
      .then((response) => {
        this.baseDates = response.baseDates
        this.portfolioStockWeights = response.portfolioStockWeights

        this.nowBaseDate = this.baseDates[0]
        this.nowStockDataList = JSON.parse(
          this.portfolioStockWeights[0].stockData
        )

        if (this.baseDates.length > 1) {
          this.previousBaseDate = this.baseDates[1]
          this.previousStockDataList = JSON.parse(
            this.portfolioStockWeights[1].stockData
          )
        }

        this.diffInfo
      })
      .catch((error) => {
        console.log('error', error)
      })
  },
  computed: {
    isPreviousBtn() {
      return this.previousIndex < this.baseDates.length - 1
    },
    isNextBtn() {
      return this.previousIndex > 1
    },
    diffInfo() {
      const diffStockDataList = []

      const nowStockIds = new Set()
      this.nowStockDataList.map((stockData) =>
        nowStockIds.add(stockData.stockId)
      )

      const previousStockIds = new Set()
      this.previousStockDataList.map((stockData) =>
        previousStockIds.add(stockData.stockId)
      )

      const unionStockIds = this.unionSet(nowStockIds, previousStockIds)

      for (let item of unionStockIds.keys()) {
        const nowStockData = this.nowStockDataList.find(
          (data) => data.stockId === item
        )
        const previousStockData = this.previousStockDataList.find(
          (data) => data.stockId === item
        )

        const diffStockData = {
          stockId: item,
          stockName: '',
          currentPrices: 0,
          diffCurrentPrices: 0,
          priceStatus: 'down',
          weight: 0,
          diffWeight: 0,
          weightStatus: 'down',
          amount: 0,
          modAmount: 0,
          amountStatus: 'down'
        }

        if (nowStockData) {
          diffStockData.stockName = nowStockData.stockName
          diffStockData.currentPrices = nowStockData.currentPrices
          diffStockData.weight = nowStockData.weight
          diffStockData.amount = this.stockCalculate(
            nowStockData.currentPrices,
            nowStockData.weight
          )
        }

        if (previousStockData) {
          if (nowStockData) {
            diffStockData.diffCurrentPrices =
              diffStockData.currentPrices - previousStockData.currentPrices

            diffStockData.diffWeight =
              diffStockData.weight - previousStockData.weight

            diffStockData.modAmount =
              diffStockData.amount -
              this.stockCalculate(
                previousStockData.currentPrices,
                previousStockData.weight
              )

            diffStockData.priceStatus =
              diffStockData.diffCurrentPrices >= 0 ? 'up' : 'down'
            diffStockData.weightStatus =
              diffStockData.diffWeight >= 0 ? 'up' : 'down'
            diffStockData.amountStatus =
              diffStockData.modAmount >= 0 ? 'up' : 'down'
          } else {
            diffStockData.stockName = previousStockData.stockName
            diffStockData.diffCurrentPrices = previousStockData.currentPrices
            diffStockData.diffWeight = previousStockData.weight
            diffStockData.modAmount = this.stockCalculate(
              previousStockData.currentPrices,
              previousStockData.weight
            )

            diffStockData.priceStatus = 'down'
            diffStockData.weightStatus = 'down'
            diffStockData.amountStatus = 'down'
          }
        } else {
          diffStockData.priceStatus = 'up'
          diffStockData.weightStatus = 'up'
          diffStockData.amountStatus = 'up'
        }

        diffStockDataList.push(diffStockData)
      }

      return diffStockDataList
    }
  },
  methods: {
    weight(val) {
      return (val * 100).toFixed(2)
    },
    stockCalculate(price, weight) {
      const totalRevenue = this.operatingFunds * 10 * 1000 * 1000
      return ((totalRevenue * weight) / price).toFixed(0)
    },
    unionSet(nowStockIds, previousStockIds) {
      let union = new Set(nowStockIds)
      previousStockIds.forEach((e) => {
        union.add(e)
      })
      return union
    },
    moveStockData(move) {
      this.previousIndex = this.previousIndex + move
      this.previousBaseDate = this.baseDates[this.previousIndex]
      this.previousStockDataList = JSON.parse(
        this.portfolioStockWeights[this.previousIndex].stockData
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.head-box {
  padding: 8px 16px;
}
.date-box {
  display: flex;
  > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    strong {
      color: #222;
      font-weight: 600;
      font-size: 16px;
    }
    .date {
      height: 24px;
      display: flex;
      gap: 4px;
      align-items: center;
      .span {
        color: #666;
      }
    }
  }
}
.compo-section {
  .compo-date {
    color: #999;
    font-size: 12px;
  }
  .grid-table {
    .table-head {
      li {
        font-weight: 500;
      }
    }
    .table-head,
    .table-list {
      padding-left: 24px;
      padding-right: 24px;
      grid-template-columns: 35% 30% 15% 20%;
    }
    .table-list {
      min-height: 42px;
      height: auto;
      padding-top: 8px;
      padding-bottom: 8px;
      > li {
        height: auto;
        min-height: auto;
        p {
          color: #666;
        }
        display: flex;
        flex-direction: column;
        .mod-price {
          color: #a1a1a1;
          font-size: 12px;
          &.up {
            color: #d74343;
          }
          &.down {
            color: #4882f5;
          }
        }
      }
    }
  }
}
</style>
