// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-edit[data-v-d8da7c92] {
  padding: 0 4.4444444%;
  font-family: Pretendard;
}
.profile-edit-form li[data-v-d8da7c92] {
  width: 100%;
  margin-bottom: 20px;
}
.profile-edit-form li label[data-v-d8da7c92],
.profile-edit-form li div[data-v-d8da7c92] {
  display: block;
  color: #999;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 12px */
  letter-spacing: -0.24px;
  margin-bottom: 8px;
}
.profile-edit-form li input[data-v-d8da7c92],
.profile-edit-form li select[data-v-d8da7c92] {
  border-radius: 14px;
  background: #fff;
  border: 1px solid #e8e8e8;
  width: 100%;
  height: 50px;
  box-sizing: border-box;
  padding: 0px 14px;
  font-size: 16px;
}
.profile-edit-form li input[data-v-d8da7c92]:disabled {
  background: #f7f7f7;
}
.profile-edit-form li input.error[data-v-d8da7c92] {
  border: 1px solid #d74343;
}
.profile-edit-form li .error-messag[data-v-d8da7c92] {
  display: none;
}
.profile-edit-form li input.error + .error-message[data-v-d8da7c92] {
  display: block;
  color: #d74343;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 12px */
  letter-spacing: -0.24px;
  margin-top: 8px;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
