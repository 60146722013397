var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "rounge-wrap layer" },
    [
      _vm.$store.getters.getUser.isUser
        ? _c("button", {
            staticClass: "btn-add-rounge",
            on: { click: _vm.openCreateModal },
          })
        : _vm._e(),
      _vm.editShow
        ? _c("SlideUpModal", {
            staticClass: "edit-modal",
            on: {
              close: function ($event) {
                _vm.editShow = false
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "main",
                  fn: function () {
                    return [
                      _c("ul", { staticClass: "edit-modal-list" }, [
                        _c("li", { on: { click: _vm.showReport } }, [
                          _vm._v("신고하기"),
                        ]),
                        _vm.isOwner
                          ? _c("li", { on: { click: _vm.moveUpdate } }, [
                              _vm._v("수정하기"),
                            ])
                          : _vm._e(),
                        _vm.isOwner
                          ? _c("li", { on: { click: _vm.deleteLounge } }, [
                              _vm._v("삭제하기"),
                            ])
                          : _vm._e(),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              3358629248
            ),
          })
        : _vm._e(),
      _vm.declarShow
        ? _c("SlideUpModal", {
            staticClass: "edit-modal",
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "flex-just mb-16" }, [
                        _c("h4", [_vm._v("이 의견을 신고하는 이유")]),
                        _c("i", {
                          staticClass: "icon--close",
                          on: {
                            click: function ($event) {
                              _vm.declarShow = false
                            },
                          },
                        }),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "main",
                  fn: function () {
                    return [
                      _c(
                        "ul",
                        { staticClass: "declar-list" },
                        _vm._l(_vm.declarList, function (d, i) {
                          return _c(
                            "li",
                            { key: i + "declar", staticClass: "input-list" },
                            [
                              _c("div", { staticClass: "l-box" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.selectedDeclarList,
                                      expression: "selectedDeclarList",
                                    },
                                  ],
                                  attrs: { id: d.id, type: "radio" },
                                  domProps: {
                                    value: d.id,
                                    checked: _vm._q(
                                      _vm.selectedDeclarList,
                                      d.id
                                    ),
                                  },
                                  on: {
                                    change: function ($event) {
                                      _vm.selectedDeclarList = d.id
                                    },
                                  },
                                }),
                                _c("label", { attrs: { for: d.id } }),
                              ]),
                              _c("div", { staticClass: "r-box" }, [
                                _c("p", [_vm._v(_vm._s(d.title))]),
                              ]),
                            ]
                          )
                        }),
                        0
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "button",
                  fn: function () {
                    return [
                      _c(
                        "button",
                        {
                          staticClass: "base-btn full cl-pri",
                          on: {
                            click: function ($event) {
                              return _vm.report()
                            },
                          },
                        },
                        [_c("span", [_vm._v("확인")])]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              4235255348
            ),
          })
        : _vm._e(),
      _vm.toggleRegistRounge
        ? _c("TextRegisterModal", {
            attrs: {
              "is-create": _vm.isCreate,
              "update-target": _vm.updateTarget,
            },
            on: { close: _vm.saveComplete },
          })
        : _vm._e(),
      _vm._m(0),
      _c("ul", { staticClass: "cate-tab-list section pt-24 pb-16" }, [
        _c(
          "li",
          {
            class: { on: _vm.cate === 0 },
            on: {
              click: function ($event) {
                return _vm.changeLounge(0, "시황·종목")
              },
            },
          },
          [_vm._v(" 시황·종목 ")]
        ),
        _c(
          "li",
          {
            class: { on: _vm.cate === 1 },
            on: {
              click: function ($event) {
                return _vm.changeLounge(1, "퀀트전략")
              },
            },
          },
          [_vm._v(" 퀀트전략 ")]
        ),
        _c(
          "li",
          {
            class: { on: _vm.cate === 2 },
            on: {
              click: function ($event) {
                return _vm.changeLounge(2, "재테크")
              },
            },
          },
          [_vm._v(" 재테크 ")]
        ),
      ]),
      _c("div", { staticClass: "sort-box flex-end pr-14 pb-16" }, [
        _c(
          "button",
          {
            on: {
              click: function ($event) {
                _vm.toggleSortOpt = true
              },
            },
          },
          [
            _vm._v(" " + _vm._s(_vm.sort) + " "),
            _c("i", { staticClass: "icon--arrow-down" }),
          ]
        ),
        _vm.toggleSortOpt
          ? _c("ul", { staticClass: "select-wrap" }, [
              _c("li", [
                _c(
                  "p",
                  {
                    class: { on: _vm.sort === "최신순" },
                    on: {
                      click: function ($event) {
                        return _vm.setSort("최신순")
                      },
                    },
                  },
                  [_vm._v(" 최신순 ")]
                ),
              ]),
              _c("li", [
                _c(
                  "p",
                  {
                    class: { on: _vm.sort === "인기순" },
                    on: {
                      click: function ($event) {
                        return _vm.setSort("인기순")
                      },
                    },
                  },
                  [_vm._v(" 인기순 ")]
                ),
              ]),
            ])
          : _vm._e(),
      ]),
      _c("div", { staticClass: "board-list-section" }, [
        _c(
          "ul",
          _vm._l(_vm.list, function (d, i) {
            return _c(
              "li",
              { key: i + "board" },
              [
                _c("BoardItemBox", {
                  attrs: { data: d, active: _vm.toggleList.includes(d.seq) },
                  on: {
                    "toggle:more": _vm.moveDetail,
                    "toggle:edit": _vm.toggleEditModal,
                  },
                }),
              ],
              1
            )
          }),
          0
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "page-head-options" }, [
      _c("span", [_vm._v("내 활동 보기")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }