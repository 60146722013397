// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.examine-tab[data-v-d4f3122c] {
  display: flex;
  align-items: center;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #e8e8e8;
}
.examine-tab div[data-v-d4f3122c] {
  width: 100%;
  height: 48px;
  line-height: 48px;
  letter-spacing: -0.32px;
  color: #aaa;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.32px;
}
.examine-tab .active[data-v-d4f3122c] {
  color: #222;
  position: relative;
}
.examine-tab .active[data-v-d4f3122c]::after {
  content: "";
  display: block;
  position: absolute;
  bottom: -1.5px;
  width: 95%;
  left: 50%;
  transform: translateX(-50%);
  height: 2px;
  background-color: #222;
}
.examine .list-body[data-v-d4f3122c] {
  padding: 0 16px;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
