<template>
  <div class="setting wrapper">
    <ul>
      <li class="setting-item flex-between">
        <span class="setting-title">Push 설정 (전체)</span>
        <ToggleButton
          :isBig="true"
          :data="push"
          :info="{ allowNotificationType: 'ALL' }"
          ref="updatePushCheckbox"
          @updated="updateNotification"
        ></ToggleButton>
      </li>
      <li class="setting-item flex-between">
        <span class="setting-title">구독중인 상품</span>
        <ToggleButton
          :isBig="true"
          :data="subscription"
          :info="{ allowNotificationType: 'SUBSCRIPTION' }"
          ref="updateSubscriptionCheckbox"
          @updated="updateNotification"
        ></ToggleButton>
      </li>
      <li class="setting-item flex-between">
        <span class="setting-title">내가 만든 포트폴리오</span>
        <ToggleButton
          :isBig="true"
          :data="myPortfolio"
          :info="{ allowNotificationType: 'MY_PORTFOLIO' }"
          ref="updateMyPortfolioCheckbox"
          @updated="updateNotification"
        ></ToggleButton>
      </li>
      <li class="setting-item flex-between">
        <span class="setting-title">라운지</span>
        <ToggleButton
          :isBig="true"
          :data="lounge"
          :info="{ allowNotificationType: 'LOUNGE' }"
          ref="updateLoungeCheckbox"
          @updated="updateNotification"
        ></ToggleButton>
      </li>
    </ul>
  </div>
</template>

<script>
import ToggleButton from '@/components/button/ToggleButton.vue'
import { axiosAuthInstance } from '@/store/axios'
export default {
  components: { ToggleButton },
  data() {
    return {
      allowNotifications: [],
      push: false,
      subscription: false,
      myPortfolio: false,
      lounge: false
    }
  },
  created() {
    axiosAuthInstance
      .get('/user', {})
      .then((response) => {
        this.allowNotifications = response.allowNotifications

        for (const notification of this.allowNotifications) {
          switch (notification.allowNotificationType) {
            case 'ALL':
              this.push = notification.permitStatus === 'ACTIVE'
              this.$refs.updatePushCheckbox.updateCheckbox(this.push)
              break
            case 'SUBSCRIPTION':
              this.subscription = notification.permitStatus === 'ACTIVE'
              this.$refs.updateSubscriptionCheckbox.updateCheckbox(
                this.subscription
              )
              break
            case 'MY_PORTFOLIO':
              this.myPortfolio = notification.permitStatus === 'ACTIVE'
              this.$refs.updateMyPortfolioCheckbox.updateCheckbox(
                this.myPortfolio
              )
              break
            case 'LOUNGE':
              this.lounge = notification.permitStatus === 'ACTIVE'
              this.$refs.updateLoungeCheckbox.updateCheckbox(this.lounge)
              break
          }
        }
      })
      .catch((error) => {
        console.log('error', error)
      })
  },
  methods: {
    updateNotification(info, isOn) {
      const param = {
        allowNotificationType: '',
        permitStatus: false
      }

      switch (info.allowNotificationType) {
        case 'ALL':
          this.push = isOn
          param.allowNotificationType = 'ALL'
          param.permitStatus = isOn ? 'ACTIVE' : 'INACTIVE'
          break
        case 'SUBSCRIPTION':
          this.subscription = isOn
          param.allowNotificationType = 'SUBSCRIPTION'
          param.permitStatus = isOn ? 'ACTIVE' : 'INACTIVE'
          break
        case 'MY_PORTFOLIO':
          this.myPortfolio = isOn
          param.allowNotificationType = 'MY_PORTFOLIO'
          param.permitStatus = isOn ? 'ACTIVE' : 'INACTIVE'
          break
        case 'LOUNGE':
          this.lounge = isOn
          param.allowNotificationType = 'LOUNGE'
          param.permitStatus = isOn ? 'ACTIVE' : 'INACTIVE'
          break
      }

      axiosAuthInstance
        .put('/user/allow/notification', JSON.stringify(param), {
          headers: {
            'Content-Type': `application/json`
          }
        })
        .then((response) => {
          console.log(response)
        })
        .catch((error) => {
          if (error.response.data.message === 'DUPLICATION_NICK_NAME') {
            this.nickNameCheck = true
          }
          console.log('error', error)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.setting {
  &-item {
    color: #222;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    letter-spacing: -0.32px;
    height: 54px;
  }
}
</style>
