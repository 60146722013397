<template>
  <div id="step05" class="step05">
    <div class="page-title-section mb-24">
      <h4>성과측정 기간을 설정하세요</h4>
    </div>
    <ToggleSwitch
      @setCheckboxVal="setCheckboxVal"
      :list="['간편선택', '직접선택']"
      :isOn="!isStatic"
      class="mb-14"
    ></ToggleSwitch>
    <ul class="tab-list mb-40" v-if="isStatic">
      <li class="tab" :class="{ on: year === 1 }" @click="year = 1">1년</li>
      <li class="tab" :class="{ on: year === 2 }" @click="year = 2">2년</li>
      <li class="tab" :class="{ on: year === 3 }" @click="year = 3">3년</li>
      <li
        class="tab"
        :class="{ on: year === 4 }"
        @click="year = 4"
        v-if="false"
      >
        4년
      </li>
      <li
        class="tab"
        :class="{ on: year === 5 }"
        @click="year = 5"
        v-if="false"
      >
        5년
      </li>
    </ul>
    <div class="set-date-box mb-40" v-if="!isStatic">
      <div class="round-input">
        <label for="start"
          ><span>{{ startDate }}</span></label
        >
        <input
          v-model="startDate"
          id="start"
          type="date"
          min="2021-01-01"
          :max="maxStartDate"
        />
      </div>
      <span>~</span>
      <div class="round-input">
        <label for="end"
          ><span>{{ endDate }}</span></label
        >
        <input
          v-model="endDate"
          id="end"
          type="date"
          :min="minEndData"
          :max="maxEndDate"
        />
      </div>
    </div>
    <div class="choose-round-list mb-40">
      <h4 class="mb-16">포트폴리오를 언제 업데이트 할까요?</h4>
      <div
        class="round-box mb-8"
        :class="{ on: updDateTp === 'MONTHLY' }"
        @click="updDateTp = 'MONTHLY'"
      >
        <p class="type-tit">
          매월 <i class="icon--q" @click="openQue('MONTHLY')"></i>
        </p>
        <p>매월 초에 새로운 종목과 비중을 업데이트</p>
      </div>
      <div
        class="round-box"
        :class="{ on: updDateTp === 'QUARTERLY' }"
        @click="updDateTp = 'QUARTERLY'"
      >
        <p class="type-tit">
          매분기 <i class="icon--q" @click="openQue('QUARTERLY')"></i>
        </p>
        <p>2월, 5월, 8월, 10월초에 새로운 종목과 비중으로 업데이트</p>
      </div>
    </div>
    <div class="choose-round-list mb-40">
      <h4 class="mb-16">종목별로 얼만큼식 투자할까요?</h4>
      <div
        class="round-box mb-8"
        :class="{ on: updAmountTp === 'EQUAL_WEIGHT' }"
        @click="updAmountTp = 'EQUAL_WEIGHT'"
      >
        <p class="type-tit">
          골고루 투자
          <i class="icon--q" @click="openQue('EQUAL_WEIGHT')"></i>
        </p>
        <p>각 종목을 동일한 비중으로 투자합니다</p>
      </div>
      <div
        class="round-box"
        :class="{ on: updAmountTp === 'MKTCAP_WEIGHT' }"
        @click="updAmountTp = 'MKTCAP_WEIGHT'"
      >
        <p class="type-tit">
          큰 기업에 많이, 작은 기업은 조금만 투자
          <i class="icon--q" @click="openQue('MKTCAP_WEIGHT')"></i>
        </p>
        <p>시가총액 가중방식으로 투자합니다.</p>
      </div>
    </div>
  </div>
</template>

<script>
import ToggleSwitch from '@/components/base/ToggleSwitch.vue'

export default {
  name: 'Step02',
  components: { ToggleSwitch },

  data() {
    return {
      step: 2,
      list: [],
      year: 1,
      questionTp: '',
      isStatic: true,
      startDate: '',
      endDate: '',
      updDateTp: 'MONTHLY',
      updAmountTp: 'EQUAL_WEIGHT',
      maxStartDate: '',
      maxEndDate: '',
      minEndData: ''
    }
  },
  created() {
    this.startDate = this.getStartDate(this.year)
    this.endDate = this.getEndDate()
    this.maxStartDate = this.getStartDate(1)
    this.maxEndDate = this.getEndDate()
    this.minEndData = this.getEndDate()
  },
  watch: {
    year: function (val) {
      this.startDate = this.getStartDate(val)
      this.endDate = this.getEndDate()
      const data = {
        updDateTp: this.updDateTp,
        updAmountTp: this.updAmountTp,
        startDate: this.startDate,
        endDate: this.endDate
      }
      this.$store.commit('SET_STEP', data)
    },
    startDate: function () {
      const minEndDate = new Date(this.startDate)

      minEndDate.setFullYear(minEndDate.getFullYear() + 1)
      //this.minEndData = this.toStringByFormatting(minEndDate)

      const data = {
        updDateTp: this.updDateTp,
        updAmountTp: this.updAmountTp,
        startDate: this.startDate,
        endDate: this.endDate
      }
      this.$store.commit('SET_STEP', data)
    },
    updDateTp: function () {
      const data = {
        updDateTp: this.updDateTp,
        updAmountTp: this.updAmountTp,
        startDate: this.startDate,
        endDate: this.endDate
      }
      this.$store.commit('SET_STEP', data)
    },
    updAmountTp: function () {
      const data = {
        updDateTp: this.updDateTp,
        updAmountTp: this.updAmountTp,
        startDate: this.startDate,
        endDate: this.endDate
      }
      this.$store.commit('SET_STEP', data)
    }
  },
  computed: {
    isQuestion() {
      return this.questionTp
    },
    data: {
      get() {
        return this.$store.getters.getAddType02.updDateTp
      },
      set(updDateTp) {
        this.$store.commit('SET_STEP', { updDateTp })
      }
    },
    data2: {
      get() {
        return this.$store.getters.getAddType02.updAmountTp
      },
      set(updAmountTp) {
        this.$store.commit('SET_STEP', { updAmountTp })
      }
    }
  },
  methods: {
    setCheckboxVal(checkbox) {
      this.isStatic = checkbox
      if (this.isStatic) {
        this.startDate = this.getStartDate(this.year)
        this.endDate = this.getEndDate()
      }
    },
    openQue(type) {
      this.$Simplert.open(this.currentQuestion(type))
    },
    closeQuestionModal() {
      this.questionTp = ''
    },
    toStringByFormatting(source) {
      const year = source.getFullYear()
      const month = this.leftPad(source.getMonth() + 1)
      const day = this.leftPad(source.getDate())

      return [year, month, day].join('-')
    },
    getEndDate() {
      const startDate = new Date()
      startDate.setDate(startDate.getDate() - 1)
      return this.toStringByFormatting(startDate)
    },
    getStartDate(year) {
      const endDate = new Date()
      endDate.setDate(endDate.getDate() - 1)
      endDate.setFullYear(endDate.getFullYear() - year)
      return this.toStringByFormatting(endDate)
    },
    getNow() {
      const date = new Date()
      return this.toStringByFormatting(date)
    },
    leftPad(value) {
      if (value >= 10) {
        return value
      }

      return `0${value}`
    },
    currentQuestion(type) {
      let result = {}
      switch (type) {
        case 'MONTHLY':
          result = {
            title: '매월',
            message:
              `<div id="strategy-add-description">
                장점 : 회원님의 전략을 빠르게 반영하는 포트폴리오 구성이 가능해요.` +
              `<br/>` +
              `단점 : 종목을 자주 변경하기 때문에 매매 수수료가 많이 발생할 수 있어요.</div>`
          }
          break
        case 'QUARTERLY':
          result = {
            title: '매분기',
            message:
              `<div id="strategy-add-description">
                장점 : 종목 변경을 자주 하지 않아 매매 비용이 적고 손이 덜가요.` +
              `<br/>` +
              `단점 : 회원님 전략에 맞는 종목 업데이트가 느려요.</div>`
          }
          break
        case 'EQUAL_WEIGHT':
          result = {
            title: '골고루 투자',
            message:
              `<div id="strategy-add-description">
                장점 : 회원님의 전략에 충실한 포트폴리오 구성이 가능해요.` +
              `<br/>` +
              `단점 : 규모가 작은 기업에도 투자하게 되므로 위험이 커질 수 있어요.</div>`
          }
          break
        case 'MKTCAP_WEIGHT':
          result = {
            title: '큰 기업에 많이, 작은 기업은 조금만 투자',
            message:
              `<div id="strategy-add-description">
                장점 : 기업의 규모를 고려하므로 안정적이에요.` +
              `<br/>` +
              `단점 : 회원님 전략의 개성이 약해질 수 있어요.</div>`
          }
          break
      }
      return result
    }
  }
}
</script>
<style lang="scss" scoped>
#add-strategy-01-wrap {
  .round-box {
    border-radius: 14px;
    border: 1px solid #e8e8e8;
    background: #fff;
    padding: 16px 20px;
    p {
      color: #999;
      font-size: 12px;
    }
    .type-tit {
      color: #222;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 6px;
    }
    &.on {
      border: 1px solid #4a4ee7;
      .type-tit {
        color: #4a4ee7;
      }
    }
    &.add-button {
      text-align: center;
      > span {
        display: inline-flex;
        align-items: center;
        color: #666;
      }
    }
  }
  .regist-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
    .round-box {
      display: flex;
      align-items: center;

      span {
        color: #666;
        margin-right: 12px;
      }
      i {
        margin-left: auto;
      }
    }
  }
  .tab-list {
    display: flex;
    gap: 8px;
    li {
      display: flex;
      height: 42px;
      flex: 1;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      border: 1px solid #e8e8e8;
      &.on {
        border: 1px solid #4a4ee7;
        color: #4a4ee7;
      }
    }
  }
  .btn-wrap {
    display: flex;
    gap: 8px;
  }
}
.set-date-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 7px;

  .round-input {
    flex: 1;
    padding: 14px;
    width: 152px;
    position: relative;
    height: 42px;
    span {
      // position: absolute;
      // width: 100%;
      // height: 100%;
      // left: 0;
      // top: 50%;
      // transform: trans;
      // z-index: 9999;
    }
    input {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 99;
      border: none;
      width: 100%;
      height: 100%;
      background-color: transparent;
      opacity: 0;
      &::-webkit-calendar-picker-indicator {
        width: 100%;
        height: 100%;
        // display: none;
        // width: 100%;
        // height: 100%;
        background-color: transparent;
        z-index: 0;
        background-image: none;
      }
    }
  }
}
</style>
