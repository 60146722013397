import { axiosAuthInstance } from '@/store/axios'

const socialLogin = {
  data() {
    return {
      loginResult: {
        status: ''
      },
      email: '',
      socialId: '',
      socialType: '',
      userExisting: false
    }
  },
  methods: {
    doSocialLogin(socialType) {
      const apiPath = '/oauth/' + socialType
      const data = {
        code: this.$route.query.code
      }
      axiosAuthInstance
        .get(apiPath, { params: data })
        .then((response) => {
          this.loginResult.status = 'SUCCESS'

          const userInfo = this.$store.getters.getUser
          this.email = userInfo.email
          this.socialId = userInfo.socialId
          this.socialType = userInfo.socialType
          this.userExisting = userInfo.userExisting
          this.$store.commit('SET_USER_INFO', {})

          if (this.userExisting) {
            this.join()
          }

          const user = {
            email: response.email,
            nickName: response.nickName,
            socialId: response.id,
            socialType: response.socialType,
            storageAccess: response.storageAccess,
            isUser: response.isUser,
            userExisting: false
          }

          this.$store.commit('SET_USER_INFO', user)

          if (response.isUser) {
            this.$router.replace('/')
          } else {
            this.$router.replace({
              name: 'Step1'
            })
          }
          window.close()
        })
        .catch((error) => {
          this.loginResult.status = 'FAIL'
          console.log('error', error)
          if (error.response.status === 403) {
            this.$Simplert.open({
              title: '',
              message: error.response.data.errors[0],
              onClose: this.goMain
            })
          }
        })
    },
    goMain() {
      this.$router.push({ name: 'Main' })
    },
    join() {
      const parma = {
        email: this.email,
        socialId: this.socialId,
        socialType: this.socialType
      }

      if (this.socialId !== 0) {
        axiosAuthInstance
          .post('/user/social/connection', JSON.stringify(parma), {
            headers: {
              'Content-Type': `application/json`
            }
          })
          .then(() => {})
          .catch((error) => {
            console.log('error', error)
          })
      }
    },
    uuidv4() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
      )
    }
  }
}
export default socialLogin
