<template><div></div></template>
<script>
import SocialLogin from '@/mixins/socialLogin'
export default {
  name: 'KakaoLogin',
  mixins: [SocialLogin],
  data() {
    return {
      code: ''
    }
  },
  created() {
    this.doSocialLogin('kakao')
  },
  methods: {}
}
</script>
