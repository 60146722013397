// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.step-box[data-v-221677d9] {
  flex-shrink: 0;
  overflow: hidden;
}
.step-box .info[data-v-221677d9] {
  text-align: end;
  color: #999;
  font-size: 12px;
}
.step-box .info > span[data-v-221677d9] {
  color: #222;
  font-size: 12px;
}
.step-box .bar[data-v-221677d9] {
  position: relative;
  width: 100%;
  height: 2px;
  border-radius: 50px;
  background: #e8e8e8;
}
.step-box .bar .inner-slider[data-v-221677d9] {
  width: 100%;
  height: 100%;
  border-radius: 50px;
  background: #4a4ee7;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
