const state = {
  toggleAddStrategyModal: false
}

const getters = {
  isAddStrategy: (state) => state.toggleAddStrategyModal
}

const actions = {
  offToggleModal({ state }) {
    state.toggleAddStrategyModal = false
  }
}

const mutations = {
  toggleAddStrategy: (state, payload) => {
    state.toggleAddStrategyModal = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
