<template>
  <portal to="slide-up-modal" v-if="width > 1024"
    ><Transition duration="550" name="fade-to-slide">
      <div v-if="show" id="toggle-slider-modal" @click.stop="$emit('close')">
        <div class="inner modal-cont-wrap">
          <div class="modal-head">
            <div class="modal-top-area"></div>
            <slot name="header"></slot>
          </div>
          <div class="modal-body"><slot name="main"></slot></div>

          <div class="modal-footer"><slot name="button"></slot></div>
        </div>
      </div> </Transition
  ></portal>
  <Transition duration="550" name="fade-to-slide" v-else>
    <div v-if="show" id="toggle-slider-modal" @click.stop="$emit('close')">
      <div class="inner modal-cont-wrap">
        <div class="modal-head">
          <div class="modal-top-area"></div>
          <slot name="header"></slot>
        </div>
        <div class="modal-body"><slot name="main"></slot></div>

        <div class="modal-footer"><slot name="button"></slot></div>
      </div>
    </div>
  </Transition>
</template>
<script>
export default {
  name: 'Join',
  data() {
    return {
      show: false,
      width: window.innerWidth
    }
  },
  computed: {
    body() {
      return document.getElementsByTagName('body')[0]
    },
    targetApp() {
      return document.getElementById('main-app')
    },
    limitTime() {
      return '03:00'
    },
    isDisable() {
      return false
    }
  },
  mounted() {
    this.show = true
    this.body.classList.add('scrollLock')
    this.targetApp.classList.add('scrollLock')
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    this.body.classList.remove('scrollLock')
    this.targetApp.classList.remove('scrollLock')
    window.removeEventListener('resize', this.onResize)
  },
  distroyed() {
    this.body.classList.remove('scrollLock')
    this.targetApp.classList.remove('scrollLock')
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      this.width = window.innerWidth
    }
  }
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1024px) {
  #toggle-slider-modal {
    z-index: 9999999;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }
}
@media screen and (min-width: 1024px) {
  #toggle-slider-modal {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
}
#toggle-slider-modal {
  z-index: 9999999;

  .modal-cont-wrap {
    border-radius: 14px 14px 0px 0px;
    background: #fff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
    position: absolute;
    left: 0;
    bottom: -6.25rem;
    padding-bottom: 6.25rem;
    width: 100%;
    max-height: calc(var(--vh, 1vh) * 100);
    overflow-y: scroll;
    background-color: #ffffff;
    min-height: 0px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none; /* Chrome, Safari, Opera*/
    }
    .modal-head {
      padding-left: 24px;
      padding-right: 24px;
      z-index: 99;
      background-color: #fff;
      position: sticky;
      display: inline-block;
      width: 100%;
      left: 0;
      top: 0;
      padding-bottom: 16px;
      .modal-top-area {
        margin: 10px auto;
        width: 39px;
        height: 4px;
        border-radius: 50px;
        background: #f1f1f1;
      }
    }
    .modal-body {
      padding-left: 24px;
      padding-right: 24px;
      padding-top: 16px;
    }
    .modal-footer {
      padding: 16px 24px;
      margin-top: auto;
      position: sticky;
      bottom: 0;
      background-color: #fff;
    }
  }
}
</style>
