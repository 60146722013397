import { getPortfolioDtl, setPortfolio, getUserPortfolioList } from '@/api'

// Set current selected library as default library
const state = {
  portfolioDetail: {},
  portfolioList: [],
  portfolioUserLimitCount: 5
}

const getters = {
  getPortfolioDtl: (state) => state.portfolioDetail || {},
  getPortfolioList: (state) => state.portfolioList,
  getPortfolioUserLimitCount: (state) => state.portfolioUserLimitCount
}

const actions = {
  fetchPortfolioUserList({ commit }) {
    return new Promise((resolve, reject) => {
      getUserPortfolioList()
        .then((resp) => {
          console.log(resp)
          commit('SET_PORT_LIST', resp.userPortfolioList)
          commit(
            'SET_PORT_USER_LIMIT_COUNT',
            resp.userPortfolioLockerLimitCount
          )
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  fetchPortfolioDtl({ commit }) {
    return new Promise((resolve, reject) => {
      getPortfolioDtl()
        .then((resp) => {
          commit('SET_PORT_DTL', resp.data.result)
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  fetchProtfolioName() {
    return new Promise((resolve, reject) => {
      setPortfolio()
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

const mutations = {
  SET_PORT_DTL(state, payload) {
    state.portfolioDetail = payload
  },
  SET_PORT_LIST(state, payload) {
    state.portfolioList = payload
  },
  SET_PORT_USER_LIMIT_COUNT(state, payload) {
    state.portfolioUserLimitCount = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
