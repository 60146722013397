var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "main-wrap" } },
    [
      _vm.isShowRegisterModal
        ? _c("StrategyRegisterModal", {
            on: {
              close: function ($event) {
                _vm.isShowRegisterModal = false
              },
            },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "main-banner-section" },
        [
          _c("SliderPhotoBox", {
            attrs: { list: _vm.mainBanner, type: "banner" },
          }),
        ],
        1
      ),
      _c("TabBox", {
        key: _vm.tabBoxKey,
        attrs: {
          myPortfolioList: _vm.myPortfolioList,
          threeMonthProfitPortfolioTopList:
            _vm.threeMonthProfitPortfolioTopList,
          latestPortfolioList: _vm.latestPortfolioList,
        },
        on: { "click:added": _vm.openAddStrategy },
      }),
      _c("div", { staticClass: "bannr-section" }, [
        _c("div", { staticClass: "bannr-image-box" }, [
          _c("img", {
            attrs: {
              src: require("@/assets/images/bannr_main_01.svg"),
              alt: "",
            },
            on: { click: _vm.openStrategy },
          }),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "slider-section mb-16 shopping-list" },
        [
          _c("SliderContents", {
            attrs: { list: _vm.shoppingList, type: "shopping" },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function () {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "flex-just cont-head mb-14",
                        on: { click: _vm.goShopping },
                      },
                      [
                        _c("h4", [_vm._v("쇼핑")]),
                        _c("i", {
                          staticClass: "icon--arrow-move",
                          on: { click: _vm.goShopping },
                        }),
                      ]
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _c("div", { staticClass: "bannr-section" }, [
        _c(
          "div",
          { staticClass: "bannr-image-box", on: { click: _vm.goShopping } },
          [
            _c("img", {
              attrs: {
                src: require("@/assets/images/bannr_main_02.svg"),
                alt: "",
              },
            }),
          ]
        ),
      ]),
      _c("div", { staticClass: "box-section mb-48" }, [
        _c("div", { staticClass: "cont-box" }, [
          _c("div", { staticClass: "flex-just cont-box-head" }, [
            _c("h4", [_vm._v("인기글")]),
            _c("i", {
              staticClass: "icon--arrow-move",
              on: {
                click: function ($event) {
                  return _vm.goLounge(0)
                },
              },
            }),
          ]),
          _c(
            "ul",
            { staticClass: "popular-list" },
            _vm._l(
              _vm.mainPopularList,
              function (
                {
                  loungeId,
                  storageAccess,
                  loungeTitle,
                  loungeContents,
                  storageAccesses,
                },
                i
              ) {
                return _c(
                  "li",
                  {
                    key: i + "po",
                    on: {
                      click: function ($event) {
                        return _vm.goLounge(loungeId)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "popular-prof" }, [
                      _c("img", { attrs: { src: storageAccess, alt: "" } }),
                    ]),
                    _c("div", { staticClass: "popular-desc no-wrap-text" }, [
                      _c("p", { staticClass: "q-txt no-wrap-text" }, [
                        _vm._v(_vm._s(loungeTitle)),
                      ]),
                      _c("span", { staticClass: "q-sub-txt" }, [
                        _vm._v(_vm._s(loungeContents)),
                      ]),
                    ]),
                    _c("div", { staticClass: "popular-thumb" }, [
                      storageAccesses[0]
                        ? _c("img", {
                            attrs: { src: storageAccesses[0], alt: "" },
                          })
                        : _vm._e(),
                    ]),
                  ]
                )
              }
            ),
            0
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "slider-section mb-48" },
        [
          _c("SliderContents", {
            attrs: { list: _vm.comList, type: "rect" },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function () {
                  return [
                    _c("div", { staticClass: "flex-just cont-head mb-20" }, [
                      _c("h4", [_vm._v("추천 커뮤니티")]),
                    ]),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "slider-section mb-48" },
        [
          _c("SliderContents", {
            attrs: { list: _vm.rankList, type: "rank" },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function () {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "flex-just cont-head mb-20",
                        on: { click: _vm.goRank },
                      },
                      [
                        _c("h4", [_vm._v("이번달 수익률 배틀! TOP5")]),
                        _c("i", {
                          staticClass: "icon--arrow-move",
                          on: { click: _vm.goRank },
                        }),
                      ]
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "slider-section mb-16" },
        [
          _c("SliderContents", {
            attrs: { list: _vm.roungeList, type: "rounge" },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function () {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "flex-just cont-head",
                        on: {
                          click: function ($event) {
                            return _vm.goLounge(0)
                          },
                        },
                      },
                      [
                        _c("h4", [_vm._v("라운지")]),
                        _c("i", {
                          staticClass: "icon--arrow-move",
                          on: {
                            click: function ($event) {
                              return _vm.goLounge(0)
                            },
                          },
                        }),
                      ]
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "slider-section" },
        [
          _c("SliderContents", {
            attrs: { list: _vm.portList, type: "port" },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function () {
                  return [
                    _c("div", { staticClass: "flex-just cont-head pb-16" }, [
                      _c("h4", [_vm._v("더 많은 포트폴리오가 보고싶나요?")]),
                    ]),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }