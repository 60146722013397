// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#join-wrap[data-v-e0fa1584] {
  display: flex;
  flex-direction: column;
}
#join-wrap .page-title-section[data-v-e0fa1584] {
  margin-bottom: 20px;
}
#join-wrap .flex-just[data-v-e0fa1584] {
  gap: 6px;
  flex: 1;
}
#join-wrap .agree-list[data-v-e0fa1584] {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
#join-wrap .agree-list > li[data-v-e0fa1584] {
  padding: 0 16px;
  display: flex;
  gap: 12px;
}
#join-wrap .agree-list > li.round-box[data-v-e0fa1584] {
  margin-bottom: 4px;
  padding: 14px 16px;
  border-radius: 14px;
  background: #f5f5ff;
}
#join-wrap .agree-list > li.round-box .flex[data-v-e0fa1584] {
  gap: 6px;
}
#join-wrap .agree-list > li p[data-v-e0fa1584] {
  font-size: 14px;
}
#join-wrap > .base-btn.full[data-v-e0fa1584] {
  margin-top: auto;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
