// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#login-wrap[data-v-0e0d6e88] {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
#login-wrap .oauth-list[data-v-0e0d6e88] {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
#login-wrap .btn-wrap[data-v-0e0d6e88] {
  display: flex;
  gap: 8px;
}
#login-wrap .btn-wrap span[data-v-0e0d6e88] {
  font-size: 16px;
  font-weight: 600;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
