var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "examine" }, [
    _c(
      "div",
      { staticClass: "examine-tab wrapper mb-32" },
      _vm._l(_vm.tabList, function (tab, index) {
        return _c(
          "div",
          {
            key: `tab-${index}`,
            class: { active: tab.isActive },
            on: {
              click: function ($event) {
                return _vm.clickTab(index)
              },
            },
          },
          [_vm._v(" " + _vm._s(tab.text) + " ")]
        )
      }),
      0
    ),
    _c(
      "div",
      { staticClass: "list-body" },
      [
        _vm.tab === 0
          ? [
              _c("RowList", {
                attrs: {
                  rows: _vm.portfolios,
                  message: "심사를 신청한 포트폴리오가 없습니다.",
                },
                on: { rowClick: _vm.portfolioClick },
              }),
            ]
          : _vm._e(),
        _vm.tab === 1
          ? [
              _c("RowList", {
                attrs: {
                  rows: _vm.communities,
                  message: "심사를 신청한 커뮤니티가 없습니다.",
                },
                on: { rowClick: _vm.communityClick },
              }),
            ]
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }