// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/images/icon_arrow_next.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.round-desc-box[data-v-384ba50e] {
  border-radius: 14px;
  background: #f7f7f7;
  margin-bottom: 16px;
  padding: 16px 20px;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: 14px 20px;
  background-position: calc(100% - 16px) 50%;
  background-repeat: no-repeat;
}
.round-desc-box .puple-txt[data-v-384ba50e] {
  color: #4a4ee7;
  font-weight: 600;
}
.slide-box[data-v-384ba50e] {
  position: relative;
  aspect-ratio: 15/9;
  margin-left: -20px;
  margin-right: -20px;
}
.slide-box .inner-txt[data-v-384ba50e] {
  position: absolute;
  left: 16px;
  bottom: 24px;
}
.comm-list[data-v-384ba50e] {
  display: flex;
  flex-direction: column;
  gap: 14px;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
