import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
import ImageViewModal from '@/components/common/modal/ImageViewModal.vue'
import { Simplert } from 'vue2-simplert-plugin'
import 'vue2-simplert-plugin/dist/vue2-simplert-plugin.min.css'

import VueApexCharts from 'vue-apexcharts'
import PortalVue from 'portal-vue'

Vue.use(PortalVue)
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)
Vue.use(Simplert)
// import VueAwesomeSwiper from 'vue-awesome-swiper'
// import 'swiper/css/swiper.css'
// import DatePicker from 'vue2-datepicker'

import 'vue2-datepicker/locale/ko'

import { directive as onClickaway } from 'vue-clickaway'
import axios from '@/store/axios'

// Vue.use(DatePicker)

// Components

// Vue.component('Modal', Modal)
// Vue.component('Pagination', Pagination)
// Vue.component('Sidebar', Sidebar)
Vue.directive('on-clickaway', onClickaway)
// Vue.component('VueIntlNumberformat', VueIntlNumberformat)

// #### Auto component registration of Base components ####
const requireComponent = require.context(
  // The relative path of the components folder
  './components/base',
  // Whether or not to look in subfolders
  false,
  // The regular expression used to match base component filenames
  /Base[A-Z]\w+\.(vue|js)$/
)
// Vue.use(VueAwesomeSwiper)
requireComponent.keys().forEach((fileName) => {
  // Get component config
  const componentConfig = requireComponent(fileName)

  // Get PascalCase name of component
  const componentName = upperFirst(
    camelCase(
      // Gets the file name regardless of folder depth
      fileName
        .split('/')
        .pop()
        .replace(/\.\w+$/, '')
    )
  )

  // Register component globally
  Vue.component(
    componentName,
    // Look for the component options on `.default`, which will
    // exist if the component was exported with `export default`,
    // otherwise fall back to module's root.
    componentConfig.default || componentConfig
  )
})
Vue.component('ImageViewModal', ImageViewModal)

Vue.config.productionTip = false
Vue.prototype.$axios = axios

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
