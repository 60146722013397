// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.set-date-box[data-v-1ecf41c0] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
}
.set-date-box .round-input[data-v-1ecf41c0] {
  flex: 1;
  padding: 14px;
  width: 152px;
  position: relative;
  height: 42px;
}
.set-date-box .round-input input[data-v-1ecf41c0] {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;
  border: none;
  width: 100%;
  height: 100%;
  background-color: transparent;
  opacity: 0;
}
.set-date-box .round-input input[data-v-1ecf41c0]::-webkit-calendar-picker-indicator {
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 0;
  background-image: none;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
