<template>
  <div class="profile-edit">
    <ul class="profile-edit-form">
      <li>
        <label>닉네임</label>
        <input
          type="text"
          :class="{ error: nickNameCheck }"
          v-model="user.nickName"
        />
        <span class="error-message" v-if="nickNameCheck"
          >이미 사용중인 닉네임이에요</span
        >
      </li>
      <li>
        <label>이메일 주소</label>
        <input type="text" :placeholder="user.email" disabled />
      </li>
      <li>
        <label>휴대폰 번호</label>
        <input type="text" :placeholder="user.phoneNumber" disabled />
      </li>
      <li>
        <div>연령대</div>
        <select v-model="user.age">
          <option
            v-for="(d, i) in ageOptions"
            :key="i + 'ageOption'"
            :value="d.age"
          >
            {{ d.ageName }}
          </option>
        </select>
      </li>
      <li>
        <div>성별</div>
        <select v-model="user.gender">
          <option
            v-for="(d, i) in genderOptions"
            :key="i + 'genderOption'"
            :value="d.gender"
          >
            {{ d.genderName }}
          </option>
        </select>
      </li>
    </ul>
    <div class="section">
      <button class="base-btn full cl-pri" @click="userUpdate">
        <span>수정 완료</span>
      </button>
    </div>
  </div>
</template>

<script>
import { axiosAuthInstance } from '@/store/axios'

export default {
  data() {
    return {
      user: {},
      nickNameCheck: false,
      genderOptions: [
        {
          genderName: '없음',
          gender: 'UNKNOWN'
        },
        {
          genderName: '남성',
          gender: 'MALE'
        },
        {
          genderName: '여성',
          gender: 'FEMALE'
        }
      ],
      ageOptions: [
        {
          ageName: '없음',
          age: 'UNKNOWN'
        },
        {
          ageName: '10대',
          age: 'AGE_10S'
        },
        {
          ageName: '20대',
          age: 'AGE_20S'
        },
        {
          ageName: '30대',
          age: 'AGE_30S'
        },
        {
          ageName: '40대',
          age: 'AGE_40S'
        },
        {
          ageName: '50대',
          age: 'AGE_50S'
        },
        {
          ageName: '60대',
          age: 'AGE_60S'
        },
        {
          ageName: '70대',
          age: 'AGE_70S'
        },
        {
          ageName: '80대 이상',
          age: 'AGE_80S'
        }
      ]
    }
  },
  created() {
    axiosAuthInstance
      .get('/user', {})
      .then((response) => {
        this.user = response

        for (const social of this.user.socialConnections) {
          console.log(social)
          switch (social.socialType) {
            case 'GOOGLE':
              this.google = true
              this.$refs.googleCheckbox.updateCheckbox(true)
              break
            case 'NAMVER':
              this.naver = true
              this.$refs.naverCheckbox.updateCheckbox(true)
              break
            case 'KAKAO':
              this.kakao = true
              this.$refs.kakaoCheckbox.updateCheckbox(true)
              break
          }
        }
      })
      .catch((error) => {
        console.log('error', error)
      })
  },
  methods: {
    userUpdate() {
      const param = {
        nickName: this.user.nickName,
        phoneNumber: this.user.phoneNumber,
        age: this.user.age,
        gender: this.user.gender
      }

      axiosAuthInstance
        .put('/user', JSON.stringify(param), {
          headers: {
            'Content-Type': `application/json`
          }
        })
        .then((response) => {
          this.$store.commit('SET_USER_INFO', { nickName: this.user.nickName })
          this.$router.push({ name: 'Profile' })
          console.log(response)
        })
        .catch((error) => {
          if (error.response.data.message === 'DUPLICATION_NICK_NAME') {
            this.nickNameCheck = true
          }
          console.log('error', error)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.profile-edit {
  padding: 0 4.4444444%;
  font-family: Pretendard;
  &-form {
    li {
      width: 100%;
      margin-bottom: 20px;
      label,
      div {
        display: block;
        color: #999;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 12px */
        letter-spacing: -0.24px;
        margin-bottom: 8px;
      }
      input,
      select {
        border-radius: 14px;
        background: #fff;
        border: 1px solid #e8e8e8;
        width: 100%;
        height: 50px;
        box-sizing: border-box;
        padding: 0px 14px;
        font-size: 16px;
      }
      input:disabled {
        background: #f7f7f7;
      }
      input.error {
        border: 1px solid #d74343;
      }
      .error-messag {
        display: none;
      }
      input.error + .error-message {
        display: block;
        color: #d74343;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 12px */
        letter-spacing: -0.24px;
        margin-top: 8px;
      }
    }
  }
}
</style>
