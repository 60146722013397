const feildSet = {
  thumb: 'https://www.womennews.co.kr/news/photo/202201/219795_358815_013.jpg',
  userThumb: 'https://dimg.donga.com/wps/NEWS/IMAGE/2014/02/04/60565256.2.jpg',
  title:
    '제목제목제목제목제목제목제목제목제목제목제목제목제목제목제목제목제목제목제목제목제목제목제목제목',
  desc: '본문본문본문본문본문본문본문본문본문본문본문본문본문본문본문본문본문본문본문본문본문본문본문본문본문',
  name: '김퀀트'
}
function mappingSampleList(length, feild) {
  const list = Array.from({ length }, (v, i) => i + 1)
  const returnData = {}
  Object.keys(feild).forEach((e) => {
    if (feildSet[feild[e]]) {
      returnData[e] = feildSet[feild[e]]
    }
  })

  return list.map((e) => {
    return {
      ...feild,
      ...returnData,
      rankNo: e,
      seq: e
    }
  })
}
export const mainShoppingList = mappingSampleList(5, {
  thumb: 'thumb',
  title: 'title',
  seq: 'seq'
})

export const mainCommunityList = mappingSampleList(5, {
  thumb: 'thumb',
  title: 'title',
  seq: 'seq'
})

export const mainRankList = mappingSampleList(5, {
  thumb: 'thumb',
  title: 'title',
  percent: '51',
  seq: 'seq'
})
export const mainRoungeList = mappingSampleList(5, {
  thumb: 'thumb',
  title: 'title',
  seq: 'seq',
  profilThumb: 'userThumb',
  content: 'desc',
  typeNm: '시황·종목'
})
export const mainPopularList = mappingSampleList(5, {
  thumb: 'thumb',
  title: 'title',
  seq: 'seq',
  profilThumb: 'userThumb',
  name: '유저정보1',
  content: 'desc',
  typeNm: '시황·종목'
})
export const mainMorePortpolio = [
  {
    title: 'title',
    seq: 'seq',
    profilThumb: 'userThumb',
    name: '퀀트졸업생',
    typeTp: 'exp',
    typeNm: '성장'
  },
  {
    title: 'title',
    seq: 'seq',
    profilThumb: 'userThumb',
    name: '퀀트졸업생',
    typeTp: 'val',
    typeNm: '가치'
  },
  {
    title: 'title',
    seq: 'seq',
    profilThumb: 'userThumb',
    name: '퀀트졸업생',
    typeTp: 'moe',
    typeNm: '모멘텀'
  },
  {
    title: 'title',
    seq: 'seq',
    profilThumb: 'userThumb',
    name: '퀀트졸업생',
    typeTp: 'money',
    typeNm: '배당'
  },
  {
    title: 'title',
    seq: 'seq',
    profilThumb: 'userThumb',
    name: '퀀트졸업생',
    typeTp: 'safe',
    typeNm: '안정'
  },
  {
    title: 'title',
    seq: 'seq',
    profilThumb: 'userThumb',
    name: '퀀트졸업생',
    typeTp: 'big',
    typeNm: '우량'
  }
]

export const rankingDataList = mappingSampleList(10, {
  thumb: 'userThumb',

  title: 'title',
  seq: 'seq',
  profNm: '김떙떙',
  typeNm: '시황·종목',
  percent: '100.88'
})

export const communityDataList = mappingSampleList(5, {
  thumb: 'thumb',
  title: 'title',
  seq: 'seq',
  desc: 'desc'
})
export const communityBnnrList = mappingSampleList(5, {
  thumb: 'thumb',
  title: 'title',
  seq: 'seq',
  desc: 'desc'
})
export const communityDataDtl = {
  name: '이름명',
  title: '입문, 초급자를 위한 커뮤니티',
  seq: 1,
  thumbList: [
    { thumb: feildSet.thumb },
    { thumb: feildSet.thumb },
    { thumb: feildSet.thumb },
    { thumb: feildSet.thumb },
    { thumb: feildSet.thumb }
  ],
  desc: `<p>
  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iste numquam
  ex tenetur ipsum neque nesciunt vel dolores. Quod quam alias consequatur
  commodi incidunt omnis suscipit assumenda error nisi! Nihil,
  accusantium.
</p>`,
  channelUrl: 'www.naver.com'
}

export const commChoosedPhotoList = mappingSampleList(5, {
  thumb: 'thumb',
  title: 'title'
})
export const searchList = mappingSampleList(20, {
  code: 'A659300',
  company: '삼성전자',
  store: '코스피'
})
export const portfolioList = mappingSampleList(5, {
  name: '포트폴리오이름',
  date: '2023.01.30'
})

export const makeResultData = {
  styleTp: 'exp',
  styleNm: '성장형',

  styleDetail: {
    stature: 10,
    value: 10,
    momentum: 10,
    allocation: 10,
    safety: 10,
    superiority: 10
  },
  CAGR: 15,
  MDD: 15,
  volatility: 25.4,
  beta: 25.4,
  sharp: 25.4,
  average: 8,
  userStrategy: 25.4,
  kospi: 25.4,
  kosdaq: 25.4,
  compoDate: '2023.06.01',
  compoList: [
    { seq: 1, company: '삼성전자', amount: 1000000, rate: 100 },
    { seq: 2, company: '삼성전자', amount: 1000000, rate: 100 },
    { seq: 3, company: '삼성전자', amount: 1000000, rate: 100 },
    { seq: 4, company: '삼성전자', amount: 1000000, rate: 100 }
  ]
}
export const portfolioDtl = {
  styleTp: 'exp',
  styleNm: '성장형',

  styleDetail: {
    stature: 10,
    value: 10,
    momentum: 10,
    allocation: 10,
    safety: 10,
    superiority: 10
  },
  CAGR: 15,
  MDD: 15,
  volatility: 25.4,
  beta: 25.4,
  sharp: 25.4,
  average: 8,
  userStrategy: 25.4,
  kospi: 25.4,
  kosdaq: 25.4,
  compoDate: '2023.06.01',
  back: {
    startDate: '2020.1.1',
    endDate: '2023.6.30',
    CAGR: 15,
    MDD: 15,
    volatility: 25.4,
    userStrategy: 25.4,
    kospi: 25.4,
    kosdaq: 25.4
  },
  PER: 80,
  ROE: 80,
  PBR: 80,

  compoList: [
    { seq: 1, company: '삼성전자', amount: 1000000, rate: 100 },
    { seq: 2, company: '삼성전자', amount: 1000000, rate: 100 },
    { seq: 3, company: '삼성전자', amount: 1000000, rate: 100 },
    { seq: 4, company: '삼성전자', amount: 1000000, rate: 100 }
  ]
}

export const mypageUser = {
  userName: '김퀀트',
  profil: '',
  email: 'xxxxx@gamil.com',
  phone: '010 0000 0000',
  age: null,
  sex: null,
  google: false,
  kakao: false,
  naver: false,
  push: false,
  subscribe: false,
  make: false,
  rounge: false
}

export const mypageQna = mappingSampleList(5, {
  thumb: 'thumb',
  title: 'title',
  content: 'desc',
  date: '2023.7.26 00:00:00',
  seq: 'seq'
}).map((e) => {
  return {
    ...e,
    thumb: [e.thumb, e.thumb, e.thumb, e.thumb],
    answerYn: e.seq % 2 === 0 ? 'N' : 'Y',
    answerDate: '2023.7.26 00:00:00',
    answer:
      e.seq % 2 === 0
        ? ''
        : '답변내용답변내용답변내용답변내용답변내용답변내용답변내용답변내용답변내용답변내용답변내용답변내용답변내용답변내용답변내용답변내용답변내용'
  }
})

export const mypagePosting = mappingSampleList(5, {
  thumb: 'thumb',
  title: 'title',
  content: 'desc',
  date: '2023.7.26',
  seq: 'seq',

  like: 4,
  coment: ['dwa', 'dwa', 'dwa'],
  isLike: true
}).map((e) => {
  return {
    ...e,
    thumb: [e.thumb, e.thumb, e.thumb, e.thumb],
    answerYn: e.seq % 2 === 0 ? 'N' : 'Y',
    answerDate: '2023.7.26 00:00:00',
    answer:
      e.seq % 2 === 0
        ? ''
        : '답변내용답변내용답변내용답변내용답변내용답변내용답변내용답변내용답변내용답변내용답변내용답변내용답변내용답변내용답변내용답변내용답변내용'
  }
})
export const mypageComent = mappingSampleList(5, {
  thumb: 'thumb',
  title: 'title',
  content: 'desc',
  date: '2023.7.26',
  seq: 'seq',

  like: 4,
  coment: ['dwa', 'dwa', 'dwa'],
  isLike: true
}).map((e) => {
  return {
    ...e,
    thumb: [e.thumb, e.thumb, e.thumb, e.thumb],
    answerYn: e.seq % 2 === 0 ? 'N' : 'Y',
    answerDate: '2023.7.26 00:00:00',
    answer:
      e.seq % 2 === 0
        ? ''
        : '답변내용답변내용답변내용답변내용답변내용답변내용답변내용답변내용답변내용답변내용답변내용답변내용답변내용답변내용답변내용답변내용답변내용'
  }
})

export const mypageTerms = mappingSampleList(5, {
  date: '2023.12.30',
  body: `
  약관내용
  <h3>제1장</h3>
  <h3>제2장</h3>
  <p>
    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iste numquam
    ex tenetur ipsum neque nesciunt vel dolores. Quod quam alias consequatur
    commodi incidunt omnis suscipit assumenda error nisi! Nihil,
    accusantium.
  </p>
  <h3>제3장</h3>
  <p>
    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iste numquam
    ex tenetur ipsum neque nesciunt vel dolores. Quod quam alias consequatur
    commodi incidunt omnis suscipit assumenda error nisi! Nihil,
    accusantium.
  </p>
  `
})

export const mypagePrivacy = {
  date: '2023.12.30',
  body: `
  개인정보
  <h3>제1장</h3>
  <h3>제2장</h3>
  <p>
    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iste numquam
    ex tenetur ipsum neque nesciunt vel dolores. Quod quam alias consequatur
    commodi incidunt omnis suscipit assumenda error nisi! Nihil,
    accusantium.
  </p>
  <h3>제3장</h3>
  <p>
    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iste numquam
    ex tenetur ipsum neque nesciunt vel dolores. Quod quam alias consequatur
    commodi incidunt omnis suscipit assumenda error nisi! Nihil,
    accusantium.
  </p>
  `
}

export const mypageProductsSell = mappingSampleList(5, {
  month: '9',
  titleList: ['상품명', '판매수량', '수수료', '판매총액', '수익금'],
  rowData: [
    {
      title: '상품명1',
      sellCount: 5,
      moneyCount: 10,
      totalMoney: 100000,
      myMoney: 50000
    },
    {
      title: '상품명2',
      sellCount: 5,
      moneyCount: 10,
      totalMoney: 100000,
      myMoney: 50000
    },
    {
      title: '상품명3',
      sellCount: 5,
      moneyCount: 10,
      totalMoney: 100000,
      myMoney: 50000
    }
  ]
}).map((e) => {
  return { ...e, month: `${e.seq + 1}` }
})

export const storageSubsList = mappingSampleList(10, {
  title: 'title',
  startDate: '2023.07.01',
  endDate: '2024.01.01'
})

export const roungeBoardList = mappingSampleList(5, {
  thumb: 'thumb',
  title: 'title',
  content: 'desc',
  date: '7분전',
  name: '김퀀트',
  coment: ['', ''],
  seq: 'seq',
  like: 11,
  useLike: true
}).map((e) => {
  return {
    ...e,
    thumb: [e.thumb, e.thumb, e.thumb, e.thumb]
  }
})

export const roungeBoardDetail = {
  thumb: roungeBoardList[0].thumb,
  title: roungeBoardList[0].title,
  content: roungeBoardList[0].content,
  date: roungeBoardList[0].date,
  name: roungeBoardList[0].name,
  seq: roungeBoardList[0].seq,
  like: 11,
  useLike: true,
  coment: [
    ...roungeBoardList.map((e) => {
      return { ...e, coment: [...roungeBoardList.slice(0, 3)] }
    })
  ]
}
