// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.close-wrap[data-v-4f1eedf3] {
  height: 48px;
}
.full-image-popup[data-v-4f1eedf3] {
  z-index: 9899999999;
  padding: 0 16px;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.image-box[data-v-4f1eedf3] {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.image-box .image[data-v-4f1eedf3] {
  height: 380px;
  display: flex;
  justify-content: center;
}
.image-box .image img[data-v-4f1eedf3] {
  border-radius: 14px;
  overflow: hidden;
  width: 328px;
  height: 328px;
}
[data-v-4f1eedf3] .swiper-container {
  height: 100%;
  position: relative;
}
[data-v-4f1eedf3] .swiper-container .swiper-pagination .swiper-pagination-bullet {
  width: 6px;
  height: 6px;
  border-radius: 12px;
  background-color: #fff;
  opacity: 0.4;
}
[data-v-4f1eedf3] .swiper-container .swiper-pagination .swiper-pagination-bullet-active {
  width: 18px;
  opacity: 1;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
