var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "layer" },
    [
      _c(
        "div",
        {
          staticClass: "fixed-head",
          class: { transparent: _vm.useTrans, "no-sticky": _vm.useNotSticky },
        },
        [
          _c("i", {
            staticClass: "icon--arrow-back",
            class: { icon_w: _vm.useTrans },
            on: { click: _vm.back },
          }),
          _c(
            "h5",
            { staticClass: "page-tit", class: { color_w: _vm.useTrans } },
            [_vm._v(_vm._s(_vm.pageTitle))]
          ),
          _c("router-link", { attrs: { to: { name: "Edit" } } }, [
            _vm.userUpdateBtn
              ? _c("span", { staticClass: "my-info-update" }, [_vm._v("수정")])
              : _vm._e(),
          ]),
        ],
        1
      ),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }