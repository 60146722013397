var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "bot-menu" } },
    [
      _vm.isShowRegisterModal
        ? _c("StrategyRegisterModal", {
            on: {
              close: function ($event) {
                _vm.isShowRegisterModal = false
              },
            },
          })
        : _vm._e(),
      _c("ul", [
        _c(
          "li",
          [
            _c("router-link", { attrs: { to: { name: "Main" } } }, [
              _c("i", {
                staticClass: "icon--menu-home",
                class: { on: _vm.current === "Main" },
              }),
              _c("span", [_vm._v("홈")]),
            ]),
          ],
          1
        ),
        _c(
          "li",
          [
            _c("router-link", { attrs: { to: { name: "Rounge" } } }, [
              _c("i", {
                staticClass: "icon--menu-rounge",
                class: { on: _vm.current === "Rounge" },
              }),
              _c("span", [_vm._v("라운지")]),
            ]),
          ],
          1
        ),
        _c(
          "li",
          { staticClass: "middle", on: { click: _vm.openStrategy } },
          [
            _c("router-link", { attrs: { to: {} } }, [
              _c("i", {
                staticClass: "icon--menu-make",
                class: { on: _vm.current === "AddStrategy02" },
              }),
              _c("span", [_vm._v("전략만들기")]),
            ]),
          ],
          1
        ),
        _c(
          "li",
          [
            _c("router-link", { attrs: { to: { name: "Shopping" } } }, [
              _c("i", {
                staticClass: "icon--menu-shopping",
                class: { on: _vm.current === "Shopping" },
              }),
              _c("span", [_vm._v("쇼핑")]),
            ]),
          ],
          1
        ),
        _c(
          "li",
          [
            _c("router-link", { attrs: { to: { name: "MyPage" } } }, [
              _c("i", {
                staticClass: "icon--menu-my",
                class: { on: _vm.current === "MyPage" },
              }),
              _c("span", [_vm._v("마이")]),
            ]),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }