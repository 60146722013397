var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "board-item", class: _vm.active ? "on" : "" },
    [
      _c("div", { staticClass: "flex-between" }, [
        _c("div", { staticClass: "prof-box" }, [
          _c("div", { staticClass: "thumb mr-8" }, [
            _vm.data.storageAccess
              ? _c("img", { attrs: { src: _vm.data.storageAccess, alt: "" } })
              : _c("i", { staticClass: "icon--profil-temp" }),
          ]),
          _c("span", { staticClass: "user-name" }, [
            _vm._v(_vm._s(_vm.data.nickName)),
          ]),
          _c("span", { staticClass: "date ml-4" }, [
            _vm._v(" " + _vm._s(_vm.data.createdDate) + " "),
          ]),
        ]),
        _c("ul", { staticClass: "status-box" }, [
          _c("li", [
            _c("i", {
              staticClass: "icon--coment",
              on: {
                click: function ($event) {
                  return _vm.$emit("toggle:more", _vm.data.loungeId)
                },
              },
            }),
            _c("span", { staticClass: "number" }, [
              _vm._v(_vm._s(_vm.data.commentCount)),
            ]),
          ]),
          !_vm.isDetail
            ? _c("li", [
                _c("i", {
                  staticClass: "icon--dot",
                  on: {
                    click: function ($event) {
                      return _vm.$emit(
                        "toggle:edit",
                        _vm.data.loungeId,
                        _vm.data.isOwner
                      )
                    },
                  },
                }),
              ])
            : _vm._e(),
        ]),
      ]),
      _c("p", { staticClass: "board-title no-wrap-text" }, [
        _vm._v(_vm._s(_vm.data.loungeTitle)),
      ]),
      _c("p", { staticClass: "board-desc no-wrap-text-2" }, [
        _vm._v(" " + _vm._s(_vm.data.loungeContents) + " "),
      ]),
      _c("ThumbBox", { attrs: { thumb: _vm.data.storageAccesses } }),
      !_vm.active
        ? _c(
            "div",
            {
              staticClass: "more-text",
              class: _vm.active ? "" : "show",
              on: {
                click: function ($event) {
                  return _vm.$emit("toggle:more", _vm.data.loungeId)
                },
              },
            },
            [_vm._v(" 더 보기 ")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }