var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "full-image-popup" }, [
    _c("div", { staticClass: "close-wrap flex-end" }, [
      _c("i", {
        staticClass: "icon--close-w",
        on: {
          click: function ($event) {
            return _vm.$emit("close")
          },
        },
      }),
    ]),
    _c("div", { staticClass: "image-box" }, [
      _c(
        "div",
        { staticClass: "wrap" },
        [
          _c(
            "swiper",
            { attrs: { "slides-per-view": "1", loop: true, pagination: true } },
            _vm._l(_vm.list, function (data, idx) {
              return _c("swiper-slide", { key: idx }, [
                _c("div", { staticClass: "image" }, [
                  _c("img", { attrs: { src: data, alt: "" } }),
                ]),
              ])
            }),
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }