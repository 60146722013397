<template>
  <div id="login-wrap" class="white-layer">
    <template v-if="hasAuthList.length === 0">
      <div class="page-title-section">
        <h4>SNS 계정으로<br />간편하게 로그인 하세요</h4>
        <p class="q-sub-txt">SNS 계정으로 간편하게 로그인 할 수 있어요.</p>
      </div>
      <ul class="oauth-list">
        <PlatformAuthBtn
          v-for="({ type, text }, i) in useOauthTypes"
          :key="`${i}_oauth_btn`"
          :type="type"
          :text="text"
          @useClick="goAuthBtn"
        />
      </ul>
    </template>
    <template v-else>
      <div class="page-title-section">
        <h4>SNS 계정으로<br />간편하게 로그인 하세요</h4>
        <p class="q-sub-txt">SNS 계정으로 간편하게 로그인 할 수 있어요.</p>
      </div>
      <ul class="oauth-list">
        <PlatformAuthBtn
          v-for="({ type, text }, i) in hasAuthList"
          :key="`${i}_oauth_btn`"
          :type="type"
          :text="text"
          @useClick="goAuthBtn"
        />
      </ul>
      <ul class="btn-wrap">
        <button
          v-if="!isBeforeLogin"
          class="base-btn full cl-pri"
          @click="goNotAccount"
        >
          <span>본인 계정이 아니에요</span>
        </button>
      </ul>
    </template>
  </div>
</template>

<script>
import PlatformAuthBtn from '@/components/button/PlatformAuthBtn.vue'
import { axiosAuthInstance } from '@/store/axios'

export default {
  name: 'Login',
  components: { PlatformAuthBtn },
  data() {
    return {
      isBeforeLogin: true,
      isUserExisting: false,
      phoneNumber: '',
      hasAuthList: []
    }
  },
  created() {
    const user = this.$store.getters.getUser

    if (user.isUser) {
      this.$router.replace({ name: 'Main' })
    }

    this.isUserExisting = user.userExisting
    this.isBeforeLogin = !this.isUserExisting
    if (this.isUserExisting) {
      this.phoneNumber = user.phoneNumber
      const userSocialsList = user.userSocials
      console.log(userSocialsList)
      userSocialsList.forEach((userSocial) => {
        this.hasAuthList.push({
          type: userSocial.socialType.toLowerCase(),
          text: this.maskingEmail(userSocial.socialEmail)
        })
      })

      if (!this.isUserExisting) {
        this.$store.commit('SET_USER_INFO', {})
      }
    }
  },
  computed: {
    useOauthTypes() {
      if (this.isBeforeLogin)
        return [
          // { type: 'kakao', text: '카카오 로그인' },
          { type: 'google', text: '구글 로그인' }
          // { type: 'naver', text: '네이버 로그인' }
        ]
      return this.hasAuthList
    }
  },
  methods: {
    goAuthBtn(payload) {
      axiosAuthInstance
        .get('/oauth/' + payload + '/url', {})
        .then((response) => {
          window.location.href = response.url
        })
        .catch((error) => {
          console.log('error', error)
        })
    },
    goNotAccount() {
      axiosAuthInstance
        .delete('/user/disconnectingNumbers', {
          headers: {
            'Content-Type': `application/json`,
            'phone_number': this.phoneNumber
          }
        })
        .then(() => {
          this.$router.push({ name: 'Step3' })
        })
        .catch((error) => {
          console.log('error', error)
        })
    },
    goAuthNext() {
      this.$router.push({ name: 'Authority' })
    },
    maskingEmail(email) {
      //const mask = '*'.repeat(email.split('@')[0].length - 3)
      //return email[0] + mask + email.slice(mask.length + 1, email.length)
      let originStr = email
      let emailStr = originStr.match(
        /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi
      )
      let strLength

      if (
        this.checkNull(originStr) == true ||
        this.checkNull(emailStr) == true
      ) {
        return originStr
      } else {
        strLength = emailStr.toString().split('@')[0].length - 4
        return originStr
          .toString()
          .replace(new RegExp('.(?=.{0,' + strLength + '}@)', 'g'), '*')
      }
    },
    checkNull: function (str) {
      if (typeof str == 'undefined' || str == null || str == '') {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#login-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .oauth-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .btn-wrap {
    display: flex;
    gap: 8px;
    span {
      font-size: 16px;
      font-weight: 600;
    }
  }
}
</style>
