// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-v-340010e4] .full-popup {
  padding: 8px 0;
}
.discount[data-v-340010e4] {
  color: #d74343;
  font-size: 20px;
  font-weight: 600;
}
.product-detail-wrap[data-v-340010e4] {
  background-color: #f1f1f1;
}
.section[data-v-340010e4] {
  background-color: #fff;
  padding: 32px 16px;
}
.slide-box[data-v-340010e4] {
  position: relative;
  aspect-ratio: 1;
}
.slide-box .inner-txt[data-v-340010e4] {
  position: absolute;
  left: 16px;
  bottom: 24px;
}
.product-info[data-v-340010e4] {
  padding: 32px 16px;
}
.product-info .title[data-v-340010e4] {
  color: #666;
  font-size: 12px;
  line-height: 140%;
  margin-bottom: 5px;
}
.product-info .desc[data-v-340010e4] {
  margin-top: 8px;
  margin-bottom: 12px;
}
.product-info .price[data-v-340010e4] {
  font-size: 22px;
  font-weight: 600;
  color: #222;
}
.product-info .price .discount[data-v-340010e4] {
  color: #d74343;
  font-size: 22px;
  font-weight: 600;
}
.product-info .before-info span[data-v-340010e4] {
  color: #999;
  font-size: 14px;
}
.product-info .before-info .before-price[data-v-340010e4] {
  text-decoration: line-through;
}
.product-info .recent-percent[data-v-340010e4] {
  display: flex;
  align-items: center;
  gap: 4px;
}
.product-info .recent-percent span[data-v-340010e4] {
  font-size: 14px;
  color: #666;
}
.product-info .recent-percent .percent[data-v-340010e4] {
  color: #d74343;
  font-weight: 600;
}
.product-info .round-date[data-v-340010e4] {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 215px;
  height: 25px;
  border-radius: 50px;
  background: #f5f5ff;
  gap: 6px;
}
.product-info .round-date span[data-v-340010e4] {
  color: #666;
  font-size: 12px;
}
.sec-head h3[data-v-340010e4] {
  font-size: 18px;
}
.sec-head .status-box strong[data-v-340010e4] {
  color: #4a4ee7;
  font-size: 20px;
  font-weight: 600;
}
.sec-head .rate-box strong[data-v-340010e4] {
  color: #d74343;
  font-size: 20px;
  font-weight: 600;
}
.sec-head span[data-v-340010e4] {
  padding-left: 8px;
  color: #999;
  font-size: 12px;
  font-weight: 400;
}
.range-section .on-txt[data-v-340010e4],
.range-section .on-price[data-v-340010e4] {
  color: #4a4ee7;
}
.range-section .on-price[data-v-340010e4] {
  margin: 20px 0 8px;
  font-weight: 600;
  font-size: 18px;
}
.range-slide-box .slide-mark[data-v-340010e4] {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}
.range-slide-box .slide-mark > span[data-v-340010e4] {
  color: #999;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 12px */
  letter-spacing: -0.24px;
  min-width: 24px;
  text-align: center;
}
[data-v-340010e4] .vue-slider-rail {
  background: #e8e8e8;
}
[data-v-340010e4] .vue-slider-process {
  background-color: #444444;
}
[data-v-340010e4] .vue-slider-dot-handle {
  background-color: #fff;
  stroke-width: 1px;
  border: 1px solid #e8e8e8;
  filter: drop-shadow(1px 4px 9px rgba(0, 0, 0, 0.12));
}
.compo-section[data-v-340010e4] {
  padding: 40px 0 0;
}
.compo-section .sec-head[data-v-340010e4] {
  padding: 0px 16px;
}
.compo-section .compo-date[data-v-340010e4] {
  color: #999;
  font-size: 12px;
}
.compo-section .grid-table .table-head li[data-v-340010e4] {
  font-weight: 500;
}
.compo-section .grid-table .table-head[data-v-340010e4],
.compo-section .grid-table .table-list[data-v-340010e4] {
  padding-left: 24px;
  padding-right: 24px;
  grid-template-columns: 35% 30% 15% 20%;
}
.compo-section .grid-table .table-list[data-v-340010e4] {
  min-height: 42px;
  height: auto;
  padding-top: 8px;
  padding-bottom: 8px;
}
.compo-section .grid-table .table-list > li[data-v-340010e4] {
  height: auto;
  min-height: auto;
  color: #666;
}
.guide-section[data-v-340010e4] {
  padding: 40px 0 0;
}
.guide-section .sec-head[data-v-340010e4] {
  padding: 0px 16px;
}
.guide-section .compo-date[data-v-340010e4] {
  color: #999;
  font-size: 12px;
}
.guide-section .grid-table .table-head li[data-v-340010e4] {
  font-weight: 500;
}
.guide-section .grid-table .table-head[data-v-340010e4],
.guide-section .grid-table .table-list[data-v-340010e4] {
  padding-left: 24px;
  padding-right: 24px;
  grid-template-columns: 70px 1fr;
}
.guide-section .grid-table .table-list[data-v-340010e4] {
  min-height: 42px;
  height: auto;
  padding-top: 8px;
  padding-bottom: 8px;
  -moz-column-gap: 20px;
       column-gap: 20px;
}
.guide-section .grid-table .table-list > li[data-v-340010e4] {
  height: auto;
  min-height: auto;
  color: #666;
  align-items: start;
  min-width: 80px;
}
.big-desc[data-v-340010e4] {
  color: #999;
  margin-top: 10px;
  font-size: 18px;
}
.big-desc strong[data-v-340010e4] {
  color: #d74343;
  font-weight: 600;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
