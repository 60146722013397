// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile[data-v-70f3cf32] {
  padding: 0 16px;
}
.profile-img[data-v-70f3cf32] {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-size: 100%;
  margin: 20px auto 24px;
}
.profile-info-list[data-v-70f3cf32] {
  margin-bottom: 24px;
}
.profile-info-list-item[data-v-70f3cf32] {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  padding: 16px 0;
  letter-spacing: -0.32px;
}
.profile-info-list-item span[data-v-70f3cf32]:first-child {
  font-weight: 600;
  color: #222;
}
.profile-info-list-item span[data-v-70f3cf32]:last-child {
  font-weight: 400;
  color: #666;
}
.profile hr[data-v-70f3cf32] {
  margin-bottom: 24px;
}
.profile-sns-title[data-v-70f3cf32] {
  color: #999;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: -0.24px;
  height: 37px;
  line-height: 37px;
}
.profile-sns-list-item[data-v-70f3cf32] {
  height: 54px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.profile-sns-list-item span[data-v-70f3cf32]:first-child {
  display: flex;
  align-items: center;
  color: #222;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
  letter-spacing: -0.32px;
}
.profile-sns-list-item i[data-v-70f3cf32] {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 8px;
  display: block;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
