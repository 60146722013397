<template>
  <div class="valid-input" :class="state">
    <span v-if="isShowSub" class="input-sub-tit"
      >{{ value.length }} / {{ maxLength }}</span
    >
    <input
      :type="type"
      :value="value"
      :disabled="disabled"
      :placeholder="placeholder"
      @input="$emit('update:value', $event.target.value)"
    />
    <i v-if="value" class="icon--delete" @click="$emit('delete')"></i>
    <div v-if="state" class="valid-txt error">{{ validTxt }}</div>
  </div>
</template>

<script>
export default {
  name: 'PageNotFound',
  props: {
    isShowSub: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: 'text'
    },
    placeholder: {
      type: String,
      default: 'text'
    },
    value: {
      type: String
    },
    maxLength: {
      type: Number,
      default: 100
    },
    disabled: {
      type: Boolean,
      default: false
    },
    validTxt: {
      type: String,
      default: ''
    },
    state: {
      type: String,
      default: 'error'
    }
  },
  computed: {},
  methods: {
    useClick() {
      this.$emit('useClick', this.type)
    }
  }
}
</script>

<style lang="scss" scoped>
.valid-input {
  border-bottom: 2px solid #f1f1f1;
  position: relative;
  margin-bottom: 20px;
  .icon--delete {
    position: absolute;
    right: 8px;
    // top: 50%;
    bottom: 8px;
    // transform: translateY(-50%);
  }
  .input-sub-tit {
    color: #bfbfbf;
    font-size: 12px;
    position: absolute;
    bottom: -20px;
    right: 0;
  }
  > input {
    height: 32px;
    font-size: 20px;
    color: #222;
    font-weight: 400;
    line-height: 100%;
    width: 90%;
    &::placeholder {
      color: #bfbfbf;
    }
  }
  .error {
    span {
      color: #d74343;
    }
  }
}
</style>
