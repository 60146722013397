<template>
  <div id="ranking-wrap" class="white-layer">
    <div class="page-title-section mt-8 mb-20">
      <h4>이번달 수익률 순위</h4>
    </div>
    <ul class="top-ranker-box">
      <li v-for="(d, i) in rankingList.slice(0, 3)" :key="i + `index`">
        <RankBox :data="d" :index="i + 1" rankType="top3" />
      </li>
    </ul>
    <ul class="ranker-box">
      <li v-for="(d, i) in rankingList.slice(3, 50)" :key="i + `index`">
        <RankBox :data="d" :index="i + 4" rankType="etc" />
      </li>
    </ul>
  </div>
</template>

<script>
import RankBox from '@/components/common/RankBox.vue'
import { axiosAuthInstance } from '@/store/axios'
export default {
  name: 'QuantMarketRoungeCard',
  data() {
    return {
      rankingList: []
    }
  },
  created() {
    const params = {
      size: 50
    }
    axiosAuthInstance
      .get('/portfolio/rank', { params: params })
      .then((response) => {
        this.rankingList = response
      })
      .catch((error) => {
        console.log('error', error)
      })
  },
  mounted() {
    this.$store.dispatch('fetchRankingList')
  },
  methods: {},
  components: { RankBox }
}
</script>

<style lang="scss" scoped>
.top-ranker-box {
  display: flex;
  gap: 16px;
  > li {
    flex: 1;
    width: 30%;
  }
}
.ranker-box {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 28px;
}
</style>
