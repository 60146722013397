<template>
  <div class="community-box">
    <div class="thumb-box">
      <img :src="data.storageAccesses[0]" alt="" />
    </div>
    <div class="desc-box">
      <p class="no-wrap-text">{{ data.name }}</p>
      <span class="no-wrap-text-2">{{ data.subTitle }}</span>
      <span class="no-wrap-text-2">{{ data.contents }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QuantMarketCommunityListBox',
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {}
  },

  mounted() {},

  methods: {}
}
</script>

<style lang="scss" scoped>
.community-box {
  display: flex;
  gap: 14px;
  .thumb-box {
    width: 130px;
    height: 80px;
    flex-shrink: 0;
    border-radius: 8px;
    overflow: hidden;
    > img {
      width: 100%;
      height: 100%;
    }
  }
  .desc-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    overflow: hidden;
    gap: 4px;
    > p {
      color: #222;
      align-self: stretch;
    }
    > span {
      font-size: 12px;
      color: #aaa;
    }
  }
}
</style>
