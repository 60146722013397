<template>
  <div class="tab-pannel-box">
    <StrategyRegisterModal
      v-if="isShowRegisterModal"
      @close="isShowRegisterModal = false"
    />
    <!-- 리스트 비동기 예외처리 추가 -->
    <div
      v-if="isUser === false || portfolioList.length === 0"
      class="empty-box"
    >
      <p v-html="message"></p>
      <button class="add-port-btn base-btn cl-pri-line" @click="added">
        <span>{{ buttonName }}</span>
      </button>
    </div>
    <div v-else class="pannel-list">
      <swiper
        slides-per-view="1"
        :loop="false"
        :pagination="true"
        :key="swiperKey"
      >
        <swiper-slide v-for="(data, idx) in portfolioList" :key="idx">
          <div class="port-box" @click="goPortfolioDetail(data.portfolioId)">
            <div class="top flex">
              <div class="l-box no-wrap-text flex coll">
                <div class="status-box flex-start">
                  <i
                    class="icon--state"
                    :class="portfolioType(data.styleType)"
                  ></i
                  ><strong>{{ portfolioTypeName(data.styleType) }}</strong>
                </div>
                <span class="user-name">{{ data.nickName }}</span>
                <p class="port-title no-wrap-text">
                  {{ data.portfolioName }}
                </p>
                <strong
                  class="port-rate"
                  :class="radarIsDown(data.rorPercent3m) ? 'down' : 'up'"
                  >{{ data.rorPercent3m }}%</strong
                >
              </div>
              <div class="r-box">
                <RadarChart
                  :data="converterRadarData(data.styleDataJson)"
                  :ref="`childRadarRef${idx}`"
                  :isDown="radarIsDown(data.rorPercent3m)"
                  :key="componentKey"
                  size="S"
                ></RadarChart>
              </div>
            </div>
            <div class="bot">
              <Graph
                :data="converterLienData(data.portfolioTimeSeries)"
                :ref="`childLineRef${idx}`"
                :isDown="radarIsDown(data.rorPercent3m)"
                :key="componentKey"
                size="S"
              ></Graph>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import { Pagination } from 'swiper'
import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'
// import 'swiper/css/swiper.css'
// import { swiper, swiperSlide } from 'vue-awesome-swiper'
SwiperCore.use([Pagination])
import 'swiper/swiper-bundle.css'
import RadarChart from './RadarChart.vue'
import Graph from './chart/Graph.vue'
import StrategyRegisterModal from '@/components/common/modal/StrategyRegisterModal.vue'
export default {
  name: 'Pannel',
  components: {
    StrategyRegisterModal,
    Swiper,
    SwiperSlide,
    RadarChart,
    Graph
  },
  props: {
    portfolioList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      swiperKey: 0,
      componentKey: 0,
      isShowRegisterModal: false
    }
  },
  computed: {
    isUser: {
      get() {
        return this.$store.getters.getUser.isUser
      }
    },
    message: {
      get() {
        let message = ''
        //console.log('isUser', this.user.isUser)
        if (this.isUser) {
          message =
            '보관함이 비어 있습니다. <br />' +
            '당신만의 포트폴리오를 만들어보세요!'
        } else {
          message =
            '로그인을 하신 후,<br />' +
            '퀀트마켓과 함께 다양한 전략을<br />' +
            '손쉽게 만들어보세요.'
        }
        return message
      }
    },
    buttonName: {
      get() {
        let buttonName = ''
        //console.log('isUser', this.user.isUser)
        if (this.isUser) {
          buttonName = '내 전략 만들기'
        } else {
          buttonName = '로그인하기'
        }
        return buttonName
      }
    }
  },
  methods: {
    added() {
      if (this.isUser) {
        this.isShowRegisterModal = true
      } else {
        this.$router.push({ name: 'Login' })
      }
    },
    goPortfolioDetail(portfolioId) {
      this.$router
        .push({
          name: 'PortfolioDetail',
          params: { id: portfolioId }
        })
        .catch(() => {})
    },
    portfolioTypeName(type) {
      switch (type) {
        case 'GROWTH':
          return '성장형'
        case 'VALUE':
          return '가치형'
        case 'MOMENTUM':
          return '모멘텀'
        case 'DIVIDEND':
          return '배당형'
        case 'LOWVOL':
          return '안정형'
        case 'QUALITY':
          return '우량형'
        case 'STOCKS':
          return '직접 선택'
      }
    },
    portfolioType(type) {
      switch (type) {
        case 'GROWTH':
          return 'exp'
        case 'VALUE':
          return 'val'
        case 'MOMENTUM':
          return 'moe'
        case 'DIVIDEND':
          return 'money'
        case 'LOWVOL':
          return 'safe'
        case 'QUALITY':
          return 'big'
        case 'STOCKS':
          return 'stocks'
      }
    },
    converterRadarData(radarData) {
      const radar = [
        Number(radarData.growth),
        Number(radarData.value),
        Number(radarData.momentum),
        Number(radarData.dividend),
        Number(radarData.lowvol),
        Number(radarData.quality)
      ]
      return radar
    },
    converterLienData(portfolioTimeSeries) {
      const timeseriesLength = portfolioTimeSeries.length
      const timeseriesStartNumber =
        timeseriesLength > 90 ? timeseriesLength - 90 : 0
      let lineData = portfolioTimeSeries.slice(
        timeseriesStartNumber,
        timeseriesLength
      )
      lineData = lineData.map((info) => {
        return { x: info.baseDate, y: (info.valueIndex - 100).toFixed(2) }
      })
      return lineData
    },
    forceRender() {
      this.swiperKey++
      this.componentKey++
    },
    radarIsDown(rorPercent) {
      return rorPercent < 0
    }
  }
}
</script>

<style lang="scss" scoped>
.tab-pannel-box {
  padding: 24px 16px;
  .empty-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    height: 230px;
    > p {
      text-align: center;
      color: #999;
    }
    > .add-port-btn {
      border-radius: 50px;

      width: 126px;
      height: 36px;
    }
  }
}
.port-box {
  .top {
    height: 124px;
    gap: 8px;
    justify-content: space-between;
    .l-box {
      .status-box {
        margin-bottom: 8px;
        strong {
          color: #4a4ee7;
          font-weight: 600;
        }
      }
      .user-name {
        margin-bottom: 4px;
        font-size: 12px;
        color: #999;
      }
      .port-title {
        color: #222;
        font-size: 16px;
        font-weight: 600;
      }
      .port-rate {
        margin-top: 8px;
        font-size: 24px;
        font-weight: 600;
      }
      .up {
        color: #d74343;
      }
      .down {
        color: #4a4ee7;
      }
    }
  }
  .r-box {
    padding: 0px 12px;
    display: flex;
    flex: 1;
    justify-content: center;
  }
}
::v-deep .swiper-container {
  height: 100%;
  position: relative;
  .swiper-pagination {
    .swiper-pagination-bullet {
      width: 6px;
      height: 6px;
      border-radius: 12px;
      background-color: #444;
      opacity: 0.1;
      &-active {
        width: 18px;
        opacity: 1;
      }
    }
  }
}
</style>
