var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.list.length > 0
    ? _c(
        "div",
        { staticClass: "quant-swiper-box" },
        [
          _c(
            "swiper",
            {
              ref: "swiper",
              attrs: {
                "slides-per-view": "1",
                loop: true,
                pagination: { type: "fraction" },
              },
              on: {
                click: _vm.onClick,
                swiper: _vm.onSwiper,
                slideChange: _vm.onSlideChange,
              },
            },
            _vm._l(_vm.list, function (data, idx) {
              return _c("swiper-slide", { key: idx }, [
                _c("div", { staticClass: "quant-slide-item" }, [
                  _vm.type === "community" ||
                  _vm.type === "store" ||
                  _vm.type === "product"
                    ? _c("img", {
                        staticClass: "slide-thumb",
                        attrs: { alt: "", src: data },
                      })
                    : _c("img", {
                        staticClass: "slide-thumb",
                        attrs: {
                          alt: "",
                          src: require(`@/assets/images/banner/${data.bannerImage}.png`),
                        },
                      }),
                  _vm.useRound
                    ? _c("div", { staticClass: "round-desc-box" }, [
                        _c("p", [_vm._v(_vm._s(data.title))]),
                        _c("p", [_vm._v(_vm._s(data.desc))]),
                      ])
                    : _vm._e(),
                  _vm.useTitle
                    ? _c("div", { staticClass: "title-box no-wrap-text" }, [
                        _c("h3", { staticClass: "mb-4" }, [
                          _vm._v(_vm._s(data.title)),
                        ]),
                        _c("p", [_vm._v(_vm._s(data.desc))]),
                      ])
                    : _vm._e(),
                ]),
              ])
            }),
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }