var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "detail wrapper" }, [
    _c("h2", { staticClass: "detail-title" }, [_vm._v(_vm._s(_vm.termsName))]),
    _c(
      "select",
      {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.termsId,
            expression: "termsId",
          },
        ],
        staticClass: "detail-select",
        on: {
          change: [
            function ($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function (o) {
                  return o.selected
                })
                .map(function (o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.termsId = $event.target.multiple
                ? $$selectedVal
                : $$selectedVal[0]
            },
            _vm.termsChange,
          ],
        },
      },
      _vm._l(_vm.termsList, function (item) {
        return _c(
          "option",
          { key: item.termsId, domProps: { value: item.termsId } },
          [_vm._v(" " + _vm._s(item.applicationDate) + " ")]
        )
      }),
      0
    ),
    _c("div", {
      staticClass: "detail-box",
      domProps: { innerHTML: _vm._s(_vm.bodyData) },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }