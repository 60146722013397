<template>
  <label class="switch" :class="{ on: isOn, big: isBig }">
    <input type="checkbox" v-model="isOn" @click="toggleCheckbox" />
    <div class="slider"></div>
  </label>
</template>

<script>
export default {
  name: 'QuantMarketToggleSwitch',
  props: {
    info: {
      type: Object
    },
    data: {
      type: Boolean,
      default: false
    },
    isBig: {
      type: Boolean,
      default: false
    },
    isManualUpdate: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return { isOn: false }
  },
  mounted() {
    this.isOn = this.data
  },
  methods: {
    updateCheckbox(value) {
      this.isOn = value
    },
    toggleCheckbox(event) {
      this.isOn = !this.isOn
      this.$emit('updated', this.info, this.isOn, event)
    }
  }
}
</script>

<style lang="scss" scoped>
.switch {
  position: relative;
  display: block;
  width: 24px;
  height: 14px;
  padding: 2px;
  background-color: #d9d9d9;
  &.on {
    background-color: #4a4ee7;
  }

  border-radius: 50px;
  input {
    display: none;
    &:checked + .slider:before {
      left: 50%;
    }
  }
  .slider {
    display: flex;

    position: absolute;
    cursor: pointer;
    top: 2px;
    left: 2px;
    right: 2px;
    bottom: 2px;
    -webkit-transition: 0.4s;
    transition: 0.4s;

    &:before {
      position: absolute;
      content: '';
      height: 100%;
      width: 50%;
      top: 0;
      left: 0;
      border-radius: 50%;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  }
  &.big {
    width: 46px;
    height: 28px;
    padding: 4px;
    .slider {
      top: 4px;
      left: 4px;
      right: 4px;
      bottom: 4px;
    }
  }
}
</style>
