var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "white-layer", attrs: { id: "community-wrap" } },
    [
      _vm.toggleAddComm
        ? _c("CommRegisterModal", {
            on: {
              close: function ($event) {
                _vm.toggleAddComm = false
              },
            },
          })
        : _vm._e(),
      _vm._m(0),
      _vm.$store.getters.getUser.isUser
        ? _c(
            "div",
            {
              staticClass: "round-desc-box",
              on: {
                click: function ($event) {
                  _vm.toggleAddComm = true
                },
              },
            },
            [
              _c("p", [_vm._v("퀀트마켓에서 공유하고 싶은 주제가 있나요?")]),
              _c("p", { staticClass: "puple-txt" }, [
                _vm._v("커뮤니티를 등록해보세요."),
              ]),
            ]
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "slide-box mb-32" },
        [
          _c("SliderPhotoBox", {
            staticClass: "inner-slider",
            attrs: { useTitle: true, list: _vm.communityBanner },
          }),
        ],
        1
      ),
      _c(
        "ul",
        { staticClass: "comm-list" },
        _vm._l(_vm.list, function (d, i) {
          return _c(
            "li",
            {
              key: i + "comm",
              on: {
                click: function ($event) {
                  return _vm.goDetail(d.communityId)
                },
              },
            },
            [_c("CommunityListBox", { attrs: { data: d } })],
            1
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "page-title-section mt-8 mb-20" }, [
      _c("h4", [_vm._v("커뮤니티")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }