var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "add-step-01" } },
    [
      _vm._m(0),
      _c(
        "ul",
        { staticClass: "regist-list" },
        [
          _vm._l(_vm.list, function (d, i) {
            return _c("li", { key: i + "list", staticClass: "round-box" }, [
              _c("span", [_vm._v("종목" + _vm._s(i + 1))]),
              _c("p", [_vm._v(_vm._s(d.stockName))]),
              _c("i", {
                staticClass: "icon--delete",
                on: {
                  click: function ($event) {
                    return _vm.removeList(d)
                  },
                },
              }),
            ])
          }),
          _vm.list.length < 5
            ? _c(
                "li",
                {
                  staticClass: "add-button round-box flex-center",
                  on: {
                    click: function ($event) {
                      _vm.toggleSearchModal = true
                    },
                  },
                },
                [_vm._m(1)]
              )
            : _vm._e(),
        ],
        2
      ),
      _vm.toggleSearchModal
        ? _c("StrategySearchModal", {
            attrs: { stockCheckList: this.list },
            on: {
              close: function ($event) {
                _vm.toggleSearchModal = false
              },
              save: _vm.addList,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "page-title-section mb-24" }, [
      _c("h4", [_vm._v("구성종목을 추가하세요")]),
      _c("p", { staticClass: "q-sub-txt" }, [_vm._v("(최대 5개)")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _vm._v("종목 추가하기"),
      _c("i", { staticClass: "icon--plus ml-4" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }