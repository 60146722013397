// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.valid-input[data-v-1f9a83c9] {
  border-bottom: 2px solid #f1f1f1;
  position: relative;
  margin-bottom: 20px;
}
.valid-input .icon--delete[data-v-1f9a83c9] {
  position: absolute;
  right: 8px;
  bottom: 8px;
}
.valid-input .input-sub-tit[data-v-1f9a83c9] {
  color: #bfbfbf;
  font-size: 12px;
  position: absolute;
  bottom: -20px;
  right: 0;
}
.valid-input > input[data-v-1f9a83c9] {
  height: 32px;
  font-size: 20px;
  color: #222;
  font-weight: 400;
  line-height: 100%;
  width: 90%;
}
.valid-input > input[data-v-1f9a83c9]::-moz-placeholder {
  color: #bfbfbf;
}
.valid-input > input[data-v-1f9a83c9]::placeholder {
  color: #bfbfbf;
}
.valid-input .error span[data-v-1f9a83c9] {
  color: #d74343;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
