// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.top-area[data-v-3e62dcd4] {
  display: flex;
  padding: 14px 16px;
  align-self: center;
  border-bottom: 1px solid #e8e8e8;
  border-top: 8px solid #e8e8e8;
}
.top-area .sort-box[data-v-3e62dcd4] {
  display: flex;
  align-items: center;
  gap: 8px;
}
.top-area .sort-box span[data-v-3e62dcd4] {
  color: #666;
}
.top-area .sort-box .on[data-v-3e62dcd4] {
  color: #222;
  font-weight: 500;
}
.top-area .my-box[data-v-3e62dcd4] {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 4px;
}
.top-area .my-box span[data-v-3e62dcd4] {
  color: #666;
}
.comment[data-v-3e62dcd4] {
  padding: 8px 16px 16px;
  border-bottom: 1px solid #e8e8e8;
}
.re-coment[data-v-3e62dcd4] {
  padding: 8px 16px 16px 44px;
}
.section[data-v-3e62dcd4] {
  padding: 0 16px;
}
.edit-modal .edit-modal-list li[data-v-3e62dcd4] {
  font-size: 16px;
  padding: 14px 0;
}
.body[data-v-3e62dcd4] {
  padding-left: 36px;
}
.input-list[data-v-3e62dcd4] {
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px 0;
}
.input-list label[data-v-3e62dcd4] {
  display: inline-block;
  width: 28px;
  height: 28px;
}
.prof-box[data-v-3e62dcd4] {
  display: flex;
  align-items: center;
  justify-content: center;
}
.prof-box .date[data-v-3e62dcd4] {
  color: #aaa;
  font-size: 12px;
}
.board-desc[data-v-3e62dcd4] {
  width: 100%;
  color: #666;
  font-size: 14px;
  padding-bottom: 14px;
}
.thumb-box[data-v-3e62dcd4] {
  display: flex;
  border-radius: 6px;
  overflow: hidden;
}
.thumb-box > div[data-v-3e62dcd4] {
  width: 50%;
}
.thumb-box > div > div[data-v-3e62dcd4] {
  display: block;
  width: 100%;
  padding-top: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}
.thumb-box > div > div .more-no[data-v-3e62dcd4] {
  position: absolute;
  font-size: 30px;
  font-weight: 700;
  top: 50%;
  left: 50%;
  color: #fff;
  transform: translate(-50%, -50%);
}
.status-box[data-v-3e62dcd4] {
  display: flex;
  align-items: center;
  gap: 14px;
}
.status-box li[data-v-3e62dcd4] {
  display: flex;
  align-items: center;
  gap: 4px;
}
.status-box li span[data-v-3e62dcd4] {
  color: #aaa;
  font-size: 12px;
}
.body .status-box[data-v-3e62dcd4] {
  margin-top: 14px;
}
.coment-input-wrap[data-v-3e62dcd4] {
  border-top: 1px solid #e8e8e8;
  padding: 12px 16px 33px;
}
.coment-input-wrap > .round-input[data-v-3e62dcd4] {
  background: #f7f7f7;
}
.coment-input-wrap > .round-input span[data-v-3e62dcd4] {
  color: #bfbfbf;
}
.empty[data-v-3e62dcd4] {
  min-height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.empty p[data-v-3e62dcd4] {
  color: #999;
  text-align: center;
  font-size: 14px;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
