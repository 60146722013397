var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "ranking-box",
      class: { "is-top-rank": _vm.isTop3 },
      on: {
        click: function ($event) {
          return _vm.goPortfolioDetail(_vm.data.portfolioId)
        },
      },
    },
    [
      _c("div", { staticClass: "prof-thumb" }, [
        _c("img", {
          class: { "rank-image-center": _vm.isTop3 },
          attrs: { src: _vm.data.storageAccess, alt: "" },
        }),
      ]),
      _c(
        "div",
        { staticClass: "rank-no" },
        [
          _c("span", { staticClass: "no" }, [_vm._v(_vm._s(_vm.index))]),
          !_vm.isTop3
            ? [
                _vm.isRankUp
                  ? _c("i", { staticClass: "icon--rank-up" })
                  : _vm._e(),
                _vm.isNew
                  ? _c("span", { staticClass: "new-badge" }, [_vm._v("new")])
                  : _vm._e(),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c("div", { staticClass: "rank-desc no-wrap-text" }, [
        _c("p", { staticClass: "no-wrap-text-2" }, [
          _vm._v(_vm._s(_vm.data.nickName)),
        ]),
        _c("span", [_vm._v(_vm._s(_vm.data.portfolioName))]),
      ]),
      _c(
        "div",
        {
          staticClass: "percent",
          class: _vm.isDown(_vm.data.rorValueIndex) ? "status" : "",
        },
        [
          _vm._v(
            " " +
              _vm._s(_vm.getSign(_vm.data.rorValueIndex)) +
              _vm._s(_vm.data.rorValueIndex) +
              "% "
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }