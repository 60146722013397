// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#authority-wrap .authority-list[data-v-49ac63d4] {
  display: flex;
  flex-direction: column;
  margin-top: 48px;
  margin-bottom: 16px;
  gap: 12px;
}
#authority-wrap .authority-list > li[data-v-49ac63d4] {
  display: flex;
  padding: 14px;
  align-items: center;
  border-radius: 14px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
  height: 68px;
  gap: 10px;
}
#authority-wrap .authority-list > li > div[data-v-49ac63d4] {
  gap: 4px;
}
#authority-wrap .base-btn[data-v-49ac63d4] {
  margin-top: auto;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
