<template>
  <div class="layer">
    <div
      class="fixed-head"
      :class="{ transparent: useTrans, 'no-sticky': useNotSticky }"
    >
      <i
        class="icon--arrow-back"
        :class="{ icon_w: useTrans }"
        @click="back"
      ></i>
      <h5 class="page-tit" :class="{ color_w: useTrans }">{{ pageTitle }}</h5>
      <router-link :to="{ name: 'Edit' }">
        <span class="my-info-update" v-if="userUpdateBtn">수정</span>
      </router-link>
    </div>
    <router-view />
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {}
  },

  computed: {
    pageTitle() {
      return this.$route.meta.pageTitle
    },
    useHistory() {
      return this.$route.meta.history
    },
    useClose() {
      return this.$route.meta.close
    },
    useTrans() {
      return this.$route.meta.trans
    },
    useNotSticky() {
      return this.$route.meta.noSticky
    },
    userUpdateBtn() {
      return this.$route.meta.userUpdateBtn
    }
  },
  beforeMount() {},
  async mounted() {},
  methods: {
    back() {
      if (this.$route.meta.history) {
        this.$router.push(this.$route.meta.history)
      } else {
        this.$router.go(-1)
      }
    }
  }
}
</script>

<style lang="scss">
.transparent {
  background-color: transparent !important;
}

.fixed-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  width: 100%;
  top: 0;
  padding: 0 16px;
  height: 48px;
  background-color: #fff;
  z-index: 99;
  &.no-sticky {
    position: relative;
  }
  > .page-tit {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 16px;
    font-weight: 600;
    &.color_w {
      color: #fff;
    }
  }
  .my-info-update {
    color: #4a4ee7;
  }
}
</style>
