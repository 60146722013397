// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.graphDisAbleLabel[data-v-78b701b0] {
  color: #999999;
}
#portfolio-detail-wrap .page-title-section[data-v-78b701b0] {
  padding: 20px 16px 40px;
  position: relative;
  background-color: transparent;
}
#portfolio-detail-wrap .page-title-section h4[data-v-78b701b0],
#portfolio-detail-wrap .page-title-section p[data-v-78b701b0] {
  color: #fff;
}
#portfolio-detail-wrap .page-title-section .exam-box[data-v-78b701b0] {
  position: absolute;
  top: 20px;
  right: 16px;
  width: 82px;
  height: 36px;
  border-radius: 10px;
  font-weight: 600;
  color: #4a4ee7;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
#portfolio-detail-wrap .page-body[data-v-78b701b0] {
  background: #f1f1f1;
  border-top-right-radius: 14px;
  border-top-left-radius: 14px;
  overflow: hidden;
}
#portfolio-detail-wrap .sec-head h3[data-v-78b701b0] {
  font-size: 18px;
}
#portfolio-detail-wrap .sec-head .status-box strong[data-v-78b701b0] {
  color: #4a4ee7;
  font-size: 20px;
  font-weight: 600;
}
#portfolio-detail-wrap .sec-head .rate-box strong[data-v-78b701b0] {
  color: #d74343;
  font-size: 20px;
  font-weight: 600;
}
#portfolio-detail-wrap .sec-head span[data-v-78b701b0] {
  padding-left: 8px;
  color: #999;
  font-size: 12px;
  font-weight: 400;
}
#portfolio-detail-wrap .date-tab-list[data-v-78b701b0] {
  display: flex;
  justify-content: space-between;
}
#portfolio-detail-wrap .date-tab-list > li[data-v-78b701b0] {
  padding: 10px;
}
#portfolio-detail-wrap .date-tab-list > li.on[data-v-78b701b0] {
  border-radius: 10px;
  font-weight: 600;
  background: #f9f9f9;
}
#portfolio-detail-wrap .box-section[data-v-78b701b0] {
  background-color: #fff;
  padding: 32px 16px;
  margin-bottom: 8px;
}
#portfolio-detail-wrap .result-section .round-box[data-v-78b701b0] {
  border-radius: 14px;
  border: 1px solid #e8e8e8;
  background: #fff;
  padding: 16px 20px;
  display: flex;
  align-items: center;
  gap: 12px;
}
#portfolio-detail-wrap .result-section .round-box .r-box strong[data-v-78b701b0] {
  color: #222;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 6px;
}
#portfolio-detail-wrap .result-section .round-box .r-box p[data-v-78b701b0] {
  color: #999;
  font-size: 12px;
  font-weight: 400;
}
#portfolio-detail-wrap .result-section .etc-box .val-list[data-v-78b701b0] {
  margin-top: 14px;
  display: flex;
  flex-direction: column;
  gap: 14px;
}
#portfolio-detail-wrap .result-section .etc-box .val-list > li[data-v-78b701b0] {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#portfolio-detail-wrap .result-section .etc-box .val-list > li span[data-v-78b701b0] {
  color: #666;
}
#portfolio-detail-wrap .round-box-list[data-v-78b701b0] {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 8px;
  -moz-column-gap: 28px;
       column-gap: 28px;
  padding: 16px 35px;
  border-radius: 14px;
  background: #f7f7f7;
}
#portfolio-detail-wrap .round-box-list > li[data-v-78b701b0] {
  display: flex;
  justify-content: space-between;
  align-self: center;
  max-width: 68px;
  padding-left: 8px;
  position: relative;
}
#portfolio-detail-wrap .round-box-list > li[data-v-78b701b0]::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: #d9d9d9;
}
#portfolio-detail-wrap .round-box-list > li .l-box[data-v-78b701b0] {
  color: #666;
}
#portfolio-detail-wrap .round-box-list > li .r-box[data-v-78b701b0] {
  font-weight: 500;
}
#portfolio-detail-wrap .compo-section .compo-date[data-v-78b701b0] {
  color: #999;
  font-size: 12px;
}
#portfolio-detail-wrap .compo-section .grid-table .table-head[data-v-78b701b0],
#portfolio-detail-wrap .compo-section .grid-table .table-list[data-v-78b701b0] {
  grid-template-columns: 1fr 1fr 1fr;
}
#portfolio-detail-wrap .compo-section .grid-table .table-list[data-v-78b701b0] {
  min-height: 42px;
  height: auto;
  padding: 8px 0;
}
#portfolio-detail-wrap .compo-section .grid-table .table-list > li[data-v-78b701b0] {
  height: auto;
  min-height: auto;
  color: #666;
}
#portfolio-detail-wrap .edit-modal .edit-modal-list li[data-v-78b701b0] {
  padding: 14px 0;
  font-size: 16px;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
