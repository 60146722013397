// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 1024px) {
.pc-cont {
    display: none;
}
}
@media screen and (min-width: 1024px) {
.pc-wrapper {
    display: flex;
    width: 1170px;
    margin: 0 auto;
    justify-content: space-between;
    position: relative;
}
.pc-wrapper .pc-cont {
    padding-top: 164px;
}
.pc-wrapper .pc-cont::before {
    content: "";
    width: 100vw;
    height: 720px;
    position: absolute;
    z-index: -2;
    left: 50%;
    top: 0%;
    transform: translateX(-50%);
    background: linear-gradient(0deg, #f5f5ff 0%, #f5f5ff 100%), linear-gradient(97deg, #e2e9f6 0%, #f1def3 100.2%), linear-gradient(0deg, #fff 0%, #fff 100%), #f5f5f7;
}
.pc-wrapper .pc-cont .btn-wrap {
    display: flex;
    gap: 8px;
}
.pc-wrapper .pc-cont .btn-wrap button {
    width: 164px;
    height: 50px;
}
.pc-wrapper .pc-cont h1 {
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
}
.pc-wrapper .pc-cont .round-box {
    border-radius: 14px;
    padding: 20px;
    background-color: #fff;
}
.pc-wrapper .pc-cont .round-box li {
    display: flex;
    align-items: center;
    gap: 18px;
    padding: 12px 0;
    border-bottom: 1px solid #ececff;
}
.pc-wrapper .pc-cont .round-box li p {
    font-size: 17px;
    font-weight: 500;
}
.pc-wrapper .pc-cont .round-box li p span {
    position: relative;
    background: linear-gradient(0deg, rgba(254, 234, 97, 0.4) 0%, rgba(254, 234, 97, 0.4) 31%, rgb(255, 255, 255) 36%, rgb(255, 255, 255) 100%);
}
.pc-wrapper .pc-cont .round-box li:last-child {
    border-bottom: none;
}
.pc-wrapper .mobile-cont {
    height: calc(var(--vh, 1vh) * 100);
    position: relative;
    max-width: 470px;
    width: 100%;
}
.pc-wrapper .mobile-cont::before {
    content: "";
    background-color: #fff;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
}
.pc-wrapper #main-app {
    height: calc(var(--vh, 1vh) * 100);
    overflow: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
.pc-wrapper #main-app::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
}
}
.mobile-cont {
  height: calc(var(--vh, 1vh) * 100);
  position: relative;
  width: 100%;
}
.mobile-cont .modal-layer {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}
.mobile-cont .modal-layer .vue-portal-target {
  width: 100%;
  position: absolute;
  height: 100%;
}
#main-app {
  position: relative;
}
#main-app .simplert {
  z-index: 99999999999;
  padding: 0 35px;
  justify-content: center;
}
#main-app .simplert__header {
  padding: 0 !important;
  text-align: left;
}
#main-app .simplert__content {
  flex: 1;
  border-radius: 14px;
  margin: 0 0;
  margin-left: 0;
  margin-right: 0;
  padding: 24px 16px 16px !important;
  min-height: 154px;
  display: flex;
  flex-direction: column;
  animation-fill-mode: none;
}
#main-app .simplert__body {
  padding: 0;
  text-align: left;
}
#main-app .simplert__body > div {
  color: #999;
}
#main-app .simplert__footer {
  margin-top: auto;
  padding: 0 !important;
  display: flex;
  gap: 8px;
}
#main-app .simplert__footer button {
  flex: 1;
  border-radius: 14px;
  line-height: 42px;
  text-align: center;
  margin: 0 !important;
  height: 42px;
  background: #edeeff;
  color: #4a4ee7;
  font-size: 16px;
}
#main-app .simplert__footer .simplert__confirm {
  order: 2;
  background: #4a4ee7;
  color: #fff;
}
#main-app .simplert__footer .simplert__close {
  order: 1;
}
#main-app .simplert__header {
  margin-bottom: 10px;
}
#main-app .simplert__body {
  margin-bottom: 20px;
}
#main-app .simplert__title {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #222;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
