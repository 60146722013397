var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "strategy-result-wrap" } },
    [
      _vm.loading ? _c("Spinner") : _vm._e(),
      [
        _vm._m(0),
        _c("div", { staticClass: "style-section box-section" }, [
          _c("div", { staticClass: "sec-head flex-just" }, [
            _c("h3", [_vm._v("스타일")]),
            _c("div", { staticClass: "status-box flex-center" }, [
              _c("i", {
                staticClass: "icon--state size-big",
                class: `${_vm.styleTp}`,
              }),
              _c("strong", [_vm._v(_vm._s(_vm.styleNm))]),
            ]),
          ]),
          _c("div", { staticClass: "sec-body" }, [
            _c(
              "div",
              { staticClass: "flex-center pt-18 pb-16" },
              [
                _c("RadarChart", {
                  ref: "childRadarRef",
                  attrs: { data: _vm.radarData },
                }),
              ],
              1
            ),
            _c("ul", { staticClass: "round-box-list" }, [
              _c("li", [
                _c("span", { staticClass: "l-box" }, [_vm._v("성장")]),
                _c("span", { staticClass: "r-box" }, [
                  _vm._v(_vm._s(_vm.radarData[0])),
                ]),
              ]),
              _c("li", [
                _c("span", { staticClass: "l-box" }, [_vm._v("가치")]),
                _c("span", { staticClass: "r-box" }, [
                  _vm._v(_vm._s(_vm.radarData[1])),
                ]),
              ]),
              _c("li", [
                _c("span", { staticClass: "l-box" }, [_vm._v("모멘텀")]),
                _c("span", { staticClass: "r-box" }, [
                  _vm._v(_vm._s(_vm.radarData[2])),
                ]),
              ]),
              _c("li", [
                _c("span", { staticClass: "l-box" }, [_vm._v("배당")]),
                _c("span", { staticClass: "r-box" }, [
                  _vm._v(_vm._s(_vm.radarData[3])),
                ]),
              ]),
              _c("li", [
                _c("span", { staticClass: "l-box" }, [_vm._v("안전")]),
                _c("span", { staticClass: "r-box" }, [
                  _vm._v(_vm._s(_vm.radarData[4])),
                ]),
              ]),
              _c("li", [
                _c("span", { staticClass: "l-box" }, [_vm._v("우량")]),
                _c("span", { staticClass: "r-box" }, [
                  _vm._v(_vm._s(_vm.radarData[5])),
                ]),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "rate-section box-section" }, [
          _c("div", { staticClass: "sec-head flex-just" }, [
            _c("h3", [_vm._v("수익률")]),
            _c(
              "div",
              {
                staticClass: "flex-center",
                class: _vm.isDown ? "status-box" : "rate-box",
              },
              [_c("strong", [_vm._v(_vm._s(_vm.rateOfReturn) + "%")])]
            ),
          ]),
          _c(
            "div",
            { staticClass: "sec-body" },
            [
              _c("Graph", {
                ref: "childLineRef",
                attrs: { data: _vm.lineData, isDown: _vm.isDown },
              }),
              _c("ul", { staticClass: "date-tab-list" }, [
                _c(
                  "li",
                  {
                    class: { on: _vm.viewRate === 1 },
                    on: {
                      click: function ($event) {
                        return _vm.changeViewRate(1)
                      },
                    },
                  },
                  [_c("span", [_vm._v("3개월")])]
                ),
                _c(
                  "li",
                  {
                    class: { on: _vm.viewRate === 2 },
                    on: {
                      click: function ($event) {
                        return _vm.changeViewRate(2)
                      },
                    },
                  },
                  [_c("span", [_vm._v("6개월")])]
                ),
                _c(
                  "li",
                  {
                    class: { on: _vm.viewRate === 3 },
                    on: {
                      click: function ($event) {
                        return _vm.changeViewRate(3)
                      },
                    },
                  },
                  [_c("span", [_vm._v("1년")])]
                ),
                _c(
                  "li",
                  {
                    class: { on: _vm.viewRate === 4 },
                    on: {
                      click: function ($event) {
                        return _vm.changeViewRate(4)
                      },
                    },
                  },
                  [_c("span", [_vm._v("2년")])]
                ),
                _c(
                  "li",
                  {
                    class: { on: _vm.viewRate === 5 },
                    on: {
                      click: function ($event) {
                        return _vm.changeViewRate(5)
                      },
                    },
                  },
                  [_c("span", [_vm._v("3년")])]
                ),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "result-section box-section" }, [
          _c("div", { staticClass: "sec-head mb-20" }, [
            _c("h3", { staticClass: "l_box" }, [
              _vm._v(" 성과지표"),
              _c("i", {
                staticClass: "icon--q ml-4",
                on: { click: _vm.performanceIndicatorsDesc },
              }),
            ]),
          ]),
          _c("div", { staticClass: "sec-body" }, [
            _c("div", { staticClass: "round-box mb-8" }, [
              _c("i", { staticClass: "icon--graph-up" }),
              _c("div", { staticClass: "r-box" }, [
                _c("strong", [
                  _vm._v("연평균수익률(CAGR)"),
                  _c("i", {
                    staticClass: "icon--q ml-4",
                    on: { click: _vm.cargDesc },
                  }),
                ]),
                _c("p", [
                  _vm._v(
                    " 테스트 기간에 연평균 " +
                      _vm._s(
                        _vm.portfolioCalculationData.performance.rorAvgPct
                      ) +
                      "%의 수익률을 거뒀어요. "
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "round-box" }, [
              _c("i", { staticClass: "icon--graph-down" }),
              _c("div", { staticClass: "r-box" }, [
                _c("strong", [
                  _vm._v("최대낙폭(MDD)"),
                  _c("i", {
                    staticClass: "icon--q ml-4",
                    on: { click: _vm.mddDesc },
                  }),
                ]),
                _c("p", [
                  _vm._v(
                    " 테스트 기간에 최고점 대비 " +
                      _vm._s(_vm.portfolioCalculationData.performance.mddPct) +
                      "% 만큼 하락했어요. "
                  ),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "etc-box" }, [
            _c("div", { staticClass: "sec-head flex-just mt-32" }, [
              _c("h3", [_vm._v("기타성과지표")]),
              _c("i", {
                class: _vm.toggleEct ? "icon--arrow-up" : "icon--arrow-down",
                on: {
                  click: function ($event) {
                    _vm.toggleEct = !_vm.toggleEct
                  },
                },
              }),
            ]),
            _vm.toggleEct
              ? _c("div", { staticClass: "sec-body" }, [
                  _c("ul", { staticClass: "val-list" }, [
                    _c("li", [
                      _c("span", { staticClass: "l-box" }, [
                        _vm._v("변동성(연)"),
                      ]),
                      _c("span", { staticClass: "r-box" }, [
                        _vm._v(
                          _vm._s(
                            _vm.portfolioCalculationData.performance.volYear
                          ) + "%"
                        ),
                      ]),
                    ]),
                    _c("li", [
                      _c("span", { staticClass: "l-box" }, [_vm._v("베타")]),
                      _c("span", { staticClass: "r-box" }, [
                        _vm._v(
                          _vm._s(
                            _vm.portfolioCalculationData.performance.beta
                          ) + "%"
                        ),
                      ]),
                    ]),
                    _c("li", [
                      _c("span", { staticClass: "l-box" }, [
                        _vm._v("샤프비율"),
                      ]),
                      _c("span", { staticClass: "r-box" }, [
                        _vm._v(
                          _vm._s(
                            _vm.portfolioCalculationData.performance.sharpeRatio
                          ) + "%"
                        ),
                      ]),
                    ]),
                    _c("li", [
                      _c("span", { staticClass: "l-box" }, [
                        _vm._v("평균보유종목"),
                      ]),
                      _c("span", { staticClass: "r-box" }, [
                        _vm._v(
                          _vm._s(
                            _vm.portfolioCalculationData.performance
                              .avgHoldingIsu
                          ) + "종목"
                        ),
                      ]),
                    ]),
                    _vm._m(1),
                    _c("li", [
                      _c("span", { staticClass: "l-box" }, [
                        _vm._v("회원님 전략"),
                      ]),
                      _c("span", { staticClass: "r-box" }, [
                        _vm._v(
                          _vm._s(
                            _vm.portfolioCalculationData.performance.rorTotPct
                          ) + "%"
                        ),
                      ]),
                    ]),
                    _c("li", [
                      _c("span", { staticClass: "l-box" }, [
                        _vm._v("코스피 지수"),
                      ]),
                      _c("span", { staticClass: "r-box" }, [
                        _vm._v(
                          _vm._s(
                            _vm.portfolioCalculationData.performance.kospiRorPct
                          ) + "%"
                        ),
                      ]),
                    ]),
                    _c("li", [
                      _c("span", { staticClass: "l-box" }, [
                        _vm._v("코스닥 지수"),
                      ]),
                      _c("span", { staticClass: "r-box" }, [
                        _vm._v(
                          _vm._s(
                            _vm.portfolioCalculationData.performance
                              .kosdaqRorPct
                          ) + "%"
                        ),
                      ]),
                    ]),
                  ]),
                ])
              : _vm._e(),
          ]),
        ]),
        _c("div", { staticClass: "compo-section box-section" }, [
          _c("div", { staticClass: "sec-head flex-start mb-24" }, [
            _c("h3", { staticClass: "l_box mr-10" }, [_vm._v("구성종목")]),
            _c("span", { staticClass: "compo-date" }, [
              _vm._v(
                _vm._s(_vm.portfolioCalculationData.savePortfolio.baseDate)
              ),
            ]),
          ]),
          _c("div", { staticClass: "sec-body" }, [
            _c(
              "div",
              { staticClass: "grid-table" },
              [
                _vm._m(2),
                _vm._l(
                  _vm.portfolioCalculationData.portfolioWeight,
                  function (d, i) {
                    return _c(
                      "ul",
                      { key: i + "search", staticClass: "table-list" },
                      [
                        _c("li", [_vm._v(_vm._s(d.stockName))]),
                        _c("li", [
                          _vm._v(
                            _vm._s(Number(d.currentPrices).toLocaleString())
                          ),
                        ]),
                        _c("li", [_vm._v(_vm._s(_vm.weight(d.weight)) + "%")]),
                      ]
                    )
                  }
                ),
              ],
              2
            ),
          ]),
        ]),
        _c("div", { staticClass: "naming-section box-section" }, [
          _c("p", { staticClass: "input-tilte mt-0 mb-8" }, [
            _vm._v("포트폴리오 이름"),
          ]),
          _c("div", { staticClass: "round-input" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.portfolioName,
                  expression: "portfolioName",
                },
              ],
              attrs: { type: "text", placeholder: "포트폴리오 이름 입력" },
              domProps: { value: _vm.portfolioName },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.portfolioName = $event.target.value
                },
              },
            }),
            _c("i", { staticClass: "icon--random" }),
          ]),
          _c("div", { staticClass: "btn-wrap mt-40" }, [
            _c(
              "button",
              {
                staticClass: "base-btn harf cl-sec",
                on: { click: _vm.portfolioCancel },
              },
              [_c("span", [_vm._v("나가기")])]
            ),
            _c(
              "button",
              { staticClass: "base-btn harf cl-pri", on: { click: _vm.save } },
              [_c("span", [_vm._v("저장하기")])]
            ),
          ]),
        ]),
      ],
      _vm.toggleValidModal
        ? _c("SlideUpModal", {
            staticClass: "valid-modal",
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "flex-just" }, [
                        _c("h4", [_vm._v("저장소가 부족해요!")]),
                        _c("i", {
                          staticClass: "icon--close",
                          on: {
                            click: function ($event) {
                              _vm.toggleValidModal = false
                            },
                          },
                        }),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "main",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "modal-body-wrap" }, [
                        _c("p", { staticClass: "q-sub-txt" }, [
                          _vm._v("기존 내용을 삭제하고 저장 공간을 확보하세요"),
                        ]),
                        _c(
                          "ul",
                          { staticClass: "port-list" },
                          _vm._l(_vm.portfolioList, function (d, i) {
                            return _c("li", { key: i + "port" }, [
                              _c(
                                "div",
                                { staticClass: "base-check-box rounded" },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.deleteList,
                                        expression: "deleteList",
                                      },
                                    ],
                                    attrs: {
                                      id: d.portfolioId,
                                      type: "checkbox",
                                    },
                                    domProps: {
                                      value: d.portfolioId,
                                      checked: Array.isArray(_vm.deleteList)
                                        ? _vm._i(
                                            _vm.deleteList,
                                            d.portfolioId
                                          ) > -1
                                        : _vm.deleteList,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = _vm.deleteList,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = d.portfolioId,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.deleteList = $$a.concat([
                                                $$v,
                                              ]))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.deleteList = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.deleteList = $$c
                                        }
                                      },
                                    },
                                  }),
                                  _c("label", {
                                    attrs: { for: d.portfolioId },
                                  }),
                                ]
                              ),
                              _c("p", { staticClass: "port-name" }, [
                                _vm._v(_vm._s(d.portfolioName)),
                              ]),
                              _c("span", { staticClass: "port-date" }, [
                                _vm._v(_vm._s(d.portfolioDate)),
                              ]),
                            ])
                          }),
                          0
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "button",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "modal-btn-wrap" }, [
                        _c(
                          "button",
                          {
                            staticClass: "base-btn harf cl-sec",
                            on: { click: _vm.removePort },
                          },
                          [_c("span", [_vm._v("선택삭제")])]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "base-btn harf cl-pri",
                            on: {
                              click: function ($event) {
                                _vm.toggleValidModal = false
                              },
                            },
                          },
                          [_c("span", [_vm._v("취소")])]
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              1766847740
            ),
          })
        : _vm._e(),
      _vm.isComplete
        ? _c("FullModal", { staticClass: "complete-modal" }, [
            _c("div", { staticClass: "complete-modal-body" }, [
              _c("i", { staticClass: "icon--complete-make" }),
              _c("h3", [
                _vm._v(" 포트폴리오가"),
                _c("br"),
                _vm._v(" 성공적으로 저장되었어요. "),
              ]),
              _c("p", [
                _vm._v(" 포트폴리오 이름, 공개여부는"),
                _c("br"),
                _vm._v("[포트폴리오-설정]에서도 가능합니다. "),
              ]),
              _c(
                "button",
                {
                  staticClass: "base-btn full cl-pri",
                  on: {
                    click: function ($event) {
                      return _vm.$router.replace({
                        name: "PortfolioStorage",
                        query: { tap: "my" },
                      })
                    },
                  },
                },
                [_c("span", [_vm._v("보관함으로 이동")])]
              ),
            ]),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "page-title-section flex coll" }, [
      _c("i", { staticClass: "icon--cong mb-8" }),
      _c("h4", [
        _vm._v(" 멋진 포트폴리오가"),
        _c("br"),
        _vm._v(" 완성되었어요! "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "sec-head mt-18" }, [
      _c("h3", [_vm._v("누적수익률 비교")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", { staticClass: "table-head" }, [
      _c("li", [_vm._v("종목")]),
      _c("li", [_vm._v("주가")]),
      _c("li", [_vm._v("비중")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }