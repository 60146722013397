// Set current selected library as default library
const state = {
  portfolio: {},
  community: {},
  type: ''
}

const getters = {
  getPortfolio: (state) => state.portfolio || {},
  getCommunity: (state) => state.community || {},
  getType: (state) => state.type || null
}

const mutations = {
  SET_PORTFOLIO(state, payload) {
    state.portfolio = payload
  },
  SET_COMMUNITY(state, payload) {
    state.community = payload
  },
  SET_TYPE(state, payload) {
    state.type = payload
  }
}

export default {
  state,
  getters,
  mutations
}
