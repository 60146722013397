<template>
  <div class="terms-wrap">
    <div class="page-title-section">
      <h4>{{ termsName }}</h4>
    </div>
    <div class="date-area">
      <div class="select-box">
        <select v-model="termsId" @change="termsChange">
          <option
            v-for="item in termsViewData"
            :key="item.termsId"
            :value="item.termsId"
          >
            {{ item.applicationDate | yyyyMMdd }}
          </option>
        </select>
      </div>
    </div>
    <div class="terms-body" v-html="bodyData"></div>
  </div>
</template>

<script>
export default {
  name: 'Terms',
  props: ['termsViewData', 'termsName'],
  data() {
    return {
      termsId: this.termsViewData[0].termsId,
      bodyData: this.termsViewData[0].termsContent
    }
  },
  computed: {
    isDisable() {
      return false
    }
  },
  methods: {
    termsChange() {
      console.log(this.termsId)
      const termsData = this.termsViewData.filter(
        (key) => key.termsId === this.termsId
      )[0]
      this.bodyData = termsData.termsContent
    }
  },
  filters: {
    // filter로 쓸 filter ID 지정
    yyyyMMdd: function (value) {
      // 들어오는 value 값이 공백이면 그냥 공백으로 돌려줌
      if (value == '') return ''

      // 현재 Date 혹은 DateTime 데이터를 javaScript date 타입화
      var js_date = new Date(value)

      // 연도, 월, 일 추출
      var year = js_date.getFullYear()
      var month = js_date.getMonth() + 1
      var day = js_date.getDate()

      // 월, 일의 경우 한자리 수 값이 있기 때문에 공백에 0 처리
      if (month < 10) {
        month = '0' + month
      }

      if (day < 10) {
        day = '0' + day
      }

      // 최종 포맷 (ex - '2021-10-08')
      return year + '-' + month + '-' + day
    }
  }
}
</script>

<style lang="scss" scoped>
.terms-wrap {
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: #fff;
  .page-title-section {
    margin-bottom: 24px;
  }
  .date-area {
    padding: 0 0 16px;
    border-bottom: 1px solid #e8e8e8;
  }
  .terms-body {
    padding: 24px 0;
    padding-right: 16px;
  }
}
</style>
