<template>
  <div class="pc-wrapper">
    <div class="pc-cont pb-80">
      <div class="top">
        <img src="@/assets/images/gnb/logo.svg" alt="" class="mb-26" />
        <h1>
          퀀트투자가 처음이라면,<br />
          <strong>퀀트마켓</strong>으로 오라!
        </h1>
        <div class="round-box mt-30">
          <ul>
            <li>
              <i class="icon--pc type01"></i>
              <p>
                <span>터치 몇 번으로</span> 내가 직접 투자 포트폴리오를 구성!
              </p>
            </li>
            <li>
              <i class="icon--pc type02"></i>
              <p><span>수익률 좋은 회원들의</span> 포트폴리오 엿보기</p>
            </li>
            <li>
              <i class="icon--pc type03"></i>
              <p>
                한 달에 한 번 씩 <span>우수 포트폴리오에 소정의 상품!</span>
              </p>
            </li>
            <li>
              <i class="icon--pc type04"></i>
              <p><span>퀀트전문가들이 만든</span> 모델포트폴리오 구독!</p>
            </li>
          </ul>
        </div>
      </div>
      <div class="bot flex mt-90">
        <div class="l-box mr-70">
          <h3>
            내 손으로 그리는 수익률, 퀀트마켓 <br /><strong
              >앱으로 만나보세요</strong
            >
          </h3>
          <div class="btn-wrap mt-16">
            <button class="btn--down-google"></button>
            <button class="btn--down-apple"></button>
          </div>
        </div>
        <div class="r-box">
          <button class="btn--down-qr"></button>
        </div>
      </div>
    </div>
    <div class="mobile-cont">
      <div class="modal-layer">
        <portal-target name="slide-up-modal"></portal-target>
        <portal-target name="btn-layer"></portal-target>
      </div>
      <div id="main-app">
        <simplert :useIcon="false" />
        <DefaultLayer v-if="mode === 'default'" />
        <FullPageLayer v-if="mode === 'full'" />
        <SubPageLayer v-if="mode === 'sub'" />
      </div>
    </div>
  </div>
</template>

<script>
import '@/assets/scss/_index.scss'
import '@/assets/css/font.css'
//
import DefaultLayer from '@/layout/DefaultLayer.vue'
import SubPageLayer from '@/layout/SubPageLayer.vue'
import FullPageLayer from '@/layout/FullPageLayer.vue'

export default {
  components: { DefaultLayer, SubPageLayer, FullPageLayer },
  data() {
    return {
      getLoading: false
    }
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        this.$store.dispatch('offToggleModal')
      }
    }
  },
  computed: {
    mode() {
      const deflt = ['Main']
      const full = ['Shopping', 'MyPage', 'Rounge', 'PlayGround']

      const sub = [
        'Login',
        'Authority',
        'KakaoLogin',
        'NaverLogin',
        'GoogleLogin',
        'Step1',
        'Step2',
        'Step3',
        'Ranking',
        'Community',
        'CommunityDetail',
        'AddStrategy01',
        'AddStrategy02',
        'Result',

        'Profile',
        'Setting',
        'ProductsSell',
        'Posting',
        'Examine',
        'ExamineDetail',
        'Edit',
        'Service',
        'Policy',
        'Faq',
        'Qna',
        'PortfolioStorage',
        'AddProduct',
        'AddProductDetail',
        'PortfolioDetail',

        'ProductDetail',
        'SubscribeDetail',

        'RoungeDetail',
        'MypageTerms',
        'MypagePrivacy'
      ]
      if (deflt.includes(this.$route.name)) return 'default'
      if (full.includes(this.$route.name)) return 'full'
      if (sub.includes(this.$route.name)) return 'sub'
      return ''
    }
  },
  created() {
    this.setScreenSize()
  },
  beforeMount() {},
  async mounted() {},
  methods: {
    setScreenSize() {
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }
  }
}
</script>
<style lang="scss">
@media screen and (max-width: 1024px) {
  .pc-cont {
    display: none;
  }
}
@media screen and (min-width: 1024px) {
  .pc-wrapper {
    display: flex;
    width: 1170px;
    margin: 0 auto;
    justify-content: space-between;
    position: relative;

    .pc-cont {
      &::before {
        content: '';
        width: 100vw;
        height: 720px;
        position: absolute;
        z-index: -2;
        left: 50%;
        top: 0%;
        transform: translateX(-50%);
        background: linear-gradient(0deg, #f5f5ff 0%, #f5f5ff 100%),
          linear-gradient(97deg, #e2e9f6 0%, #f1def3 100.2%),
          linear-gradient(0deg, #fff 0%, #fff 100%), #f5f5f7;
      }
      .btn-wrap {
        display: flex;
        gap: 8px;
        button {
          width: 164px;
          height: 50px;
        }
      }
      h1 {
        font-size: 50px;
        font-style: normal;
        font-weight: 700;
      }
      .round-box {
        border-radius: 14px;
        padding: 20px;
        background-color: #fff;
        li {
          display: flex;
          align-items: center;
          gap: 18px;
          padding: 12px 0;
          border-bottom: 1px solid #ececff;
          p {
            font-size: 17px;
            font-weight: 500;
            span {
              position: relative;
              background: linear-gradient(
                0deg,
                rgba(254, 234, 97, 0.4) 0%,
                rgba(254, 234, 97, 0.4) 31%,
                rgba(255, 255, 255, 1) 36%,
                rgba(255, 255, 255, 1) 100%
              );
            }
          }
          &:last-child {
            border-bottom: none;
          }
        }
      }
      padding-top: 164px;
    }
    .mobile-cont {
      // margin-top: 62px;
      height: calc(var(--vh, 1vh) * 100);
      position: relative;
      max-width: 470px;
      width: 100%;
      &::before {
        content: '';
        background-color: #fff;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -2;
      }
    }
    #main-app {
      // height: calc(100vh - 62px);
      height: calc(var(--vh, 1vh) * 100);

      overflow: scroll;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Opera*/
      }
    }
  }
}
.mobile-cont {
  height: calc(var(--vh, 1vh) * 100);
  position: relative;
  width: 100%;
  .modal-layer {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    .vue-portal-target {
      width: 100%;
      position: absolute;
      height: 100%;
    }
  }
}

#main-app {
  .simplert {
    z-index: 99999999999;
    padding: 0 35px;
    justify-content: center;
  }
  position: relative;
  .simplert__header {
    padding: 0 !important;
    text-align: left;
  }
  .simplert__content {
    flex: 1;
    border-radius: 14px;
    margin: 0 0;
    margin-left: 0;
    margin-right: 0;
    padding: 24px 16px 16px !important;
    min-height: 154px;
    display: flex;
    flex-direction: column;
    animation-fill-mode: none;
  }
  .simplert__body {
    padding: 0;
    text-align: left;
    > div {
      color: #999;
    }
  }
  .simplert__footer {
    margin-top: auto;
    padding: 0 !important;
    display: flex;
    gap: 8px;
    button {
      flex: 1;
      border-radius: 14px;
      line-height: 42px;
      text-align: center;
      margin: 0 !important;
      height: 42px;
      background: #edeeff;
      color: #4a4ee7;
      font-size: 16px;
    }
    .simplert__confirm {
      order: 2;
      background: #4a4ee7;
      color: #fff;
    }
    .simplert__close {
      order: 1;
    }
  }
  .simplert__header {
    margin-bottom: 10px;
  }
  .simplert__body {
    margin-bottom: 20px;
  }
  .simplert__title {
    font-size: 16px !important;
    font-weight: 600 !important;
    color: #222;
  }
}
</style>
