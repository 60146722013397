// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mypage[data-v-309e4e18] {
  width: 100%;
  height: calc(var(--vh, 1vh) * 100 - 48px);
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0 4.444444%;
  font-family: "Pretendard";
  background-color: #fff;
}
.mypage-title[data-v-309e4e18] {
  font-size: 22px;
  height: 48px;
  line-height: 48px;
  color: #222;
  margin-bottom: 12px;
  font-weight: 600;
}
.mypage-profile[data-v-309e4e18] {
  color: #222;
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 32px;
  letter-spacing: -0.4px;
}
.mypage-profile-user-image[data-v-309e4e18] {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 14px;
  background-repeat: no-repeat;
}
.mypage-list[data-v-309e4e18] {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}
.mypage-list-item[data-v-309e4e18] {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 16px;
  padding: 16px 0;
  box-sizing: border-box;
  color: #666;
  font-weight: 500;
  position: relative;
}
.mypage-list-item-icon[data-v-309e4e18] {
  display: block;
  width: 28px;
  margin-right: 8px;
}
.mypage-list-item-btn[data-v-309e4e18] {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
