<template>
  <div class="layer">
    <div class="page-head-options">
      <i class="icon--paper"></i>
    </div>
    <div class="slide-box mb-32">
      <SliderPhotoBox
        :useTitle="true"
        class="inner-slider"
        :list="shoppingBanner"
      />
    </div>
    <div
      class="slider-section mb-40"
      v-for="(d, i) in exhibitions"
      :key="i + 'exhibitions'"
    >
      <SliderContents :list="d.products" type="shopping">
        <template #header>
          <div class="flex-just cont-head">
            <h4>{{ d.exhibitionName }}</h4>
          </div>
        </template>
      </SliderContents>
    </div>

    <div class="pl-16 mb-40">
      <FilterTabList
        :list="styleTypeList"
        :tab="styleType"
        @tab="changeStyleType"
      ></FilterTabList>
    </div>
    <div class="product-wrap">
      <div class="sort-box flex-end pr-14 pb-16">
        <button @click="toggleSortOpt = true">
          {{ sortName }} <i class="icon--arrow-down"></i>
        </button>
        <ul class="select-wrap" v-if="toggleSortOpt">
          <li>
            <p
              :class="{ on: sort === 'SALES' }"
              @click="changeSortType('SALES')"
            >
              판매순
            </p>
          </li>
          <li>
            <p
              :class="{ on: sort === 'POPULAR' }"
              @click="changeSortType('POPULAR')"
            >
              인기순
            </p>
          </li>
          <li>
            <p
              :class="{ on: sort === 'NEWEST' }"
              @click="changeSortType('NEWEST')"
            >
              최신순
            </p>
          </li>
        </ul>
      </div>
      <ul>
        <li v-for="(d, i) in list" :key="i + 'list'">
          <ProductRow :data="d"></ProductRow>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import SliderContents from '@/components/common/SliderContents.vue'
import SliderPhotoBox from '@/components/common/SliderPhotoBox.vue'
import ProductRow from '../../components/common/ProductRow.vue'
import FilterTabList from '../../components/common/FilterTabList.vue'
import { axiosAuthInstance } from '@/store/axios'
export default {
  name: 'QuantMarketIndex',
  components: {
    SliderContents,
    SliderPhotoBox,
    ProductRow,
    FilterTabList
  },
  data() {
    return {
      shoppingBanner: [
        {
          bannerImage: 'shopping/shopping_banner_1'
        }
      ],
      toggleSortOpt: false,
      sort: 'SALES',
      styleType: 'ALL',
      list: [],
      parameters: {
        index: 0,
        size: 50,
        sortType: 'SALES',
        styleType: 'ALL'
      },
      styleTypeList: [
        { text: '전체', id: 'ALL' },
        { text: '성장', id: 'GROWTH' },
        { text: '가치', id: 'VALUE' },
        { text: '모멘텀', id: 'MOMENTUM' },
        { text: '배당', id: 'DIVIDEND' },
        { text: '안전', id: 'LOWVOL' },
        { text: '우량', id: 'QUALITY' },
        { text: '직접', id: 'STOCKS' }
      ],
      exhibitions: []
    }
  },
  created() {
    // 목록 조회
    this.getProducts()
    this.getExhibition()
  },
  computed: {
    sortName() {
      let sortName = ''
      switch (this.sort) {
        case 'SALES':
          sortName = '판매순'
          break
        case 'POPULAR':
          sortName = '인기순'
          break
        case 'NEWEST':
          sortName = '최신순'
          break
      }
      return sortName
    }
  },
  mounted() {
    this.$store.dispatch('fetchCommunityBannr')
  },
  methods: {
    changeStyleType(value) {
      console.log(value)
      this.toggleSortOpt = false
      this.styleType = value
      this.parameters.styleType = value
      this.parameters.index = 0
      this.getProducts()
    },
    changeSortType(value) {
      this.toggleSortOpt = false
      this.sort = value
      this.parameters.sortType = value
      this.parameters.index = 0
      this.getProducts()
    },
    getProducts() {
      axiosAuthInstance
        .get('/product', { params: this.parameters })
        .then((response) => {
          this.list = response.products
        })
        .catch((error) => {
          console.log('error', error)
        })
    },
    getExhibition() {
      axiosAuthInstance
        .get('/product/exhibition', {})
        .then((response) => {
          this.exhibitions = response.exhibitions
        })
        .catch((error) => {
          console.log('error', error)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.sort-box {
  position: relative;
  button {
    display: flex;
    align-items: center;
    color: #666;
  }
  .select-wrap {
    position: absolute;
    width: 114px;
    right: 14px;
    top: 100%;
    border-radius: 8px;
    border: 1px solid #e8e8e8;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 20px 16px;
    .on {
      font-weight: 500;
      color: #222;
    }
  }
}
.slide-box {
  position: relative;
  aspect-ratio: 15/9;
  .inner-txt {
    position: absolute;
    left: 16px;
    bottom: 24px;
  }
}
.slider-section {
  padding-left: 16px;
}
.tab-list {
  display: flex;
  gap: 8px;
  padding: 0 16px;
  li {
    display: flex;
    height: 42px;
    padding: 8px 17px;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: 1px solid #e8e8e8;
    &.on {
      border: 1px solid #4a4ee7;
      color: #4a4ee7;
    }
  }
}
</style>
