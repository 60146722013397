var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "setting wrapper" }, [
    _c("ul", [
      _c(
        "li",
        { staticClass: "setting-item flex-between" },
        [
          _c("span", { staticClass: "setting-title" }, [
            _vm._v("Push 설정 (전체)"),
          ]),
          _c("ToggleButton", {
            ref: "updatePushCheckbox",
            attrs: {
              isBig: true,
              data: _vm.push,
              info: { allowNotificationType: "ALL" },
            },
            on: { updated: _vm.updateNotification },
          }),
        ],
        1
      ),
      _c(
        "li",
        { staticClass: "setting-item flex-between" },
        [
          _c("span", { staticClass: "setting-title" }, [
            _vm._v("구독중인 상품"),
          ]),
          _c("ToggleButton", {
            ref: "updateSubscriptionCheckbox",
            attrs: {
              isBig: true,
              data: _vm.subscription,
              info: { allowNotificationType: "SUBSCRIPTION" },
            },
            on: { updated: _vm.updateNotification },
          }),
        ],
        1
      ),
      _c(
        "li",
        { staticClass: "setting-item flex-between" },
        [
          _c("span", { staticClass: "setting-title" }, [
            _vm._v("내가 만든 포트폴리오"),
          ]),
          _c("ToggleButton", {
            ref: "updateMyPortfolioCheckbox",
            attrs: {
              isBig: true,
              data: _vm.myPortfolio,
              info: { allowNotificationType: "MY_PORTFOLIO" },
            },
            on: { updated: _vm.updateNotification },
          }),
        ],
        1
      ),
      _c(
        "li",
        { staticClass: "setting-item flex-between" },
        [
          _c("span", { staticClass: "setting-title" }, [_vm._v("라운지")]),
          _c("ToggleButton", {
            ref: "updateLoungeCheckbox",
            attrs: {
              isBig: true,
              data: _vm.lounge,
              info: { allowNotificationType: "LOUNGE" },
            },
            on: { updated: _vm.updateNotification },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }