var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "layer" },
    [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "slide-box mb-32" },
        [
          _c("SliderPhotoBox", {
            staticClass: "inner-slider",
            attrs: { useTitle: true, list: _vm.shoppingBanner },
          }),
        ],
        1
      ),
      _vm._l(_vm.exhibitions, function (d, i) {
        return _c(
          "div",
          { key: i + "exhibitions", staticClass: "slider-section mb-40" },
          [
            _c("SliderContents", {
              attrs: { list: d.products, type: "shopping" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "header",
                    fn: function () {
                      return [
                        _c("div", { staticClass: "flex-just cont-head" }, [
                          _c("h4", [_vm._v(_vm._s(d.exhibitionName))]),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                true
              ),
            }),
          ],
          1
        )
      }),
      _c(
        "div",
        { staticClass: "pl-16 mb-40" },
        [
          _c("FilterTabList", {
            attrs: { list: _vm.styleTypeList, tab: _vm.styleType },
            on: { tab: _vm.changeStyleType },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "product-wrap" }, [
        _c("div", { staticClass: "sort-box flex-end pr-14 pb-16" }, [
          _c(
            "button",
            {
              on: {
                click: function ($event) {
                  _vm.toggleSortOpt = true
                },
              },
            },
            [
              _vm._v(" " + _vm._s(_vm.sortName) + " "),
              _c("i", { staticClass: "icon--arrow-down" }),
            ]
          ),
          _vm.toggleSortOpt
            ? _c("ul", { staticClass: "select-wrap" }, [
                _c("li", [
                  _c(
                    "p",
                    {
                      class: { on: _vm.sort === "SALES" },
                      on: {
                        click: function ($event) {
                          return _vm.changeSortType("SALES")
                        },
                      },
                    },
                    [_vm._v(" 판매순 ")]
                  ),
                ]),
                _c("li", [
                  _c(
                    "p",
                    {
                      class: { on: _vm.sort === "POPULAR" },
                      on: {
                        click: function ($event) {
                          return _vm.changeSortType("POPULAR")
                        },
                      },
                    },
                    [_vm._v(" 인기순 ")]
                  ),
                ]),
                _c("li", [
                  _c(
                    "p",
                    {
                      class: { on: _vm.sort === "NEWEST" },
                      on: {
                        click: function ($event) {
                          return _vm.changeSortType("NEWEST")
                        },
                      },
                    },
                    [_vm._v(" 최신순 ")]
                  ),
                ]),
              ])
            : _vm._e(),
        ]),
        _c(
          "ul",
          _vm._l(_vm.list, function (d, i) {
            return _c(
              "li",
              { key: i + "list" },
              [_c("ProductRow", { attrs: { data: d } })],
              1
            )
          }),
          0
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "page-head-options" }, [
      _c("i", { staticClass: "icon--paper" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }