var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "chart" } },
    [
      _c("apexchart", {
        key: _vm.componentKey,
        attrs: {
          type: "area",
          options: _vm.chartOption,
          series: _vm.chartSeries,
          height: _vm.chartSize,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }