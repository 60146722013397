// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.head-box[data-v-128618a4] {
  padding: 8px 16px;
}
.date-box[data-v-128618a4] {
  display: flex;
}
.date-box > div[data-v-128618a4] {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.date-box > div strong[data-v-128618a4] {
  color: #222;
  font-weight: 600;
  font-size: 16px;
}
.date-box > div .date[data-v-128618a4] {
  height: 24px;
  display: flex;
  gap: 4px;
  align-items: center;
}
.date-box > div .date .span[data-v-128618a4] {
  color: #666;
}
.compo-section .compo-date[data-v-128618a4] {
  color: #999;
  font-size: 12px;
}
.compo-section .grid-table .table-head li[data-v-128618a4] {
  font-weight: 500;
}
.compo-section .grid-table .table-head[data-v-128618a4],
.compo-section .grid-table .table-list[data-v-128618a4] {
  padding-left: 24px;
  padding-right: 24px;
  grid-template-columns: 35% 30% 15% 20%;
}
.compo-section .grid-table .table-list[data-v-128618a4] {
  min-height: 42px;
  height: auto;
  padding-top: 8px;
  padding-bottom: 8px;
}
.compo-section .grid-table .table-list > li[data-v-128618a4] {
  height: auto;
  min-height: auto;
  display: flex;
  flex-direction: column;
}
.compo-section .grid-table .table-list > li p[data-v-128618a4] {
  color: #666;
}
.compo-section .grid-table .table-list > li .mod-price[data-v-128618a4] {
  color: #a1a1a1;
  font-size: 12px;
}
.compo-section .grid-table .table-list > li .mod-price.up[data-v-128618a4] {
  color: #d74343;
}
.compo-section .grid-table .table-list > li .mod-price.down[data-v-128618a4] {
  color: #4882f5;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
