// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#add-step-01[data-v-1ec12a3f] {
  display: flex;
  flex-direction: column;
  flex: 1;
}
#add-step-01 .base-btn[data-v-1ec12a3f] {
  margin-top: auto;
}
#add-step-01 .regist-list .round-box p[data-v-1ec12a3f] {
  color: #222;
  font-size: 16px;
  font-weight: 600;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
