<template>
  <div class="detail wrapper">
    <h2 class="detail-title">{{ termsName }}</h2>
    <select class="detail-select" v-model="termsId" @change="termsChange">
      <option
        v-for="item in termsList"
        :key="item.termsId"
        :value="item.termsId"
      >
        {{ item.applicationDate }}
      </option>
    </select>
    <div class="detail-box" v-html="bodyData"></div>
  </div>
</template>

<script>
import { axiosAuthInstance } from '@/store/axios'

export default {
  data() {
    return {
      termsType: '',
      termsList: [],
      bodyData: '',
      termsName: '',
      termsId: ''
    }
  },
  created() {
    axiosAuthInstance
      .get('/terms')
      .then((response) => {
        console.log(this.$route.params.type)
        this.termsType = this.$route.params.type
        switch (this.termsType) {
          case 'operating': //퀀트마켓 이용약관
            this.termsName = '퀀트마켓 이용약관'
            this.termsList = response.operating
            break
          case 'terms': //퀀트마켓 개인정보 처리방침
            this.termsName = '퀀트마켓 개인정보 처리방침'
            this.termsList = response.terms
            break
          case 'marketing': //마케팅 수신 동의
            this.termsName = '마케팅 수신 동의'
            this.termsList = response.marketing
            break
        }

        this.termsId = this.termsList[0].termsId
        this.bodyData = this.termsList[0].termsContent
      })
      .catch((error) => {
        console.log('error', error)
      })
  },
  methods: {
    termsChange() {
      console.log(this.termsId)
      const termsData = this.termsList.filter(
        (key) => key.termsId === this.termsId
      )[0]
      this.bodyData = termsData.termsContent
    }
  }
}
</script>

<style lang="scss" scoped>
.detail {
  &-title {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    font-size: 22px;
    font-weight: 600;
    line-height: 130%; /* 28.6px */
    letter-spacing: -0.44px;
    margin-bottom: 24px;
  }
  &-select {
    border-radius: 10px;
    border: 1px solid #e8e8e8;
    background: #fff;
    color: #666;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    width: 118px;
    height: 38px;
    box-sizing: border-box;
    padding: 0 12px;
  }
  &-box {
    margin-top: 16px;
    padding-top: 24px;
    border-top: 1px solid #e8e8e8;
    h3 {
      font-size: 14px;
      font-weight: 500;
      line-height: 140%; /* 19.6px */
      letter-spacing: -0.28px;
      margin-bottom: 16px;
    }
    p {
      color: #666;
      font-size: 14px;
      font-weight: 400;
      line-height: 160%; /* 22.4px */
      letter-spacing: -0.28px;
      margin-bottom: 48px;
    }
  }
}
</style>
