// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#community-detail-wrap[data-v-351624aa] {
  display: flex;
  flex-direction: column;
}
.slide-box[data-v-351624aa] {
  position: relative;
  aspect-ratio: 15/9;
  border-radius: 14px;
  overflow: hidden;
}
.slide-box .inner-txt[data-v-351624aa] {
  position: absolute;
  left: 16px;
  bottom: 24px;
}
.content-box[data-v-351624aa] {
  color: #666;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 22.4px */
  letter-spacing: -0.28px;
}
.go-button[data-v-351624aa] {
  margin-top: auto;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
