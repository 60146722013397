<template>
  <div id="join-wrap" class="white-layer">
    <Terms
      v-if="isTerms"
      v-bind:termsViewData="termsViewData"
      v-bind:termsName="termsName"
    />
    <div class="page-title-section">
      <h4>퀀트마켓을 이용하려면<br />동의가 필요해요</h4>
    </div>
    <ul class="agree-list">
      <li class="round-box">
        <div class="base-check-box">
          <input
            id="termsAll"
            v-model="termsAll"
            type="checkbox"
            @change="termsAllCheck"
          />
          <label for="termsAll"></label>
        </div>
        <div class="flex coll">
          <p>위의 내용을 모두 읽었으며 이에 동의합니다.</p>
          <span class="q-sub-txt">(선택항목 포함)</span>
        </div>
      </li>
      <li>
        <div class="base-check-box">
          <input
            id="ageTerms"
            v-model="ageTerms"
            type="checkbox"
            value="ACTIVE"
            @change="termsValid"
          />
          <label for="ageTerms"></label>
        </div>
        <div class="flex coll">
          <p>[필수] 만 14세 이상.</p>
          <span class="q-sub-txt"
            >본 서비스는 만 14세 미만 고객에게 제공되지 않습니다.
            <br />
            본인이 만 14세 이상이면 체크박스를 클릭해주세요</span
          >
        </div>
      </li>
      <li>
        <div class="base-check-box">
          <input
            id="memberTerms"
            v-model="memberTerms"
            type="checkbox"
            @change="termsValid"
          />
          <label for="memberTerms"></label>
        </div>
        <div class="flex-just" @click="viewTerms('operating')">
          <p>[필수] 회원 이용약관</p>
          <i class="icon--arrow-move"></i>
        </div>
      </li>
      <li>
        <div class="base-check-box">
          <input
            id="memberPersonalTerms"
            v-model="memberPersonalTerms"
            type="checkbox"
            @change="termsValid"
          />
          <label for="memberPersonalTerms"></label>
        </div>
        <div class="flex-just" @click="viewTerms('terms')">
          <p>[필수] 회원 개인정보 수집 및 이용 동의</p>
          <i class="icon--arrow-move"></i>
        </div>
      </li>
      <li>
        <div class="base-check-box">
          <input
            id="marketingTerms"
            v-model="marketingTerms"
            type="checkbox"
            @change="termsValid"
          />
          <label for="marketingTerms"></label>
        </div>
        <div class="flex-just" @click="viewTerms('marketing')">
          <p>[선택] 회원 마케팅 수신동의</p>
          <i class="icon--arrow-move"></i>
        </div>
      </li>
    </ul>
    <button
      class="base-btn cl-pri full"
      :disabled="!isTermsBtn"
      @click="goAuthBtn"
    >
      <span class="txt-size--m">동의하고 계속하기</span>
    </button>
  </div>
</template>

<script>
import Terms from '@/components/auth/Terms.vue'
import { axiosAuthInstance } from '@/store/axios'

export default {
  name: 'Join',

  components: { Terms },
  data() {
    return {
      isTermsBtn: false,
      isTerms: false,
      termsAll: false,
      ageTerms: false,
      memberTerms: false,
      memberPersonalTerms: false,
      marketingTerms: false,
      termsInfo: {},
      termsViewData: {},
      termsName: ''
    }
  },
  created() {
    const user = this.$store.getters.getUser

    this.ageTerms = user.ageTerms
    this.memberTerms = user.memgerTerms
    this.memberPersonalTerms = user.memberPersonalTerms
    this.marketingTerms = user.marketingTerms
    console.log(user.marketingTerms)

    this.termsValid()

    axiosAuthInstance
      .get('/terms')
      .then((response) => {
        this.termsInfo = response
      })
      .catch((error) => {
        console.log('error', error)
      })
  },
  computed: {},
  methods: {
    goAuthBtn() {
      if (this.termsValid()) {
        this.termsInfo.marketing = this.termsInfo.marketing.map(
          (marketingInfo) => {
            return { ...marketingInfo, consentStatus: this.marketingTerms }
          }
        )
        this.termsInfo.terms = this.termsInfo.terms.map((termsInfo) => {
          return { ...termsInfo, consentStatus: this.termsValid() }
        })
        this.termsInfo.operating = this.termsInfo.operating.map(
          (operatingInfo) => {
            return { ...operatingInfo, consentStatus: this.termsValid() }
          }
        )

        const data = {
          ageTerms: this.ageTerms,
          memgerTerms: this.memberTerms,
          memberPersonalTerms: this.memberPersonalTerms,
          marketingTerms: this.marketingTerms,
          termsConsents: this.termsInfo
        }
        this.$store.commit('SET_USER_INFO', data)
        this.$router.push('/join/step3')
      }
    },
    termsValid() {
      this.termsCheck()
      if (
        this.ageTerms === true &&
        this.memberTerms === true &&
        this.memberPersonalTerms === true
      ) {
        this.isTermsBtn = true
        return true
      } else {
        this.isTermsBtn = false
        return false
      }
    },
    termsAllCheck() {
      if (this.termsAll) {
        this.ageTerms = true
        this.memberTerms = true
        this.memberPersonalTerms = true
        this.termsAll = true
        this.marketingTerms = true
        this.isTermsBtn = true
      } else {
        this.ageTerms = false
        this.memberTerms = false
        this.memberPersonalTerms = false
        this.termsAll = false
        this.marketingTerms = false
        this.isTermsBtn = false
      }
    },
    termsCheck() {
      if (
        this.ageTerms === true &&
        this.memberTerms === true &&
        this.memberPersonalTerms === true &&
        this.marketingTerms === true
      ) {
        this.termsAll = true
      } else {
        this.termsAll = false
      }
    },
    viewTerms(termsType) {
      if (termsType === 'marketing') {
        this.termsViewData = this.termsInfo.marketing
        this.termsName = '마케팅 수신동의'
        this.isTerms = true
        this.$router.push({
          name: 'MypageTerms',
          params: { type: 'marketing' }
        })
      } else if (termsType === 'terms') {
        this.termsViewData = this.termsInfo.terms
        this.termsName = '개인정보 수집 및 이용 동의'
        this.isTerms = true
        this.$router.push({
          name: 'MypageTerms',
          params: { type: 'terms' }
        })
      } else if (termsType === 'operating') {
        this.termsViewData = this.termsInfo.operating
        this.termsName = '회원 이용약관'
        this.isTerms = true
        this.$router.push({
          name: 'MypageTerms',
          params: { type: 'operating' }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#join-wrap {
  display: flex;
  flex-direction: column;
  .page-title-section {
    margin-bottom: 20px;
  }
  .flex-just {
    gap: 6px;
    flex: 1;
  }

  .agree-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
    > li {
      padding: 0 16px;
      &.round-box {
        margin-bottom: 4px;
        padding: 14px 16px;
        border-radius: 14px;
        background: #f5f5ff;
        .flex {
          gap: 6px;
        }
      }

      p {
        font-size: 14px;
      }
      display: flex;
      gap: 12px;
    }
  }
  > .base-btn.full {
    margin-top: auto;
  }
}
</style>
