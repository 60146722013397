// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 1024px) {
#toggle-slider-modal[data-v-5110a167] {
    z-index: 9999999;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
}
}
@media screen and (min-width: 1024px) {
#toggle-slider-modal[data-v-5110a167] {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}
}
#toggle-slider-modal[data-v-5110a167] {
  z-index: 9999999;
}
#toggle-slider-modal .modal-cont-wrap[data-v-5110a167] {
  border-radius: 14px 14px 0px 0px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
  position: absolute;
  left: 0;
  bottom: -6.25rem;
  padding-bottom: 6.25rem;
  width: 100%;
  max-height: calc(var(--vh, 1vh) * 100);
  overflow-y: scroll;
  background-color: #ffffff;
  min-height: 0px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
#toggle-slider-modal .modal-cont-wrap[data-v-5110a167]::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}
#toggle-slider-modal .modal-cont-wrap .modal-head[data-v-5110a167] {
  padding-left: 24px;
  padding-right: 24px;
  z-index: 99;
  background-color: #fff;
  position: sticky;
  display: inline-block;
  width: 100%;
  left: 0;
  top: 0;
  padding-bottom: 16px;
}
#toggle-slider-modal .modal-cont-wrap .modal-head .modal-top-area[data-v-5110a167] {
  margin: 10px auto;
  width: 39px;
  height: 4px;
  border-radius: 50px;
  background: #f1f1f1;
}
#toggle-slider-modal .modal-cont-wrap .modal-body[data-v-5110a167] {
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 16px;
}
#toggle-slider-modal .modal-cont-wrap .modal-footer[data-v-5110a167] {
  padding: 16px 24px;
  margin-top: auto;
  position: sticky;
  bottom: 0;
  background-color: #fff;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
