<template>
  <div id="join-wrap" class="white-layer">
    <div class="page-title-section mb-48">
      <h4>회원가입을 위한<br />휴대폰 인증이 필요해요</h4>
    </div>
    <ul class="input-list">
      <li class="valid-input" :class="{ error: isValid }">
        <p class="q-sub-txt txt-size--xs">휴대폰 번호</p>
        <input
          v-model="phoneNumber"
          placeholder="휴대폰 번호"
          type="text"
          maxlength="11"
        /><i
          class="icon--delete"
          v-if="phoneNumber.length > 0 && !isDisable"
          @click="phoneNumber = ''"
        ></i>
        <i v-if="isDisable" class="icon--done"></i>
        <span v-if="isValid" class="valid-txt">{{ validTxt }}</span>
      </li>
      <li
        v-if="isPhoneAuth"
        class="valid-input mt-40"
        :class="{ error: isAuthValid }"
      >
        <input
          v-model="authNumber"
          placeholder="인증번호 6자리 입력"
          type="text"
          maxlength="6"
        />
        <p class="limit-time">{{ resTimeData }}</p>
        <button
          class="base-btn disabled small q-sub-txt retry-btn"
          @click="smsReset"
        >
          인증번호 재전송
        </button>
        <span v-if="isAuthValid" class="valid-txt">{{ authValidTxt }}</span>
      </li>
    </ul>
    <button
      class="base-btn cl-pri full"
      :disabled="isValid || !isDisable"
      @click="goJoinNext"
    >
      <span class="txt-size--m">{{ buttonName }}</span>
    </button>
  </div>
</template>

<script>
import { axiosAuthInstance } from '@/store/axios'

export default {
  name: 'Join',
  data() {
    return {
      phoneNumber: '',
      authNumber: '',
      timeCounter: 180, //3분
      resTimeData: '',
      isPhoneAuth: false,
      buttonName: '인증번호 발송',
      validTxt: '',
      authValidTxt: '',
      isValid: false,
      isAuthValid: false,
      isPass: false
    }
  },
  created() {
    const user = this.$store.getters.getUser
    console.log(user)
    if (user.phoneNumber !== '') {
      this.phoneNumber = user.phoneNumber
      this.isPass = true
      this.isPhoneAuth = false
      this.isAuthValid = true
      this.buttonName = '다음'
    }
  },
  computed: {
    isDisable() {
      return this.phoneNumber.length === 11
    }
  },
  watch: {
    'phoneNumber': function () {
      return (this.phoneNumber = this.phoneNumber.replace(/[^0-9]/g, ''))
    },
    'authNumber': function () {
      return (this.authNumber = this.authNumber.replace(/[^0-9]/g, ''))
    }
  },
  methods: {
    goJoinNext() {
      if (this.isPass) {
        this.$router.push('/join/step2')
      } else {
        if (this.isPhoneAuth) {
          // 인증 번호 인증
          if (!this.isAuthNumberValid()) {
            this.isAuthValid = true
          } else {
            const param = {
              phoneNumber: this.phoneNumber,
              authenticationNumber: this.authNumber
            }

            axiosAuthInstance
              .post('/user/authentication/check', JSON.stringify(param), {
                headers: {
                  'Content-Type': `application/json`
                }
              })
              .then((response) => {
                let data = {
                  phoneNumber: this.phoneNumber
                }
                this.$store.commit('SET_USER_INFO', data)
                // 기존 사용자 안내
                if (response.userExisting === true) {
                  data = {
                    userExisting: response.userExisting,
                    userSocials: response.userSocials
                  }
                  this.$store.commit('SET_USER_INFO', data)
                  this.$router.push('/login')
                } else {
                  this.$router.push('/join/step2')
                }
              })
              .catch((error) => {
                this.$Simplert.open({
                  title: '인증번호를 다시 확인해 주세요.',
                  message:
                    '인증번호가 일치하지 않아요. 인증번호를 확인해 주세요.'
                })
                // this.isAuthValid = true
                // this.authValidTxt = '인증번호를 다시 확인해 주세요.'
                console.log('error', error)
              })
          }
        } else {
          // 핸드폰 번혼 검증
          if (!this.isPhoneNumberValid()) {
            this.isValid = true
          } else {
            this.isValid = false
            this.isPhoneAuth = true
            this.start()
            this.buttonName = '다음'
          }
        }
      }
    },
    sendSms(phoneNumber) {
      const param = {
        phoneNumber: phoneNumber
      }
      axiosAuthInstance
        .post('/user/authentication', JSON.stringify(param), {
          headers: {
            'Content-Type': `application/json`
          }
        })
        .then(() => {})
        .catch((error) => {
          if (error.response.data.message === 'PHONE_NUMBER_SEND_LIMIT') {
            this.$Simplert.open({
              title: '인증번호는 1일 최대 5회까지만 발송할 수 있습니다.',
              message:
                '인증번호는 1일 최대 5회까지만 발송할 수 있습니다. 발송 5회를 초과하여 익일에 다시 시도해주세요.'
            })
          }
          console.log('error', error)
        })
    },
    isPhoneNumberValid() {
      if (this.phoneNumber === '') {
        this.validTxt = '휴대폰 번호를 다시 확인해 주세요.'
        return false
      }

      if (!this.checkPhoneNumber(this.phoneNumber)) {
        this.validTxt = '휴대폰 번호를 다시 확인해 주세요.'
        return false
      }

      return true
    },
    isAuthNumberValid() {
      if (this.authNumber === '' || this.authNumber.length !== 6) {
        this.authValidTxt = '인증번호를 다시 확인해 주세요.'
        return false
      }

      // 3분 인증 시간 오버
      if (this.timeCounter == 0) {
        this.authValidTxt = '유효시간이 만료되었습니다.'
        this.$Simplert.open({
          title: '유효시간이 만료되었습니다.',
          message:
            '유효시간이 만료된 인증번호 입니다.<br/>인증번호를 재전송 버튼을 눌러 인증번호를 새로 받아주세요.'
        })
        return false
      }

      return true
    },
    start() {
      //문자 발송
      this.sendSms(this.phoneNumber)

      // 1초에 한번씩 start 호출
      this.polling = setInterval(() => {
        this.timeCounter-- //1찍 감소
        this.resTimeData = this.prettyTime()
        if (this.timeCounter <= 0) {
          this.timeStop()
          this.authValidTxt = '유효시간이 만료되었습니다.'
          this.isAuthValid = true
        }
      }, 1000)
    },
    // 시간 형식으로 변환 리턴
    prettyTime() {
      let time = this.timeCounter / 60
      let minutes = parseInt(time)
      let secondes = Math.round((time - minutes) * 60)
      return this.pad(minutes, 2) + ':' + this.pad(secondes, 2)
    },
    // 2자리수로 만들어줌 09,08...
    pad(n, width) {
      n = n + ''
      return n.length >= width
        ? n
        : new Array(width - n.length + 1).join('0') + n
    },
    timeStop() {
      clearInterval(this.polling)
    },
    // 재발행
    smsReset() {
      // 핸드폰 번혼 검증
      clearInterval(this.polling)
      this.timeCounter = 180
      this.resTimeData = this.prettyTime()
      if (!this.isPhoneNumberValid()) {
        this.isValid = true
      } else {
        this.isValid = false
        this.isPhoneAuth = true
        this.start()
      }
    },
    checkPhoneNumber(value) {
      const regex = /^01([0|1|6|7|8|9])?([0-9]{3,4})?([0-9]{4})$/
      if (regex.test(value) === true) {
        return true
      }
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
#join-wrap {
  display: flex;
  flex-direction: column;

  .input-list {
    gap: 32px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    .valid-input {
      position: relative;
      i,
      span,
      .limit-time {
        position: absolute;
        right: 8px;
        bottom: 6px;
      }
      .valid-txt {
        position: absolute;
        left: 0;
        top: 100%;
        font-size: 12px;
        transform: translateY(8px);
      }
      &.error {
        border-color: #d74343;
        span,
        p {
          color: #d74343;
        }
      }
      .retry-btn {
        position: absolute;
        bottom: -48px;
        right: 0;
        color: #999;
        display: inline-flex;
        height: auto;
        padding: 8px 9px;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        border-radius: 8px;
        background: #f1f1f1;
      }
    }
  }
  > .base-btn.full {
    margin-top: auto;
  }
}
</style>
