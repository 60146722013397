var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._m(0),
      _c("ToggleSwitch", {
        staticClass: "mb-14",
        attrs: { list: ["간편선택", "직접선택"], isOn: !_vm.isStatic },
        on: { setCheckboxVal: _vm.setCheckboxVal },
      }),
      _vm.isStatic
        ? _c("ul", { staticClass: "tab-list mb-40" }, [
            _c(
              "li",
              {
                staticClass: "tab",
                class: { on: _vm.year === 1 },
                on: {
                  click: function ($event) {
                    _vm.year = 1
                  },
                },
              },
              [_vm._v("1년")]
            ),
            _c(
              "li",
              {
                staticClass: "tab",
                class: { on: _vm.year === 2 },
                on: {
                  click: function ($event) {
                    _vm.year = 2
                  },
                },
              },
              [_vm._v("2년")]
            ),
            _c(
              "li",
              {
                staticClass: "tab",
                class: { on: _vm.year === 3 },
                on: {
                  click: function ($event) {
                    _vm.year = 3
                  },
                },
              },
              [_vm._v("3년")]
            ),
            false
              ? _c(
                  "li",
                  {
                    staticClass: "tab",
                    class: { on: _vm.year === 4 },
                    on: {
                      click: function ($event) {
                        _vm.year = 4
                      },
                    },
                  },
                  [_vm._v(" 4년 ")]
                )
              : _vm._e(),
            false
              ? _c(
                  "li",
                  {
                    staticClass: "tab",
                    class: { on: _vm.year === 5 },
                    on: {
                      click: function ($event) {
                        _vm.year = 5
                      },
                    },
                  },
                  [_vm._v(" 5년 ")]
                )
              : _vm._e(),
          ])
        : _vm._e(),
      !_vm.isStatic
        ? _c("div", { staticClass: "set-date-box mb-40" }, [
            _c("div", { staticClass: "round-input" }, [
              _c("label", { attrs: { for: "start" } }, [
                _c("span", [_vm._v(_vm._s(_vm.startDate))]),
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.startDate,
                    expression: "startDate",
                  },
                ],
                attrs: {
                  id: "start",
                  type: "date",
                  min: "2021-01-01",
                  max: _vm.maxStartDate,
                },
                domProps: { value: _vm.startDate },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.startDate = $event.target.value
                  },
                },
              }),
            ]),
            _c("span", [_vm._v("~")]),
            _c("div", { staticClass: "round-input" }, [
              _c("label", { attrs: { for: "end" } }, [
                _c("span", [_vm._v(_vm._s(_vm.endDate))]),
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.endDate,
                    expression: "endDate",
                  },
                ],
                attrs: {
                  id: "end",
                  type: "date",
                  min: _vm.minEndData,
                  max: _vm.maxEndDate,
                },
                domProps: { value: _vm.endDate },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.endDate = $event.target.value
                  },
                },
              }),
            ]),
          ])
        : _vm._e(),
      _c("div", { staticClass: "choose-round-list mb-40" }, [
        _c("h4", { staticClass: "mb-16" }, [
          _vm._v("포트폴리오를 언제 업데이트 할까요?"),
        ]),
        _c(
          "div",
          {
            staticClass: "round-box mb-8",
            class: { on: _vm.updDateTp === "MONTHLY" },
            on: {
              click: function ($event) {
                _vm.updDateTp = "MONTHLY"
              },
            },
          },
          [
            _c("p", { staticClass: "type-tit" }, [
              _vm._v(" 매월 "),
              _c("i", {
                staticClass: "icon--q",
                on: {
                  click: function ($event) {
                    return _vm.openQue("MONTHLY")
                  },
                },
              }),
            ]),
            _c("p", [_vm._v("매월 초에 새로운 종목과 비중을 업데이트")]),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "round-box",
            class: { on: _vm.updDateTp === "QUARTERLY" },
            on: {
              click: function ($event) {
                _vm.updDateTp = "QUARTERLY"
              },
            },
          },
          [
            _c("p", { staticClass: "type-tit" }, [
              _vm._v(" 매분기 "),
              _c("i", {
                staticClass: "icon--q",
                on: {
                  click: function ($event) {
                    return _vm.openQue("QUARTERLY")
                  },
                },
              }),
            ]),
            _c("p", [
              _vm._v("2월, 5월, 8월, 10월초에 새로운 종목과 비중으로 업데이트"),
            ]),
          ]
        ),
      ]),
      _c("div", { staticClass: "choose-round-list mb-40" }, [
        _c("h4", { staticClass: "mb-16" }, [
          _vm._v("종목별로 얼만큼식 투자할까요?"),
        ]),
        _c(
          "div",
          {
            staticClass: "round-box mb-8",
            class: { on: _vm.updAmountTp === "EQUAL_WEIGHT" },
            on: {
              click: function ($event) {
                _vm.updAmountTp = "EQUAL_WEIGHT"
              },
            },
          },
          [
            _c("p", { staticClass: "type-tit" }, [
              _vm._v(" 골고루 투자 "),
              _c("i", {
                staticClass: "icon--q",
                on: {
                  click: function ($event) {
                    return _vm.openQue("EQUAL_WEIGHT")
                  },
                },
              }),
            ]),
            _c("p", [_vm._v("각 종목을 동일한 비중으로 투자합니다")]),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "round-box",
            class: { on: _vm.updAmountTp === "MKTCAP_WEIGHT" },
            on: {
              click: function ($event) {
                _vm.updAmountTp = "MKTCAP_WEIGHT"
              },
            },
          },
          [
            _c("p", { staticClass: "type-tit" }, [
              _vm._v(" 큰 기업에 많이, 작은 기업은 조금만 투자 "),
              _c("i", {
                staticClass: "icon--q",
                on: {
                  click: function ($event) {
                    return _vm.openQue("MKTCAP_WEIGHT")
                  },
                },
              }),
            ]),
            _c("p", [_vm._v("시가총액 가중방식으로 투자합니다.")]),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "page-title-section mb-24" }, [
      _c("h4", [_vm._v("성과측정 기간을 설정하세요")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }