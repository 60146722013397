var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "bg-layer" }, [
      _c("div", { staticClass: "spinner" }, [
        _c("div", { staticClass: "loader-box" }, [
          _c("span", { staticClass: "loader" }),
        ]),
        _c("h4", [_vm._v("전략을 만드는 중 입니다.")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }