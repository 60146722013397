<template>
  <div
    class="radar-chart-wrapper"
    :class="{ small: size === 'S' }"
    :style="bindStyled"
  >
    <span v-for="(d, i) in categories" :key="i + 'label'">{{ d }}</span>

    <apexchart
      type="radar"
      :options="chartOption"
      :series="chartSeries"
      :width="chartSize.width"
      :height="chartSize.height"
      :key="componentKey"
    ></apexchart>
  </div>
</template>
<script>
export default {
  data: function () {
    return {
      componentKey: 0,
      categories: ['성장', '가치', '모멘텀', '배당', '안전', '우량'],
      chartOptions: {
        chart: {
          id: 'radar',
          toolbar: {
            show: false
          },
          width: '100%',
          animations: {
            enabled: true
          }
        },
        xaxis: {
          categories: ['성장', '가치', '모멘텀', '배당', '안전', '우량'],
          labels: {
            show: false
          }
        },

        yaxis: {
          show: false,
          tickAmount: 5,
          min: 0, // 최소 값 설정
          max: 10 // 최대 값 설정
        },
        fill: {
          colors: '#4882F5'
        },
        markers: {
          size: 0
        },
        plotOptions: {
          radar: {
            polygons: {
              //strokeColors: '#fff',
              connectorColors: '#fff',
              fill: {
                colors: ['#fff']
              }
            }
          }
        }
      },

      series: [
        {
          name: 'series-1',
          data: this.data
        }
      ]
    }
  },
  props: {
    size: {
      type: String,
      default: 'M'
    },
    isDown: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    chartSize() {
      if (this.$props.size === 'S') {
        return { width: 137, height: 160 }
      }
      if (this.$props.size === 'M') {
        return { width: 184, height: 214 }
      }
      return { width: 190, height: 180 }
    },
    bindStyled() {
      if (this.$props.size === 'S') {
        return { width: `116px`, height: `124px` }
      }
      if (this.$props.size === 'M') {
        return { width: `164px`, height: `184px` }
      }
      return {}
    },
    chartOption() {
      let opt = { ...this.chartOptions }
      opt.colors = this.$props.isDown ? ['#4882F5'] : ['#D74343']
      opt.fill.colors = this.$props.isDown ? '#4882F5' : '#D74343'
      return opt
    },
    chartSeries() {
      let series = this.series
      if (this.$props.data.length > 0) {
        series.data = this.$props.data
      }
      return series
    }
  },
  methods: {
    forceRender() {
      this.componentKey++
      if (this.componentKey > 1) {
        this.chartOption.chart.animations.enabled = false
      }
    }
  }
}
</script>
<style lang="scss">
.radar-chart-wrapper {
  position: relative;
  > div {
    position: absolute;
    min-height: auto !important;
    top: 53%;
    left: 49%;
    transform: translate(-50%, -50%);
  }
  span {
    font-size: 12px;
    color: #666;
    position: absolute;
    &:nth-child(1) {
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
    &:nth-child(2) {
      top: 25%;
      right: 0;
      transform: translate(40%, -50%);
    }
    &:nth-child(3) {
      top: 75%;
      right: 0;
      transform: translate(40%, -50%);
    }
    &:nth-child(4) {
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
    &:nth-child(5) {
      bottom: 25%;
      left: 0;
      transform: translate(-40%, 50%);
    }
    &:nth-child(6) {
      bottom: 75%;
      left: 0;
      transform: translate(-40%, 50%);
    }
  }
  &.small {
    span {
      font-size: 8px;
    }
  }
}
.apexcharts-radar-series.apexcharts-plot-series {
  polygon {
    stroke-dasharray: 2;
    stroke-width: 1;
    &:nth-child(1) {
      stroke-dasharray: 0;
      stroke-width: 2;
    }
  }
}
#apexchartsradar {
  .apexcharts-yaxistooltip {
    display: none;
  }
}
</style>
