// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.choose-mode-list[data-v-f0151afe] {
  display: flex;
  gap: 12px;
  margin: 40px 0 50px;
}
.choose-mode-list .mode-box[data-v-f0151afe] {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 65px 0;
  border-radius: 14px;
  border: 2px solid #e8e8e8;
  background: #fff;
}
.choose-mode-list .mode-box.on[data-v-f0151afe] {
  border: 2px solid #4a4ee7;
}
.choose-mode-list .mode-box > i[data-v-f0151afe] {
  margin-bottom: 16px;
}
.choose-mode-list .mode-box .mode-name[data-v-f0151afe] {
  color: #4a4ee7;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  line-height: 100%; /* 20px */
  margin-bottom: 8px;
}
.choose-mode-list .mode-box .mode-desc[data-v-f0151afe] {
  color: #666;
  text-align: center;
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
  letter-spacing: -0.24px;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
