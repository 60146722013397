var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "white-layer flex coll",
      attrs: { id: "add-strategy-01-wrap" },
    },
    [
      _c("StepBar", {
        staticClass: "mb-30",
        attrs: { stage: _vm.stage, step: _vm.step },
      }),
      _vm.stage === 1 ? [_c("Step01")] : [_c("Step02")],
      _c("div", { staticClass: "btn-wrap foot-btn-wrap" }, [
        _vm.stage === 1
          ? _c(
              "button",
              {
                staticClass: "base-btn full cl-pri",
                attrs: { disabled: _vm.stepData.list.length === 0 },
                on: { click: _vm.goNext },
              },
              [_c("span", [_vm._v("다음")])]
            )
          : _vm._e(),
        _vm.stage === 2
          ? _c(
              "button",
              {
                staticClass: "base-btn harf cl-sec",
                on: { click: _vm.goBefore },
              },
              [_c("span", [_vm._v("이전")])]
            )
          : _vm._e(),
        _vm.stage === 2
          ? _c(
              "button",
              {
                staticClass: "base-btn harf cl-pri",
                on: { click: _vm.goResult },
              },
              [_c("span", [_vm._v("결과보기")])]
            )
          : _vm._e(),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }