// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#add-strategy-01-wrap {
  overflow-y: scroll;
}
#add-strategy-01-wrap .round-box {
  border-radius: 14px;
  border: 1px solid #e8e8e8;
  background: #fff;
  padding: 16px 20px;
}
#add-strategy-01-wrap .round-box p {
  color: #999;
  font-size: 12px;
}
#add-strategy-01-wrap .round-box .type-tit {
  color: #222;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 6px;
}
#add-strategy-01-wrap .round-box.on {
  border: 1px solid #4a4ee7;
}
#add-strategy-01-wrap .round-box.on .type-tit {
  color: #4a4ee7;
}
#add-strategy-01-wrap .round-box.add-button {
  text-align: center;
}
#add-strategy-01-wrap .round-box.add-button > span {
  display: inline-flex;
  align-items: center;
  color: #666;
}
#add-strategy-01-wrap .regist-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
#add-strategy-01-wrap .regist-list .round-box {
  display: flex;
  align-items: center;
}
#add-strategy-01-wrap .regist-list .round-box span {
  color: #666;
  margin-right: 12px;
}
#add-strategy-01-wrap .regist-list .round-box i {
  margin-left: auto;
}
#add-strategy-01-wrap .tab-list {
  display: flex;
  gap: 8px;
}
#add-strategy-01-wrap .tab-list li {
  display: flex;
  height: 42px;
  flex: 1;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid #e8e8e8;
}
#add-strategy-01-wrap .tab-list li.on {
  border: 1px solid #4a4ee7;
  color: #4a4ee7;
}
#add-strategy-01-wrap .btn-wrap {
  margin-top: auto;
  display: flex;
  gap: 8px;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
