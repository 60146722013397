// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bg-layer[data-v-546ee203] {
  display: flex;
  align-items: center;
  justify-content: center;
}
.spinner .loader-box[data-v-546ee203] {
  height: 48px;
  margin-left: -20px;
  text-align: center;
}
.spinner h4[data-v-546ee203] {
  margin-top: 32px;
  color: #fff;
  text-align: center;
  font-size: 22px;
  font-weight: 600;
}
.spinner .loader[data-v-546ee203] {
  width: 8px;
  height: 32px;
  border-radius: 4px;
  display: inline-block;
  margin-left: 20px;
  margin-top: 10px;
  position: relative;
  background: currentColor;
  color: #fff;
  box-sizing: border-box;
  animation: animloader-546ee203 0.5s 0.5s linear infinite alternate;
}
.spinner .loader[data-v-546ee203]::after,
.spinner .loader[data-v-546ee203]::before {
  content: "";
  box-sizing: border-box;
  width: 8px;
  height: 32px;
  border-radius: 4px;
  background: currentColor;
  position: absolute;
  bottom: 0;
  left: 20px;
  animation: animloader1-546ee203 0.5s 0.65s linear infinite alternate;
}
.spinner .loader[data-v-546ee203]::before {
  left: -20px;
  animation-delay: 0s;
}
@keyframes animloader-546ee203 {
0% {
    height: 32px;
    transform: translateY(0);
}
100% {
    height: 10px;
    transform: translateY(30px);
}
}
@keyframes animloader1-546ee203 {
0% {
    height: 48px;
}
100% {
    height: 4.8px;
}
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
