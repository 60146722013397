// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.switch[data-v-df0ed134] {
  position: relative;
  display: block;
  width: 100%;
  height: 44px;
  padding: 4px;
  background-color: #f1f1f1;
  border-radius: 50px;
}
.switch input[data-v-df0ed134] {
  display: none;
}
.switch input:checked + .slider[data-v-df0ed134]:before {
  left: 50%;
}
.switch .slider[data-v-df0ed134] {
  display: flex;
  position: absolute;
  cursor: pointer;
  top: 4px;
  left: 4px;
  right: 4px;
  bottom: 4px;
  transition: 0.4s;
}
.switch .slider span[data-v-df0ed134] {
  flex: 1;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}
.switch .slider span.check-text[data-v-df0ed134] {
  color: #222;
}
.switch .slider[data-v-df0ed134]:before {
  position: absolute;
  content: "";
  height: 100%;
  width: 50%;
  top: 0;
  left: 0;
  border-radius: 50px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
  background-color: white;
  transition: 0.4s;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
