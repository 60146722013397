// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.detail-title[data-v-2b449754] {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  font-size: 22px;
  font-weight: 600;
  line-height: 130%; /* 28.6px */
  letter-spacing: -0.44px;
  margin-bottom: 24px;
}
.detail-select[data-v-2b449754] {
  border-radius: 10px;
  border: 1px solid #e8e8e8;
  background: #fff;
  color: #666;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
  width: 118px;
  height: 38px;
  box-sizing: border-box;
  padding: 0 12px;
}
.detail-box[data-v-2b449754] {
  margin-top: 16px;
  padding-top: 24px;
  border-top: 1px solid #e8e8e8;
}
.detail-box h3[data-v-2b449754] {
  font-size: 14px;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
  letter-spacing: -0.28px;
  margin-bottom: 16px;
}
.detail-box p[data-v-2b449754] {
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: 160%; /* 22.4px */
  letter-spacing: -0.28px;
  margin-bottom: 48px;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
