import { getMain } from '@/api'

// Set current selected library as default library
const state = {
  main: null
}

const getters = {
  getMainShoppingList: (state) => state.main.mainShoppingList || [],
  getMainCommunityList: (state) => state.main.mainCommunityList || [],
  getMainRankList: (state) => state.main.mainRankList || [],
  getMainRoungeList: (state) => state.main.mainRoungeList || [],
  getMainPopularList: (state) => state.main.mainPopularList || [],
  getMainMorePortpolio: (state) => state.main.mainMorePortpolio || []
}

const actions = {
  fetchMain({ commit }) {
    return new Promise((resolve, reject) => {
      getMain()
        .then((resp) => {
          commit('SET_MAIN_DATA', resp.data.result)
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

const mutations = {
  SET_MAIN_DATA(state, payload) {
    state.main = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
