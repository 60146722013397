// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.swiper-container[data-v-4bae3287] {
  margin: 0;
}
.swiper-slide[data-v-4bae3287] {
  flex-shrink: 1;
}
.filter-tab[data-v-4bae3287] {
  height: 42px;
  width: 90px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #999;
  border-radius: 10px;
  border: 1px solid #e8e8e8;
}
.filter-tab.on[data-v-4bae3287] {
  border: 1px solid #4a4ee7;
  color: #4a4ee7;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
