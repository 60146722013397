<template>
  <div class="products-sell">
    <div class="products-sell-datepicker">
      <!-- <img src="" alt="<" /> -->
      <i class="icon--arrow-date" @click="previousYear()"></i>
      <span>{{ year }}</span>
      <!-- <img src="" alt=">" /> -->
      <i class="icon--arrow-date right" @click="nextYear()"></i>
    </div>
    <template>
      <div
        v-for="item in mypageProductsSell"
        :key="item.month"
        class="products-sell-list"
      >
        <h2 class="products-sell-list-month">{{ item.month }}월</h2>
        <div class="grid-table">
          <ul class="table-head">
            <li>상품명</li>
            <li>판매수량</li>
            <li>수수료</li>
            <li>판매총액</li>
            <li><span class="proceeds">수익금</span></li>
          </ul>
          <ul
            v-for="(row, i) in item.rowData"
            :key="i + 'search'"
            class="table-list"
          >
            <li>
              <p class="no-wrap-text-2">{{ row.title }}</p>
            </li>
            <li>{{ row.sellCount }}</li>
            <li>{{ row.moneyCount }}</li>
            <li>{{ row.totalMoney }}</li>
            <li>
              <span class="proceeds">{{ row.myMoney }}</span>
            </li>
          </ul>
        </div>
        <!-- <ul class="products-sell-list-box">
          <li
            v-for="(title, idx) in item.titleList"
            :key="idx"
            class="row-title"
            :class="{ price: title === '수익금' }"
          >
            <span>{{ title }}</span>
          </li>
          <li
            v-for="(row, idx) in item.rowData"
            :key="`row-${idx}`"
            class="row-data"
          >
            <span>{{ row.title }}</span>
            <span>{{ row.sellCount }}</span>
            <span>{{ row.moneyCount }}</span>
            <span>{{ row.totalMoney }}</span>
            <span>{{ row.myMoney }}</span>
          </li>
        </ul> -->
      </div>
    </template>

    <template>
      <p class="null-message">상품 판매 내역이 없습니다.</p>
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      year: 2023,
      mypageProductsSell: []
    }
  },
  created() {
    const now = new Date() // 현재 날짜 및 시간
    this.year = now.getFullYear() // 연도
  },
  methods: {
    previousYear() {
      this.year -= 1
    },
    nextYear() {
      this.year += 1
    }
  }
}
</script>

<style lang="scss" scoped>
.grid-table {
  .table-head {
    li,
    span {
      font-weight: 500;
    }
  }
  .table-head,
  .table-list {
    padding-left: 24px;
    padding-right: 24px;
    grid-template-columns: repeat(5, 1fr);
    padding: 0;
    .no-wrap-text-2 {
      padding-left: 16px;
    }
    .proceeds {
      color: #4a4ee7;
    }
  }
  .table-list {
    min-height: 42px;
    height: auto;
    padding-top: 8px;
    padding-bottom: 8px;

    > li {
      height: auto;
      min-height: auto;
      color: #666;
    }
  }
}
.products-sell {
  padding: 16px 0 38px;
  &-datepicker {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 26px;
    border-bottom: 1px solid #e8e8e8;
    span {
      font-size: 20px;
      font-weight: 500;
      margin: 0 12px;
    }
  }
  &-list {
    padding: 24px 0 0 0;
    &-month {
      padding-left: 16px;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: -0.32px;
      margin-bottom: 16px;
    }
    &-box {
      display: flex;
      flex-wrap: wrap;
      text-align: center;
      margin-bottom: 24px;
      .row-title {
        background: #f7f7f7;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: -0.28px;
        width: 20%;
        height: 36px;
        line-height: 36px;
      }
      .row-title.price {
        color: #4a4ee7;
      }

      .row-data {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        color: #666;
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: -0.28px;
        border-bottom: 1px solid #e8e8e8;
        span {
          display: inline-block;
          width: 20%;
          padding: 13px 0;
        }
        span:last-child {
          color: #4a4ee7;
        }
      }
      .row-data:last-child {
        color: #4a4ee7;
      }
    }
  }
  .null-message {
    color: #999;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.28px;
    margin-top: 48px;
  }
}
</style>
