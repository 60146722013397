<template>
  <div id="authority-wrap" class="flex coll white-layer">
    <div class="page-title-section">
      <h4>이용을 위해 권한 허용이 필요해요</h4>
      <p class="q-sub-txt">
        퀀트마켓 이용을 위해 다음 권한의 허용이 필요합니다.
      </p>
    </div>
    <ul class="authority-list">
      <li>
        <i class="icon--call"></i>
        <div>
          <p class="txt-size--s">전화(필수)</p>
          <span class="q-sub-txt txt-size--xs"
            >휴대전화 본인인증, 기기 식별인증 및 전화 연결</span
          >
        </div>
      </li>
      <li>
        <i class="icon--photo"></i>
        <div>
          <p class="txt-size--s">저장(필수)</p>
          <span class="q-sub-txt txt-size--xs"
            >사진, 미디어, 파일 저장 및 불러오기 접근 허용</span
          >
        </div>
      </li>
      <li>
        <i class="icon--camera"></i>
        <div>
          <p class="txt-size--s">카메라(선택)</p>
          <span class="q-sub-txt txt-size--xs"
            >실명인증을 위한 신분증 또는 면허증 제출</span
          >
        </div>
      </li>
      <li>
        <i class="icon--area"></i>
        <div>
          <p class="txt-size--s">위치(선택)</p>
          <span class="q-sub-txt txt-size--xs">주소 찾기</span>
        </div>
      </li>
    </ul>
    <p class="q-sub-txt txt-size--s">
      ※ 선택 접근 권한은 동의하지 않아도 서비스 이용 할 수 있습니다.
    </p>
    <button class="base-btn cl-pri full" @click="goAuthBtn">
      <span class="txt-size--m">확인</span>
    </button>
  </div>
</template>

<script>
import { axiosAuthInstance } from '@/store/axios'

export default {
  name: 'Authority',
  data() {
    return {}
  },

  computed: {
    useOauthTypes() {
      return ''
    }
  },
  methods: {
    goAuthBtn() {
      //this.$router.push('/')
      const termsConsents = []
      const user = this.$store.getters.getUser
      const terms = user.termsConsents
      if (user.marketingTerms) {
        for (const termsInfo of terms.marketing) {
          termsConsents.push(termsInfo.termsId)
        }
      }
      if (
        user.ageTerms === true &&
        user.memberPersonalTerms === true &&
        user.memgerTerms === true
      ) {
        for (const termsInfo of terms.terms) {
          termsConsents.push(termsInfo.termsId)
        }
      }

      const parma = {
        nickName: user.nickName,
        phoneNumber: user.phoneNumber,
        email: user.email,
        gender: 'UNKNOWN',
        birthDay: null,
        userType: 'GENERAL',
        socialId: user.socialId,
        socialType: user.socialType,
        termsConsentItems: termsConsents
      }

      axiosAuthInstance
        .post('/user', JSON.stringify(parma), {
          headers: {
            'Content-Type': `application/json`
          }
        })
        .then(() => {
          this.$router.push('/')
          return false
        })
        .catch((error) => {
          console.log('error', error)
          return true
        })
    }
  }
}
</script>

<style lang="scss" scoped>
#authority-wrap {
  .authority-list {
    display: flex;
    flex-direction: column;
    margin-top: 48px;
    margin-bottom: 16px;
    gap: 12px;
    > li {
      display: flex;
      padding: 14px;
      align-items: center;
      border-radius: 14px;
      background: #fff;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
      height: 68px;
      gap: 10px;
      > div {
        gap: 4px;
      }
    }
  }
  .base-btn {
    margin-top: auto;
  }
}
</style>
