var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "diff-wrap" }, [
    _c("div", { staticClass: "head-box" }, [
      _c("h3", [_vm._v("구성종목")]),
      _vm._m(0),
      _c("div", { staticClass: "date-box flex mt-32" }, [
        _vm.previousBaseDate
          ? _c("div", { staticClass: "l-box" }, [
              _c("strong", [_vm._v("비교대상")]),
              _c("div", { staticClass: "date flex-center" }, [
                _vm.isPreviousBtn
                  ? _c("i", {
                      staticClass: "icon--arrow-back",
                      on: {
                        click: function ($event) {
                          return _vm.moveStockData(1)
                        },
                      },
                    })
                  : _vm._e(),
                _c("span", [_vm._v(_vm._s(_vm.previousBaseDate))]),
                _vm.isNextBtn
                  ? _c("i", {
                      staticClass: "icon--arrow-move",
                      on: {
                        click: function ($event) {
                          return _vm.moveStockData(-1)
                        },
                      },
                    })
                  : _vm._e(),
              ]),
            ])
          : _vm._e(),
        _c("div", { staticClass: "r-box" }, [
          _c("strong", [_vm._v("현재")]),
          _c("div", { staticClass: "date" }, [
            _c("span", [_vm._v(_vm._s(_vm.nowBaseDate))]),
          ]),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "diff-body mt-16" }, [
      _c("div", { staticClass: "compo-section" }, [
        _c("div", { staticClass: "sec-body" }, [
          _c(
            "div",
            { staticClass: "grid-table" },
            [
              _vm._m(1),
              _vm._l(_vm.diffInfo, function (d, i) {
                return _c(
                  "ul",
                  { key: i + "search", staticClass: "table-list" },
                  [
                    _c("li", [_c("p", [_vm._v(_vm._s(d.stockName))])]),
                    _c("li", [
                      _c("p", [
                        _vm._v(
                          _vm._s(Number(d.currentPrices).toLocaleString())
                        ),
                      ]),
                      _c(
                        "p",
                        { staticClass: "mod-price", class: d.priceStatus },
                        [
                          _vm._v(
                            " (" +
                              _vm._s(
                                Number(d.diffCurrentPrices).toLocaleString()
                              ) +
                              ") "
                          ),
                        ]
                      ),
                    ]),
                    _c("li", [
                      _c("p", [_vm._v(_vm._s(_vm.weight(d.weight)) + "%")]),
                      _c(
                        "p",
                        { staticClass: "mod-price", class: d.weightStatus },
                        [
                          _vm._v(
                            " (" + _vm._s(_vm.weight(d.diffWeight)) + "%) "
                          ),
                        ]
                      ),
                    ]),
                    _c("li", [
                      _c("p", [
                        _vm._v(
                          _vm._s(Number(d.amount).toLocaleString()) + "주"
                        ),
                      ]),
                      _c(
                        "p",
                        { staticClass: "mod-price", class: d.amountStatus },
                        [
                          _vm._v(
                            " (" +
                              _vm._s(Number(d.modAmount).toLocaleString()) +
                              ") "
                          ),
                        ]
                      ),
                    ]),
                  ]
                )
              }),
            ],
            2
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", { staticClass: "disc-list mt-8" }, [
      _c("li", [
        _vm._v("원 투자금액 기준 이전 포트포트폴리오 구성비 변경 분 입니다."),
      ]),
      _c("li", [_vm._v("수익 및 손실은 적용되지 않습니다.")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", { staticClass: "table-head" }, [
      _c("li", [_vm._v("종목")]),
      _c("li", [_vm._v("주가")]),
      _c("li", [_vm._v("비중")]),
      _c("li", [_vm._v("주식수")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }