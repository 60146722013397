<template>
  <div class="policy">
    <ul>
      <li
        class="policy-item"
        @click="
          $router.push({ name: 'MypageTerms', params: { type: 'operating' } })
        "
      >
        <span>퀀트마켓 이용약관</span>
        <i class="icon--arrow-move"></i>
      </li>
      <li
        class="policy-item"
        @click="
          $router.push({
            name: 'MypageTerms',
            params: { type: 'terms' }
          })
        "
      >
        <span>퀀트마켓 개인정보 처리방침</span>
        <i class="icon--arrow-move"></i>
      </li>
    </ul>
    <div class="bottom-box wrapper">
      <h2 class="bottom-box-title">서비스 이용 동의 현황</h2>
      <p class="bottom-box-item">
        <span>회원 마케팅 수신동의</span>
        <ToggleButton
          :isBig="true"
          :data="isUserTermsMarketing"
          ref="termsCheckbox"
          @updated="updateTerms"
        ></ToggleButton>
      </p>
      <p
        class="bottom-box-text"
        @click="
          $router.push({ name: 'MypageTerms', params: { type: 'marketing' } })
        "
      >
        마케팅 수신 동의
        <i class="icon--arrow-move"></i>
      </p>
    </div>
  </div>
</template>

<script>
import ToggleButton from '@/components/button/ToggleButton.vue'
import { axiosAuthInstance } from '@/store/axios'
export default {
  components: { ToggleButton },
  data() {
    return { isUserTermsMarketing: false }
  },
  created() {
    axiosAuthInstance
      .get('/user/terms/marketing', {})
      .then((response) => {
        this.isUserTermsMarketing = response.isUserTermsMarketing
        this.$refs.termsCheckbox.updateCheckbox(this.isUserTermsMarketing)
      })
      .catch((error) => {
        console.log('error', error)
      })
  },
  methods: {
    updateTerms(info, isOn) {
      console.log(isOn)
      const param = { consentStatus: isOn ? 'ACTIVE' : 'INACTIVE' }
      axiosAuthInstance
        .put('/user/terms/marketing', JSON.stringify(param), {
          headers: {
            'Content-Type': `application/json`
          }
        })
        .then(() => {})
        .catch((error) => {
          console.log('error', error)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.policy {
  &-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    color: #666;
    width: 100%;
    height: 54px;
    padding: 0 4.4444444%;
  }
  &-item:nth-child(2) {
    height: 66px;
    border-bottom: 12px solid #f1f1f1;
  }
  .bottom-box {
    &-title {
      color: #222;
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%; /* 16px */
      letter-spacing: -0.32px;
      margin: 32px 0 16px;
    }
    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 22.4px */
      color: #222;
      width: 100%;
      height: 54px;
    }
    &-text {
      color: #666;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 19.6px */
      letter-spacing: -0.28px;
      margin-top: 8px;
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
