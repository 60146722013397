export default {
  // RELATED
  squareOptions: {
    loop: false,
    // slidesPerView: 'auto'
    cssWidthAndHeight: true,
    slidesPerView: 4,
    visibleFullFit: true
    // autoResize: false
  }
  // 추천 교제
}
