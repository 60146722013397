// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/images/icon_crown.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rank-image-center[data-v-31eacb6a] {
  position: absolute !important;
  width: 70% !important;
  height: 70% !important;
  left: 15% !important;
  top: 15% !important;
}
.ranking-box[data-v-31eacb6a] {
  display: flex;
  align-items: center;
  gap: 12px;
}
.ranking-box .prof-thumb[data-v-31eacb6a] {
  border-radius: 50%;
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
  flex-shrink: 0;
  position: relative;
}
.ranking-box .prof-thumb > img[data-v-31eacb6a] {
  border-radius: 50%;
  display: inline-block;
  width: 100%;
  height: 100%;
}
.ranking-box .rank-no[data-v-31eacb6a] {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-basis: 20px;
  flex-shrink: 0;
  font-size: 14px;
  font-weight: 600;
  gap: 6px;
}
.ranking-box .rank-no > .no[data-v-31eacb6a] {
  font-size: 14px;
}
.ranking-box .rank-no > .new-badge[data-v-31eacb6a] {
  color: #d74343;
  text-align: center;
  font-size: 8px;
}
.ranking-box .rank-desc[data-v-31eacb6a] {
  display: flex;
  flex-direction: column;
}
.ranking-box .rank-desc > p[data-v-31eacb6a] {
  font-size: 14px;
  color: #222;
  font-weight: 500;
  margin-bottom: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}
.ranking-box .rank-desc > span[data-v-31eacb6a] {
  color: #aaa;
  font-size: 12px;
  font-weight: 400;
}
.ranking-box .percent[data-v-31eacb6a] {
  color: #d74343;
  font-size: 18px;
  font-weight: 600;
  margin-left: auto;
}
.ranking-box .percent.status[data-v-31eacb6a] {
  color: #4a4ee7;
}
.ranking-box.is-top-rank[data-v-31eacb6a] {
  flex: 1;
  border-radius: 14px;
  border: 1px solid #f1f1f1;
  height: 187px;
  background: #f7f7f7;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  gap: 8px;
  padding: 16px 10px 20px;
}
.ranking-box.is-top-rank .prof-thumb[data-v-31eacb6a] {
  min-width: 66px;
  min-height: 66px;
  border-radius: 50px;
  border: 2px solid #feea61;
}
.ranking-box.is-top-rank .prof-thumb[data-v-31eacb6a]::after {
  content: "";
  position: absolute;
  display: inline-block;
  top: 0;
  left: 50%;
  width: 22px;
  height: 14px;
  transform: translate(-50%, -9px);
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
.ranking-box.is-top-rank .rank-no[data-v-31eacb6a] {
  position: absolute;
  left: 8px;
  top: 8px;
  display: block;
  font-size: 16px;
}
.ranking-box.is-top-rank .rank-no > .no[data-v-31eacb6a] {
  font-size: 16px;
}
.ranking-box.is-top-rank .rank-desc[data-v-31eacb6a] {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.ranking-box.is-top-rank .rank-desc > p[data-v-31eacb6a] {
  text-align: center;
}
.ranking-box.is-top-rank .percent[data-v-31eacb6a] {
  text-align: center;
  color: #d74343;
  font-size: 18px;
  font-weight: 600;
  margin-left: auto;
  margin-right: auto;
}
.ranking-box.is-top-rank .percent.status[data-v-31eacb6a] {
  color: #4a4ee7;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
