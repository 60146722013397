<template>
  <div id="add-products-wrap" class="flex coll white-layer layer">
    <div class="page-head-options">
      <i class="icon--close"></i>
    </div>
    <div class="page-title-section">
      <h3>상품 만들기 신청</h3>
      <p class="q-sub-txt">회원님의 멋진 아이디어를 상품으로 만들어보세요.</p>
    </div>
    <button class="go-detail-btn" @click="toggleQues = true">
      <span>상세안내</span><i class="icon--arrow-move"></i>
    </button>
    <div class="round-wrap-box">
      <h5 class="mb-24">상품 만들기가 가능한 포트폴리오</h5>
      <ExamRowList :rows="portfoliosReview" @rowClick="rowClick" />
    </div>
    <SlideUpModal v-if="toggleReqeust">
      <template #header>
        <div class="flex-just mb-16">
          <h5>심사 요청하기</h5>
          <i class="icon--close" @click="toggleReqeust = false"></i>
        </div>
      </template>
      <template #main>
        <InputText
          v-model="comment"
          placeholder="회원님 포트폴리오를 설명해주세요"
          @update:value="comment = $event"
        ></InputText>
      </template>
      <template #button>
        <button
          :disabled="comment.length === 0"
          class="base-btn full cl-pri req-btn"
          @click="portfolioReviewApplication"
        >
          <span>전송</span>
        </button>
      </template>
    </SlideUpModal>
    <FullModal v-if="toggleQues">
      <div class="full-modal">
        <div class="flex-end full-modal-head">
          <i class="icon--close" @click="toggleQues = false"></i>
        </div>
        <div class="full-modal-body">
          <h3 class="mt-8 mb-24">상품 만들기 절차</h3>
          <ul>
            <li v-for="(d, i) in steps" :key="i + 'step'">
              <div class="l-box">
                <span>{{ `STEP${i + 1}` }}</span>
              </div>
              <div class="r-box">
                <p class="title">{{ d.title }}</p>
                <p class="desc">{{ d.desc }}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </FullModal>
  </div>
</template>

<script>
import ExamRowList from '@/components/ExamRowList'
import SlideUpModal from '@/components/common/modal/SlideUpModal'
import InputText from '@/components/base/InputText.vue'
import FullModal from '@/components/common/modal/FullModal.vue'
import { axiosAuthInstance } from '@/store/axios'
export default {
  name: 'QuantMarketAddProduct',
  components: {
    ExamRowList,
    SlideUpModal,
    InputText,
    FullModal
  },
  data() {
    return {
      toggleReqeust: false,
      toggleQues: false,
      comment: '',
      steps: [
        {
          title: '심사요청(회원님)',
          desc: `회원님이 저장한 포트폴리오를 가지고 판매
심사 요청을 할 수 있어요.
저장된 포트폴리오 없더라도, 좋은 아이디어가
있다면 퀀트마켓과 공동개발 할 수 있어요.`
        },
        {
          title: '심사(퀀트마켓)',
          desc: `퀀트마켓이 회원님 제안을 심사합니다.
심사기간은 영업일 기준 최대 3일 소요됩니다.`
        },
        {
          title: '상품화 작업',
          desc: `심사가 통과되면, 퀀트마켓은 회원님 아이디어를 상품화합니다. 이 과정에서 회원님 아이디어가
일부 수정될 수 있습니다. 퀀트마켓이 상품가격
책정, 수익배분 등을 회원님과 논의합니다.`
        },
        {
          title: '계약',
          desc: `회원님의 아이디어를 퀀트마켓에 판매하는
계약을 체결합니다.`
        },
        {
          title: '운영',
          desc: `퀀트마켓은 상품 관리자로써 성실하게 포트폴리오를 운영하고 판매합니다.
회원은 언제든지 본인의 아이디어를 바탕으로
제작된 상품의 판매현황을 퀀트마켓을 통해 확인할 수 있습니다.`
        }
      ],
      portfolios: [],
      portfoliosReview: [],
      portfolioId: 0
    }
  },

  created() {
    this.getPortfolioList()
  },
  mounted() {},

  methods: {
    getPortfolioList() {
      const param = {
        index: 0,
        size: 50
      }
      axiosAuthInstance
        .get('/portfolio', JSON.stringify(param))
        .then((response) => {
          console.log(response)
          this.portfolios = response.portfolios.filter(
            (portfolio) => portfolio.reviewStatus !== 'UNABLE'
          )
          this.portfoliosReview = this.portfolios.map((portfolio) => {
            console.log(portfolio.reviewStatus)
            let status
            switch (portfolio.reviewStatus) {
              case 'WAIT':
                status = 'add'
                break
              case 'REQUEST':
                status = 'add'
                break
              case 'UNDER_REVIEW':
                status = 'check'
                break
              case 'APPROVAL':
                status = 'finish'
                break
              case 'REJECT':
                status = 'cancel'
                break
              case 'UNABLE':
                status = 'cancel'
                break
            }
            return {
              rowTitle: portfolio.portfolioName,
              createdDate: portfolio.createdDate,
              status: status
            }
          })
        })
        .catch((error) => {
          console.log('error', error)
        })
    },
    rowClick(data) {
      if (data.reviewStatus === 'WAIT') {
        this.portfolioId = data.portfolioId
        this.toggleReqeust = true
        return
      } else if (data.reviewStatus !== 'UNABLE') {
        axiosAuthInstance
          .get('/portfolio/review/' + data.portfolioId, {})
          .then((response) => {
            this.portfolios = response.portfolios

            const reviewDetail = {
              buttonText: '',
              createdDate: data.createdDate,
              rowTitle: data.portfolioName,
              status: this.convertStatus(data.reviewStatus),
              reviews: response.reviews
            }
            console.log(reviewDetail)
            this.$store.commit('SET_TYPE', 'portfolio')
            this.$store.commit('SET_PORTFOLIO', reviewDetail)
            this.$router.push({
              name: 'ExamineDetail'
            })
          })
          .catch((error) => {
            console.log('error', error)
          })
      }
    },
    portfolioReviewApplication() {
      const param = {
        comment: this.comment
      }
      axiosAuthInstance
        .post('/portfolio/reviews/' + this.portfolioId, JSON.stringify(param), {
          headers: {
            'Content-Type': `application/json`
          }
        })
        .then(() => {
          this.toggleReqeust = false
          this.getPortfolioList()
        })
        .catch((error) => {
          console.log('error', error)
        })
    }
  },
  convertStatus(value) {
    console.log(value)
    if (value === 'WAIT') return 'add'
    if (value === 'REQUEST') return 'add'
    if (value === 'UNDER_REVIEW') return 'check'
    if (value === 'APPROVAL') return 'finish'
    if (value === 'REJECT') return 'cancel'
    if (value === 'UNABLE') return 'cancel'
  }
}
</script>

<style lang="scss" scoped>
#add-products-wrap {
  .go-detail-btn {
    display: inline-flex;
    margin: 16px 0 32px;
    align-items: center;
    justify-content: center;
    width: 83px;
    height: 28px;
    border-radius: 6px;
    gap: 3px;
    background: #f5f5ff;
  }
  .round-wrap-box {
    border-radius: 14px;
    border: 1px solid #e8e8e8;
    padding: 32px 16px;
  }
  .req-btn {
    margin-top: 170px;
  }
  .full-modal {
    &-head {
      padding-bottom: 16px;
    }
    &-body {
      ul {
        display: flex;
        flex-direction: column;
        gap: 32px;
        li {
          display: flex;
          gap: 10px;
          .l-box {
            span {
              padding: 8px 12px;
              font-weight: 500;
              border-radius: 50px;
              background: #feea61;
            }
          }
          .r-box {
            .title {
              font-size: 16px;
              font-weight: 600;
              margin-bottom: 10px;
            }
            .desc {
              color: #666;
              line-height: 160%; /* 22.4px */
            }
          }
        }
      }
    }
  }
}
</style>
