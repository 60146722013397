var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "examine wrapper layer" },
    [
      _vm._m(0),
      _vm.type === "port"
        ? _c("div", { staticClass: "examine-top" }, [
            _c("h2", { staticClass: "examine-top-title" }, [
              _vm._v(_vm._s(_vm.data.title)),
            ]),
            _c("div", { staticClass: "sub-box" }, [
              _c("p", { staticClass: "examine-top-description" }, [
                _vm._v(_vm._s(_vm.data.name)),
              ]),
            ]),
          ])
        : _vm.type === "comm"
        ? _c("div", { staticClass: "examine-top" }, [
            _c("h2", { staticClass: "examine-top-title" }, [
              _vm._v(_vm._s(_vm.data.title)),
            ]),
            _c("div", { staticClass: "sub-box" }, [
              _c("p", { staticClass: "examine-top-description" }, [
                _vm._v(_vm._s(_vm.data.name)),
              ]),
              _c(
                "button",
                {
                  staticClass: "flex-between",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("detail")
                    },
                  },
                },
                [
                  _vm._v(" 상세 "),
                  _c("i", {
                    staticClass: "icon--arrow-move",
                    on: { click: _vm.goDetail },
                  }),
                ]
              ),
            ]),
          ])
        : _c("div", { staticClass: "examine-top" }, [
            _c("h3", [_vm._v("판매심사현황")]),
          ]),
      [
        _c("ul", { staticClass: "examine-status" }, [
          _c(
            "li",
            {
              staticClass: "examine-status-item",
              class: { active: _vm.crntStage === 1 },
            },
            [_vm._v(" 심사 접수 ")]
          ),
          _c("li", { staticClass: "bar" }),
          _c(
            "li",
            {
              staticClass: "examine-status-item",
              class: { active: _vm.crntStage === 2 },
            },
            [_vm._v(" 심사중 ")]
          ),
          _c("li", { staticClass: "bar" }),
          _c(
            "li",
            {
              staticClass: "examine-status-item",
              class: { active: _vm.crntStage === 3 },
            },
            [_vm._v(" 심사 완료 ")]
          ),
        ]),
      ],
      [
        _c("div", { staticClass: "examine-text" }, [
          _c(
            "div",
            { staticClass: "examine-text-box" },
            [
              _vm.crntStage !== 3
                ? _c("p", { staticClass: "examine-text-box-description" }, [
                    _vm._v(" " + _vm._s(_vm.stageMessage) + " "),
                  ])
                : _vm._e(),
              _vm.crntStage === 3
                ? [
                    _c("p", [_vm._v(_vm._s(_vm.examStatus))]),
                    _c(
                      "div",
                      {
                        staticClass: "examine-text-box-status reject",
                        class: _vm.resultTp,
                      },
                      [_vm._v(" 결과: " + _vm._s(_vm.resultName) + " ")]
                    ),
                    _vm.resultReason
                      ? _c("p", { staticClass: "examine-text-box-reject" }, [
                          _vm._v(" " + _vm._s(_vm.resultReason) + " "),
                        ])
                      : _vm._e(),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ]),
      ],
      [
        _c("div", { staticClass: "examine-list" }, [
          _c("div", { staticClass: "examine-list-nav flex-between" }, [
            _c("p", { staticClass: "examine-list-nav-title" }, [
              _vm._v("진행내역"),
            ]),
            _c(
              "button",
              {
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.$emit("cancel")
                  },
                },
              },
              [
                _vm._v(" 커뮤니티 삭제 "),
                _c("i", { staticClass: "icon--arrow-move" }),
              ]
            ),
          ]),
          _c(
            "ul",
            { staticClass: "examine-list-box" },
            _vm._l(_vm.progressList, function (d, i) {
              return _c(
                "li",
                { key: i + "hist", staticClass: "examine-list-box-item" },
                [
                  _c("span", { staticClass: "examine-list-box-item-date" }, [
                    _vm._v(_vm._s(d.date)),
                  ]),
                  _c("span", [_vm._v("회원님이 심사요청을 하셨습니다.")]),
                ]
              )
            }),
            0
          ),
        ]),
      ],
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "page-head-options" }, [
      _c("i", { staticClass: "icon--close" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }