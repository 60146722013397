<template>
  <SlideUpModal class="strategy-regist-modal">
    <template #header>
      <div class="flex-just">
        <h4>전략만들기 타입을 선택해주세요</h4>
        <i class="icon--close" @click="closeSlider"></i>
      </div>
    </template>
    <template #main>
      <div class="choose-mode-list">
        <div
          class="mode-box"
          :class="{ on: choose === '01' }"
          @click="choose = '01'"
        >
          <i class="icon--mode-01"></i>
          <h4 class="mode-name">직접종목선정</h4>
          <p class="mode-desc">마음에 드는 종목만<br />쏙쏙 골라 담기</p>
        </div>
        <div
          class="mode-box"
          :class="{ on: choose === '02' }"
          @click="choose = '02'"
        >
          <i class="icon--mode-02"></i>
          <h4 class="mode-name">전략구현하기</h4>
          <p class="mode-desc">
            설정한 조건에 따라<br />
            만들어지는 포트폴리오
          </p>
        </div>
      </div>
    </template>
    <template #button>
      <div class="modal-btn-wrap">
        <button class="base-btn full cl-pri" @click="goAdded">
          <span>만들기</span>
        </button>
      </div>
    </template>
  </SlideUpModal>
</template>

<script>
import SlideUpModal from '@/components/common/modal/SlideUpModal.vue'

export default {
  name: 'QuantMarketCommRegisterModal',
  components: { SlideUpModal },
  data() {
    return {
      choose: '01'
    }
  },
  mounted() {},
  methods: {
    closeSlider() {
      this.$store.commit('toggleAddStrategy', false)
      this.$emit('close')
    },
    goAdded() {
      if (this.choose === '01') {
        this.$router.push({ name: 'AddStrategy01' }).catch(() => {})
      } else {
        this.$router.push({ name: 'AddStrategy02' }).catch(() => {})
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.choose-mode-list {
  display: flex;
  gap: 12px;
  margin: 40px 0 50px;
  .mode-box {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    // padding-top: 65px;
    padding: 65px 0;
    border-radius: 14px;

    border: 2px solid #e8e8e8;

    background: #fff;
    &.on {
      border: 2px solid #4a4ee7;
    }
    > i {
      margin-bottom: 16px;
    }
    .mode-name {
      color: #4a4ee7;
      text-align: center;
      font-size: 20px;
      font-weight: 600;
      line-height: 100%; /* 20px */
      margin-bottom: 8px;
    }
    .mode-desc {
      color: #666;

      text-align: center;
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 16.8px */
      letter-spacing: -0.24px;
    }
  }
}
</style>
