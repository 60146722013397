<template>
  <div>
    <!-- <LineChart></LineChart> -->
    <!-- <CancelSubsModal></CancelSubsModal> -->
    <!-- <TextRegisterModal :filter="list" /> -->
    <!-- <ImageViewModal></ImageViewModal> -->
    <Spinner></Spinner>
    <div class="flex">
      <!-- <Radar></Radar> -->
      <!-- <Radar></Radar> -->
    </div>
    <!-- <RadarChart></RadarChart> -->
    <CommRegisterModal></CommRegisterModal>
    <Graph></Graph>
  </div>
</template>

<script>
// import ImageViewModal from '../components/common/modal/ImageViewModal.vue'

// import TextRegisterModal from '@/components/common/modal/TextRegisterModal.vue'
// import CancelSubsModal from '../components/common/modal/CancelSubsModal.vue'

// import LineChart from '@/components/common/LineChart'
// import Radar from '@/components/common/chart/Radar.vue'
import Graph from '@/components/common/chart/Graph.vue'
import Spinner from '../components/common/modal/Spinner.vue'
import CommRegisterModal from '../components/common/modal/CommRegisterModal.vue'
// import RadarChart from '../components/common/RadarChart.vue'
export default {
  name: 'QuantMarketPlayGround',
  components: {
    // Radar,
    Graph,
    // ImageViewModal
    // RadarChart
    Spinner,
    CommRegisterModal
  },

  data() {
    return {
      list: [
        { id: 0, text: '시황' },
        { id: 0, text: '시황' },
        { id: 0, text: '시황' },
        { id: 0, text: '시황' },
        { id: 0, text: '시황' },
        { id: 0, text: '시황' }
      ]
    }
  },

  mounted() {},

  methods: {}
}
</script>

<style lang="scss" scoped></style>
