<template>
  <div id="add-strategy-02-wrap" class="white-layer">
    <StepBar class="mb-20" :stage="stage" :step="step"></StepBar>
    <Step01 v-if="stage === 1" @updated="setStage1Data"></Step01>
    <Step02
      v-if="stage === 2"
      :data="stage02"
      @updated="setStage2Data"
    ></Step02>
    <Step03
      v-if="stage === 3"
      :data="stage03"
      @updated="setStage3Data"
    ></Step03>
    <Step04
      v-if="stage === 4"
      :data="stage04"
      @updated="setStage4Data"
    ></Step04>
    <Step05
      v-if="stage === 5"
      :data="stage05"
      @updated="setStage5Data"
    ></Step05>
    <div v-if="stage === 1" class="btn-wrap">
      <button class="base-btn full cl-pri" @click="stage++">
        <span>다음</span>
      </button>
    </div>

    <div v-else-if="stage === 5" class="btn-wrap">
      <button class="base-btn harf cl-sec" @click="stage--">
        <span>이전</span>
      </button>
      <button class="base-btn harf cl-pri" @click="goResult">
        <span>결과보기</span>
      </button>
    </div>

    <div v-else class="btn-wrap">
      <button class="base-btn harf cl-sec" @click="stage--">
        <span>이전</span>
      </button>
      <button class="base-btn harf cl-pri" @click="stage++">
        <span>다음</span>
      </button>
    </div>
  </div>
</template>

<script>
import StepBar from '@/components/base/StepBar.vue'
import Step01 from './type02/Step01.vue'
import Step02 from './type02/Step02.vue'
import Step03 from './type02/Step03.vue'
import Step04 from './type02/Step04.vue'
import Step05 from './type02/Step05.vue'

export default {
  name: 'QuantMarketAddStrategyType1',
  components: { StepBar, Step01, Step02, Step03, Step04, Step05 },
  data() {
    return {
      //  step1
      stage: 1,
      step: 5,
      // step2
      stage02: 50,
      // step3
      stage03: 50,
      // step3
      stage04: [50, 50],
      stage05: null
    }
  },
  created() {
    this.$store.commit('SET_STEP', {})
  },
  mounted() {},
  methods: {
    setStage1Data(data) {
      this.stage01 = data
    },
    setStage2Data(data) {
      this.stage02 = data
    },
    setStage3Data(data) {
      this.stage03 = data
    },
    setStage4Data(data) {
      this.stage04 = data
    },
    setStage5Data(data) {
      this.stage05 = data
    },
    removeList(data) {
      const { seq } = data
      this.list = this.list.filter((e) => e.seq !== seq)
    },
    goResult() {
      const step = {
        'type': 'STRATEGY'
      }
      this.$store.commit('SET_STEP', step)
      this.$router.replace({ name: 'Result' })
    }
  }
}
</script>

<style lang="scss">
#add-strategy-02-wrap {
  display: flex;
  flex-direction: column;
  .round-box {
    .current-status-tit {
      color: #222;
      font-size: 18px;
      font-weight: 600;
      font-family: Pretendard;
      strong {
        padding-left: 4px;
        color: #d74343;
      }
    }
    border-radius: 14px;
    border: 1px solid #e8e8e8;
    background: #fff;
    padding: 16px 20px;
    p {
      color: #999;
      font-size: 12px;
    }
    .type-tit {
      color: #222;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 6px;
      display: flex;
      align-items: center;
      &.range-tit {
        color: #4a4ee7;
      }
      > i {
        margin-left: 4px;
      }
      .toggle-btn-wrap {
        margin-left: auto;
        display: flex;
        align-items: center;
        gap: 12px;
      }
    }
    &.on {
      border: 1px solid #4a4ee7;
      .type-tit {
        color: #4a4ee7;
      }
    }
    &.add-button {
      text-align: center;
      > span {
        display: inline-flex;
        align-items: center;
        color: #666;
      }
    }
  }
  .regist-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
    .round-box {
      display: flex;
      align-items: center;

      span {
        color: #666;
        margin-right: 12px;
      }
      i {
        margin-left: auto;
      }
    }
  }
  .tab-list {
    display: flex;
    gap: 8px;
    li {
      display: flex;
      height: 42px;
      flex: 1;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      border: 1px solid #e8e8e8;
      &.on {
        border: 1px solid #4a4ee7;
        color: #4a4ee7;
      }
    }
  }
  .btn-wrap {
    display: flex;
    gap: 8px;
    margin-top: auto;
  }
  .stage01-type-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
  }
}
</style>
