<template>
  <div class="examine wrapper layer">
    <div class="page-head-options">
      <i class="icon--close"></i>
    </div>
    <!-- 포트폴리오 -->
    <div class="examine-top" v-if="type === 'port'">
      <h2 class="examine-top-title">{{ data.title }}</h2>
      <div class="sub-box">
        <p class="examine-top-description">{{ data.name }}</p>
      </div>
    </div>
    <!-- 커뮤니티 -->
    <div class="examine-top" v-else-if="type === 'comm'">
      <h2 class="examine-top-title">{{ data.title }}</h2>
      <div class="sub-box">
        <p class="examine-top-description">{{ data.name }}</p>
        <button type="button" class="flex-between" @click="$emit('detail')">
          상세
          <i class="icon--arrow-move" @click="goDetail"></i>
        </button>
      </div>
    </div>
    <!-- 상품만들기 -->
    <div class="examine-top" v-else>
      <h3>판매심사현황</h3>
    </div>

    <!-- 심사현황 ul에 cancel 클래스 존재시 opacity처리 -->
    <template>
      <ul class="examine-status">
        <li class="examine-status-item" :class="{ active: crntStage === 1 }">
          심사 접수
        </li>
        <li class="bar"></li>
        <li class="examine-status-item" :class="{ active: crntStage === 2 }">
          심사중
        </li>
        <li class="bar"></li>
        <li class="examine-status-item" :class="{ active: crntStage === 3 }">
          심사 완료
        </li>
      </ul>
    </template>

    <template>
      <div class="examine-text">
        <div class="examine-text-box">
          <!-- 심사 완료가 아닐 때. -->

          <p v-if="crntStage !== 3" class="examine-text-box-description">
            {{ stageMessage }}
          </p>
          <!-- 심사 완료 일 때. -->
          <template v-if="crntStage === 3">
            <p>{{ examStatus }}</p>
            <div class="examine-text-box-status reject" :class="resultTp">
              결과: {{ resultName }}
            </div>
            <p class="examine-text-box-reject" v-if="resultReason">
              {{ resultReason }}
            </p>
          </template>
        </div>
      </div>
    </template>

    <template>
      <div class="examine-list">
        <div class="examine-list-nav flex-between">
          <p class="examine-list-nav-title">진행내역</p>
          <button type="button" @click="$emit('cancel')">
            커뮤니티 삭제
            <i class="icon--arrow-move"></i>
          </button>
        </div>
        <ul class="examine-list-box">
          <li
            class="examine-list-box-item"
            v-for="(d, i) in progressList"
            :key="i + 'hist'"
          >
            <span class="examine-list-box-item-date">{{ d.date }}</span>
            <span>회원님이 심사요청을 하셨습니다.</span>
          </li>
        </ul>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'ExamineDetail',
  props: {
    type: {
      // product , port , comm
      type: String,
      default: ''
    },
    data: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    crntStage() {
      return this.$props.data.stage
    },
    stageMessage() {
      if (this.$props.type === 'port') {
        return this.crntStage === 1
          ? '관리자에게 회원님의 투자전략 심사를 요청하고 있습니다.'
          : '관리자가 회원님의 투자전략 판매요청을 심사하고 있습니다.'
      }
      if (this.$props.type === 'comm') {
        return this.crntStage === 1
          ? '관리자에게 회원님의 커뮤니티 심사를 요청하고 있습니다.'
          : '관리자가 회원님의 커뮤니티 등록 요청을 심사하고 있습니다.'
      }
      return this.crntStage === 1
        ? '관리자에게 회원님의 투자전략 심사를 요청하고 있습니다.'
        : '관리자가 회원님의 투자전략 판매요청을 심사하고 있습니다.'
    },
    resultTp() {
      return this.$props.data.resultTp
    },
    examState() {
      return this.$props.data.state
    },
    examStatus() {
      return this.crntStage === 3 ? '심사가 완료되었습니다.' : ''
    },
    resultName() {
      return this.examState === 'REJECT' ? '반려' : '승인'
    },
    resultReason() {
      if (this.examState !== 'REJECT') return
      return this.$props.data.reason
    },
    progressList() {
      return this.$props.data.progressList
    }
  }
}
</script>

<style lang="scss" scoped>
.examine {
  &-top {
    &-title {
      font-size: 16px;
      font-weight: 500;
      flex: 1;
      flex-shrink: 0;
    }
    &-description {
      color: #666;
      margin-top: 8px;
    }
    .sub-box {
      display: flex;
      justify-content: space-between;
      align-self: center;
      button {
        padding: 4px 9px;
        border-radius: 6px;
        background: #f5f5ff;
      }
    }
  }

  // 심사 상태 step
  // status.cancel 일 경우 opacity처리
  &-status {
    &.cancel li {
      opacity: 0.5;
    }
    margin-top: 24px;
    display: flex;
    align-items: center;

    &-item {
      flex-basis: 80px;
      flex-shrink: 0;
      height: 38px;
      line-height: 38px;
      text-align: center;
      font-weight: 500;
      border-radius: 10px;
      border: 1px solid #e8e8e8;
      background: #fff;
    }

    &-item.active {
      border: 1px solid #4a4ee7;
      color: #4a4ee7;
      font-weight: 600;
    }

    .bar {
      flex: 1;
      height: 1px;
      background: #e8e8e8;
    }
    .bar.active {
      background-color: #4a4ee7;
    }
  }

  &-text {
    width: 100%;
    border-radius: 14px;
    background-color: #f5f5ff;
    margin-top: 24px;
    &-box {
      padding: 24px 16px;
      p {
        color: #444;
        text-align: center;
        font-weight: 500;
      }
      &-status {
        padding: 8px 16px;
        border-radius: 14px;
        background-color: #fff;
        color: #4882f5;
        font-size: 16px;
        font-weight: 600;
        margin-top: 8px;
        width: 130px;
        margin: 8px auto 0;
        text-align: center;
      }

      &-reject {
        display: none;
      }

      &-status.reject + &-reject {
        display: block;
        color: #999;
        text-align: center;

        line-height: 19.6px;
        margin-top: 12px;
      }
      &-status.reject {
        color: #d74343;
      }
      &-description {
        color: #444;
        text-align: center;
        margin: 0 auto;
        width: 215px;
      }
    }
  }

  // 진행내역
  &-list {
    margin-top: 24px;
    padding-top: 24px;
    border-top: 1px solid #e8e8e8;
    &-nav {
      &-title {
        color: #222;
        font-size: 16px;
        font-weight: 500;
      }
      button {
        display: flex;
        align-items: center;
        gap: 3px;
        padding: 4px 9px;
        background-color: #f1f1f1;
        border-radius: 6px;
      }
    }
    &-box {
      margin-top: 17px;
      &-item {
        color: #666;
        margin-bottom: 12px;
        &-date {
          margin-right: 16px;
        }
      }
    }
  }
}
</style>
