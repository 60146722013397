var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Spinner"),
      _c("div", { staticClass: "flex" }),
      _c("CommRegisterModal"),
      _c("Graph"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }