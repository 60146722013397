import Vue from 'vue'
import VueRouter from 'vue-router'

import vuexStore from '@/store'

// Store
import Main from '@/views/Main'

import PageNotFound from '@/views/PageNotFound'
// 인증
import Login from '@/views/auth/Login'
import Terms from '@/components/auth/Terms'
import Authority from '@/views/auth/Authority'
import KakaoLogin from '@/views/callback/KakaoLogin'
import NaverLogin from '@/views/callback/NaverLogin'
import GoogleLogin from '@/views/callback/GoogleLogin'
import Step1 from '@/views/auth/join/Step1'
import Step2 from '@/views/auth/join/Step2'
import Step3 from '@/views/auth/join/Step3'

// 랭킹
import Ranking from '@/views/Ranking'

// 마이
import MyPage from '@/views/mypage'
import Profile from '@/views/mypage/profile'
import Service from '@/views/mypage/profile/service'
import Policy from '@/views/mypage/profile/service/policy'
import PolicyDetail from '@/views/mypage/profile/service/policy/detail'
import Faq from '@/views/mypage/profile/service/faq'
import Qna from '@/views/mypage/profile/service/qna'
import Setting from '@/views/mypage/setting'
import ProductsSell from '@/views/mypage/products-sell'
import Posting from '@/views/mypage/posting'
import Examine from '@/views/mypage/examine'
import ExamineDetail from '@/views/mypage/examine/detail'
import Edit from '@/views/mypage/edit'

// 커뮤니티
import Community from '@/views/community/Community'
import CommunityDetail from '@/views/community/CommunityDetail'

// 전략생성
import AddStrategy01 from '@/views/strategy/AddStrategy01'
import AddStrategy02 from '@/views/strategy/AddStrategy02'
import Result from '@/views/strategy/Result'

// 포트폴리오
import PortfolioStorage from '@/views/portfolio/PortfolioStorage'
import PortfolioDetail from '@/views/portfolio/PortfolioDetail'
import AddProduct from '@/views/portfolio/AddProduct'
import AddProductDetail from '@/views/portfolio/AddProductDetail'

// 쇼핑
import Shopping from '@/views/shopping'
import ProductDetail from '@/views/shopping/ProductDetail'
import SubscribeDetail from '@/views/shopping/SubscribeDetail'

// 라운지
import Rounge from '@/views/rounge'
import RoungeDetail from '@/views/rounge/RoungeDetail'
// import ProductDetail from '@/views/shopping/ProductDetail'
// import SubscribeDetail from '@/views/shopping/SubscribeDetail'

import PlayGround from '@/views/PlayGround'
Vue.use(VueRouter)

const routes = [
  // STORE
  {
    path: '/',
    name: 'Main',
    component: Main
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      close: false,
      history: '/',
      requiresAuth: false
    }
  },
  {
    path: '/authority',
    name: 'Authority',
    component: Authority,
    meta: {
      close: false,
      history: '/login'
    }
  },
  {
    path: '/callback/kakaoLogin',
    name: 'KakaoLogin',
    component: KakaoLogin,
    meta: {
      back: false,
      close: false,
      history: '/login'
    }
  },
  {
    path: '/callback/naverLogin',
    name: 'NaverLogin',
    component: NaverLogin,
    meta: {
      back: false,
      close: false,
      history: '/login'
    }
  },
  {
    path: '/callback/googleLogin',
    name: 'GoogleLogin',
    component: GoogleLogin,
    meta: {
      back: false,
      close: false,
      history: '/login'
    }
  },
  {
    path: '/join/step1',
    name: 'Step1',
    component: Step1,
    meta: {
      close: false,
      history: '/login'
    }
  },
  {
    path: '/join/step2',
    name: 'Step2',
    component: Step2,
    meta: {
      back: true,
      close: false
    }
  },
  {
    path: '/join/terms',
    name: 'Terms',
    component: Terms
  },
  {
    path: '/join/step3',
    name: 'Step3',
    component: Step3,
    meta: {
      true: false,
      close: false
    }
  },
  {
    path: '/ranking',
    name: 'Ranking',
    meta: { pageTitle: '수익률 배틀' },
    component: Ranking
  },
  {
    path: '/comm',
    name: 'Community',
    meta: { pageTitle: '커뮤니티', history: '/' },
    component: Community
  },
  {
    path: '/comm/info',
    name: 'CommunityDetail',
    component: CommunityDetail,
    meta: {
      pageTitle: '커뮤니티 상세',
      history: '/comm',
      requiresAuth: true
    }
  },
  {
    path: '/strategy/added01',
    name: 'AddStrategy01',
    meta: { pageTitle: '직접종목선정', requiresAuth: true },
    component: AddStrategy01
  },
  {
    path: '/strategy/added02',
    name: 'AddStrategy02',
    meta: { pageTitle: '전략구현하기', requiresAuth: true },
    component: AddStrategy02
  },
  {
    path: '/strategy/result',
    name: 'Result',
    component: Result
  },
  {
    path: '/shopping',
    name: 'Shopping',
    component: Shopping,
    meta: {
      pageTitle: '쇼핑',
      history: '/',
      back: true
    }
  },
  {
    path: '/shopping/detail',
    name: 'ProductDetail',
    component: ProductDetail,
    meta: {
      pageTitle: '상품상세',
      requiresAuth: true
    }
  },
  {
    path: '/shopping/subs/detail/:id',
    name: 'SubscribeDetail',
    component: SubscribeDetail,
    meta: {
      pageTitle: '상품상세'
    }
  },
  {
    path: '/mypage',
    name: 'MyPage',
    component: MyPage,
    meta: {
      history: '/',
      requiresAuth: true,
      back: false
    }
  },
  {
    path: '/mypage/profile',
    name: 'Profile',
    meta: { pageTitle: '내정보', userUpdateBtn: true },
    component: Profile,
    requiresAuth: true
  },
  {
    path: '/mypage/service',
    name: 'Service',
    meta: { pageTitle: '고객센터' },
    component: Service,
    requiresAuth: true
  },
  {
    path: '/mypage/service/policy',
    name: 'Policy',
    meta: { pageTitle: '약관 및 정책' },
    component: Policy,
    requiresAuth: true
  },
  {
    path: '/mypage/service/policy/:type',
    name: 'MypageTerms',
    meta: { pageTitle: '약관 및 정책' },
    component: PolicyDetail,
    requiresAuth: true
  },
  {
    path: '/mypage/service/faq',
    name: 'Faq',
    meta: { pageTitle: '1:1 문의' },
    component: Faq,
    requiresAuth: true
  },
  {
    path: '/mypage/service/qna',
    name: 'Qna',
    meta: { pageTitle: '1:1 문의' },
    component: Qna,
    requiresAuth: true
  },
  {
    path: '/mypage/edit',
    name: 'Edit',
    meta: { pageTitle: '내정보 수정' },
    component: Edit,
    requiresAuth: true
  },
  {
    path: '/mypage/setting',
    name: 'Setting',
    meta: { pageTitle: '알림 설정' },
    component: Setting,
    requiresAuth: true
  },
  {
    path: '/mypage/products-sell',
    name: 'ProductsSell',
    meta: { pageTitle: '내 상품 판매 내역' },
    component: ProductsSell,
    requiresAuth: true
  },
  {
    path: '/mypage/posting',
    name: 'Posting',
    meta: { pageTitle: '내가 쓴 글' },
    component: Posting,
    requiresAuth: true
  },
  {
    path: '/mypage/examine',
    name: 'Examine',
    meta: { pageTitle: '내 심사 내역' },
    component: Examine,
    requiresAuth: true
  },
  {
    path: '/mypage/examine/detail',
    name: 'ExamineDetail',
    meta: { pageTitle: '심사현황' },
    component: ExamineDetail
  },
  {
    path: '/storage',
    name: 'PortfolioStorage',
    component: PortfolioStorage,
    meta: { pageTitle: '포트폴리오 보관함' }
  },
  {
    path: '/portfolio/:id',
    name: 'PortfolioDetail',
    component: PortfolioDetail,
    meta: {
      pageTitle: '포트폴리오 상세',
      trans: true,
      noSticky: true,
      requiresAuth: true
    }
  },
  {
    path: '/add/product',
    name: 'AddProduct',

    component: AddProduct
  },
  {
    path: '/add/product/:id',
    name: 'AddProductDetail',

    component: AddProductDetail
  },
  {
    path: '/rounge',
    name: 'Rounge',

    component: Rounge,
    meta: {
      pageTitle: '라운지',
      history: '/',
      back: true
    }
  },
  {
    path: '/rounge/detail',
    name: 'RoungeDetail',
    component: RoungeDetail,
    meta: {
      pageTitle: '라운지',
      history: '/rounge',
      requiresAuth: true
    }
  },

  {
    path: '/test',
    name: 'PlayGround',
    component: PlayGround
  },
  {
    path: '*',
    name: '404',
    component: PageNotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return { selector: to.hash }
    }
    return { x: 0, y: 0 }
  }
})

router.beforeEach(async (to, from, next) => {
  if (to.meta.requiresAuth && !vuexStore.getters.getUser.isUser) {
    try {
      next({ name: 'Login' })
    } catch (err) {
      next({ name: 'Login' })
    }
  } else {
    next()
  }
})

export default router
