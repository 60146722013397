// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/images/icon_crown.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rank-image-center[data-v-70922c7f] {
  position: absolute !important;
  width: 70% !important;
  height: 70% !important;
  left: 15% !important;
  top: 15% !important;
}
.quant-swiper-box[data-v-70922c7f] {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.quant-swiper-box .swiper-container[data-v-70922c7f] {
  margin: 0;
}
.quant-swiper-box .swiper-slide[data-v-70922c7f] {
  flex-shrink: 1;
}
.quant-slide-item .slide-thumb[data-v-70922c7f] {
  width: 132px;
  height: 132px;
  border-radius: 8px;
  overflow: hidden;
  background-color: grey;
}
.quant-slide-item .slide-thumb > img[data-v-70922c7f] {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.quant-slide-item .slide-desc[data-v-70922c7f] {
  font-size: 14px;
  color: #666;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.quant-slide-item.rect .slide-thumb[data-v-70922c7f] {
  width: 153px;
  height: 100px;
  border-radius: 14px;
  overflow: hidden;
}
.quant-slide-item.rect .slide-desc[data-v-70922c7f] {
  color: #222;
}
.quant-rank-slide-item .slide-thumb[data-v-70922c7f] {
  border-radius: 50%;
  width: 92px;
  height: 92px;
  position: relative;
  border: 2px solid #feea61;
}
.quant-rank-slide-item .slide-thumb > img[data-v-70922c7f] {
  border-radius: 50%;
  width: 100%;
  height: 100%;
}
.quant-rank-slide-item .slide-thumb > .rank-no[data-v-70922c7f] {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #444;
  color: #fff;
  border-radius: 50%;
  left: 2px;
  top: 5px;
  width: 17px;
  height: 17px;
}
.quant-rank-slide-item .slide-thumb[data-v-70922c7f]::after {
  content: "";
  position: absolute;
  display: inline-block;
  top: 0;
  left: 50%;
  width: 22px;
  height: 14px;
  transform: translate(-50%, -9px);
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
.quant-rank-slide-item .slide-txt-cont .percent[data-v-70922c7f] {
  color: #d74343;
  font-size: 16px;
}
.quant-rank-slide-item .slide-txt-cont .percent.status[data-v-70922c7f] {
  color: #4a4ee7;
}
.quant-rank-slide-item .slide-txt-cont .ranker-name[data-v-70922c7f] {
  font-size: 12px;
  color: #aaa;
}
.quant-port-slide-item[data-v-70922c7f] {
  text-align: center;
}
.quant-port-slide-item .circle-box[data-v-70922c7f] {
  border-radius: 50%;
  width: 92px;
  height: 92px;
  border-radius: 50px;
  background: #fafafb;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;
}
.quant-port-slide-item .circle-box strong[data-v-70922c7f] {
  color: #4a4ee7;
  font-size: 16px;
}
.quant-port-slide-item .port-title[data-v-70922c7f] {
  font-size: 14px;
  color: #444;
  margin: 8px 0 0;
  max-width: 92px;
  min-width: 92px;
}
.quant-port-slide-item .user-name[data-v-70922c7f] {
  font-size: 12px;
  color: #aaa;
}
.lounge-swiper[data-v-70922c7f] {
  padding-left: 10px;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
