<template>
  <div class="examine wrapper">
    <div class="examine-top">
      <h2 class="examine-top-title">심사항목</h2>
      <div class="sub-box" v-if="isPortfolio">
        <p class="examine-top-description">{{ reviewData.rowTitle }}</p>
        <button type="button" class="flex-between" @click="movePortfolioDetail">
          상세
          <i class="icon--arrow-move"></i>
        </button>
      </div>
    </div>

    <!-- 심사현황 ul에 cancel 클래스 존재시 opacity처리 -->
    <template>
      <ul class="examine-status">
        <li class="examine-status-item" :class="isActive('REQUEST')">
          심사 접수
        </li>
        <li class="bar"></li>
        <li class="examine-status-item" :class="isActive('UNDER_REVIEW')">
          심사중
        </li>
        <li class="bar"></li>
        <li class="examine-status-item" :class="isActive('APPROVAL')">
          심사 완료
        </li>
      </ul>
    </template>

    <template>
      <div class="examine-text">
        <div class="examine-text-box">
          <div v-if="lastReview.reviewStatus === 'APPROVAL'">
            <!-- 심사 완료 일 때. -->
            <p>{{ lastReview.comment }}</p>
            <div v-if="lastReview.reviewStatus === 'REJECT'">
              <div class="examine-text-box-status reject">결과: 반려</div>
              <p class="examine-text-box-reject">
                {{ lastReview.comment }}
              </p>
            </div>
          </div>
          <div v-else>
            <!-- 심사 완료가 아닐 때. -->
            <p class="examine-text-box-description">
              {{ lastReview.comment }}
            </p>
          </div>
        </div>
      </div>
    </template>

    <template>
      <div class="examine-list">
        <div class="examine-list-nav flex-between">
          <p class="examine-list-nav-title">진행내역</p>
          <button
            type="button"
            @click="cancelExam"
            v-if="lastReview.reviewStatus === 'REQUEST'"
          >
            심사 요청취소
            <i class="icon--arrow-move"></i>
          </button>
        </div>
        <ul class="examine-list-box">
          <li
            class="examine-list-box-item"
            v-for="item in reviewData.reviews"
            :key="item.reviewId"
          >
            <span class="examine-list-box-item-date">
              {{ item.processDate }}</span
            >
            <span>{{ item.comment }}</span>
          </li>
        </ul>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      communities: [],
      reviewData: {},
      lastReview: {},
      isPortfolio: true
    }
  },
  created() {
    this.getReviewData()
  },
  methods: {
    getReviewData() {
      this.isPortfolio = this.$store.getters.getType === 'portfolio'
      if (this.isPortfolio) {
        console.log('portfolio')
        this.reviewData = this.$store.getters.getPortfolio
        console.log(this.reviewData)
        this.lastReview = this.reviewData.reviews[0]
        console.log(this.lastReview)
      } else {
        console.log('community')
        this.reviewData = this.$store.getters.getCommunity
        console.log(this.reviewData)
        this.lastReview = this.reviewData.reviews[0]
        console.log(this.lastReview)
      }
    },
    isActive(value) {
      console.log(value)
      return this.lastReview.reviewStatus === value ? 'active' : ''
    },
    movePortfolioDetail() {
      this.$router.push({
        name: 'PortfolioDetail',
        params: { id: this.lastReview.portfolioId }
      })
    },
    cancelExam() {
      let obj = {
        title: '',
        message: `<div class="cancel-exam-alert">
         <strong>울트라슈퍼모멘텀8월</strong>
         투자전략
심사 요청을 취소하시겠습니까?
        </div>`,
        type: 'info',
        useConfirmBtn: true,
        customConfirmBtnText: '확인',
        customCloseBtnText: '취소'
        // onConfirm: this.confirmClick
      }
      this.$Simplert.open(obj)
    }
  }
}
</script>

<style lang="scss" scoped>
.examine {
  &-top {
    &-title {
      font-size: 16px;
      font-weight: 500;
      flex: 1;
      flex-shrink: 0;
    }
    &-description {
      color: #666;
      margin-top: 8px;
    }
    .sub-box {
      display: flex;
      justify-content: space-between;
      align-self: center;
      button {
        padding: 4px 9px;
        border-radius: 6px;
        background: #f5f5ff;
      }
    }
  }

  // 심사 상태 step
  // status.cancel 일 경우 opacity처리
  &-status {
    &.cancel li {
      opacity: 0.5;
    }
    margin-top: 24px;
    display: flex;
    align-items: center;

    &-item {
      flex-basis: 80px;
      flex-shrink: 0;
      height: 38px;
      line-height: 38px;
      text-align: center;
      font-weight: 500;
      border-radius: 10px;
      border: 1px solid #e8e8e8;
      background: #fff;
    }

    &-item.active {
      border: 1px solid #4a4ee7;
      color: #4a4ee7;
      font-weight: 600;
    }

    .bar {
      flex: 1;
      height: 1px;
      background: #e8e8e8;
    }
    .bar.active {
      background-color: #4a4ee7;
    }
  }

  &-text {
    width: 100%;
    border-radius: 14px;
    background-color: #f5f5ff;
    margin-top: 24px;
    &-box {
      padding: 24px 16px;
      p {
        color: #444;
        text-align: center;
        font-weight: 500;
      }
      &-status {
        padding: 8px 16px;
        border-radius: 14px;
        background-color: #fff;
        color: #4882f5;
        font-size: 16px;
        font-weight: 600;
        margin-top: 8px;
        width: 130px;
        margin: 8px auto 0;
        text-align: center;
      }

      &-reject {
        display: none;
      }

      &-status.reject + &-reject {
        display: block;
        color: #999;
        text-align: center;

        line-height: 19.6px;
        margin-top: 12px;
      }
      &-status.reject {
        color: #d74343;
      }
      &-description {
        color: #444;
        text-align: center;
      }
    }
  }

  // 진행내역
  &-list {
    margin-top: 24px;
    padding-top: 24px;
    border-top: 1px solid #e8e8e8;
    &-nav {
      &-title {
        color: #222;
        font-size: 16px;
        font-weight: 500;
      }
      button {
        display: flex;
        align-items: center;
        gap: 3px;
        padding: 4px 9px;
        background-color: #f1f1f1;
        border-radius: 6px;
      }
    }
    &-box {
      margin-top: 17px;
      &-item {
        color: #666;
        margin-bottom: 12px;
        &-date {
          margin-right: 16px;
        }
      }
    }
  }
}
</style>
