var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "white-layer", attrs: { id: "join-wrap" } }, [
    _vm._m(0),
    _c("ul", { staticClass: "input-list" }, [
      _c("li", { staticClass: "valid-input", class: { error: _vm.isValid } }, [
        _c("p", { staticClass: "q-sub-txt txt-size--xs" }, [
          _vm._v("휴대폰 번호"),
        ]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.phoneNumber,
              expression: "phoneNumber",
            },
          ],
          attrs: { placeholder: "휴대폰 번호", type: "text", maxlength: "11" },
          domProps: { value: _vm.phoneNumber },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.phoneNumber = $event.target.value
            },
          },
        }),
        _vm.phoneNumber.length > 0 && !_vm.isDisable
          ? _c("i", {
              staticClass: "icon--delete",
              on: {
                click: function ($event) {
                  _vm.phoneNumber = ""
                },
              },
            })
          : _vm._e(),
        _vm.isDisable ? _c("i", { staticClass: "icon--done" }) : _vm._e(),
        _vm.isValid
          ? _c("span", { staticClass: "valid-txt" }, [
              _vm._v(_vm._s(_vm.validTxt)),
            ])
          : _vm._e(),
      ]),
      _vm.isPhoneAuth
        ? _c(
            "li",
            {
              staticClass: "valid-input mt-40",
              class: { error: _vm.isAuthValid },
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.authNumber,
                    expression: "authNumber",
                  },
                ],
                attrs: {
                  placeholder: "인증번호 6자리 입력",
                  type: "text",
                  maxlength: "6",
                },
                domProps: { value: _vm.authNumber },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.authNumber = $event.target.value
                  },
                },
              }),
              _c("p", { staticClass: "limit-time" }, [
                _vm._v(_vm._s(_vm.resTimeData)),
              ]),
              _c(
                "button",
                {
                  staticClass: "base-btn disabled small q-sub-txt retry-btn",
                  on: { click: _vm.smsReset },
                },
                [_vm._v(" 인증번호 재전송 ")]
              ),
              _vm.isAuthValid
                ? _c("span", { staticClass: "valid-txt" }, [
                    _vm._v(_vm._s(_vm.authValidTxt)),
                  ])
                : _vm._e(),
            ]
          )
        : _vm._e(),
    ]),
    _c(
      "button",
      {
        staticClass: "base-btn cl-pri full",
        attrs: { disabled: _vm.isValid || !_vm.isDisable },
        on: { click: _vm.goJoinNext },
      },
      [
        _c("span", { staticClass: "txt-size--m" }, [
          _vm._v(_vm._s(_vm.buttonName)),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "page-title-section mb-48" }, [
      _c("h4", [
        _vm._v("회원가입을 위한"),
        _c("br"),
        _vm._v("휴대폰 인증이 필요해요"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }