var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "profile" },
    [
      _vm.isProfile
        ? _c("SlideUpModal", {
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "flex-just" }, [
                        _c("h4", [_vm._v("프로필 이미지를 선택해주세요")]),
                        _c("i", {
                          staticClass: "icon--close",
                          on: { click: _vm.closeSlider },
                        }),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "main",
                  fn: function () {
                    return [
                      _c("ul", { staticClass: "send-modal-body" }, [
                        _c(
                          "li",
                          {
                            class: { on: _vm.tempProfileId === "m1" },
                            on: {
                              click: function ($event) {
                                return _vm.setProfile("m1")
                              },
                            },
                          },
                          [_c("i", { staticClass: "icon--char m1" })]
                        ),
                        _c(
                          "li",
                          {
                            class: { on: _vm.tempProfileId === "m2" },
                            on: {
                              click: function ($event) {
                                return _vm.setProfile("m2")
                              },
                            },
                          },
                          [_c("i", { staticClass: "icon--char m2" })]
                        ),
                        _c(
                          "li",
                          {
                            class: { on: _vm.tempProfileId === "m3" },
                            on: {
                              click: function ($event) {
                                return _vm.setProfile("m3")
                              },
                            },
                          },
                          [_c("i", { staticClass: "icon--char m3" })]
                        ),
                        _c(
                          "li",
                          {
                            class: { on: _vm.tempProfileId === "m4" },
                            on: {
                              click: function ($event) {
                                return _vm.setProfile("m4")
                              },
                            },
                          },
                          [_c("i", { staticClass: "icon--char m4" })]
                        ),
                        _c(
                          "li",
                          {
                            class: { on: _vm.tempProfileId === "w1" },
                            on: {
                              click: function ($event) {
                                return _vm.setProfile("w1")
                              },
                            },
                          },
                          [_c("i", { staticClass: "icon--char w1" })]
                        ),
                        _c(
                          "li",
                          {
                            class: { on: _vm.tempProfileId === "w2" },
                            on: {
                              click: function ($event) {
                                return _vm.setProfile("w2")
                              },
                            },
                          },
                          [_c("i", { staticClass: "icon--char w2" })]
                        ),
                        _c(
                          "li",
                          {
                            class: { on: _vm.tempProfileId === "w3" },
                            on: {
                              click: function ($event) {
                                return _vm.setProfile("w3")
                              },
                            },
                          },
                          [_c("i", { staticClass: "icon--char w3" })]
                        ),
                        _c(
                          "li",
                          {
                            class: { on: _vm.tempProfileId === "w4" },
                            on: {
                              click: function ($event) {
                                return _vm.setProfile("w4")
                              },
                            },
                          },
                          [_c("i", { staticClass: "icon--char w4" })]
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "button",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "modal-btn-wrap" }, [
                        _c(
                          "button",
                          {
                            staticClass: "base-btn harf cl-sec",
                            on: { click: _vm.closeSlider },
                          },
                          [_c("span", [_vm._v("취소")])]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "base-btn harf cl-pri",
                            on: { click: _vm.chooseProfile },
                          },
                          [_c("span", [_vm._v("선택")])]
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              1955058076
            ),
          })
        : _vm._e(),
      _c(
        "figure",
        {
          staticClass: "profile-img",
          style: {
            backgroundImage: "url(" + _vm.user.storageAccess + ")",
          },
          on: { click: _vm.profileShow },
        },
        [
          _c("i", {
            staticClass: "icon--black--plus type01",
            staticStyle: { position: "relative", top: "80px", left: "80px" },
          }),
        ]
      ),
      _c("ul", { staticClass: "profile-info-list" }, [
        _c("li", { staticClass: "profile-info-list-item" }, [
          _c("span", [_vm._v("닉네임")]),
          _c("span", [_vm._v(_vm._s(_vm.user.nickName))]),
        ]),
        _c("li", { staticClass: "profile-info-list-item" }, [
          _c("span", [_vm._v("이메일 주소")]),
          _c("span", [_vm._v(_vm._s(_vm.user.email))]),
        ]),
        _c("li", { staticClass: "profile-info-list-item" }, [
          _c("span", [_vm._v("휴대폰 번호")]),
          _c("span", [_vm._v(_vm._s(_vm.user.phoneNumber))]),
        ]),
        _c("li", { staticClass: "profile-info-list-item" }, [
          _c("span", [_vm._v("연령대")]),
          _c("span", [_vm._v(_vm._s(_vm.age))]),
        ]),
        _c("li", { staticClass: "profile-info-list-item" }, [
          _c("span", [_vm._v("성별")]),
          _c("span", [_vm._v(_vm._s(_vm.gender))]),
        ]),
      ]),
      _c("hr"),
      _c("div", { staticClass: "profile-sns" }, [
        _c("h2", { staticClass: "profile-sns-title" }, [
          _vm._v("SNS 계정 연동"),
        ]),
        _c("ul", { staticClass: "profile-sns-list" }, [
          _c("li", { staticClass: "profile-sns-list-item" }, [
            _vm._m(0),
            _c(
              "div",
              [
                _c("ToggleButton", {
                  ref: "kakaoCheckbox",
                  attrs: { data: _vm.kakao, info: { socialType: "KAKAO" } },
                  on: { updated: _vm.updateSocial },
                }),
              ],
              1
            ),
          ]),
          _c("li", { staticClass: "profile-sns-list-item" }, [
            _vm._m(1),
            _c(
              "div",
              [
                _c("ToggleButton", {
                  ref: "googleCheckbox",
                  attrs: { data: _vm.google, info: { socialType: "GOOGLE" } },
                  on: { updated: _vm.updateSocial },
                }),
              ],
              1
            ),
          ]),
          _c("li", { staticClass: "profile-sns-list-item" }, [
            _vm._m(2),
            _c(
              "div",
              [
                _c("ToggleButton", {
                  ref: "naverCheckbox",
                  attrs: { data: _vm.naver, info: { socialType: "NAVER" } },
                  on: { updated: _vm.updateSocial },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("i", { staticClass: "icon--mypage-sns kakao" }),
      _vm._v(" 카카오 계정 연결 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("i", { staticClass: "icon--mypage-sns google" }),
      _vm._v(" 구글 계정 연결 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("i", { staticClass: "icon--mypage-sns naver" }),
      _vm._v(" 네이버 계정 연결 "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }