import { render, staticRenderFns } from "./SubPageLayer.vue?vue&type=template&id=0dbdfabe"
import script from "./SubPageLayer.vue?vue&type=script&lang=js"
export * from "./SubPageLayer.vue?vue&type=script&lang=js"
import style0 from "./SubPageLayer.vue?vue&type=style&index=0&id=0dbdfabe&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/smyu/Digital_Nomad/workspace/qmarket-official/client-front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0dbdfabe')) {
      api.createRecord('0dbdfabe', component.options)
    } else {
      api.reload('0dbdfabe', component.options)
    }
    module.hot.accept("./SubPageLayer.vue?vue&type=template&id=0dbdfabe", function () {
      api.rerender('0dbdfabe', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/layout/SubPageLayer.vue"
export default component.exports