<template>
  <div class="step01">
    <div class="page-title-section">
      <h4>
        {{ styleNm }} 포트폴리오에 <br />
        필요한 <strong>지표를 설정</strong>하세요.
      </h4>
    </div>
    <div class="chart-box">
      <RadarChart :data="radarData" ref="childRadarRef"></RadarChart>
    </div>
    <div
      class="round-box mb-8"
      v-for="(info, index) in essentialList"
      :key="index + 'hist'"
    >
      <p class="type-tit range-tit">
        {{ info.indicatorName }}
        <i class="icon--q" @click="openQuestionModal(info)"></i>
      </p>
      <p>{{ info.description }}</p>
      <p class="current-status-tit mt-24">
        {{ sortOrderName(info.sortOrderAsc) }}<strong>{{ info.value }}%</strong>
      </p>

      <div class="range-slide-box">
        <div class="slide-mark">
          <span>10</span>
          <span>50</span>
          <span>90</span>
        </div>
        <vue-slider
          v-model="info.value"
          dot-size="34"
          tooltip="none"
          :min="10"
          :max="90"
          :interval="10"
          :drag-on-click="true"
          @change="sliderChange(info)"
        ></vue-slider>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
import RadarChart from '@/components/common/RadarChart.vue'
import calculateValues from '@/util/calculateValues'

export default {
  name: 'Step02',
  components: {
    VueSlider,
    RadarChart
  },
  data() {
    return {
      essentialList: [],
      localData: 50,
      loaded: false,
      questionTitle: '',
      questionDesc: '',
      radarData: [5, 5, 5, 5, 5, 5],
      styleNm: ''
    }
  },
  created() {
    let portfolioStyledList = this.$store.getters.getAddType.list
    portfolioStyledList = portfolioStyledList
      .filter((info) => {
        return info.styleSecondaryType === 'ESSENTIAL'
      })
      .map((data) => ({
        ...data,
        isData: true
      }))

    this.essentialList = portfolioStyledList

    const styleType = this.$store.getters.getAddType.styleType
    this.styleNmConverter(styleType)
  },
  mounted() {
    let portfolioList = this.$store.getters.getAddType.list
    this.updateRadar(portfolioList)
  },
  computed: {
    currentQuestion() {
      let result = {
        title: this.questionTitle,
        desc: this.questionDesc
      }

      return result
    }
  },
  watch: {
    localData() {
      this.$emit('updated', this.localData)
    }
  },
  methods: {
    sliderChange(info) {
      if (info.value <= 9) {
        info.value = 10
      }

      if (info.value >= 90) {
        info.value = 90
      }

      let portfolioList = this.$store.getters.getAddType.list
      for (let index in portfolioList) {
        if (portfolioList[index].styleId === info.styleId) {
          portfolioList[index].value = info.value
          portfolioList[index].isData = true
        }
      }

      this.$store.commit('SET_STEP', { list: portfolioList })
      this.updateRadar(portfolioList)
    },
    updateRadar(portfolioList) {
      const factors = {}
      portfolioList
        .filter((info) => {
          return info.isData === true
        })
        .forEach((info) => {
          factors[info.indicatorCode] = info.value / 100
        })
      const radarValues = calculateValues.calculateValues(factors)

      this.radarData[0] = Number(radarValues.growth) // 성장
      this.radarData[1] = Number(radarValues.value) // 가치
      this.radarData[2] = Number(radarValues.momentum) // 모멘텀
      this.radarData[3] = Number(radarValues.dividend) // 배당
      this.radarData[4] = Number(radarValues.lowvol) // 안정
      this.radarData[5] = Number(radarValues.quality) // 우량

      this.$refs.childRadarRef.forceRender()
    },
    openQuestionModal(info) {
      this.$Simplert.open({
        title: info.indicatorName,
        message: `<div id="strategy-add-description">${info.descriptionDetail}</div>`
      })
    },
    sortOrderName(sortOrderAsc) {
      if (sortOrderAsc) {
        return '상위'
      } else {
        return '하위'
      }
    },
    styleNmConverter(styleType) {
      switch (styleType) {
        case 'GROWTH':
          this.styleNm = '성장형'
          break
        case 'VALUE':
          this.styleNm = '가치형'
          break
        case 'MOMENTUM':
          this.styleNm = '모멘텀'
          break
        case 'DIVIDEND':
          this.styleNm = '배당형'
          break
        case 'LOWVOL':
          this.styleNm = '안정형'
          break
        case 'QUALITY':
          this.styleNm = '우량형'
          break
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.current-status-tit {
  color: #222;

  font-family: Pretendard;
  font-size: 18px;
  strong {
    color: #d74343;
  }
}
.range-slide-box {
  .slide-mark {
    > span {
      color: #999;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 12px */
      letter-spacing: -0.24px;
      min-width: 24px;
      text-align: center;
    }
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }
}
::v-deep {
  .vue-slider-rail {
    background: #e8e8e8;
  }
  .vue-slider-process {
    background-color: #444444;
  }
  .vue-slider-dot-handle {
    background-color: #fff;
    stroke-width: 1px;
    border: 1px solid #e8e8e8;
    filter: drop-shadow(1px 4px 9px rgba(0, 0, 0, 0.12));
  }
}
</style>
