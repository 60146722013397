var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "white-layer", attrs: { id: "join-wrap" } },
    [
      _vm.isTerms
        ? _c("Terms", {
            attrs: {
              termsViewData: _vm.termsViewData,
              termsName: _vm.termsName,
            },
          })
        : _vm._e(),
      _vm._m(0),
      _c("ul", { staticClass: "agree-list" }, [
        _c("li", { staticClass: "round-box" }, [
          _c("div", { staticClass: "base-check-box" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.termsAll,
                  expression: "termsAll",
                },
              ],
              attrs: { id: "termsAll", type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.termsAll)
                  ? _vm._i(_vm.termsAll, null) > -1
                  : _vm.termsAll,
              },
              on: {
                change: [
                  function ($event) {
                    var $$a = _vm.termsAll,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.termsAll = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.termsAll = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.termsAll = $$c
                    }
                  },
                  _vm.termsAllCheck,
                ],
              },
            }),
            _c("label", { attrs: { for: "termsAll" } }),
          ]),
          _vm._m(1),
        ]),
        _c("li", [
          _c("div", { staticClass: "base-check-box" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.ageTerms,
                  expression: "ageTerms",
                },
              ],
              attrs: { id: "ageTerms", type: "checkbox", value: "ACTIVE" },
              domProps: {
                checked: Array.isArray(_vm.ageTerms)
                  ? _vm._i(_vm.ageTerms, "ACTIVE") > -1
                  : _vm.ageTerms,
              },
              on: {
                change: [
                  function ($event) {
                    var $$a = _vm.ageTerms,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = "ACTIVE",
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.ageTerms = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.ageTerms = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.ageTerms = $$c
                    }
                  },
                  _vm.termsValid,
                ],
              },
            }),
            _c("label", { attrs: { for: "ageTerms" } }),
          ]),
          _vm._m(2),
        ]),
        _c("li", [
          _c("div", { staticClass: "base-check-box" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.memberTerms,
                  expression: "memberTerms",
                },
              ],
              attrs: { id: "memberTerms", type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.memberTerms)
                  ? _vm._i(_vm.memberTerms, null) > -1
                  : _vm.memberTerms,
              },
              on: {
                change: [
                  function ($event) {
                    var $$a = _vm.memberTerms,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.memberTerms = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.memberTerms = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.memberTerms = $$c
                    }
                  },
                  _vm.termsValid,
                ],
              },
            }),
            _c("label", { attrs: { for: "memberTerms" } }),
          ]),
          _c(
            "div",
            {
              staticClass: "flex-just",
              on: {
                click: function ($event) {
                  return _vm.viewTerms("operating")
                },
              },
            },
            [
              _c("p", [_vm._v("[필수] 회원 이용약관")]),
              _c("i", { staticClass: "icon--arrow-move" }),
            ]
          ),
        ]),
        _c("li", [
          _c("div", { staticClass: "base-check-box" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.memberPersonalTerms,
                  expression: "memberPersonalTerms",
                },
              ],
              attrs: { id: "memberPersonalTerms", type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.memberPersonalTerms)
                  ? _vm._i(_vm.memberPersonalTerms, null) > -1
                  : _vm.memberPersonalTerms,
              },
              on: {
                change: [
                  function ($event) {
                    var $$a = _vm.memberPersonalTerms,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.memberPersonalTerms = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.memberPersonalTerms = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.memberPersonalTerms = $$c
                    }
                  },
                  _vm.termsValid,
                ],
              },
            }),
            _c("label", { attrs: { for: "memberPersonalTerms" } }),
          ]),
          _c(
            "div",
            {
              staticClass: "flex-just",
              on: {
                click: function ($event) {
                  return _vm.viewTerms("terms")
                },
              },
            },
            [
              _c("p", [_vm._v("[필수] 회원 개인정보 수집 및 이용 동의")]),
              _c("i", { staticClass: "icon--arrow-move" }),
            ]
          ),
        ]),
        _c("li", [
          _c("div", { staticClass: "base-check-box" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.marketingTerms,
                  expression: "marketingTerms",
                },
              ],
              attrs: { id: "marketingTerms", type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.marketingTerms)
                  ? _vm._i(_vm.marketingTerms, null) > -1
                  : _vm.marketingTerms,
              },
              on: {
                change: [
                  function ($event) {
                    var $$a = _vm.marketingTerms,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.marketingTerms = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.marketingTerms = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.marketingTerms = $$c
                    }
                  },
                  _vm.termsValid,
                ],
              },
            }),
            _c("label", { attrs: { for: "marketingTerms" } }),
          ]),
          _c(
            "div",
            {
              staticClass: "flex-just",
              on: {
                click: function ($event) {
                  return _vm.viewTerms("marketing")
                },
              },
            },
            [
              _c("p", [_vm._v("[선택] 회원 마케팅 수신동의")]),
              _c("i", { staticClass: "icon--arrow-move" }),
            ]
          ),
        ]),
      ]),
      _c(
        "button",
        {
          staticClass: "base-btn cl-pri full",
          attrs: { disabled: !_vm.isTermsBtn },
          on: { click: _vm.goAuthBtn },
        },
        [
          _c("span", { staticClass: "txt-size--m" }, [
            _vm._v("동의하고 계속하기"),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "page-title-section" }, [
      _c("h4", [
        _vm._v("퀀트마켓을 이용하려면"),
        _c("br"),
        _vm._v("동의가 필요해요"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex coll" }, [
      _c("p", [_vm._v("위의 내용을 모두 읽었으며 이에 동의합니다.")]),
      _c("span", { staticClass: "q-sub-txt" }, [_vm._v("(선택항목 포함)")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex coll" }, [
      _c("p", [_vm._v("[필수] 만 14세 이상.")]),
      _c("span", { staticClass: "q-sub-txt" }, [
        _vm._v("본 서비스는 만 14세 미만 고객에게 제공되지 않습니다. "),
        _c("br"),
        _vm._v(" 본인이 만 14세 이상이면 체크박스를 클릭해주세요"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }