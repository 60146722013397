// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.terms-wrap[data-v-4c014c50] {
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: #fff;
}
.terms-wrap .page-title-section[data-v-4c014c50] {
  margin-bottom: 24px;
}
.terms-wrap .date-area[data-v-4c014c50] {
  padding: 0 0 16px;
  border-bottom: 1px solid #e8e8e8;
}
.terms-wrap .terms-body[data-v-4c014c50] {
  padding: 24px 0;
  padding-right: 16px;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
