<template>
  <div id="port-storage-wrap">
    <ul class="tab-header">
      <li :class="{ on: tab === 1 }" @click="tab = 1">구독상품</li>
      <li :class="{ on: tab === 2 }" @click="tab = 2">MY</li>
      <li v-if="false" :class="{ on: tab === 3 }" @click="tab = 3">찜목록</li>
    </ul>
    <div class="btn-wrap" :style="tab !== 2 ? { border: 'none' } : ''">
      <button
        v-if="tab === 2"
        class="add-btn"
        @click="
          $router.push({
            name: 'AddProduct'
          })
        "
      >
        상품만들기 신청<i class="icon--edit ml-4"></i>
      </button>
    </div>
    <ul class="inner-list" v-if="tab === 1">
      <li
        v-for="(d, i) in subscriptions"
        :key="i + 'subscriptions'"
        @click="goSubscriptionsDetail(d)"
      >
        <!-- <p class="status" :class="{blue , gray ,black}"></p> -->
        <div class="sub-txt">
          <p
            class="status"
            :class="isSubStatus(d.subscriptionEndDate) ? 'blue' : 'gray'"
          >
            {{ isSubStatus(d.subscriptionEndDate) ? '구독중' : '구독종료' }}
          </p>
        </div>
        <div class="thumb-box">
          <img :src="d.storageAccesses" alt="" />
        </div>
        <div class="detail-info">
          <div class="title">{{ d.productName }}</div>
          <div :class="radarIsDown(d.rorPercent3m) ? 'status' : 'rate'">
            최근3개월 <strong>{{ rorPercentConvert(d.rorPercent3m) }}%</strong>
          </div>
          <p class="start-date">시작일 | {{ d.subscriptionStartDate }}</p>
          <p class="end-date">종료일 | {{ d.subscriptionEndDate }}</p>
        </div>
        <i class="icon--fav" v-if="tab === 3" @click="removeFav"></i>
      </li>
    </ul>
    <ul class="inner-list" v-if="tab === 2">
      <li
        v-for="(d, i) in portfolios"
        :key="i + 'portfolio'"
        @click="goPortfolioDetail(d)"
      >
        <!-- <p class="status" :class="{blue , gray ,black}"></p> -->
        <p class="status blue"></p>
        <div class="r-box">
          <RadarChart
            :data="converterRadarData(d.styleData)"
            :isDown="radarIsDown(d.rorPercent3m)"
            size="S"
          ></RadarChart>
        </div>
        <div class="detail-info">
          <div class="title">{{ d.portfolioName }}</div>
          <div :class="radarIsDown(d.rorPercent3m) ? 'status' : 'rate'">
            최근3개월 <strong>{{ rorPercentConvert(d.rorPercent3m) }}%</strong>
          </div>
          <p class="start-date">시작일 | {{ d.startDate }}</p>
          <p class="end-date">종료일 | {{ d.endDate }}</p>
        </div>
        <i class="icon--fav" v-if="tab === 3" @click="removeFav"></i>
      </li>
    </ul>
  </div>
</template>

<script>
import { axiosAuthInstance } from '@/store/axios'
import RadarChart from '@/components/common/RadarChart.vue'

export default {
  name: 'QuantMarketPortfolioStorage',
  components: { RadarChart },

  data() {
    return {
      tab: 1,
      subscriptions: [],
      portfolios: []
    }
  },
  created() {
    console.log(this.$route.query.tap)
    this.tab = this.$route.query.tap === 'my' ? 2 : 1
    this.getPortfolioList()
    this.getProductSubscriptionList()
  },
  methods: {
    getProductSubscriptionList() {
      const param = {
        index: 0,
        size: 50
      }

      axiosAuthInstance
        .get('/product/subscription', JSON.stringify(param))
        .then((response) => {
          console.log(response)
          this.subscriptions = response.productSubscriptions
        })
        .catch((error) => {
          console.log('error', error)
        })
    },
    getPortfolioList() {
      const param = {
        index: 0,
        size: 50
      }
      axiosAuthInstance
        .get('/portfolio', JSON.stringify(param))
        .then((response) => {
          console.log(response)
          this.portfolios = response.portfolios
        })
        .catch((error) => {
          console.log('error', error)
        })
    },
    goSubscriptionsDetail(subscriptions) {
      const { orderId } = subscriptions
      this.$router.push({
        name: 'SubscribeDetail',
        params: { id: orderId }
      })
    },
    goPortfolioDetail(portfolioInfo) {
      const { portfolioId } = portfolioInfo
      this.$router.push({
        name: 'PortfolioDetail',
        params: { id: portfolioId }
      })
    },
    removeFav() {
      let obj = {
        title: `관심목록에서 삭제하시겠습니까?`,
        message: '',
        type: 'info',
        useConfirmBtn: true,
        customConfirmBtnText: '확인',
        customCloseBtnText: '취소'
        // onConfirm: this.confirmClick
      }
      this.$Simplert.open(obj)
    },
    converterRadarData(radarData) {
      console.log(radarParam)
      const radarParam = JSON.parse(radarData)
      const radar = [
        Number(radarParam.growth),
        Number(radarParam.value),
        Number(radarParam.momentum),
        Number(radarParam.dividend),
        Number(radarParam.lowvol),
        Number(radarParam.quality)
      ]
      return radar
    },
    radarIsDown(rorPercent) {
      return rorPercent < 0
    },
    isSubStatus(subEndDate) {
      const endDate = new Date(subEndDate)
      const now = new Date()
      return endDate > now
    },
    rorPercentConvert(rorPercent) {
      return rorPercent >= 0 ? '+' + rorPercent : rorPercent
    }
  }
}
</script>

<style lang="scss" scoped>
.tab-header {
  display: flex;
  gap: 16px;
  border-bottom: 1px solid #e8e8e8;
  padding: 0 16px;
  li {
    flex: 1;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    color: #aaa;
    padding: 15px 0;
    &.on {
      color: #222;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        bottom: -2px;
        left: 0;
        display: inline-block;
        width: 100%;
        height: 2px;
        background-color: #222;
      }
    }
  }
}
.btn-wrap {
  display: flex;
  border-bottom: 1px solid #e8e8e8;
  padding: 16px;

  .add-btn {
    color: #666;
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 4px;
  }
}
.inner-list {
  li {
    padding: 16px;
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #e8e8e8;
    .r-box {
      padding: 0px 12px;
      justify-content: center;
    }
    .sub-txt {
      width: 100%;
      padding-bottom: 5px;
      p {
        font-weight: 500;
        &.blue {
          color: #4a4ee7;
        }
        &.gray {
          color: #bfbfbf;
        }
      }
    }
    .thumb-box {
      border-radius: 8px;
      overflow: hidden;
      width: 100px;
      height: 100px;
      background-color: #d9d9d9;
      margin-right: 16px;
      img {
        width: 100%;
      }
    }
    .detail-info {
      max-width: 60%;
      display: flex;
      flex-direction: column;
      gap: 4px;
      .status {
        font-size: 14px;
        font-weight: 500;
        &.blue {
          color: #4a4ee7;
        }
        &.gray {
          color: #bfbfbf;
        }
        &.black {
          color: #222;
        }
      }
      .title {
        font-weight: 500;
        color: #444;
      }
      .rate {
        color: #666;
        font-size: 12px;
        strong {
          font-size: 14px;
          font-weight: 600;
          color: #d74343;
        }
      }
      .start-date,
      .end-date {
        font-size: 12px;
        color: #999;
      }
    }
    .icon--fav {
      margin-left: auto;
      flex-shrink: 0;
    }
  }
}
</style>
