var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "flex coll white-layer layer",
      attrs: { id: "add-products-wrap" },
    },
    [
      _vm._m(0),
      _vm._m(1),
      _c(
        "button",
        {
          staticClass: "go-detail-btn",
          on: {
            click: function ($event) {
              _vm.toggleQues = true
            },
          },
        },
        [
          _c("span", [_vm._v("상세안내")]),
          _c("i", { staticClass: "icon--arrow-move" }),
        ]
      ),
      _c(
        "div",
        { staticClass: "round-wrap-box" },
        [
          _c("h5", { staticClass: "mb-24" }, [
            _vm._v("상품 만들기가 가능한 포트폴리오"),
          ]),
          _c("ExamRowList", {
            attrs: { rows: _vm.portfoliosReview },
            on: { rowClick: _vm.rowClick },
          }),
        ],
        1
      ),
      _vm.toggleReqeust
        ? _c("SlideUpModal", {
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "flex-just mb-16" }, [
                        _c("h5", [_vm._v("심사 요청하기")]),
                        _c("i", {
                          staticClass: "icon--close",
                          on: {
                            click: function ($event) {
                              _vm.toggleReqeust = false
                            },
                          },
                        }),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "main",
                  fn: function () {
                    return [
                      _c("InputText", {
                        attrs: {
                          placeholder: "회원님 포트폴리오를 설명해주세요",
                        },
                        on: {
                          "update:value": function ($event) {
                            _vm.comment = $event
                          },
                        },
                        model: {
                          value: _vm.comment,
                          callback: function ($$v) {
                            _vm.comment = $$v
                          },
                          expression: "comment",
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "button",
                  fn: function () {
                    return [
                      _c(
                        "button",
                        {
                          staticClass: "base-btn full cl-pri req-btn",
                          attrs: { disabled: _vm.comment.length === 0 },
                          on: { click: _vm.portfolioReviewApplication },
                        },
                        [_c("span", [_vm._v("전송")])]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              1191624387
            ),
          })
        : _vm._e(),
      _vm.toggleQues
        ? _c("FullModal", [
            _c("div", { staticClass: "full-modal" }, [
              _c("div", { staticClass: "flex-end full-modal-head" }, [
                _c("i", {
                  staticClass: "icon--close",
                  on: {
                    click: function ($event) {
                      _vm.toggleQues = false
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "full-modal-body" }, [
                _c("h3", { staticClass: "mt-8 mb-24" }, [
                  _vm._v("상품 만들기 절차"),
                ]),
                _c(
                  "ul",
                  _vm._l(_vm.steps, function (d, i) {
                    return _c("li", { key: i + "step" }, [
                      _c("div", { staticClass: "l-box" }, [
                        _c("span", [_vm._v(_vm._s(`STEP${i + 1}`))]),
                      ]),
                      _c("div", { staticClass: "r-box" }, [
                        _c("p", { staticClass: "title" }, [
                          _vm._v(_vm._s(d.title)),
                        ]),
                        _c("p", { staticClass: "desc" }, [
                          _vm._v(_vm._s(d.desc)),
                        ]),
                      ]),
                    ])
                  }),
                  0
                ),
              ]),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "page-head-options" }, [
      _c("i", { staticClass: "icon--close" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "page-title-section" }, [
      _c("h3", [_vm._v("상품 만들기 신청")]),
      _c("p", { staticClass: "q-sub-txt" }, [
        _vm._v("회원님의 멋진 아이디어를 상품으로 만들어보세요."),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }