import { render, staticRenderFns } from "./CommunityDetail.vue?vue&type=template&id=351624aa&scoped=true"
import script from "./CommunityDetail.vue?vue&type=script&lang=js"
export * from "./CommunityDetail.vue?vue&type=script&lang=js"
import style0 from "./CommunityDetail.vue?vue&type=style&index=0&id=351624aa&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "351624aa",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/smyu/Digital_Nomad/workspace/qmarket-official/client-front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('351624aa')) {
      api.createRecord('351624aa', component.options)
    } else {
      api.reload('351624aa', component.options)
    }
    module.hot.accept("./CommunityDetail.vue?vue&type=template&id=351624aa&scoped=true", function () {
      api.rerender('351624aa', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/community/CommunityDetail.vue"
export default component.exports