// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.policy-item[data-v-46cdfc1f] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
  color: #666;
  width: 100%;
  height: 54px;
  padding: 0 4.4444444%;
}
.policy-item[data-v-46cdfc1f]:nth-child(2) {
  height: 66px;
  border-bottom: 12px solid #f1f1f1;
}
.policy .bottom-box-title[data-v-46cdfc1f] {
  color: #222;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 16px */
  letter-spacing: -0.32px;
  margin: 32px 0 16px;
}
.policy .bottom-box-item[data-v-46cdfc1f] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
  color: #222;
  width: 100%;
  height: 54px;
}
.policy .bottom-box-text[data-v-46cdfc1f] {
  color: #666;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
  letter-spacing: -0.28px;
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
