import Vue from 'vue'
import axios from 'axios'
import Vuex from 'vuex'

import auth from './modules/auth'
import community from './modules/community'
import main from './modules/main'
import ranking from './modules/ranking'
import strategy from './modules/strategy'
import portfolio from './modules/portfolio'
import review from './modules/review'
import ui from './modules/ui'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex, axios)

const initToken = localStorage.getItem('token') || ''

if (initToken) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${initToken}`
}

export default new Vuex.Store({
  modules: {
    auth,
    community,
    main,
    ranking,
    strategy,
    portfolio,
    ui,
    review
  },
  plugins: [createPersistedState()]
})
