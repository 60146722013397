// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.row-component .use-list li[data-v-6defec0e] {
  display: flex;
  align-items: center;
  padding: 16px 0;
  border-bottom: 1px solid #e8e8e8;
}
.row-component .use-list li[data-v-6defec0e]:first-child {
  padding-top: 0;
}
.row-component .use-list li .l-box[data-v-6defec0e] {
  flex: 1;
}
.row-component .use-list li .l-box .title[data-v-6defec0e] {
  color: #444;
  margin-bottom: 4px;
}
.row-component .use-list li .l-box .save-date[data-v-6defec0e] {
  color: #999;
  font-size: 12px;
}
.row-component .use-list li .r-box[data-v-6defec0e] {
  margin-left: auto;
  flex-shrink: 0;
}
.row-component .use-list li .r-box .status-btn[data-v-6defec0e] {
  width: 90px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #e8e8e8;
  font-weight: 500;
}
.row-component .use-list li .r-box .status-btn.before[data-v-6defec0e] {
  font-weight: 600;
  border: 1px solid #4a4ee7;
  color: #4a4ee7;
}
.row-component .use-list li .r-box .status-btn.after[data-v-6defec0e] {
  color: #666;
}
.row-component .use-list li .r-box .status-btn.ing[data-v-6defec0e] {
  color: #d74343;
}
.row-component .use-list li .r-box .status-btn.done[data-v-6defec0e] {
  color: #4a4ee7;
}
.row-component .use-list li.empty[data-v-6defec0e] {
  padding: 80px 0 120px;
  text-align: center;
  color: #999;
  justify-content: center;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
