// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tab-header[data-v-0e6992b4] {
  display: flex;
  gap: 16px;
  border-bottom: 1px solid #e8e8e8;
  padding: 0 16px;
}
.tab-header li[data-v-0e6992b4] {
  flex: 1;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  color: #aaa;
  padding: 15px 0;
}
.tab-header li.on[data-v-0e6992b4] {
  color: #222;
  position: relative;
}
.tab-header li.on[data-v-0e6992b4]::before {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 2px;
  background-color: #222;
}
.btn-wrap[data-v-0e6992b4] {
  display: flex;
  border-bottom: 1px solid #e8e8e8;
  padding: 16px;
}
.btn-wrap .add-btn[data-v-0e6992b4] {
  color: #666;
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 4px;
}
.inner-list li[data-v-0e6992b4] {
  padding: 16px;
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #e8e8e8;
}
.inner-list li .r-box[data-v-0e6992b4] {
  padding: 0px 12px;
  justify-content: center;
}
.inner-list li .sub-txt[data-v-0e6992b4] {
  width: 100%;
  padding-bottom: 5px;
}
.inner-list li .sub-txt p[data-v-0e6992b4] {
  font-weight: 500;
}
.inner-list li .sub-txt p.blue[data-v-0e6992b4] {
  color: #4a4ee7;
}
.inner-list li .sub-txt p.gray[data-v-0e6992b4] {
  color: #bfbfbf;
}
.inner-list li .thumb-box[data-v-0e6992b4] {
  border-radius: 8px;
  overflow: hidden;
  width: 100px;
  height: 100px;
  background-color: #d9d9d9;
  margin-right: 16px;
}
.inner-list li .thumb-box img[data-v-0e6992b4] {
  width: 100%;
}
.inner-list li .detail-info[data-v-0e6992b4] {
  max-width: 60%;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.inner-list li .detail-info .status[data-v-0e6992b4] {
  font-size: 14px;
  font-weight: 500;
}
.inner-list li .detail-info .status.blue[data-v-0e6992b4] {
  color: #4a4ee7;
}
.inner-list li .detail-info .status.gray[data-v-0e6992b4] {
  color: #bfbfbf;
}
.inner-list li .detail-info .status.black[data-v-0e6992b4] {
  color: #222;
}
.inner-list li .detail-info .title[data-v-0e6992b4] {
  font-weight: 500;
  color: #444;
}
.inner-list li .detail-info .rate[data-v-0e6992b4] {
  color: #666;
  font-size: 12px;
}
.inner-list li .detail-info .rate strong[data-v-0e6992b4] {
  font-size: 14px;
  font-weight: 600;
  color: #d74343;
}
.inner-list li .detail-info .start-date[data-v-0e6992b4],
.inner-list li .detail-info .end-date[data-v-0e6992b4] {
  font-size: 12px;
  color: #999;
}
.inner-list li .icon--fav[data-v-0e6992b4] {
  margin-left: auto;
  flex-shrink: 0;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
