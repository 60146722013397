<template>
  <header>
    <div class="logo-box"><i class="icon--gnb-logo on"></i></div>
    <ul class="gnb-menu">
      <li>
        <router-link :to="{ name: 'Ranking' }"
          ><i class="icon--gnb-rank on"></i
        ></router-link>
      </li>
      <li>
        <router-link :to="{ name: 'Community' }"
          ><i class="icon--gnb-message on"></i
        ></router-link>
      </li>
      <li v-if="false">
        <router-link :to="{ name: 'Ranking' }"
          ><i class="icon--gnb-alarm on"></i
        ></router-link>
      </li>
    </ul>
  </header>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Header',
  data() {
    return {
      scrollPosition: null,
      showMobileMenu: false
    }
  },
  computed: {
    isLoggedIn() {
      return this.getCurrentUser
    },
    ...mapGetters(['isSidebarOpen'])
  },
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  padding: 0 16px;
  .gnb-menu {
    display: flex;
    gap: 12px;
  }
}
// @import '../../../src/assets/scss/_variables.scss';
</style>
