// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-desc[data-v-cbee8a26] {
  color: #bfbfbf;
  font-size: 12px;
  font-weight: 400;
}
.margin-top-10p[data-v-cbee8a26] {
  margin-top: 10%;
}
.qna[data-v-cbee8a26] {
  width: 100%;
  padding: 0 16px;
}
.qna *[data-v-cbee8a26] {
  line-height: 140%;
}
.qna .qna-list-box[data-v-cbee8a26] {
  border-bottom: 1px solid #e8e8e8;
}
.qna .qna-list-box .qna-list-box-title[data-v-cbee8a26] {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.qna .qna-list-box .qna-list-box-description[data-v-cbee8a26] {
  display: none;
}
.qna .qna-list-box.on[data-v-cbee8a26] {
  position: relative;
}
.qna .qna-list-box.on .qna-list-box-title[data-v-cbee8a26] {
  -webkit-line-clamp: unset;
}
.qna .qna-list-box.on .qna-list-box-description[data-v-cbee8a26] {
  display: block;
}
.qna .qna-list-box.on[data-v-cbee8a26]::before {
  content: "";
  position: absolute;
  display: inline-block;
  z-index: -1;
  width: 100vw;
  height: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #f7f7f7;
}
.qna .qna-list-box-img[data-v-cbee8a26] {
  display: flex;
}
.qna .qna-list-box-img > div[data-v-cbee8a26] {
  width: 50%;
}
.qna .qna-list-box-img > div > div[data-v-cbee8a26] {
  display: block;
  width: 100%;
  padding-top: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}
.qna .qna-list-box-img > div > div .more-no[data-v-cbee8a26] {
  position: absolute;
  font-size: 30px;
  font-weight: 700;
  top: 50%;
  left: 50%;
  color: #fff;
  transform: translate(-50%, -50%);
}
.qna-tab[data-v-cbee8a26] {
  display: flex;
  align-items: center;
  width: 100%;
  text-align: center;
}
.qna-tab div[data-v-cbee8a26] {
  width: 100%;
  height: 48px;
  line-height: 48px;
  letter-spacing: -0.32px;
  color: #aaa;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.32px;
}
.qna-tab .active[data-v-cbee8a26] {
  border-bottom: 1px solid #000;
  color: #222;
}
.qna .qna-list[data-v-cbee8a26] {
  padding-top: 28px;
}
.qna .qna-list-box[data-v-cbee8a26] {
  padding: 20px 0;
}
.qna .qna-list-box-date[data-v-cbee8a26] {
  color: #aaa;
  text-align: left;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 12px;
  font-weight: 400;
  line-height: 100%; /* 12px */
  letter-spacing: -0.24px;
  margin-bottom: 4px;
}
.qna .qna-list-box-title[data-v-cbee8a26] {
  color: #222;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
  letter-spacing: -0.28px;
  margin-bottom: 4px;
}
.qna .qna-list-box-description[data-v-cbee8a26] {
  color: #666;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
  letter-spacing: -0.28px;
}
.qna .qna-list-box-img[data-v-cbee8a26] {
  margin-top: 12px;
  width: 100%;
  min-height: 164px;
  max-height: 164px;
  background-color: #000;
  border-radius: 6px;
  overflow: hidden;
}
.qna .qna-list-box-status[data-v-cbee8a26] {
  color: #d74343;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.24px;
  margin-top: 18px;
}
.qna .qna-list-box-status.finish[data-v-cbee8a26] {
  color: #4a4ee7;
}
.qna .qna-list-box-answer-box[data-v-cbee8a26] {
  padding-left: 14px;
  border-left: 2px solid #4a4ee7;
}
.qna .qna-list-box-answer-box-date[data-v-cbee8a26] {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.qna .qna-list-box-answer-box-date span[data-v-cbee8a26] {
  color: #aaa;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: -0.24px;
  margin-left: 8px;
}
.qna .qna-list-box-answer[data-v-cbee8a26] {
  padding: 24px 0;
}
.qna .qna-add[data-v-cbee8a26] {
  padding-top: 48px;
}
.qna .qna-add-input[data-v-cbee8a26] {
  width: 100%;
  padding-bottom: 6px;
  border-bottom: 2px solid #f1f1f1;
  margin-bottom: 8px;
  font-size: 20px;
}
.qna .qna-add-input[data-v-cbee8a26]::-moz-placeholder {
  color: #bfbfbf;
}
.qna .qna-add-input[data-v-cbee8a26]::placeholder {
  color: #bfbfbf;
}
.qna .qna-add h2[data-v-cbee8a26] {
  color: #999;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: -0.24px;
  margin-bottom: 6px;
}
.qna .qna-add textarea[data-v-cbee8a26] {
  font-size: 20px;
  font-weight: 400;
  line-height: 28px; /* 28px */
}
.qna .qna-add-title-length[data-v-cbee8a26], .qna .qna-add-description-length[data-v-cbee8a26] {
  text-align: right;
  margin-bottom: 30px;
  color: #bfbfbf;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: -0.24px;
}
.qna .qna-add-photo-list[data-v-cbee8a26] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-content: space-around;
  margin-bottom: 120px;
  grid-gap: 8px;
}
.qna .qna-add-photo-list li[data-v-cbee8a26] {
  width: 20.54054054vw;
  height: 20.54054054vw;
  background-color: lightgreen;
  position: relative;
}
.qna .qna-add-photo-list li[data-v-cbee8a26]:after {
  content: "x";
  color: #fff;
  text-align: center;
  line-height: 18px;
  display: block;
  position: absolute;
  top: 2px;
  right: 2px;
  width: 18px;
  height: 18px;
  background-color: #000;
  border-radius: 50%;
}
.qna .modal[data-v-cbee8a26] {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  min-height: calc(var(--vh, 1vh) * 100);
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 11;
}
.qna .modal .navigator[data-v-cbee8a26] {
  width: 100%;
  height: 48px;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
}
.qna .modal .photo-area[data-v-cbee8a26] {
  width: 100%;
}
.qna .modal .photo-area figure[data-v-cbee8a26] {
  margin: 116px auto 0;
  display: block;
  max-width: 328px;
  min-height: 328px;
  border-radius: 14px;
  background: #d9d9d9;
  overflow: hidden;
  margin-bottom: 24px;
}
.qna .modal .photo-area .dot-list[data-v-cbee8a26] {
  margin-top: 24px;
  display: flex;
  justify-content: center;
  gap: 6px;
}
.qna .modal .photo-area .dot-list-item[data-v-cbee8a26] {
  min-width: 6px;
  min-height: 6px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.4);
}
.qna .modal .photo-area .dot-list-item.active[data-v-cbee8a26] {
  width: 16px;
  border-radius: 7px;
  background-color: #fff;
}
.qna .photo-add-list[data-v-cbee8a26] {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 8px;
  margin-bottom: 10px;
}
.qna .photo-add-list > li[data-v-cbee8a26] {
  width: 72px;
  height: 72px;
  border-radius: 8px;
  flex-shrink: 0;
  border: 1px solid #e8e8e8;
  overflow: hidden;
}
.qna .photo-add-list .photo-add-btn[data-v-cbee8a26] {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.qna .photo-add-list .choose-user-photo[data-v-cbee8a26] {
  position: relative;
}
.qna .photo-add-list .choose-user-photo > i[data-v-cbee8a26] {
  position: absolute;
  top: 2px;
  right: 2px;
}
.qna .photo-add-list span[data-v-cbee8a26] {
  margin-top: -8px;
  color: #999;
  font-size: 12px;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
