<template>
  <div id="bot-menu">
    <StrategyRegisterModal
      v-if="isShowRegisterModal"
      @close="isShowRegisterModal = false"
    />
    <ul>
      <li>
        <router-link :to="{ name: 'Main' }"
          ><i class="icon--menu-home" :class="{ on: current === 'Main' }"></i
          ><span>홈</span></router-link
        >
      </li>
      <li>
        <router-link :to="{ name: 'Rounge' }"
          ><i
            class="icon--menu-rounge"
            :class="{ on: current === 'Rounge' }"
          ></i
          ><span>라운지</span></router-link
        >
      </li>
      <li class="middle" @click="openStrategy">
        <router-link :to="{}"
          ><i
            class="icon--menu-make"
            :class="{ on: current === 'AddStrategy02' }"
          ></i
          ><span>전략만들기</span></router-link
        >
      </li>
      <li>
        <router-link :to="{ name: 'Shopping' }">
          <i
            class="icon--menu-shopping"
            :class="{ on: current === 'Shopping' }"
          ></i
          ><span>쇼핑</span></router-link
        >
      </li>
      <li>
        <router-link :to="{ name: 'MyPage' }">
          <i class="icon--menu-my" :class="{ on: current === 'MyPage' }"></i
          ><span>마이</span></router-link
        >
      </li>
    </ul>
  </div>
</template>

<script>
import StrategyRegisterModal from './modal/StrategyRegisterModal.vue'

export default {
  name: 'BotMenu',
  data() {
    return {
      isShowRegisterModal: false
    }
  },
  computed: {
    current() {
      return this.$route.name
    }
  },
  methods: {
    openStrategy() {
      this.isShowRegisterModal = true
      // this.$store.commit('toggleAddStrategy', true)
    }
  },
  components: { StrategyRegisterModal }
}
</script>

<style lang="scss" scoped>
#bot-menu {
  z-index: 98999;
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 8px 16px 12px;
  background-color: #fff;
  ul {
    display: flex;
    justify-content: space-between;
    gap: 22px;
    > li {
      flex: 1;
      min-width: 48px;
      a {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        span {
          font-size: 10px;
          color: #999;
        }
        i.on ~ span {
          color: #222;
        }
      }
    }
  }
}
</style>
