var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "product-box" }, [
    _c(
      "div",
      {
        staticClass: "thumb",
        class: {
          "badge-edtion": _vm.data.state === "edtion",
          "badge-rec": _vm.data.recommendationStatus === "ACTIVE",
          "badge-discount": _vm.data.discountApplied,
        },
        on: {
          click: function ($event) {
            return _vm.goShoppingDetail(_vm.data.productId)
          },
        },
      },
      [
        _c("span", { staticClass: "badge", class: { edtion: true } }),
        _c("img", { attrs: { src: _vm.data.storageAccesses[0], alt: "" } }),
      ]
    ),
    _c("p", { staticClass: "title no-wrap-text-2 mt-6" }, [
      _vm._v(_vm._s(_vm.data.productName)),
    ]),
    _c("strong", { staticClass: "price" }, [
      _c("b", { staticClass: "discount" }, [
        _vm._v(
          _vm._s(
            _vm.discountRateCalculation(
              _vm.data.discountValue,
              _vm.data.price,
              _vm.data.discountApplicationMethod
            )
          ) + "%"
        ),
      ]),
      _vm._v(" " + _vm._s(Number(_vm.data.salePrice).toLocaleString()) + "원 "),
    ]),
    _c("p", { staticClass: "before-info" }, [
      _c("span", [
        _c("span", { staticClass: "before-price" }, [
          _vm._v(" " + _vm._s(Number(_vm.data.price).toLocaleString()) + "원 "),
        ]),
        _vm._v(" (6개월) "),
      ]),
    ]),
    _c("p", { staticClass: "recent-percent" }, [
      _c("span", [_vm._v("최근" + _vm._s(_vm.data.period) + "개월")]),
      _c("span", [_vm._v("|")]),
      _c(
        "span",
        {
          staticClass: "percent",
          class: _vm.radarIsDown(_vm.data.rorPercent3m) ? "isDown" : "",
        },
        [_vm._v(_vm._s(_vm.rorPercentConvert(_vm.data.rorPercent3m)) + "%")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }