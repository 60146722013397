<template>
  <div id="join-wrap" class="white-layer">
    <SlideUpModal v-if="isProfile">
      <template #header>
        <div class="flex-just">
          <h4>프로필 이미지를 선택해주세요</h4>
          <i class="icon--close" @click="closeSlider"></i>
        </div>
      </template>
      <template #main>
        <ul class="send-modal-body">
          <li @click="setProfile('m1')" :class="{ on: tempProfileId === 'm1' }">
            <i class="icon--char m1"></i>
          </li>
          <li @click="setProfile('m2')" :class="{ on: tempProfileId === 'm2' }">
            <i class="icon--char m2"></i>
          </li>
          <li @click="setProfile('m3')" :class="{ on: tempProfileId === 'm3' }">
            <i class="icon--char m3"></i>
          </li>
          <li @click="setProfile('m4')" :class="{ on: tempProfileId === 'm4' }">
            <i class="icon--char m4"></i>
          </li>

          <li @click="setProfile('w1')" :class="{ on: tempProfileId === 'w1' }">
            <i class="icon--char w1"></i>
          </li>
          <li @click="setProfile('w2')" :class="{ on: tempProfileId === 'w2' }">
            <i class="icon--char w2"></i>
          </li>
          <li @click="setProfile('w3')" :class="{ on: tempProfileId === 'w3' }">
            <i class="icon--char w3"></i>
          </li>
          <li @click="setProfile('w4')" :class="{ on: tempProfileId === 'w4' }">
            <i class="icon--char w4"></i>
          </li>
        </ul>
      </template>
      <template #button>
        <div class="modal-btn-wrap">
          <button class="base-btn harf cl-sec" @click="closeSlider">
            <span>취소</span>
          </button>
          <button class="base-btn harf cl-pri" @click="chooseProfile">
            <span>선택</span>
          </button>
        </div>
      </template>
    </SlideUpModal>
    <div class="join-head">
      <i class="icon--logo"></i>
      <h4>퀀트마켓 회원이 되신것을<br />환영합니다!</h4>
      <p>사용하실 닉네임과 프로필 이미지를 선택해주세요</p>
      <p>(MY페이지에서 다시 수정이 가능합니다.)</p>
    </div>
    <div class="round-box">
      <i
        :class="profileId"
        class="icon--add-prof icon--char"
        @click="profileShow"
      ></i>
      <p class="input-tilte">닉네임</p>
      <div class="round-input">
        <input
          v-model="nickName"
          type="text"
          placeholder="한글, 영문, 숫자, 조합 6~12자"
          maxlength="12"
          minlength="6"
        />
        <i class="icon--random"></i>
        <p v-if="isValid" class="valid-txt error">{{ validTxt }}</p>
      </div>
    </div>
    <button class="base-btn cl-pri full static-height" @click="goUserJoin">
      <span class="txt-size--m">시작하기</span>
    </button>
  </div>
</template>

<script>
import SlideUpModal from '@/components/common/modal/SlideUpModal.vue'
import { axiosAuthInstance } from '@/store/axios'

export default {
  name: 'Join',
  components: { SlideUpModal },
  data() {
    return {
      nickName: '',
      toggleAddProf: false,
      isShowSlider: false,
      profileId: '',
      isProfile: false,
      isValid: false,
      validTxt: '한글, 영문, 숫자, 조합 6~12자에 맞지 않아요',
      tempProfileId: ''
    }
  },
  methods: {
    closeSlider() {
      this.isProfile = false
    },
    setProfile(profileId) {
      this.tempProfileId = profileId
    },
    profileShow() {
      this.isProfile = true
    },
    chooseProfile() {
      this.isProfile = false
      this.profileId = this.tempProfileId
    },
    goGallery() {
      console.log('goGallery')
    },
    goCamera() {
      console.log('goCamera')
    },
    goAuthBtn(payload) {
      console.log(payload)
    },
    goUserJoin() {
      console.log(this.profileId)
      if (this.profileId === '') {
        this.$Simplert.open({
          title: '알림',
          message: '프로필 이미지를 선택해주세요.'
        })
        this.isValid = false
        return false
      }
      if (!this.isNickNameValid()) {
        // 닉네임 중복 체크
        axiosAuthInstance
          .get('/user/duplication/' + this.nickName)
          .then(() => {
            const data = {
              nickName: this.nickName,
              storageHash: 'icon_man_3'
            }
            this.$store.commit('SET_USER_INFO', data)
            this.join()
          })
          .catch((error) => {
            this.$Simplert.open({
              title: '알림',
              message:
                '이미 사용중인 닉네임이에요. 다른 닉네임을 입력해 주세요.'
            })
            this.validTxt = ''
            this.isValid = false
            console.log('error', error)
          })
      }
    },
    isNickNameValid() {
      // 닉네임 6자 ~ 12자 체크
      if (this.nickName.length < 6 || this.nickName > 12) {
        this.validTxt = '한글, 영문, 숫자, 조합 6~12자에 맞지 않아요.'
        this.isValid = true
        return true
      }

      // 닉네임 한글, 영문, 숫자 조합 체크
      const regType = /^[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9|\\*]+$/
      if (!regType.test(this.nickName)) {
        this.validTxt = '한글, 영문, 숫자, 조합 6~12자에 맞지 않아요.'
        this.isValid = true
        return true
      }
    },
    join() {
      //this.$router.push('/')
      const termsConsents = []
      const user = this.$store.getters.getUser
      const terms = user.termsConsents
      if (user.marketingTerms) {
        for (const termsInfo of terms.marketing) {
          termsConsents.push(termsInfo.termsId)
        }
      }
      if (
        user.ageTerms === true &&
        user.memberPersonalTerms === true &&
        user.memgerTerms === true
      ) {
        for (const termsInfo of terms.terms) {
          termsConsents.push(termsInfo.termsId)
        }
      }

      const parma = {
        nickName: user.nickName,
        phoneNumber: user.phoneNumber,
        email: user.email,
        gender: 'UNKNOWN',
        birthDay: null,
        userType: 'GENERAL',
        socialId: user.socialId,
        socialType: user.socialType,
        termsConsentItems: termsConsents,
        storageHash: this.getStorageHash()
      }

      axiosAuthInstance
        .post('/user', JSON.stringify(parma), {
          headers: {
            'Content-Type': `application/json`
          }
        })
        .then((response) => {
          console.log(response)

          const user = {
            email: parma.email,
            socialId: parma.socialId,
            socialType: parma.socialType,
            storageAccess: parma.storageAccess,
            isUser: true
          }

          this.$store.commit('SET_USER_INFO', user)

          this.$router.push('/')
          return false
        })
        .catch((error) => {
          console.log('error', error)
          return true
        })
    },
    getStorageHash() {
      switch (this.profileId) {
        case 'm1':
          return 'icon_man_1'
        case 'm2':
          return 'icon_man_2'
        case 'm3':
          return 'icon_man_3'
        case 'm4':
          return 'icon_man_4'
        case 'w1':
          return 'icon_wom_1'
        case 'w2':
          return 'icon_wom_2'
        case 'w3':
          return 'icon_wom_3'
        case 'w4':
          return 'icon_wom_4'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#join-wrap {
  display: flex;
  flex-direction: column;

  .join-head {
    display: flex;
    flex-direction: column;
    align-items: center;
    > h4 {
      margin: 10px 0 12px;
      text-align: center;
    }
    > p {
      text-align: center;
    }
  }
  .round-box {
    border-radius: 14px;
    background: #f7f7f7;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 16px;
    margin-top: 40px;
    .input-tilte {
      color: #666;
      font-size: 12px;
      font-weight: 500;
      width: 100%;
      margin: 24px 0 8px;
    }
    .round-input {
      display: flex;
      position: relative;
      width: 100%;
      border-radius: 14px;
      border: 1px solid #e8e8e8;
      background: #fff;
      justify-content: space-between;
      align-items: center;
      padding: 17px 14px;
    }
  }

  > .base-btn.full {
    margin-top: auto;
  }

  .send-modal-body {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr 1fr;
    gap: 12px;
    input {
      display: none;
    }
    > li {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #f1f1f1;
      border-radius: 50%;
      // padding-top: 100%;
      aspect-ratio: 1/1;
      position: relative;
      &.on {
        border: 2px solid #4a4ee7;
        box-sizing: border-box;
      }
      > i {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .modal-btn-wrap {
      display: flex;
      margin-top: 48px;
      gap: 8px;
    }
    // flex-wrap: wrap;
  }
}
</style>
