<template>
  <div class="service">
    <ul>
      <li
        class="service-item flex-between"
        @click="$router.push({ name: 'Faq' })"
      >
        <span class="service-title">자주 묻는 질문</span>
        <i class="icon--arrow-move"></i>
      </li>
      <li
        class="service-item flex-between"
        @click="$router.push({ name: 'Qna' })"
      >
        <span class="service-title">1:1 문의</span>
        <i class="icon--arrow-move"></i>
      </li>
      <li
        class="service-item flex-between"
        @click="$router.push({ name: 'Policy' })"
      >
        <span class="service-title">약관 및 정책</span>
        <i class="icon--arrow-move"></i>
      </li>
      <li class="service-item flex-between">
        <span class="service-title">버전정보</span>
        <button class="service-version">현재 버전 1.0</button>
      </li>
      <li class="service-item flex-between" @click="userLogoutBtn">
        <span class="service-title">로그아웃</span>
        <i class="icon--arrow-move"></i>
      </li>
      <li class="service-item flex-between" @click="userLeaveBtn">
        <span class="service-title">탈퇴하기</span>
        <i class="icon--arrow-move"></i>
      </li>
    </ul>
  </div>
</template>

<script>
import { axiosAuthInstance } from '@/store/axios'

export default {
  methods: {
    userLogoutBtn() {
      this.$Simplert.open({
        title: '로그아웃',
        message: '로그아웃 하시겠습니까?',
        type: 'info',
        useConfirmBtn: true,
        customConfirmBtnText: '확인',
        customCloseBtnText: '취소',
        onConfirm: this.userLogout
      })
    },
    userLogout() {
      axiosAuthInstance
        .delete('/user/logout')
        .then(() => {
          localStorage.setItem('accessToken', '')
          localStorage.setItem('refreshToken', '')
          this.$store.commit('SET_USER_INFO', {})
          this.$router.push('/')
        })
        .catch((error) => {
          console.log('error', error)
        })
    },
    userLeaveBtn() {
      console.log('탈퇴 하기')
      this.$Simplert.open({
        title: '회원 탈퇴',
        message: '회원 탈퇴를 하시겠습니까?',
        type: 'info',
        useConfirmBtn: true,
        customConfirmBtnText: '확인',
        customCloseBtnText: '취소',
        onConfirm: this.userLeave
      })
    },
    userLeave() {
      axiosAuthInstance
        .delete('/user')
        .then(() => {
          this.$store.commit('SET_USER_INFO', {})
          localStorage.setItem('accessToken', '')
          localStorage.setItem('refreshToken', '')
          this.$router.push('/')
        })
        .catch((error) => {
          console.log('error', error)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.service {
  ul {
    width: 100%;
  }
  &-item {
    padding: 0 4.4444444%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 54px;
    color: #666;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    letter-spacing: -0.32px;
    .service-version {
      color: #999;
      text-align: right;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      letter-spacing: -0.32px;
    }
  }
  &-item:nth-child(3),
  &-item:nth-child(4),
  &-item:nth-child(5) {
    height: 66px;
    border-bottom: 12px solid #f1f1f1;
  }

  line {
    width: 100%;
    height: 12px;
    background-color: #f1f1f1;
  }
}
</style>
