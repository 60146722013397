// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#join-wrap[data-v-e1164486] {
  display: flex;
  flex-direction: column;
}
#join-wrap .input-list[data-v-e1164486] {
  gap: 32px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
#join-wrap .input-list .valid-input[data-v-e1164486] {
  position: relative;
}
#join-wrap .input-list .valid-input i[data-v-e1164486],
#join-wrap .input-list .valid-input span[data-v-e1164486],
#join-wrap .input-list .valid-input .limit-time[data-v-e1164486] {
  position: absolute;
  right: 8px;
  bottom: 6px;
}
#join-wrap .input-list .valid-input .valid-txt[data-v-e1164486] {
  position: absolute;
  left: 0;
  top: 100%;
  font-size: 12px;
  transform: translateY(8px);
}
#join-wrap .input-list .valid-input.error[data-v-e1164486] {
  border-color: #d74343;
}
#join-wrap .input-list .valid-input.error span[data-v-e1164486],
#join-wrap .input-list .valid-input.error p[data-v-e1164486] {
  color: #d74343;
}
#join-wrap .input-list .valid-input .retry-btn[data-v-e1164486] {
  position: absolute;
  bottom: -48px;
  right: 0;
  color: #999;
  display: inline-flex;
  height: auto;
  padding: 8px 9px;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  border-radius: 8px;
  background: #f1f1f1;
}
#join-wrap > .base-btn.full[data-v-e1164486] {
  margin-top: auto;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
