// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.examine-top-title[data-v-3a7f16de] {
  font-size: 16px;
  font-weight: 500;
  flex: 1;
  flex-shrink: 0;
}
.examine-top-description[data-v-3a7f16de] {
  color: #666;
  margin-top: 8px;
}
.examine-top .sub-box[data-v-3a7f16de] {
  display: flex;
  justify-content: space-between;
  align-self: center;
}
.examine-top .sub-box button[data-v-3a7f16de] {
  padding: 4px 9px;
  border-radius: 6px;
  background: #f5f5ff;
}
.examine-status[data-v-3a7f16de] {
  margin-top: 24px;
  display: flex;
  align-items: center;
}
.examine-status.cancel li[data-v-3a7f16de] {
  opacity: 0.5;
}
.examine-status-item[data-v-3a7f16de] {
  flex-basis: 80px;
  flex-shrink: 0;
  height: 38px;
  line-height: 38px;
  text-align: center;
  font-weight: 500;
  border-radius: 10px;
  border: 1px solid #e8e8e8;
  background: #fff;
}
.examine-status-item.active[data-v-3a7f16de] {
  border: 1px solid #4a4ee7;
  color: #4a4ee7;
  font-weight: 600;
}
.examine-status .bar[data-v-3a7f16de] {
  flex: 1;
  height: 1px;
  background: #e8e8e8;
}
.examine-status .bar.active[data-v-3a7f16de] {
  background-color: #4a4ee7;
}
.examine-text[data-v-3a7f16de] {
  width: 100%;
  border-radius: 14px;
  background-color: #f5f5ff;
  margin-top: 24px;
}
.examine-text-box[data-v-3a7f16de] {
  padding: 24px 16px;
}
.examine-text-box p[data-v-3a7f16de] {
  color: #444;
  text-align: center;
  font-weight: 500;
}
.examine-text-box-status[data-v-3a7f16de] {
  padding: 8px 16px;
  border-radius: 14px;
  background-color: #fff;
  color: #4882f5;
  font-size: 16px;
  font-weight: 600;
  margin-top: 8px;
  width: 130px;
  margin: 8px auto 0;
  text-align: center;
}
.examine-text-box-reject[data-v-3a7f16de] {
  display: none;
}
.examine-text-box-status.reject + .examine-text-box-reject[data-v-3a7f16de] {
  display: block;
  color: #999;
  text-align: center;
  line-height: 19.6px;
  margin-top: 12px;
}
.examine-text-box-status.reject[data-v-3a7f16de] {
  color: #d74343;
}
.examine-text-box-description[data-v-3a7f16de] {
  color: #444;
  text-align: center;
  margin: 0 auto;
  width: 215px;
}
.examine-list[data-v-3a7f16de] {
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid #e8e8e8;
}
.examine-list-nav-title[data-v-3a7f16de] {
  color: #222;
  font-size: 16px;
  font-weight: 500;
}
.examine-list-nav button[data-v-3a7f16de] {
  display: flex;
  align-items: center;
  gap: 3px;
  padding: 4px 9px;
  background-color: #f1f1f1;
  border-radius: 6px;
}
.examine-list-box[data-v-3a7f16de] {
  margin-top: 17px;
}
.examine-list-box-item[data-v-3a7f16de] {
  color: #666;
  margin-bottom: 12px;
}
.examine-list-box-item-date[data-v-3a7f16de] {
  margin-right: 16px;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
