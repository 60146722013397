<template>
  <div class="default-layer">
    <Header />
    <router-view />
    <BotMenu />
  </div>
</template>

<script>
import Header from '@/components/common/Header.vue'
import BotMenu from '@/components/common/BotMenu.vue'

export default {
  components: {
    Header,
    BotMenu
  },
  // **********************event
  data() {
    return {}
  },

  computed: {},
  beforeMount() {},
  async mounted() {},
  methods: {}
}
</script>
<style lang="scss"></style>
