<template>
  <div class="step01">
    <div class="page-title-section mb-24">
      <h4>
        6가지 핵심전략 중 <br />
        <strong>한 가지</strong>를 고르세요
      </h4>
    </div>
    <div class="stage01-type-list">
      <div
        class="round-box"
        :class="{ on: styleType === 'GROWTH' }"
        @click="styleType = 'GROWTH'"
      >
        <i class="icon--state exp"></i>
        <p class="type-tit">성장형</p>
        <p>아가야, 무럭무럭 자라라</p>
      </div>
      <div
        class="round-box"
        :class="{ on: styleType === 'VALUE' }"
        @click="styleType = 'VALUE'"
      >
        <i class="icon--state val"></i>
        <p class="type-tit">가치형</p>
        <p>저평가된 흙속의 진주찾기</p>
      </div>
      <div
        class="round-box"
        :class="{ on: styleType === 'MOMENTUM' }"
        @click="styleType = 'MOMENTUM'"
      >
        <i class="icon--state moe"></i>
        <p class="type-tit">모멘텀</p>
        <p>달리는 말에 올라타기</p>
      </div>
      <div
        class="round-box"
        :class="{ on: styleType === 'DIVIDEND' }"
        @click="styleType = 'DIVIDEND'"
      >
        <i class="icon--state money"></i>
        <p class="type-tit">배당형</p>
        <p>안정적인 현금흐름 만들기</p>
      </div>
      <div
        class="round-box"
        :class="{ on: styleType === 'LOWVOL' }"
        @click="styleType = 'LOWVOL'"
      >
        <i class="icon--state safe"></i>
        <p class="type-tit">안정형</p>
        <p>마음 편하게 천천히 가자</p>
      </div>
      <div
        class="round-box"
        :class="{ on: styleType === 'QUALITY' }"
        @click="styleType = 'QUALITY'"
      >
        <i class="icon--state big"></i>
        <p class="type-tit">우량형</p>
        <p>좋은 기업이 좋은 수익 준다</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Step01',
  created() {
    this.$store
      .dispatch('getPortfolioStyled')
      .then(() => {
        const styleType = this.$store.getters.getAddType.styleType
        let portfolioStyledList = this.$store.getters.getPortfolioStyledList
        portfolioStyledList = portfolioStyledList
          .filter((info) => {
            return info.styleType === styleType
          })
          .map((data) => ({
            ...data,
            value: 50,
            isData:
              data.styleSecondaryType === 'ESSENTIAL' ||
              data.styleSecondaryType === 'COMPLEMENT',
            isToggleBox: false
          }))

        this.$store.commit('SET_STEP', {
          list: portfolioStyledList,
          styleType
        })
      })
      .catch()
  },
  computed: {
    styleType: {
      get() {
        return this.$store.getters.getAddType.styleType
      },
      set(styleType) {
        let portfolioStyledList = this.$store.getters.getPortfolioStyledList
        portfolioStyledList = portfolioStyledList
          .filter((info) => {
            return info.styleType === styleType
          })
          .map((data) => {
            let isData = false
            if (
              data.styleSecondaryType === 'ESSENTIAL' ||
              data.styleSecondaryType === 'COMPLEMENT'
            ) {
              isData = true
            }
            return {
              ...data,
              value: 50,
              isData: isData,
              isToggleBox: false
            }
          })
        this.$store.commit('SET_STEP', {
          list: portfolioStyledList,
          styleType
        })
      }
    }
  },
  mounted() {},
  methods: {}
}
</script>
