<template>
  <div class="qna">
    <div class="qna-tab">
      <div
        v-for="(tab, index) in tabList"
        :key="`tab-${index}`"
        :class="{ active: tab.isActive }"
        @click="clickTab(index)"
      >
        {{ tab.text }}
      </div>
    </div>

    <!-- list Component -->
    <template v-if="tab === 0">
      <ul class="qna-list">
        <li
          v-for="d in list"
          :key="d.qnaId"
          class="qna-list-box"
          :class="{ on: toggleList.includes(d.qnaId) }"
        >
          <p class="qna-list-box-date">{{ d.createdDate }}</p>
          <p class="qna-list-box-title">
            {{ d.title }}
          </p>
          <p class="qna-list-box-description">
            {{ d.content }}
          </p>
          <ThumbBox :thumb="d.storageAccesses" />
          <p
            class="qna-list-box-status flex-just"
            :class="{ finish: d.replyStatus }"
          >
            {{ d.replyStatus ? '답변완료' : '미답변' }}
            <i
              v-if="d.replyStatus"
              :class="`icon--arrow-${
                toggleList.includes(d.qnaId) ? 'up' : 'down'
              }`"
              @click="
                toggleList.includes(d.qnaId)
                  ? toggleList.pop(d.qnaId)
                  : toggleList.push(d.qnaId)
              "
            ></i>
          </p>
          <div
            v-if="d.replyStatus && toggleList.includes(d.qnaId)"
            class="qna-list-box-answer"
          >
            <ul class="qna-list-box-answer-box">
              <li class="qna-list-box-answer-box-date flex-start">
                <i class="icon--answer"></i>
                <span>{{ d.replyDate }}</span>
              </li>
              <li class="qna-list-box-answer-box-description">
                {{ d.replyContent }}
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </template>

    <!-- addComponent -->
    <template v-if="tab === 1">
      <div class="qna-add">
        <h2>제목</h2>
        <InputText
          v-model="title"
          @update:value="title = $event"
          :max-length="50"
          placeholder="제목"
        />
        <h2>내용</h2>
        <InputText
          v-model="content"
          @update:value="content = $event"
          :max-length="2000"
          placeholder="내용"
        />
        <input
          type="file"
          name="file"
          id="file-element"
          @change="uploadFile($event.target.files)"
          ref="files"
          hidden
          multiple
        />
        <ul class="photo-add-list" style="margin-top: 64px">
          <li class="photo-add-btn" @click="openUpload">
            <i class="icon--photo-b"></i><span>사진추가</span>
          </li>
          <li
            v-for="(d, i) in images"
            :key="i + 'photo'"
            class="choose-user-photo"
          >
            <img
              :src="d.accessPath"
              alt=""
              style="max-width: 100%; object-fit: contain"
            />
            <i class="icon--delete" @click="deleteImage(i)"></i>
          </li>
        </ul>
        <span class="list-desc"
          >*5MB 미만 jpg, jpeg, png, heic 만 등록 가능</span
        >
        <button
          class="base-btn cl-pri full static-height margin-top-10p"
          @click="saveQna"
        >
          <span>저장하기</span>
        </button>
      </div>
    </template>
  </div>
</template>

<script>
import { mypageQna } from '@/data/data'
import ThumbBox from '@/components/common/ThumbBox.vue'
import InputText from '@/components/base/InputText.vue'
import { isNumber } from 'chart.js/helpers'
import { axiosAuthInstance } from '@/store/axios'

export default {
  data() {
    return {
      mypageQna,
      list: [],
      tabList: [
        { id: 0, text: '문의 내역', isActive: true },
        { id: 1, text: '문의 하기', isActive: false }
      ],
      tab: 0,
      toggleList: [],
      toggleImage: false,
      title: '',
      content: '',
      images: []
    }
  },
  components: { ThumbBox, InputText },
  created() {
    this.getQnas()
  },
  methods: {
    getQnas() {
      axiosAuthInstance
        .get('/qna')
        .then((response) => {
          this.list = response
          console.log(response)
          console.log(this.list)

          for (let data of this.list) {
            console.log(data)
            data.storageAccesses = [data.storageAccess]
          }
          console.log(this.list)
        })
        .catch((error) => {
          console.log('error', error)
        })
    },
    deleteImage(index) {
      console.log(index)
      let changeImages = []
      this.images.forEach((value, imageIndex) => {
        console.log(value, imageIndex)
        if (index !== imageIndex) changeImages.push(value)
      })
      this.images = changeImages
    },
    saveQna() {
      console.log('saveQna')
      let parameters = {
        title: this.title,
        content: this.content,
        storageHash: this.images[0].storageHash,
        storageAccess: this.images[0].accessPath
      }
      console.log(this.title)
      console.log(this.content)
      console.log(parameters)
      axiosAuthInstance
        .post('/qna', JSON.stringify(parameters), {
          headers: {
            'Content-Type': `application/json`
          }
        })
        .then((response) => {
          console.log('response', response)
          let obj = {
            title: '등록되었습니다',
            message: '응답까지 5영업일 이상 소요될 수 있습니다.',
            type: 'info',
            useConfirmBtn: false
          }
          this.$Simplert.open(obj)
          this.clickTab(0)
        })
        .catch((error) => {
          console.log('error', error)
        })
    },
    uploadFile(targetFile) {
      console.log('targetFile', targetFile)
      for (const index in targetFile) {
        console.log('file => ', index)
        if (!isNumber(index)) {
          break
        }
        console.log(index)
        const formData = new FormData()
        formData.append('file', targetFile[index])
        axiosAuthInstance
          .post('/file', formData, {
            header: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then((response) => {
            console.log(response)
            this.images = [
              {
                storageHash: response.storageHash,
                accessPath: response.accessPath
              }
            ]
          })
      }
    },
    openUpload() {
      document.getElementById('file-element').click()
    },
    clickTab(idx) {
      this.tabList = this.tabList.map((el) => {
        return el.id === idx
          ? {
              ...el,
              isActive: true
            }
          : {
              ...el,
              isActive: false
            }
      })
      this.tab = idx
    }
  }
}
</script>

<style lang="scss" scoped>
.list-desc {
  color: #bfbfbf;
  font-size: 12px;
  font-weight: 400;
}
.margin-top-10p {
  margin-top: 10%;
}
.qna {
  * {
    line-height: 140%;
  }
  .qna-list-box {
    border-bottom: 1px solid #e8e8e8;
    .qna-list-box-title {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .qna-list-box-description {
      display: none;
    }
    &.on {
      .qna-list-box-title {
        -webkit-line-clamp: unset;
      }
      .qna-list-box-description {
        display: block;
      }
      position: relative;
      &::before {
        content: '';
        position: absolute;
        display: inline-block;
        z-index: -1;
        width: 100vw;
        height: 100%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background: #f7f7f7;
      }
    }
  }
  .qna-list-box-img {
    display: flex;
    > div {
      width: 50%;
      > div {
        display: block;
        width: 100%;
        padding-top: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        .more-no {
          position: absolute;
          font-size: 30px;
          font-weight: 700;
          top: 50%;
          left: 50%;
          color: #fff;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
  width: 100%;
  padding: 0 16px;

  &-tab {
    display: flex;
    align-items: center;
    width: 100%;
    text-align: center;
    div {
      width: 100%;
      height: 48px;
      line-height: 48px;
      letter-spacing: -0.32px;
      color: #aaa;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: -0.32px;
    }
    .active {
      border-bottom: 1px solid #000;
      color: #222;
    }
  }

  // 리스트 컴포넌트
  .qna-list {
    padding-top: 28px;
    &-box {
      padding: 20px 0;
      &-date {
        color: #aaa;
        text-align: left;
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 12px;
        font-weight: 400;
        line-height: 100%; /* 12px */
        letter-spacing: -0.24px;
        margin-bottom: 4px;
      }
      &-title {
        color: #222;
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 14px;
        font-weight: 500;
        line-height: 140%; /* 19.6px */
        letter-spacing: -0.28px;
        margin-bottom: 4px;
      }
      &-description {
        color: #666;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Pretendard;
        font-size: 14px;
        font-weight: 400;
        line-height: 140%; /* 19.6px */
        letter-spacing: -0.28px;
      }

      &-img {
        margin-top: 12px;
        width: 100%;
        min-height: 164px;
        max-height: 164px;
        background-color: #000;
        border-radius: 6px;
        overflow: hidden;
      }
      &-status {
        color: #d74343;
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: -0.24px;
        margin-top: 18px;
      }
      &-status.finish {
        color: #4a4ee7;
      }
      &-answer {
        &-box {
          padding-left: 14px;
          border-left: 2px solid #4a4ee7;
          &-date {
            display: flex;
            align-items: center;
            span {
              color: #aaa;
              font-feature-settings: 'clig' off, 'liga' off;
              font-size: 12px;
              font-weight: 400;
              letter-spacing: -0.24px;
              margin-left: 8px;
            }
            margin-bottom: 8px;
          }
        }
      }
    }
  }
  .qna-list-box-answer {
    padding: 24px 0;
  }

  // 문의 추가 컴포넌트
  .qna-add {
    padding-top: 48px;
    &-input {
      width: 100%;
      padding-bottom: 6px;
      border-bottom: 2px solid #f1f1f1;
      margin-bottom: 8px;
      font-size: 20px;
    }
    &-input::placeholder {
      color: #bfbfbf;
    }
    h2 {
      color: #999;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: -0.24px;
      margin-bottom: 6px;
    }
    textarea {
      font-size: 20px;
      font-weight: 400;
      line-height: 28px; /* 28px */
    }
    &-title-length,
    &-description-length {
      text-align: right;
      margin-bottom: 30px;
      color: #bfbfbf;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: -0.24px;
    }
    &-photo-list {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      justify-content: space-around;
      margin-bottom: 120px;
      grid-gap: 8px;
      li {
        width: 20.54054054vw;
        height: 20.54054054vw;
        background-color: lightgreen;
        position: relative;
        // margin: 0 auto 8px;
      }
      li:after {
        content: 'x';
        color: #fff;
        text-align: center;
        line-height: 18px;
        display: block;
        position: absolute;
        top: 2px;
        right: 2px;
        width: 18px;
        height: 18px;
        background-color: #000;
        border-radius: 50%;
      }
    }
  }

  // 모달 컴포넌트
  .modal {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100vw;
    min-height: calc(var(--vh, 1vh) * 100);
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 11;
    .navigator {
      width: 100%;
      height: 48px;
      color: #fff;
      font-size: 16px;
      font-weight: bold;
    }
    .photo-area {
      width: 100%;
      figure {
        margin: 116px auto 0;
        display: block;
        max-width: 328px;
        min-height: 328px;
        border-radius: 14px;
        background: #d9d9d9;
        overflow: hidden;
        margin-bottom: 24px;
      }
      .dot-list {
        margin-top: 24px;
        display: flex;
        justify-content: center;
        gap: 6px;

        &-item {
          min-width: 6px;
          min-height: 6px;
          border-radius: 50%;
          background-color: rgba(0, 0, 0, 0.4);
        }
        &-item.active {
          width: 16px;
          border-radius: 7px;
          background-color: #fff;
        }
      }
    }
  }
  .photo-add-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 8px;
    margin-bottom: 10px;

    > li {
      width: 72px;
      height: 72px;
      border-radius: 8px;
      flex-shrink: 0;
      border: 1px solid #e8e8e8;
      overflow: hidden;
    }
    .photo-add-btn {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .choose-user-photo {
      position: relative;
      > i {
        position: absolute;
        top: 2px;
        right: 2px;
      }
    }
    span {
      margin-top: -8px;
      color: #999;
      font-size: 12px;
    }
  }
}
</style>
