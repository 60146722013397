var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "white-layer", attrs: { id: "join-wrap" } },
    [
      _vm.isProfile
        ? _c("SlideUpModal", {
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "flex-just" }, [
                        _c("h4", [_vm._v("프로필 이미지를 선택해주세요")]),
                        _c("i", {
                          staticClass: "icon--close",
                          on: { click: _vm.closeSlider },
                        }),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "main",
                  fn: function () {
                    return [
                      _c("ul", { staticClass: "send-modal-body" }, [
                        _c(
                          "li",
                          {
                            class: { on: _vm.tempProfileId === "m1" },
                            on: {
                              click: function ($event) {
                                return _vm.setProfile("m1")
                              },
                            },
                          },
                          [_c("i", { staticClass: "icon--char m1" })]
                        ),
                        _c(
                          "li",
                          {
                            class: { on: _vm.tempProfileId === "m2" },
                            on: {
                              click: function ($event) {
                                return _vm.setProfile("m2")
                              },
                            },
                          },
                          [_c("i", { staticClass: "icon--char m2" })]
                        ),
                        _c(
                          "li",
                          {
                            class: { on: _vm.tempProfileId === "m3" },
                            on: {
                              click: function ($event) {
                                return _vm.setProfile("m3")
                              },
                            },
                          },
                          [_c("i", { staticClass: "icon--char m3" })]
                        ),
                        _c(
                          "li",
                          {
                            class: { on: _vm.tempProfileId === "m4" },
                            on: {
                              click: function ($event) {
                                return _vm.setProfile("m4")
                              },
                            },
                          },
                          [_c("i", { staticClass: "icon--char m4" })]
                        ),
                        _c(
                          "li",
                          {
                            class: { on: _vm.tempProfileId === "w1" },
                            on: {
                              click: function ($event) {
                                return _vm.setProfile("w1")
                              },
                            },
                          },
                          [_c("i", { staticClass: "icon--char w1" })]
                        ),
                        _c(
                          "li",
                          {
                            class: { on: _vm.tempProfileId === "w2" },
                            on: {
                              click: function ($event) {
                                return _vm.setProfile("w2")
                              },
                            },
                          },
                          [_c("i", { staticClass: "icon--char w2" })]
                        ),
                        _c(
                          "li",
                          {
                            class: { on: _vm.tempProfileId === "w3" },
                            on: {
                              click: function ($event) {
                                return _vm.setProfile("w3")
                              },
                            },
                          },
                          [_c("i", { staticClass: "icon--char w3" })]
                        ),
                        _c(
                          "li",
                          {
                            class: { on: _vm.tempProfileId === "w4" },
                            on: {
                              click: function ($event) {
                                return _vm.setProfile("w4")
                              },
                            },
                          },
                          [_c("i", { staticClass: "icon--char w4" })]
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "button",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "modal-btn-wrap" }, [
                        _c(
                          "button",
                          {
                            staticClass: "base-btn harf cl-sec",
                            on: { click: _vm.closeSlider },
                          },
                          [_c("span", [_vm._v("취소")])]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "base-btn harf cl-pri",
                            on: { click: _vm.chooseProfile },
                          },
                          [_c("span", [_vm._v("선택")])]
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              1955058076
            ),
          })
        : _vm._e(),
      _vm._m(0),
      _c("div", { staticClass: "round-box" }, [
        _c("i", {
          staticClass: "icon--add-prof icon--char",
          class: _vm.profileId,
          on: { click: _vm.profileShow },
        }),
        _c("p", { staticClass: "input-tilte" }, [_vm._v("닉네임")]),
        _c("div", { staticClass: "round-input" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.nickName,
                expression: "nickName",
              },
            ],
            attrs: {
              type: "text",
              placeholder: "한글, 영문, 숫자, 조합 6~12자",
              maxlength: "12",
              minlength: "6",
            },
            domProps: { value: _vm.nickName },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.nickName = $event.target.value
              },
            },
          }),
          _c("i", { staticClass: "icon--random" }),
          _vm.isValid
            ? _c("p", { staticClass: "valid-txt error" }, [
                _vm._v(_vm._s(_vm.validTxt)),
              ])
            : _vm._e(),
        ]),
      ]),
      _c(
        "button",
        {
          staticClass: "base-btn cl-pri full static-height",
          on: { click: _vm.goUserJoin },
        },
        [_c("span", { staticClass: "txt-size--m" }, [_vm._v("시작하기")])]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "join-head" }, [
      _c("i", { staticClass: "icon--logo" }),
      _c("h4", [
        _vm._v("퀀트마켓 회원이 되신것을"),
        _c("br"),
        _vm._v("환영합니다!"),
      ]),
      _c("p", [_vm._v("사용하실 닉네임과 프로필 이미지를 선택해주세요")]),
      _c("p", [_vm._v("(MY페이지에서 다시 수정이 가능합니다.)")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }