<template>
  <button class="oauth-btn" :class="is" @click="useClick">
    <span :class="type === 'naver' ? 'cl-white' : ''">{{ text }}</span>
  </button>
</template>

<script>
export default {
  name: 'PageNotFound',
  props: {
    type: {
      type: String,
      default: 'kakao'
    },
    text: {
      type: String,
      default: 'kakao'
    }
  },
  computed: {
    is() {
      return this.$props.type
    }
  },
  methods: {
    useClick() {
      this.$emit('useClick', this.type)
    }
  }
}
</script>

<style lang="scss" scoped>
#login-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
