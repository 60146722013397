// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#add-products-wrap .go-detail-btn[data-v-3ac408ef] {
  display: inline-flex;
  margin: 16px 0 32px;
  align-items: center;
  justify-content: center;
  width: 83px;
  height: 28px;
  border-radius: 6px;
  gap: 3px;
  background: #f5f5ff;
}
#add-products-wrap .round-wrap-box[data-v-3ac408ef] {
  border-radius: 14px;
  border: 1px solid #e8e8e8;
  padding: 32px 16px;
}
#add-products-wrap .req-btn[data-v-3ac408ef] {
  margin-top: 170px;
}
#add-products-wrap .full-modal-head[data-v-3ac408ef] {
  padding-bottom: 16px;
}
#add-products-wrap .full-modal-body ul[data-v-3ac408ef] {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
#add-products-wrap .full-modal-body ul li[data-v-3ac408ef] {
  display: flex;
  gap: 10px;
}
#add-products-wrap .full-modal-body ul li .l-box span[data-v-3ac408ef] {
  padding: 8px 12px;
  font-weight: 500;
  border-radius: 50px;
  background: #feea61;
}
#add-products-wrap .full-modal-body ul li .r-box .title[data-v-3ac408ef] {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}
#add-products-wrap .full-modal-body ul li .r-box .desc[data-v-3ac408ef] {
  color: #666;
  line-height: 160%; /* 22.4px */
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
