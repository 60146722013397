// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.thumb-box[data-v-d289050e] {
  display: flex;
  border-radius: 6px;
  overflow: hidden;
}
.thumb-box > div[data-v-d289050e] {
  width: 50%;
}
.thumb-box > div > div[data-v-d289050e] {
  display: block;
  width: 100%;
  padding-top: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}
.thumb-box > div > div .more-no[data-v-d289050e] {
  position: absolute;
  font-size: 30px;
  font-weight: 700;
  top: 50%;
  left: 50%;
  color: #fff;
  transform: translate(-50%, -50%);
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
