var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "step01" }, [
    _vm._m(0),
    _c("div", { staticClass: "stage01-type-list" }, [
      _c(
        "div",
        {
          staticClass: "round-box",
          class: { on: _vm.styleType === "GROWTH" },
          on: {
            click: function ($event) {
              _vm.styleType = "GROWTH"
            },
          },
        },
        [
          _c("i", { staticClass: "icon--state exp" }),
          _c("p", { staticClass: "type-tit" }, [_vm._v("성장형")]),
          _c("p", [_vm._v("아가야, 무럭무럭 자라라")]),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "round-box",
          class: { on: _vm.styleType === "VALUE" },
          on: {
            click: function ($event) {
              _vm.styleType = "VALUE"
            },
          },
        },
        [
          _c("i", { staticClass: "icon--state val" }),
          _c("p", { staticClass: "type-tit" }, [_vm._v("가치형")]),
          _c("p", [_vm._v("저평가된 흙속의 진주찾기")]),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "round-box",
          class: { on: _vm.styleType === "MOMENTUM" },
          on: {
            click: function ($event) {
              _vm.styleType = "MOMENTUM"
            },
          },
        },
        [
          _c("i", { staticClass: "icon--state moe" }),
          _c("p", { staticClass: "type-tit" }, [_vm._v("모멘텀")]),
          _c("p", [_vm._v("달리는 말에 올라타기")]),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "round-box",
          class: { on: _vm.styleType === "DIVIDEND" },
          on: {
            click: function ($event) {
              _vm.styleType = "DIVIDEND"
            },
          },
        },
        [
          _c("i", { staticClass: "icon--state money" }),
          _c("p", { staticClass: "type-tit" }, [_vm._v("배당형")]),
          _c("p", [_vm._v("안정적인 현금흐름 만들기")]),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "round-box",
          class: { on: _vm.styleType === "LOWVOL" },
          on: {
            click: function ($event) {
              _vm.styleType = "LOWVOL"
            },
          },
        },
        [
          _c("i", { staticClass: "icon--state safe" }),
          _c("p", { staticClass: "type-tit" }, [_vm._v("안정형")]),
          _c("p", [_vm._v("마음 편하게 천천히 가자")]),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "round-box",
          class: { on: _vm.styleType === "QUALITY" },
          on: {
            click: function ($event) {
              _vm.styleType = "QUALITY"
            },
          },
        },
        [
          _c("i", { staticClass: "icon--state big" }),
          _c("p", { staticClass: "type-tit" }, [_vm._v("우량형")]),
          _c("p", [_vm._v("좋은 기업이 좋은 수익 준다")]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "page-title-section mb-24" }, [
      _c("h4", [
        _vm._v(" 6가지 핵심전략 중 "),
        _c("br"),
        _c("strong", [_vm._v("한 가지")]),
        _vm._v("를 고르세요 "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }