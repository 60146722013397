<template>
  <label class="switch">
    <input v-model="localCheck" type="checkbox" @click="toggleCheckbox" />
    <div class="slider">
      <span :class="{ 'check-text': isOn }">{{ list[0] }}</span>
      <span :class="{ 'check-text': !isOn }">{{ list[1] }}</span>
    </div>
  </label>
</template>

<script>
export default {
  name: 'QuantMarketToggleSwitch',
  props: {
    list: {
      type: Array,
      default: () => {
        return ['체크', '미체크']
      }
    },
    isOn: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localCheck: true
    }
  },
  mounted() {
    this.localCheck = this.$props.isOn
  },

  methods: {
    toggleCheckbox() {
      this.$emit('setCheckboxVal', this.localCheck)
    }
  }
}
</script>

<style lang="scss" scoped>
.switch {
  position: relative;
  display: block;
  width: 100%;
  height: 44px;
  padding: 4px;
  background-color: #f1f1f1;

  border-radius: 50px;
  input {
    display: none;
    &:checked + .slider:before {
      left: 50%;
    }
  }
  .slider {
    display: flex;

    position: absolute;
    cursor: pointer;
    top: 4px;
    left: 4px;
    right: 4px;
    bottom: 4px;

    -webkit-transition: 0.4s;
    transition: 0.4s;
    span {
      flex: 1;
      z-index: 9999;
      display: flex;
      justify-content: center;
      align-items: center;

      font-size: 14px;
      &.check-text {
        color: #222;
      }
    }
    &:before {
      position: absolute;
      content: '';
      height: 100%;
      width: 50%;
      top: 0;
      left: 0;
      border-radius: 50px;
      box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  }
}
</style>
