import { render, staticRenderFns } from "./ThumbBox.vue?vue&type=template&id=d289050e&scoped=true"
import script from "./ThumbBox.vue?vue&type=script&lang=js"
export * from "./ThumbBox.vue?vue&type=script&lang=js"
import style0 from "./ThumbBox.vue?vue&type=style&index=0&id=d289050e&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d289050e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/smyu/Digital_Nomad/workspace/qmarket-official/client-front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d289050e')) {
      api.createRecord('d289050e', component.options)
    } else {
      api.reload('d289050e', component.options)
    }
    module.hot.accept("./ThumbBox.vue?vue&type=template&id=d289050e&scoped=true", function () {
      api.rerender('d289050e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/common/ThumbBox.vue"
export default component.exports