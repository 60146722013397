// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.comm-regist-modal[data-v-54c835f0] .modal-cont-wrap {
  display: flex;
  flex-direction: column;
  height: calc(var(--vh, 1vh) * 100);
}
.input-list[data-v-54c835f0] {
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 32px;
  margin-bottom: 32px;
}
.photo-add-list[data-v-54c835f0] {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 8px;
  margin-bottom: 10px;
}
.photo-add-list > li[data-v-54c835f0] {
  width: 72px;
  height: 72px;
  border-radius: 8px;
  flex-shrink: 0;
  border: 1px solid #e8e8e8;
  overflow: hidden;
}
.photo-add-list .photo-add-btn[data-v-54c835f0] {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.photo-add-list .choose-user-photo[data-v-54c835f0] {
  position: relative;
}
.photo-add-list .choose-user-photo > i[data-v-54c835f0] {
  position: absolute;
  top: 2px;
  right: 2px;
}
.photo-add-list span[data-v-54c835f0] {
  margin-top: -8px;
  color: #999;
  font-size: 12px;
}
.list-desc[data-v-54c835f0] {
  color: #bfbfbf;
  font-size: 12px;
  font-weight: 400;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
