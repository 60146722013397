// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rounge-card-view[data-v-0ee39508] {
  padding: 24px 20px;
  width: 287px;
  height: 184px;
  border-radius: 14px;
  background: #fff;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.14);
}
.rounge-card-view .top-box[data-v-0ee39508] {
  display: flex;
  margin-bottom: 12px;
}
.rounge-card-view .top-box .l-box[data-v-0ee39508] {
  flex: 1;
  display: flex;
  gap: 12px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
.rounge-card-view .top-box .l-box .rounge-type[data-v-0ee39508] {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  height: 28px;
  border-radius: 50px;
  border: 1px solid #4a4ee7;
  color: #4a4ee7;
  font-size: 12px;
}
.rounge-card-view .top-box .l-box .rounge-profil[data-v-0ee39508] {
  display: flex;
  align-items: center;
  gap: 8px;
}
.rounge-card-view .top-box .l-box .rounge-profil .prof-thumb[data-v-0ee39508] {
  width: 28px;
  height: 28px;
}
.rounge-card-view .top-box .l-box .rounge-profil .prof-thumb > img[data-v-0ee39508] {
  width: 100%;
  height: 100%;
}
.rounge-card-view .top-box .l-box .rounge-profil .profil-name[data-v-0ee39508] {
  color: #666;
}
.rounge-card-view .top-box .r-box[data-v-0ee39508] {
  width: 68px;
  height: 68px;
  background-color: lightblue;
  border-radius: 6px;
  overflow: hidden;
  flex-shrink: 0;
}
.rounge-card-view .top-box .r-box .lounge-img[data-v-0ee39508] {
  width: 100%;
}
.rounge-card-view .bot-box p[data-v-0ee39508] {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-size: 14px;
  font-weight: 400;
  color: #999;
  line-height: 140%;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
