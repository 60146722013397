// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/images/icon_arrow_next.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.round-desc-box[data-v-b4d027c4] {
  border-radius: 14px;
  background: #f7f7f7;
  margin-bottom: 16px;
  padding: 16px 20px;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: 14px 20px;
  background-position: calc(100% - 16px) 50%;
  background-repeat: no-repeat;
}
.round-desc-box .puple-txt[data-v-b4d027c4] {
  color: #4a4ee7;
  font-weight: 600;
}
.quant-swiper-box[data-v-b4d027c4] {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.quant-swiper-box .quant-slide-item[data-v-b4d027c4] {
  width: 100%;
  height: 100%;
  background-color: grey;
  position: relative;
}
.quant-swiper-box .quant-slide-item img[data-v-b4d027c4] {
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  width: 100%;
  height: 100%;
}
.quant-swiper-box .quant-slide-item .title-box[data-v-b4d027c4] {
  position: absolute;
  max-width: 50%;
  bottom: 24px;
  left: 24px;
  z-index: 99;
}
.quant-swiper-box .quant-slide-item .title-box *[data-v-b4d027c4] {
  color: #fff;
}
.quant-swiper-box[data-v-b4d027c4] .swiper-container {
  height: 100%;
  position: relative;
}
.quant-swiper-box[data-v-b4d027c4] .swiper-container .swiper-pagination {
  position: absolute;
  bottom: 20px;
  left: calc(100% - 34px);
  transform: translateX(-50%);
  display: inline-flex;
  padding: 4px 6px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  width: 32px;
}
.quant-swiper-box[data-v-b4d027c4] .swiper-container .swiper-pagination span {
  color: #fff;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
