// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.service ul[data-v-7af0f458] {
  width: 100%;
}
.service-item[data-v-7af0f458] {
  padding: 0 4.4444444%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 54px;
  color: #666;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
  letter-spacing: -0.32px;
}
.service-item .service-version[data-v-7af0f458] {
  color: #999;
  text-align: right;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.32px;
}
.service-item[data-v-7af0f458]:nth-child(3), .service-item[data-v-7af0f458]:nth-child(4), .service-item[data-v-7af0f458]:nth-child(5) {
  height: 66px;
  border-bottom: 12px solid #f1f1f1;
}
.service line[data-v-7af0f458] {
  width: 100%;
  height: 12px;
  background-color: #f1f1f1;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
